import { Text } from 'native-base'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Platform } from 'react-native'
import DefaultModal from '../components/modals/DefaultModal'
import ProgressModal from '../components/_shared/ProgressModal'
import getActivityModalDetails from '../constants/activityModalDetails'
import useCalendar from '../hooks/useCalendar'
import useTabNavigation from '../hooks/useTabNavigation'

const ModalContext = createContext(undefined)

export const useModal = () => useContext(ModalContext)

export const ModalProvider = ({
  children,
  activity,
  handleParentClose = () => {},
}) => {
  const [actionModalShown, setActionModalShown] = useState(false)
  const [actionModalTotallyHidden, setActionModalTotallyHidden] = useState(true)
  const { navigate } = useTabNavigation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onSuccessCallback = useCallback(() => {
    setTimeout(() => {
      setIsSubmitting(true)
    }, 400)
  }, [])
  const { addEvent } = useCalendar(activity.id, onSuccessCallback)

  const addEventCallback = useCallback(() => {
    addEvent(activity.title, activity.startDate, activity.endDate)
  }, [activity])

  const [modalInfo, setModalInfo] = useState(
    getActivityModalDetails(
      activity.type,
      navigate,
      addEventCallback,
      handleParentClose
    )[activity.registration] ?? {
      title: 'Not mapped yet',
      description: 'A modal will be created later',
      buttonText: 'Go back',
    }
  )

  const updateModalInfo = useCallback(() => {
    setModalInfo(
      getActivityModalDetails(
        activity.type,
        navigate,
        addEventCallback,
        handleParentClose
      )[activity.registration] ?? {
        title: 'Not mapped yet',
        description: 'A modal will be created later',
        buttonText: 'Go back',
        action: () => {
          navigate('Explore')
        },
      }
    )
  }, [activity])

  useEffect(() => {
    if (!actionModalTotallyHidden) return
    updateModalInfo()
  }, [activity, actionModalTotallyHidden])

  const openModal = useCallback(() => {
    //TODO Not opening in iOS without timeout
    setActionModalTotallyHidden(false)
    if (Platform.OS === 'ios')
      setTimeout(() => {
        setActionModalShown(true)
      }, 300)
    else {
      setActionModalShown(true)
    }
  }, [setActionModalShown, setActionModalTotallyHidden])

  return (
    <>
      <ModalContext.Provider value={{ openModal }}>
        <DefaultModal
          activity={activity}
          show={actionModalShown}
          action={() => {
            modalInfo.action()
          }}
          modalInfo={modalInfo}
          closeModal={() => {
            setActionModalShown(false)
            //Should work with onModalHide, but it doesn't, the default timeout for the modal to hide totally is 300ms
            setTimeout(async () => setActionModalTotallyHidden(true), 300)
          }}
        />
        <ProgressModal
          downloadProgress={100}
          isVisible={isSubmitting}
          successMessage={<Text>Live group added to calendar!</Text>}
          animationDuration={1000}
          position={{
            bottom: 100,
          }}
          callback={() => {
            setTimeout(() => {
              setIsSubmitting(false)
            }, 500)
          }}
        />
        {children}
      </ModalContext.Provider>
    </>
  )
}
