import { Button, Flex, HStack, ScrollView, VStack } from 'native-base'
import { useState } from 'react'
import { Platform } from 'react-native'
import BackButton from '../../navigation/BackButton'
import FaIcon from '../FaIcon'

const Stepper = ({
  steps,
  stepConditions,
  onCompleteSteps = [],
  openStepOnStart = false,
}) => {
  const getNextIndex = () => {
    const parsedConditions = stepConditions.map((item) => {
      if (item) return true
      return false
    })
    const index = parsedConditions.indexOf(false)
    return index != -1 ? index : 0
  }
  const [openIndex, setOpenIndex] = useState(
    !openStepOnStart ? 0 : getNextIndex()
  )

  const goNext = async () => {
    try {
      if (onCompleteSteps[openIndex]) await onCompleteSteps[openIndex]()
      setOpenIndex((value) => value + 1)
    } catch (e) {
      console.debug(e)
    }
  }

  const goBack = () => {
    if (openIndex > 0) setOpenIndex((value) => value - 1)
  }

  return (
    <VStack h="100%">
      <BackButton
        hasText={false}
        isDisabled={openIndex === 0}
        onPress={goBack}
        style={{ marginTop: -20 }}
      />
      <HStack
        space="2"
        w="100%"
        maxW="280px"
        mx="auto"
        my="3"
        pr={Platform.OS === 'ios' ? '0' : '32px'}>
        {steps.map((step, index) => (
          <HStack key={index} flex={index < steps.length - 1 ? 1 : 0} space="2">
            <Button
              colorScheme="secondary"
              borderRadius="full"
              size="sm"
              w="32px"
              h="32px"
              p="1"
              variant={index > openIndex ? 'outline' : 'solid'}
              isDisabled={index > openIndex}
              onPress={() => setOpenIndex(index)}>
              {index < openIndex ? (
                <FaIcon name="check" size={16} color="white" />
              ) : (
                index + 1
              )}
            </Button>
            {index < steps.length - 1 && (
              <Flex flex="1" alignSelf="center" h="2px" bg="#C3EBE7" />
            )}
          </HStack>
        ))}
      </HStack>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        keyboardShouldPersistTaps="handled">
        {steps[openIndex]}
      </ScrollView>
      <Button
        m={Platform.OS === 'ios' ? '5' : '0'}
        isDisabled={!stepConditions[openIndex]}
        onPress={goNext}>
        Next
      </Button>
    </VStack>
  )
}

export default Stepper
