import { Box, HStack, Select, Spinner, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'

import { Camera, NO_CAMERA } from '../../../../domain/models/interfaces/camera'
import { useCamerasContext } from '../../providers/CameraProvider'
import FaIcon from '../_shared/FaIcon'

const CameraPicker = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { cameras, refresh, selectCamera, selectedCamera } = useCamerasContext()

  const handleSelect = (selectedName: string) => {
    const selectedCamera = cameras.find(
      (camera) => camera.name === selectedName
    )
    if (selectedCamera) selectCamera(selectedCamera)
  }

  const doRefresh = () => {
    setLoading(true)
    refresh().finally(() => setLoading(false))
  }

  const picker = (
    <VStack space={4}>
      <HStack alignItems="center" justifyContent="space-between">
        <Select
          selectedValue={selectedCamera?.name || ''}
          accessibilityLabel="Select Camera"
          placeholder="Select Camera"
          size="lg"
          onValueChange={handleSelect}>
          {cameras.map((cam) => (
            <Select.Item key={cam.name} label={cam.name} value={cam.name} />
          ))}
        </Select>
        <FaIcon name="rotate" onPress={() => doRefresh()} />
      </HStack>
    </VStack>
  )

  const spinner = (
    <HStack space={4} height={8} alignItems={'center'}>
      <Spinner />
      <Text>Loading cameras...</Text>
    </HStack>
  )

  return loading ? spinner : picker
}

export default CameraPicker
