import { Image, Text, VStack, ZStack } from 'native-base'
import { useState } from 'react'
import AudioPlayer from './AudioPlayer'

const AudioDesktopHeader = ({ activity }) => {
  const [height, setHeight] = useState(0)

  return (
    <ZStack
      alignItems="center"
      height="100%"
      width={'100%'}
      onLayout={(event) => setHeight(event.nativeEvent.layout.height)}>
      <Image
        source={require('./../../../assets/images/live-groups/violetBackgroundWeb.png')}
        height="100%"
        width="100%"
        alt="Background waves"
      />
      <VStack>
        <Text fontSize={24} fontWeight="bold" color="white" mt={height / 10}>
          {activity?.title}
        </Text>
        <AudioPlayer activity={activity} customHeight={height} />
      </VStack>
    </ZStack>
  )
}

export default AudioDesktopHeader
