import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { LinearGradient } from 'expo-linear-gradient'
import {
  Box,
  Button,
  HStack,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import Carousel from 'react-native-reanimated-carousel'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useRenderFocusAreaIcon } from '../../hooks/useRenderFocusAreasIcon'
import { useCompleteStepMutation } from '../../queries/voyageQueries'
import { NavigationParams } from '../../screens/NavigationScreen'
import BackButton from '../navigation/BackButton'
import ActivityCard from '../_shared/card/ActivityCard'
import FaIcon from '../_shared/FaIcon'
import Loader from '../_shared/Loader'
import FocusAreaHeading from './FocusAreaHeading'

const CarouselPagination = ({ data, activeSlide }) => {
  return (
    <HStack justifyContent="center" mt={2}>
      {data.map((_, i) => {
        let backgroundColor = i === activeSlide ? 'primary.700' : 'muted.400'
        return (
          <HStack
            key={i}
            width={2}
            height={2}
            borderRadius="full"
            backgroundColor={backgroundColor}
            margin={1}
          />
        )
      })}
    </HStack>
  )
}

const ThumbsIcon = ({
  iconName,
  selectedIcon,
  setSelectedIcon,
  shadow,
  handleSlide,
}) => {
  const isSelected = selectedIcon === iconName
  const isUp = iconName === 'up'
  const color = isSelected ? (isUp ? 'white' : 'error.500') : 'muted.500'
  const backgroundColor = isSelected && isUp ? 'success.500' : 'white'
  const borderWidth = selectedIcon === null || (isSelected && !isUp) ? 1 : 0
  const borderColor =
    selectedIcon === null || (isSelected && !isUp) ? 'muted.200' : 'white'

  return (
    <Pressable
      onPress={() => {
        setSelectedIcon(iconName)
        handleSlide()
      }}
      shadow={shadow}
      backgroundColor={backgroundColor}
      borderWidth={borderWidth}
      borderColor={borderColor}
      borderRadius="full"
      justifyContent="center"
      alignItems="center"
      display="flex"
      width="64px"
      height="64px">
      <FaIcon
        name={`thumbs-${iconName}`}
        size={24}
        color={color}
        isSolid={isSelected}
      />
    </Pressable>
  )
}

const Completed = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { width } = useWindowDimensions()
  const isDesktop = useDesktopBreakpoint()
  const { data } = useCompleteStepMutation()
  const carouselRef = useRef(null)
  const { icon, color, bg } = useRenderFocusAreaIcon(
    (data as any)?.nextPath?.focusAreaName
  )
  const hasNextStep = (data as { nextStep: any }).nextStep !== null
  const hasNextPath = (data as { nextPath: any }).nextPath !== null
  const finished = !hasNextStep && !hasNextPath

  const [activeSlide, setActiveSlide] = useState(0)
  const [selectedIcon, setSelectedIcon] = useState(null)

  const boxWidth = width / 3 - 12
  const carouselWidth = width - 30

  const goToActivity = (data) => {
    if (data.nextStep.activity.id === 'COMPASS') {
      navigation.navigate('Compass')
    } else {
      navigation.navigate('Activity', {
        activityId: data.nextStep.activity.id,
        stepId: data.nextStep.activity.stepId,
      })
    }
  }

  useEffect(() => {
    if (hasNextPath && data) {
      analytics.track('Path Completed', {
        title: data.nextPath.name,
        focusArea: data.nextPath.focusAreaName,
      })
    }
  }, [data])

  return (
    <>
      {!isDesktop ? (
        <HStack
          width="100%"
          alignItems="flex-end"
          justifyContent="space-between"
          height={98}
          backgroundColor="white"
          borderBottomWidth={1}
          px={6}
          shadow={0.5}
          borderBottomColor="muted.50"
          pb={2}>
          <BackButton customText="" />
          <Text color="primary.800" fontSize="20px" fontWeight={500}>
            Complete!
          </Text>
          <Box width={boxWidth} />
        </HStack>
      ) : (
        <Box>
          <BackButton customText="" />
        </Box>
      )}
      {data !== null && (
        <ScrollView
          borderRadius={isDesktop ? '20px' : 0}
          my={isDesktop ? 2 : 0}
          borderColor={isDesktop ? 'grey.200' : 'transparent'}
          borderWidth={isDesktop ? 1 : 0}
          backgroundColor="white"
          pt={6}
          p={4}
          showsVerticalScrollIndicator={false}>
          <Box
            alignSelf="center"
            width={'307px'}
            height={!isDesktop && '240px'}>
            <Loader source="completed" />
          </Box>
          <Text
            textAlign="center"
            color="primary.900"
            fontWeight={700}
            fontSize="30px"
            mt={isDesktop ? 2 : 6}>
            {hasNextStep ? 'Way to grow!' : 'You did it!'}
          </Text>
          <Text
            mt={2}
            textAlign="center"
            color="primary.900"
            fontWeight={500}
            fontSize="16px">
            {hasNextStep
              ? `You’ve earned ${data.currentActivity.minutes} Mental Health Minutes`
              : 'Congratulations on completing your path!'}
          </Text>
          <Box
            flex={1}
            alignSelf="center"
            mt={8}
            width={isDesktop ? '358px' : '100%'}>
            <Carousel
              ref={carouselRef}
              width={isDesktop ? 358 : carouselWidth}
              data={Array.from({ length: 2 })}
              loop={false}
              height={268}
              onSnapToItem={(index) => setActiveSlide(index)}
              renderItem={({ item, index }) =>
                index === 0 ? (
                  <VStack
                    height="100%"
                    key={index}
                    borderRadius="20px"
                    borderColor="muted.200"
                    py={5}
                    px={4}
                    borderWidth={1}>
                    <HStack
                      height="88px"
                      borderRadius="20px"
                      borderColor="muted.200"
                      alignItems="center"
                      p={4}
                      borderWidth={1}>
                      <Box width="56px" height="56px">
                        <LinearGradient
                          colors={['#CEEDCD', '#8FE28D']}
                          start={{ x: 0, y: 0 }}
                          end={{ x: 0, y: 1 }}
                          style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 10,
                          }}>
                          <FaIcon
                            name="check"
                            size={20}
                            color="secondary.700"
                          />
                        </LinearGradient>
                      </Box>
                      <Text
                        ml={4}
                        color="primary.900"
                        fontWeight={600}
                        width="80%"
                        fontSize="16px">
                        {data.currentActivity.title}
                      </Text>
                    </HStack>
                    <VStack alignItems="center" py={5}>
                      <Text
                        mb={2}
                        color="text.800"
                        fontWeight={600}
                        fontSize="16px">
                        Did you enjoy this lesson?
                      </Text>
                      <HStack space={2} justifyContent="center">
                        <ThumbsIcon
                          iconName="up"
                          selectedIcon={selectedIcon}
                          setSelectedIcon={setSelectedIcon}
                          shadow={selectedIcon === 'up' && 4}
                          handleSlide={() => {
                            setActiveSlide(1)
                            carouselRef.current.next()
                          }}
                        />
                        <ThumbsIcon
                          iconName="down"
                          selectedIcon={selectedIcon}
                          setSelectedIcon={setSelectedIcon}
                          shadow={false}
                          handleSlide={() => {
                            setActiveSlide(1)
                            carouselRef.current.next()
                          }}
                        />
                      </HStack>
                    </VStack>
                  </VStack>
                ) : (
                  <VStack>
                    {!finished && (
                      <Text
                        mb={3}
                        color="text.800"
                        fontWeight={600}
                        fontSize="16px">
                        Up next:
                      </Text>
                    )}

                    <HStack
                      borderRadius="20px"
                      borderColor="muted.200"
                      alignItems="center"
                      p={4}
                      borderWidth={1}>
                      {hasNextStep && (
                        <VStack width="100%">
                          <HStack mb={4}>
                            <HStack
                              mr={1.5}
                              width={6}
                              height={6}
                              backgroundColor="success.500"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="full">
                              <Text
                                fontSize="12px"
                                color="white"
                                fontWeight={600}>
                                {data.nextStep.stepNumber}
                              </Text>
                            </HStack>
                            <Text
                              color="primary.900"
                              fontSize="16px"
                              fontWeight={500}>
                              {data.nextStep.pathName}
                            </Text>
                          </HStack>
                          <ActivityCard
                            activity={data.nextStep.activity as any}
                            onPress={async () => {
                              goToActivity(data)
                            }}
                          />
                          <Button
                            mt={4}
                            onPress={async () => {
                              goToActivity(data)
                            }}>
                            <Text
                              color="white"
                              fontSize="16px"
                              fontWeight={500}>
                              Start activity
                            </Text>
                          </Button>
                        </VStack>
                      )}
                      {hasNextPath && (
                        <VStack width="100%">
                          <FocusAreaHeading
                            name={data.nextPath.focusAreaName}
                            description={null}
                            color={color}
                            icon={icon}
                            fontSize="14px"
                            size={6}
                            iconSize={12}
                          />
                          <HStack
                            mt={isDesktop ? 0 : -6}
                            width="100%"
                            borderRadius="20px"
                            borderColor="muted.200"
                            alignItems="center"
                            p={4}
                            borderWidth={1}>
                            <HStack
                              mr={4}
                              borderRadius="10px"
                              alignItems="center"
                              justifyContent="center"
                              width="56px"
                              height="56px"
                              background={bg}>
                              <FaIcon name={icon} color={color} size={30} />
                            </HStack>
                            <VStack>
                              <Text color="primary.900" fontWeight={500}>
                                {data.nextPath.name}
                              </Text>
                              <Text color="text.500" fontSize="10px">
                                {data.nextPath.description}
                              </Text>
                            </VStack>
                          </HStack>
                          <Button
                            mt={4}
                            onPress={async () => {
                              navigation.navigate('Path', {
                                focusArea: data.nextPath.focusAreaName,
                                id: data.nextPath.id,
                              })
                            }}>
                            <Text
                              color="white"
                              fontSize="16px"
                              fontWeight={500}>
                              View path
                            </Text>
                          </Button>
                        </VStack>
                      )}
                      {finished && (
                        <VStack width="100%">
                          <Text
                            textAlign="center"
                            color="primary.900"
                            fontWeight={700}
                            fontSize="32px">
                            Congrats!
                          </Text>
                          <Text
                            my={5}
                            textAlign="center"
                            fontSize="16px"
                            color="primary.900">
                            You have completed all Voyage activities to date.
                            We’ll be adding new paths and activities soon.
                          </Text>
                          <Button
                            mt={4}
                            onPress={async () => {
                              navigation.navigate('LandingVoyage')
                            }}>
                            <Text
                              color="white"
                              fontSize="16px"
                              fontWeight={500}>
                              Keep exploring
                            </Text>
                          </Button>
                        </VStack>
                      )}
                    </HStack>
                  </VStack>
                )
              }
            />
            <CarouselPagination
              data={Array.from({ length: 2 })}
              activeSlide={activeSlide}
            />
          </Box>

          {Platform.OS === 'ios' && <Box height="50px" />}
        </ScrollView>
      )}
    </>
  )
}

export default Completed
