import { HStack, Text } from 'native-base'
import { VoyageBadgeProps } from '../../../../domain/models/interfaces/voyagesInterfaces'
import FaIcon from '../_shared/FaIcon'

const VoyageBadge = ({ text }: VoyageBadgeProps) => {
  return (
    <HStack
      alignSelf="flex-start"
      bg="info.200"
      p={2}
      borderRadius="48px"
      alignItems="center">
      <FaIcon name="bullseye-arrow" color="muted.900" size={12} />
      <Text ml={1} color="muted.900" fontSize="13px" fontWeight={500}>
        {text}
      </Text>
    </HStack>
  )
}

export default VoyageBadge
