import { Box, HStack, VStack } from 'native-base'
import { useRenderFocusAreaIcon } from '../../../hooks/useRenderFocusAreasIcon'
import BackButton from '../../navigation/BackButton'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import FocusAreaHeading from '../FocusAreaHeading'
import PathAccordion from '../PathAccordion'
import VoyageChip from '../VoyageChip'

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" py={4}>
    {Array.from({ length: 8 }).map((_, index) => (
      <SkeletonLoader
        key={index}
        mb={4}
        size={{ width: '100%', height: '40px' }}
      />
    ))}
  </VStack>
)

const FocusAreaDesktop = ({ data, isLoading, focusArea }) => {
  const { icon, color } =
    !isLoading && data !== null && useRenderFocusAreaIcon(data.name)

  const openIndex = isLoading
    ? -1
    : data.paths.findIndex((path) => !path.pathCompleted)

  return (
    <Box bg="muted.50" mb={6}>
      <HStack alignItems="center" justifyContent="space-between" mb={2}>
        <BackButton />
        {!isLoading && data && data.minutesEarned > 0 && (
          <VoyageChip text={`${data.minutesEarned} min`} />
        )}
      </HStack>
      <HStack>
        <VStack w={'35%'}>
          <Box
            borderRadius="20px"
            bg="white"
            p={4}
            borderColor="grey.200"
            borderWidth={1}>
            {isLoading ? (
              <LoadingView />
            ) : (
              <FocusAreaHeading
                name={data.name}
                description={data.description}
                color={color}
                icon={icon}
              />
            )}
          </Box>
        </VStack>

        <VStack ml={6} w="624px">
          {isLoading ? (
            <Box
              borderRadius="20px"
              bg="white"
              p={4}
              borderColor="grey.200"
              borderWidth={1}>
              <SkeletonLoader size={{ width: '100%', height: '300px' }} />
            </Box>
          ) : (
            data.paths.map((path, index) => (
              <PathAccordion
                color={color}
                key={path.id}
                path={path}
                borderColor={color}
                focusArea={focusArea}
                startOpen={index === openIndex}
              />
            ))
          )}
        </VStack>
      </HStack>
    </Box>
  )
}

export default FocusAreaDesktop
