import { useMemo } from 'react'
import { ModalProvider } from '../../../providers/ModalProvider'
import BottomSheet from '../../_shared/notification/BottomSheet'
import ActivityPreview from './ActivityPreview'

const ActivityModalPreview = ({
  modalData,
  bottomSheetStyling,
  modalShown,
  handleCloseModal,
}) => {
  const bottomSheet = useMemo(
    () => (
      <BottomSheet
        body={
          <ActivityPreview
            activity={modalData}
            handleClose={handleCloseModal}
          />
        }
        isOpen={modalShown}
        handleClose={handleCloseModal}
        {...bottomSheetStyling}
      />
    ),
    [modalData, handleCloseModal, modalShown, bottomSheetStyling]
  )

  return modalData ? (
    <ModalProvider activity={modalData} handleParentClose={handleCloseModal}>
      {bottomSheet}
    </ModalProvider>
  ) : (
    bottomSheet
  )
}

export default ActivityModalPreview
