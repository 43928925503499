import { Box, HStack, Text } from 'native-base'
import { useEffect, useRef } from 'react'
import { Animated, StyleSheet } from 'react-native'
import Modal from 'react-native-modal'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import FaIcon from './FaIcon'

const ProgressModal = ({
  isVisible,
  downloadProgress,
  progressMessage,
  successMessage,
  animationDuration,
  position,
  callback = () => {},
}) => {
  const { bottom, top, right, left } = position
  const counter = useRef(new Animated.Value(0)).current
  const isMobile = useMobileBreakpoint()

  useEffect(() => {
    if (isVisible) load()
  }, [isVisible, downloadProgress])

  const load = () => {
    Animated.timing(counter, {
      toValue: 100,
      duration: animationDuration,
      useNativeDriver: false,
    }).start(callback)
  }

  const width = counter.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
    extrapolate: 'clamp',
  })

  return (
    <Modal isVisible={isVisible} hasBackdrop={false}>
      <Box
        position="absolute"
        bottom={bottom}
        top={top}
        right={right}
        left={left}
        w={isMobile ? '94%' : '329px'}
        alignSelf="center"
        borderRadius="4px"
        bgColor="success.200">
        <Animated.View
          style={
            ([StyleSheet.absoluteFill],
            {
              backgroundColor: '#15803D',
              width,
              height: 4,
              borderTopStartRadius: 4,
              borderTopEndRadius: 4,
            })
          }
        />
        <HStack alignItems="center" mx="12px">
          <FaIcon
            name={
              downloadProgress === 100 ? 'circle-check' : 'cloud-arrow-down'
            }
            isSolid={downloadProgress === 100}
            color="success.700"
            size={20}
          />
          <Text color="text.900" my="16px" ml="8px">
            {downloadProgress === 100 ? successMessage : progressMessage}
          </Text>
        </HStack>
      </Box>
    </Modal>
  )
}

export default ProgressModal
