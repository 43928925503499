import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { Animated, useWindowDimensions } from 'react-native'
import voyagesQuestions from '../../../domain/models/data/voyagesQuestionnaire.json'
import { useDesktopBreakpoint } from '../../../domain/services/useBreakpoint'
import { useScreenerAnswersMutation } from '../queries/voyageQueries'
import LoadingScreen from '../screens/LoadingScreen'
import { NavigationParams } from '../screens/NavigationScreen'

const VoyagesScreenerContext = createContext({
  currentQuestionIndex: 0,
  handleNext: () => {},
  handlePrev: () => {},
  handleAnswerSelect: (questionId, answerIds, isMultiple) => {},
  isNextButtonDisabled: () => {},
  selectedAnswers: [],
  fadeAnim: new Animated.Value(0),
  progress: 0,
  setSelectedAnswers: (value) => {},
  handleExit: () => {},
  isQuestionActive: (questionId, selectedAnswers) => {},
  setCurrentQuestionIndex: (value) => {},
  setProgress: (value) => {},
})

export const useVoyagesScreenerContext = () =>
  useContext(VoyagesScreenerContext)

export const VoyagesScreenerProvider = ({ children }) => {
  const { mutate, isLoading } = useScreenerAnswersMutation()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { width } = useWindowDimensions()
  const isDesktop = useDesktopBreakpoint()

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [showLoadingScreen, setShowLoadingScreen] = useState(false)

  const fadeAnim = useRef(new Animated.Value(width)).current
  const numOfQuestions = isDesktop ? 4 : 1

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start()
  }, [currentQuestionIndex])

  const handleNext = () => {
    if (currentQuestionIndex < voyagesQuestions.length - numOfQuestions) {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start(() => {
        setCurrentQuestionIndex(currentQuestionIndex + numOfQuestions)
        fadeAnim.setValue(0)
      })
    }

    if (
      (isDesktop && currentQuestionIndex > 3) ||
      (!isDesktop && currentQuestionIndex === 7)
    ) {
      mutate(selectedAnswers)
      setShowLoadingScreen(true)
      if (!isLoading) {
        setTimeout(() => {
          setShowLoadingScreen(false)
          navigation.navigate('VoyageScreenerResults')
        }, 3000)
      }
    }
  }

  if (showLoadingScreen)
    return (
      <LoadingScreen
        hasRandomMessage={false}
        bg="white"
        title="Calculating..."
      />
    )

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start(() => {
        setCurrentQuestionIndex(currentQuestionIndex - numOfQuestions)
        fadeAnim.setValue(0)
      })
    }
    if (currentQuestionIndex === 5) {
      // To Do: Fix state not persisting on multiple choice questions

      setSelectedAnswers((prevAnswers) => {
        return prevAnswers.filter((answer) => answer.question !== 4)
      })
    }
  }

  const handleAnswerSelect = (questionId, answerIds, isMultiple = false) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      let existingAnswer = prevSelectedAnswers.find(
        (q) => q.question === questionId
      )

      if (existingAnswer) {
        existingAnswer.answers = isMultiple ? answerIds : [answerIds]
      } else {
        prevSelectedAnswers.push({
          question: questionId,
          answers: isMultiple ? answerIds : [answerIds],
        })
      }

      return [...prevSelectedAnswers]
    })

    const answeredQuestions = selectedAnswers.filter(
      (q) => q.answers.length > 0
    ).length
    setProgress(answeredQuestions * 12.5)
  }

  const isNextButtonDisabled = () => {
    const currentQuestions = voyagesQuestions.slice(
      currentQuestionIndex,
      currentQuestionIndex + numOfQuestions
    )

    return !currentQuestions.every((question) =>
      selectedAnswers.some((q) => q.question === question.id)
    )
  }

  const handleExit = () => {
    navigation.navigate('Voyage')
    setSelectedAnswers([])
    setProgress(0)
  }

  const isQuestionActive = (questionId, selectedAnswers): boolean => {
    if (selectedAnswers.length === 0) {
      return questionId === 0
    }
    const selectedQuestionIndex = selectedAnswers.findIndex(
      (selected) => selected.question === questionId
    )
    if (selectedQuestionIndex !== -1) {
      return true
    }
    const lastSelectedQuestion = Math.max(
      ...selectedAnswers.map((selected) => selected.question)
    )
    return questionId === lastSelectedQuestion + 1
  }

  return (
    <VoyagesScreenerContext.Provider
      value={{
        currentQuestionIndex,
        handleNext,
        handlePrev,
        handleAnswerSelect,
        isNextButtonDisabled,
        selectedAnswers,
        fadeAnim,
        progress,
        setSelectedAnswers,
        handleExit,
        isQuestionActive,
        setCurrentQuestionIndex,
        setProgress,
      }}>
      {children}
    </VoyagesScreenerContext.Provider>
  )
}

export default useVoyagesScreenerContext
