import AsyncStorage from '@react-native-async-storage/async-storage'
import { Audio } from 'expo-av'
import { Camera, PermissionStatus } from 'expo-camera'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Platform } from 'react-native'
import * as Tone from 'tone'
import { RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video'
import AttendeeRole from '../../../domain/enums/AttendeeRole'
import AttendeeState from '../../../domain/enums/AttendeeState'
import analytics from '../../../domain/services/analytics/AnalyticsService'
import useMicLevel from '../../../domain/services/micLevel/useMicLevel'
import useStorage from '../../../domain/services/useStorage'
import useRoomContext from './RoomProvider'

const RoomClientContext = createContext(undefined)

export const useRoomClientContext = () => useContext(RoomClientContext)

export const RoomClientProvider = ({ children }) => {
  /* State */
  const room = useRoomContext()
  const [hasPermission, setHasPermission] = useState(null)
  const [chatOpen, setChatOpen] = useStorage('@chat_open', true)
  const [rosterOpen, setRosterOpen] = useStorage('@roster_open', true)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [endGroupOpen, setEndGroupOpen] = useState(false)
  const [userHasMic, setUserHasMic] = useState(true)

  const [micId, setMicId] = useStorage('@mic_id', '')
  const [cameraId, setCameraId] = useStorage('@camera_id', '')
  let newDate = new Date()
  newDate.setDate(newDate.getDate() + 1)
  const [pitchData, setPitchData] = useStorage('@pitch_' + room.activityId, {
    pitch: 0,
    endDate: newDate,
  })
  const pitch = pitchData.pitch
  const setPitch = useCallback(
    (pitch) => {
      setPitchData({
        ...pitchData,
        pitch: pitch,
      })
    },
    [pitchData, setPitchData]
  )

  //Clean past event data
  useEffect(() => {
    AsyncStorage.getAllKeys().then((keys) => {
      for (let key of keys) {
        if (key.includes('@pitch')) {
          AsyncStorage.getItem(key).then((item) => {
            const parsedItem = JSON.parse(item)
            if (new Date(parsedItem.endDate) < new Date())
              AsyncStorage.removeItem(key)
          })
        }
      }
    })
  }, [])

  const [lastReadIndex, setLastReadIndex] = useState(0)

  const [microphoneTrack, setMicrophoneTrack] = useState(null)
  const [cameraTrack, setCameraTrack] = useState(null)
  const [screenVideoTrack, setScreenVideoTrack] = useState(null)
  const [screenAudioTrack, setScreenAudioTrack] = useState(null)

  const [remoteAudioTracks, setRemoteAudioTracks] = useState(
    new Array<RemoteAudioTrack>()
  )
  const [remoteCameraTracks, setRemoteCameraTracks] = useState(
    new Array<RemoteVideoTrack>()
  )
  const [remoteScreenVideoTracks, setRemoteScreenVideoTracks] = useState(
    new Array<RemoteVideoTrack>()
  )

  const { micLevel, isMicHot, isSpeaking } = useMicLevel(
    true,
    microphoneTrack,
    room.me.state === AttendeeState.Orientation
  )

  const hasMicrophone =
    room?.me?.role === AttendeeRole.Moderator ||
    room?.me?.role === AttendeeRole.Participant ||
    room?.me?.role === AttendeeRole.Spectator

  const hasCamera = room?.me?.role === AttendeeRole.Moderator

  /* Functions */
  const toggleChat = () => setChatOpen(!chatOpen)
  const toggleRoster = () => setRosterOpen(!rosterOpen)
  const toggleSettings = () => setSettingsOpen(!settingsOpen)
  const toggleEndGroup = () => setEndGroupOpen(!endGroupOpen)

  const getAudioTrack = (stream) => stream.getAudioTracks()[0]
  const getVideoTrack = (stream) => stream.getVideoTracks()[0]

  const requestPermission = async () => {
    try {
      if (Platform.OS === 'web') {
        /* Web */
        // Get audio and video permissions then stop the tracks
        const constraints = { audio: hasMicrophone, video: hasCamera }
        await navigator.mediaDevices
          .getUserMedia(constraints)
          .then((mediaStream) => {
            mediaStream.getTracks().forEach((track) => track.stop())
          })
      } else {
        /* Mobile */
        hasCamera
          ? await Camera.requestCameraPermissionsAsync()
          : await Camera.requestMicrophonePermissionsAsync()

        await Audio.setAudioModeAsync({
          allowsRecordingIOS: true,
          playsInSilentModeIOS: true,
        })
      }

      roomClient.setHasPermission(true)
    } catch (error) {
      roomClient.setHasPermission(false)
      roomClient.setUserHasMic(false)
      console.debug(error)
    }
  }

  const shareScreen = async () => {
    const clearScreenTracks = () => {
      setScreenVideoTrack(null)
      setScreenAudioTrack(null)
    }

    if (room.me.screenOn) {
      // Toggle off
      clearScreenTracks()
      room.toggleScreen()
    } else {
      // Toggle on
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: true })
        .then((stream) => {
          const screenVideoTrack = getVideoTrack(stream)

          // Toggle screen off if ended by browser
          screenVideoTrack.onended = () => {
            room.toggleScreen()
            setScreenVideoTrack(null)
          }

          setScreenVideoTrack(screenVideoTrack)
          setScreenAudioTrack(getAudioTrack(stream))
          room.toggleScreen()
        })
        .catch((error) => {
          console.error(`Screenshare error: ${error}`)
          clearScreenTracks()
        })
    }
  }

  const shiftPitch = (stream: MediaStream): MediaStream => {
    if (pitch === 0) return stream

    analytics.track('Pitch Changed', { pitch })

    // Audio input to pitch shift filter
    const source = Tone.context.createMediaStreamSource(stream)
    const pitchShift = new Tone.PitchShift(pitch)
    Tone.connect(source, pitchShift)

    // Pitch shift filter to audio output
    const destination = Tone.context.createMediaStreamDestination()
    Tone.connect(pitchShift, destination)
    return destination.stream
  }

  /* Hooks */
  // Get existing permissions
  useEffect(() => {
    ;(async () => {
      let hasExistingPermission = false

      if (Platform.OS === 'web') {
        /* Web */
        const devices = await navigator.mediaDevices.enumerateDevices()
        hasExistingPermission = devices.some((d) => d.deviceId)
      } else {
        /* Mobile */
        const permissionResponse = await Camera.getMicrophonePermissionsAsync()
        const isDetermined = (status) =>
          status === PermissionStatus.GRANTED ||
          status === PermissionStatus.DENIED
        hasExistingPermission = isDetermined(permissionResponse.status)
      }

      if (hasExistingPermission) await requestPermission()
    })()
  }, [])

  // Set local audio track
  useEffect(() => {
    if (!hasPermission || !hasMicrophone) return

    const constraints = {
      audio: micId ? { deviceId: micId } : true,
    }

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      window.localAudioStream = stream
      const filteredStream = shiftPitch(stream)
      setMicrophoneTrack(getAudioTrack(filteredStream))
    })

    return () => {
      window.localAudioStream.getTracks().forEach((track) => {
        track.stop()
      })
    }
  }, [hasPermission, micId, pitch])

  // Set local video track
  useEffect(() => {
    if (!hasPermission || !hasCamera) return

    const constraints = {
      video: cameraId ? { deviceId: cameraId } : true,
    }

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      window.localVideoStream = stream
      setCameraTrack(getVideoTrack(stream))
    })

    return () => {
      window.localVideoStream.getTracks().forEach((track) => {
        track.stop()
      })
    }
  }, [hasPermission, cameraId])

  // Toggle local video on/off
  useEffect(() => {
    if (cameraTrack) cameraTrack.enabled = room.me.cameraOn
  }, [room.me])

  // Toggle off screenshare on reload
  useEffect(() => {
    if (room.me.screenOn && !screenVideoTrack) {
      shareScreen()
    }
  }, [room.me])

  // Update active speaker
  useEffect(() => {
    if (room?.me?.state === AttendeeState.Joined) {
      if (room?.me?.activeSpeaker != isMicHot) room.setActiveSpeaker(isMicHot)
    }
  }, [isMicHot, room.me])

  /* Render */
  const roomClient = {
    hasPermission,
    setHasPermission,
    requestPermission,
    chatOpen,
    toggleChat,
    rosterOpen,
    toggleRoster,
    settingsOpen,
    toggleSettings,
    micId,
    setMicId,
    cameraId,
    setCameraId,
    micLevel,
    isMicHot,
    isSpeaking,
    lastReadIndex,
    setLastReadIndex,
    pitch,
    setPitch,
    microphoneTrack,
    cameraTrack,
    screenVideoTrack,
    screenAudioTrack,
    remoteAudioTracks,
    setRemoteAudioTracks,
    remoteCameraTracks,
    setRemoteCameraTracks,
    remoteScreenVideoTracks,
    setRemoteScreenVideoTracks,
    shareScreen,
    endGroupOpen,
    toggleEndGroup,
    setUserHasMic,
    userHasMic,
  }

  return (
    <RoomClientContext.Provider value={roomClient}>
      {children}
    </RoomClientContext.Provider>
  )
}

export default useRoomClientContext
