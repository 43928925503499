import { createContext, useContext, useEffect, useState } from 'react'
import AttendeeRole from '../../../domain/enums/AttendeeRole'
import { useRoster } from '../../../domain/services/useRoster'
import { useCabanaApi } from './CabanaApiProvider'
import { useUserProfileContext } from './UserProfileProvider'

const RoomContext = createContext(undefined)

export const useRoomContext = () => useContext(RoomContext)

export const RoomProvider = ({ children, id, activityId, ended }) => {
  /* State */
  const { RoomApi } = useCabanaApi()
  const { userProfile } = useUserProfileContext()
  const [
    roster,
    toggleMicrophone,
    toggleCamera,
    toggleHandRaised,
    toggleScreen,
  ] = useRoster(id)

  const [isUserTyping, setIsUserTyping] = useState(undefined)

  const setAlias = (alias) => RoomApi.changeAlias(id, alias)
  const join = () => RoomApi.join(id)
  const leave = () => RoomApi.leave(id)
  const muteParticipant = (userId) => RoomApi.muteParticipant(id, userId)
  const setActiveSpeaker = (activeSpeaker) =>
    RoomApi.setActiveSpeaker(id, activeSpeaker)

  const [room, setRoom] = useState({
    ended,
    setAlias,
    join,
    leave,
    toggleMicrophone,
    toggleCamera,
    toggleScreen,
    toggleHandRaised,
    muteParticipant,
    setActiveSpeaker,
    roomId: null,
    me: { state: null }, // Added property to prevent TypeScript error
    moderator: {},
    activityId: activityId,
    isUserTyping,
    setIsUserTyping,
  })

  /* Hooks */
  // Update roster
  useEffect(() => {
    const me = roster?.attendees.find(
      (attendee) => attendee.userId === userProfile.id
    )
    const moderator = roster?.attendees.find(
      (attendee) => attendee.role === AttendeeRole.Moderator
    )
    setRoom({
      ...room,
      ...roster,
      me: me,
      moderator: moderator,
      isUserTyping,
      setIsUserTyping,
    })
  }, [roster, userProfile])

  /* Render */
  return (
    <RoomContext.Provider value={room}>
      {room.me?.state ? children : <></>}
    </RoomContext.Provider>
  )
}

export default useRoomContext
