import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  Box,
  Divider,
  HStack,
  SectionList,
  Text,
  View,
  VStack,
} from 'native-base'
import { useWindowDimensions } from 'react-native'
import { ActivityCardBorderState } from '../../../../../domain/models/interfaces/activityInterfaces'
import useGetShadowProps from '../../../hooks/useGetShadowProps'
import { useMyGroups } from '../../../queries/activitiesQueries'
import { NavigationParams } from '../../../screens/NavigationScreen'
import EmptyListComponent from '../../EmptyListComponent'
import ActivityCard from '../../_shared/card/ActivityCard'
import ProfileContainer from '../../_shared/layout/ProfileContainer'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import { parseSections } from './parseSections'

const EmptyImage = require('../../../assets/images/vibe-check/empty-groups.png')

const MyGroupsDesktop = () => {
  const { registeredGroups, isLoading } = useMyGroups()
  const sections = parseSections(registeredGroups)
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { height } = useWindowDimensions()
  const shadow = useGetShadowProps(15)

  const borderStyle = {
    borderColor: 'muted.300',
    borderWidth: '1',
    borderRadius: '10px',
  }

  const borderStyleCompleted = {
    borderColor: 'primary.700',
    borderWidth: '1',
    borderRadius: '10px',
  }

  const borderStyleLive = {
    borderColor: 'tertiary.500',
    borderWidth: '1',
    borderRadius: '10px',
  }

  return (
    <ProfileContainer headerText="My groups" title="Group history">
      <>
        {isLoading ? (
          <VStack padding={4} bg="white" borderRadius="20px">
            <SkeletonLoader size={{ width: '100%', height: 300 }} />
          </VStack>
        ) : (
          <>
            <SectionList
              sections={sections}
              renderSectionHeader={({ section }) => {
                return section.title === 'past groups' ? (
                  <>
                    <HStack mr={4} justifyContent="space-between" mt={6} mb={3}>
                      <Text
                        fontWeight="700"
                        color="primary.900"
                        fontSize="16px">
                        {`My ${section.title}`}
                      </Text>
                      <HStack>
                        <HStack alignItems="center" mr={2}>
                          <View w={3} h={3} bg="primary.700" mr={1} />
                          <Text fontSize="12px" color="text.700">
                            Attending / Attended
                          </Text>
                        </HStack>
                        <HStack alignItems="center">
                          <View w={3} h={3} bg="muted.300" mr={1} />
                          <Text fontSize="12px" color="text.700">
                            Missed
                          </Text>
                        </HStack>
                      </HStack>
                    </HStack>
                    <Divider color="muted.300" height="1px" mb={3} w="98%" />
                  </>
                ) : (
                  <Box
                    mr={4}
                    bg="white"
                    pl={4}
                    pt={5}
                    borderTopLeftRadius="20px"
                    borderTopRightRadius="20px">
                    <Text
                      fontWeight="700"
                      color="primary.900"
                      mb={4}
                      fontSize="16px">
                      {`My ${section.title}`}
                    </Text>
                  </Box>
                )
              }}
              renderItem={({ section, item }) => {
                return section.title === 'past groups' ? (
                  <Box mr={4}>
                    <Text
                      fontSize="16px"
                      fontWeight="700"
                      mb={3}
                      color="primary.900">
                      {item.month}
                    </Text>

                    {item.data.map((group) => {
                      return (
                        <Box pb={4} key={group.id}>
                          <ActivityCard
                            borderStyle={
                              group.registration === 'ATTENDING' ||
                              group.registration === 'COMPLETED' ||
                              group.registration === 'MODERATING'
                                ? borderStyleCompleted
                                : group.cardBorderState ===
                                  ActivityCardBorderState.LIVE
                                ? borderStyleLive
                                : borderStyle
                            }
                            hasShadow={false}
                            reduceOpacityOnComplete={false}
                            activity={group}
                            onPress={() =>
                              navigation.navigate('Activity', {
                                activityId: group.id,
                              })
                            }
                          />
                        </Box>
                      )
                    })}
                  </Box>
                ) : (
                  <Box
                    mr={4}
                    bg="white"
                    px={4}
                    pb={5}
                    borderBottomLeftRadius={item.hasBorder ? '20px' : 0}
                    borderBottomRightRadius={item.hasBorder ? '20px' : 0}>
                    <ActivityCard
                      borderStyle={
                        item.cardBorderState === 'COMPLETED' ||
                        item.cardBorderState === 'ATTENDING'
                          ? borderStyleCompleted
                          : borderStyle
                      }
                      hasShadow={false}
                      reduceOpacityOnComplete={false}
                      activity={item}
                      onPress={() =>
                        navigation.navigate('Activity', { activityId: item.id })
                      }
                    />
                  </Box>
                )
              }}
              keyExtractor={(item) => item.id}
              showsVerticalScrollIndicator={false}
              maxH={height * 0.73}
              stickySectionHeadersEnabled={false}
              ListEmptyComponent={
                <HStack
                  borderRadius="20px"
                  p={4}
                  bg="white"
                  flexDirection="column"
                  borderColor="muted.300"
                  borderWidth={1}>
                  <EmptyListComponent
                    title="Group history"
                    description="Participate or register in a live-group to view history"
                    buttonText="View upcoming groups"
                    buttonAction={() => navigation.navigate('LiveGroups')}
                    imageSource={EmptyImage}
                  />
                </HStack>
              }
              px="2px"
              pb="20px"
            />
          </>
        )}
      </>
    </ProfileContainer>
  )
}

export default MyGroupsDesktop
