import { Box, Button, HStack, ScrollView, StatusBar, Text } from 'native-base'
import { StyleSheet, Text as TextNative } from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import VoyagesIntroImage from './VoyagesIntroImage'
const Intro = require('../../assets/images/voyages/intro.png')

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Poppins_600SemiBold',
  },
  italic: {
    fontFamily: 'Poppins_400Regular_Italic',
  },
})

const VoyagesIntro = ({ onPress }) => {
  const isDesktop = useDesktopBreakpoint()
  return (
    <>
      <StatusBar barStyle="dark-content" />
      {!isDesktop && (
        <HStack
          width="100%"
          alignItems="flex-end"
          alignContent="flex-start"
          height={98}
          backgroundColor="white"
          borderBottomWidth={1}
          px={6}
          shadow={0.5}
          borderBottomColor="muted.50">
          <Text fontSize="xl" fontWeight={500} mb={2}>
            Voyage
          </Text>
        </HStack>
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        flex={1}
        alignSelf="center"
        alignContent="center"
        px={6}
        width={isDesktop ? '70%' : '100%'}>
        <Box
          justifyContent={'center'}
          margin={'auto'}
          width={isDesktop ? '342px' : '283px'}
          height={isDesktop ? '240px' : '195px'}>
          <VoyagesIntroImage />
        </Box>
        <Text fontSize="24px" color="primary.900" fontWeight={700}>
          Uncover paths to a happier, healthier you
        </Text>
        <Text color="primary.900" fontSize="md" my={4}>
          <TextNative style={styles.bold}>{`Voyage `}</TextNative>
          <Text>
            is our guided paths to discover your
            <TextNative style={styles.italic}>{` balanced self`}</TextNative>
            —authentic, content, peaceful, calm, focused, ready, and present.
          </Text>
        </Text>
        <Text color="primary.900" fontSize="md" mt={2}>
          <Text>
            Navigate personalized bite-sized wellness modules to gain practical
            insights in five minutes or less, concentrating on the&nbsp;
            <TextNative style={styles.italic}>{`Focus Areas`}</TextNative> we
            designed to improve your well-being.
          </Text>
        </Text>
        <Button
          alignSelf="center"
          _text={{ fontWeight: 500 }}
          mt={isDesktop ? 8 : 4}
          mb={isDesktop ? 0 : 10}
          onPress={onPress}
          width={isDesktop ? '342px' : '100%'}>
          Start now
        </Button>
      </ScrollView>
    </>
  )
}

export default VoyagesIntro
