export const useRenderFocusAreaIcon = (name) => {
  switch (name) {
    case 'Value':
      return { icon: 'compass', color: 'tertiary.600', bg: 'tertiary.100' }
    case 'Values':
      return { icon: 'compass', color: 'tertiary.600', bg: 'tertiary.100' }
    case 'Foundations':
      return { icon: 'gear', color: 'tertiary.600', bg: 'tertiary.100' }
    case 'Emotions':
      return {
        icon: 'hand-holding-heart',
        color: 'darkBlue.600',
        bg: 'darkBlue.100',
      }
    case 'Communications':
      return {
        icon: 'comment-lines',
        color: 'darkBlue.600',
        bg: 'darkBlue.100',
      }
    case 'Communication':
      return {
        icon: 'comment-lines',
        color: 'darkBlue.600',
        bg: 'darkBlue.100',
      }
    case 'Decision Making':
      return { icon: 'brain-circuit', color: 'amber.600', bg: 'amber.100' }
    case 'Decision-making':
      return { icon: 'brain-circuit', color: 'amber.600', bg: 'amber.100' }
    case 'Executive Functioning':
      return { icon: 'list', color: 'amber.600', bg: 'amber.100' }
    case 'Executive functioning':
      return { icon: 'list', color: 'amber.600', bg: 'amber.100' }
    case 'Cognitive skills':
      return { icon: 'list', color: 'amber.600', bg: 'amber.100' }
    case 'Critical Thinking':
      return { icon: 'gear', color: 'amber.600', bg: 'amber.100' }
    case 'Acceptance':
      return { icon: 'seedling', color: 'secondary.600', bg: 'secondary.100' }
    case 'Adaptability':
      return { icon: 'umbrella', color: 'secondary.600', bg: 'secondary.100' }
    default:
      return { icon: 'seedling', color: 'secondary.500', bg: 'secondary.100' }
  }
}
