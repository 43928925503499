import { Box, HStack, Text, VStack } from 'native-base'
import { LeaderboardParticipant } from '../../../../../domain/models/interfaces/leaderboardInterfaces'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { useUserProfileContext } from '../../../providers/UserProfileProvider'
import Orb from '../../room/Orb'
import FaIcon from '../../_shared/FaIcon'

interface ParticipantRowProps {
  participant: LeaderboardParticipant
  type?: string
}

const ParticipantRow = ({ participant, type = 'all' }: ParticipantRowProps) => {
  const isMobile = useMobileBreakpoint()
  const { userProfile } = useUserProfileContext()
  const top3 = [1, 2, 3].includes(participant.rankPosition)
  const medalColor =
    (participant.rankPosition === 1 && '#E7C200') ||
    (participant.rankPosition === 2 && '#737373') ||
    (participant.rankPosition === 3 && '#C87C30')
  const space = isMobile ? 4 : 6

  return (
    <HStack
      alignItems="center"
      my={2}
      height="50px"
      px={space}
      justifyContent="space-between"
      bg={userProfile.id === participant.memberId && 'primary.50'}>
      <HStack alignItems="center">
        {type === 'all' && top3 ? (
          <Box mr={2.5}>
            <FaIcon isSolid color={medalColor} name="medal" size={24} />
          </Box>
        ) : (
          <Text width="32px" color="text.900" fontSize={top3 ? '24px' : '16px'}>
            {participant.rankPosition}
          </Text>
        )}
        <Orb size="28px" participantColor={participant.color} />
        <Text fontSize={top3 ? '16px' : '14px'} color="text.900" ml={2.5}>
          {userProfile.id === participant.memberId
            ? 'You'
            : participant.username}
        </Text>
      </HStack>
      <VStack>
        <Text
          fontSize={top3 ? '18px' : '16px'}
          fontWeight={600}
          color="text.900"
          textAlign="right">
          {new Intl.NumberFormat('en-US').format(participant.totalMinutes)}
        </Text>
        <Text fontSize="9px" color="text.500" textAlign="right">
          Minutes
        </Text>
      </VStack>
    </HStack>
  )
}

export default ParticipantRow
