import { useEffect } from 'react'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import PathDesktop from '../../components/voyages/path/PathDesktop'
import PathMobile from '../../components/voyages/path/PathMobile'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import secureScreen from '../../hooks/authentication/secureScreen'
import DefaultLayout from '../../layouts/DefaultLayout'
import { usePath } from '../../queries/voyageQueries'

const PathScreen = ({ route }) => {
  const { focusArea, id } = route.params
  const formattedFocusArea = focusArea.replace(/\s+/g, '_')
  const { data, isLoading } = usePath(formattedFocusArea, id)

  let orderedSteps = []

  if (data && data.steps) {
    orderedSteps = [...data.steps].sort((a, b) => {
      if (a.completed === b.completed) return 0
      if (a.completed && !b.completed) return -1
      if (!a.completed && b.completed) return 1
    })
  }
  const isDesktop = useDesktopBreakpoint()

  useEffect(() => {
    if (data) {
      analytics.track('Path Viewed', {
        focusArea: formattedFocusArea,
        title: data.name,
        progress: data.stepsCompleted,
        url: window.location?.href, //elvis to prevent iOS crash
      })
    }
  }, [data])

  return (
    <DefaultLayout
      hasNavFooter={false}
      scrollView={isDesktop ? true : false}
      activeLabel="Voyage"
      bg={isDesktop ? 'muted.50' : 'white'}>
      {!isDesktop ? (
        <PathMobile
          orderedSteps={orderedSteps}
          data={data}
          isLoading={isLoading}
          focusAreaName={focusArea}
        />
      ) : (
        <FixedContainer size="lg">
          <PathDesktop
            orderedSteps={orderedSteps}
            data={data}
            isLoading={isLoading}
            focusAreaName={focusArea}
          />
        </FixedContainer>
      )}
    </DefaultLayout>
  )
}

export default secureScreen(PathScreen)
