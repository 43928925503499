import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, HStack, ScrollView, Text } from 'native-base'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import analytics from '../../../../../domain/services/analytics/AnalyticsService'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import {
  useCompass,
  useCompleteStepMutation,
} from '../../../queries/voyageQueries'
import { NavigationParams } from '../../../screens/NavigationScreen'
import BackButton from '../../navigation/BackButton'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import CompassValues from './CompassValues'

const CompassResults = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  const { data, isLoading } = useCompass()
  const { mutation } = useCompleteStepMutation(true)

  useEffect(() => {
    analytics.track('Values Exercise Completed', {
      North: data?.north,
      East: data?.east,
      South: data?.south,
      West: data?.west,
    })
  }, [])

  return (
    <>
      {!isDesktop ? (
        <HStack
          ml={3}
          width="100%"
          alignItems="flex-end"
          justifyContent="space-between"
          height={98}
          backgroundColor="white"
          borderBottomWidth={1}
          shadow={0.5}
          borderBottomColor="muted.50"
          pb={2}>
          <BackButton customText="" />
        </HStack>
      ) : (
        <Box>
          <BackButton customText="" />
        </Box>
      )}
      <ScrollView
        borderRadius={isDesktop ? '20px' : 0}
        shadow={isDesktop ? 4 : 0}
        my={isDesktop ? 2 : 0}
        backgroundColor="white"
        pt={isDesktop ? 10 : 6}
        p={isDesktop ? 10 : 4}
        showsVerticalScrollIndicator={false}>
        {data && (
          <Box display="flex" flexDirection={isDesktop ? 'row' : 'column'}>
            <Box width={!isDesktop ? '100%' : '60%'}>
              <Text mb={4} color="primary.900" fontSize="24px" fontWeight={500}>
                All done!
              </Text>
              <Text color="primary.900">
                These are values you can fall back on in a difficult situation,
                perhaps even without thinking, and rely on them to guide you to
                handle situations in harmony with what is most important to you.
              </Text>
              <Text mt={6} color="primary.900">
                Before you go, review your compass. Feel free to revisit it here
                any time.
              </Text>
              {isLoading && (
                <SkeletonLoader
                  mb={6}
                  size={{ width: '100%', height: '88px' }}
                />
              )}
              {!isDesktop && <CompassValues data={data} />}
              <Text color="primary.900">
                You feel best when your behavior and life choices align with
                your values. They are who you are at your core before the dust
                of life's pressures accumulates and hides that bright and
                shining compass.
              </Text>
              <Button
                alignSelf="center"
                width={!isDesktop ? '100%' : '342px'}
                mt={6}
                onPress={() => {
                  mutation.mutate('recdlNqiz08fUAc9BCOMPASS')
                  navigation.navigate('LandingVoyage')
                }}>
                <Text color="white" fontSize="16px" fontWeight={500}>
                  Finish
                </Text>
              </Button>
            </Box>
            {isDesktop && (
              <Box width={!isDesktop ? '100%' : '40%'}>
                <CompassValues data={data} />
              </Box>
            )}
          </Box>
        )}

        {Platform.OS === 'ios' && <Box height="50px" />}
      </ScrollView>
    </>
  )
}

export default CompassResults
