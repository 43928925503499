import { Flex, Image, Text } from 'native-base'
import { useMemo } from 'react'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import useRandomQuote from '../../hooks/useRandomQuote'
const topWaveMobile = require('../../assets/images/ui/top-quote-wave.png')
const bottomWaveMobile = require('../../assets/images/ui/bottom-quote-wave.png')
const topWaveWeb = require('../../assets/images/ui/top-quote-wave.web.png')
const bottomWaveWeb = require('../../assets/images/ui/bottom-quote-wave.web.png')

const InspirationalQuote = ({}) => {
  const randomQuote = useMemo(useRandomQuote, [])
  const isMobile = useMobileBreakpoint()

  return (
    <Flex bg="primary.50" borderRadius="10px" p="5" my="5" overflow="hidden">
      <Image
        source={isMobile ? topWaveMobile : topWaveWeb}
        alt="thin wavy lines"
        position="absolute"
        left="0"
        top="0"
        h={isMobile ? '25%' : '20%'}
        w={isMobile ? '60%' : '50%'}
      />
      <Text fontSize="md" textAlign="center">
        ”{randomQuote.quote}”
      </Text>
      <Text fontWeight="medium" textAlign="center" mt="3">
        {randomQuote.author}
      </Text>
      <Image
        source={isMobile ? bottomWaveMobile : bottomWaveWeb}
        alt="thin wavy lines"
        position="absolute"
        right="0"
        bottom="0"
        h={isMobile ? '25%' : '20%'}
        w={isMobile ? '60%' : '50%'}
      />
    </Flex>
  )
}

export default InspirationalQuote
