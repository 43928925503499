import { Text } from 'native-base'

const Caption = ({
  children,
  textAlign = null,
  lineHeight = null,
  fontWeight = 'medium',
}) => {
  return (
    <Text
      textAlign={textAlign}
      lineHeight={lineHeight}
      fontSize="xs"
      fontWeight={fontWeight}
      color="text.500">
      {children}
    </Text>
  )
}

export default Caption
