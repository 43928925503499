import { Center, HStack, useBreakpointValue } from 'native-base'
import FixedContainer from '../../_shared/layout/FixedContainer'
import OrientationStep from './OrientationStep'

const OrientationStepper = ({
  titles,
  steps,
  stepConditions,
  stepperIndex,
  setStepperIndex,
  handleGoToNextStep,
  handleGoToPrevStep,
  isLinear = false,
}) => {
  const isLinearDefault = useBreakpointValue({
    base: true,
    md: steps.length > 2,
    lg: steps.length > 3,
  })

  return (
    <>
      {isLinear || isLinearDefault ? (
        <FixedContainer size="sm">
          <Center>
            <OrientationStep
              index={stepperIndex}
              stepperIndex={stepperIndex}
              setStepperIndex={setStepperIndex}
              title={titles[stepperIndex]}
              body={steps[stepperIndex]}
              condition={stepConditions[stepperIndex]}
              isLinear={true}
              isLast={stepperIndex === steps.length - 1}
              onPressBack={handleGoToPrevStep[stepperIndex]}
              onPressNext={handleGoToNextStep[stepperIndex]}
            />
          </Center>
        </FixedContainer>
      ) : (
        <HStack space={3}>
          {steps.map((step, index) => (
            <OrientationStep
              key={index}
              stepperIndex={stepperIndex}
              index={index}
              setStepperIndex={setStepperIndex}
              title={titles[index]}
              body={step}
              condition={stepConditions[index]}
              isDisabled={index > stepperIndex}
              isLast={index === steps.length - 1}
              onPressBack={handleGoToPrevStep[stepperIndex]}
              onPressNext={handleGoToNextStep[stepperIndex]}
            />
          ))}
        </HStack>
      )}
    </>
  )
}

export default OrientationStepper
