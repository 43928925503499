import { useQuery } from '@tanstack/react-query'
import { useCabanaApi } from '../providers/CabanaApiProvider'

const useGetProfessionalRoles = () => {
  const { ProfessionalRoleApi } = useCabanaApi()
  const { data: professionalRoles, isLoading } = useQuery({
    queryKey: ['professionalRoles'],
    queryFn: () => ProfessionalRoleApi.getProfessionalRoles(),
    initialData: [],
  })

  return { professionalRoles, isLoading }
}

export default useGetProfessionalRoles
