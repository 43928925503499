import {
  Button,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
  View,
  VStack,
} from 'native-base'
import { useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import CalendarButtonsWeb from '../explore/activities/CalendarButtonsWeb'
import Sheet from '../_shared/card/Sheet'
import FaIcon from '../_shared/FaIcon'

const DefaultModal = ({
  show,
  action,
  closeModal = null,
  modalInfo,
  activity,
  body = null,
}) => {
  const { height, width } = useWindowDimensions()
  const isMobile = useMobileBreakpoint()

  return (
    <View>
      <Modal
        isVisible={show}
        deviceHeight={height}
        deviceWidth={width}
        onBackdropPress={closeModal}>
        <Sheet
          props={{
            w: '100%',
            maxW:
              modalInfo.buttonText !== 'Add to calendar'
                ? '320px'
                : isMobile
                ? '300px'
                : '400px',
            minH: isMobile && height < 400 ? '405px' : '420px',
            maxH: isMobile ? '557px' : undefined,
            alignSelf: 'center',
            mx: '7',
          }}>
          <VStack h="100%">
            <Button
              variant="ghost"
              borderRadius="full"
              p="3"
              ml="auto"
              onPress={closeModal}
              position="absolute"
              right={0}
              zIndex={1}>
              <FaIcon name="xmark" size={20} />
            </Button>
            <Flex flex="1" p="4">
              {body
                ? body
                : height > 600 && (
                    <Image
                      source={
                        typeof modalInfo.image === 'string'
                          ? { uri: modalInfo.image }
                          : modalInfo.image
                      }
                      alt="Sea animal"
                      h={modalInfo.h ?? 168}
                      w={modalInfo.w ?? 168}
                      mx="auto"
                    />
                  )}

              <Heading
                size="sm"
                textAlign="center"
                mt={isMobile ? '10px' : '6'}
                color="primary.900">
                {modalInfo.title}
              </Heading>
              <Text textAlign="center" mt="2" color="primary.900">
                {modalInfo.description}
              </Text>
              <Spacer />
              {modalInfo.buttonText !== 'Add to calendar' ? (
                <Button
                  w="100%"
                  mt="4"
                  onPress={() => {
                    closeModal()
                    action()
                  }}>
                  {modalInfo.buttonText}
                </Button>
              ) : (
                <CalendarButtonsWeb activity={activity} />
              )}
            </Flex>
          </VStack>
        </Sheet>
      </Modal>
    </View>
  )
}

export default DefaultModal
