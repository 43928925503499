import { useNavigation } from '@react-navigation/native'
import { Button, Center, Text } from 'native-base'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import WaveLayout from '../../../layouts/WaveLayout'

const FeedbackSubmitted = () => {
  const isMobile = useMobileBreakpoint()
  if (!isMobile) {
    return (
      <WaveLayout>
        <FeedBackSubmittedView />
      </WaveLayout>
    )
  }
  return <FeedBackSubmittedView />
}

const FeedBackSubmittedView = () => {
  const isMobile = useMobileBreakpoint()
  const navigation = useNavigation()
  const goHome = () => {
    navigation.replace('Home')
  }
  return (
    <Center my="auto">
      <Text textAlign="center" fontSize={(isMobile ? 2 : 3) + 'xl'}>
        Thanks for your feedback!
      </Text>
      <Button colorScheme="primary" onPress={goHome} mt="10">
        Return to home
      </Button>
    </Center>
  )
}

export default FeedbackSubmitted
