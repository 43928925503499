import {
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  HStack,
  VStack,
} from 'native-base'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  useGetContentUtilizationReport,
  useGetEmailLeadsReport,
  useGetMemberReport,
  useGetMentalHealthMinutesReport,
  useGetOrganizations,
  useGetParticipationReport,
  useGetVibeCheckDataReport,
} from './queries'

const Reports = () => {
  const firstYearDate = new Date(new Date().getFullYear(), 0, 1)
  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [selectedOrganizationTypes, setSelectedOrganizationTypes] = useState([])

  const [organizations, setOrganizations] = useState([])
  const [includeAllOrganizations, setIncludeAllOrganizations] = useState(true)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [clearCalendar, setClearCalendar] = useState(false)
  const { fetchOrganizationNames } = useGetOrganizations()
  const { isLoadingMemberReport, fetchMemberReport } = useGetMemberReport(
    selectedOrganizations,
    startDate || firstYearDate,
    endDate || new Date()
  )
  const { isLoadingParticipationReport, fetchParticipationReport } =
    useGetParticipationReport(
      selectedOrganizations,
      startDate || firstYearDate,
      endDate || new Date()
    )
  const { isLoadingContentUtilizationReport, fetchContentUtilizationReport } =
    useGetContentUtilizationReport(
      selectedOrganizations,
      startDate || firstYearDate,
      endDate || new Date()
    )
  const { isLoadingMentalHealthMinutesReport, fetchMentalHealthMinutesReport } =
    useGetMentalHealthMinutesReport(
      selectedOrganizations,
      startDate || firstYearDate,
      endDate || new Date()
    )
  const { isLoadingVibeCheckDataReport, fetchVibeCheckDataReport } =
    useGetVibeCheckDataReport(
      selectedOrganizations,
      startDate || firstYearDate,
      endDate || new Date()
    )

  const { isLoadingEmailLeadsReport, fetchEmailLeadsReport } =
    useGetEmailLeadsReport(startDate || firstYearDate, endDate || new Date())

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const onCalendarClear = () => {
    setStartDate(null)
    setEndDate(null)
    setClearCalendar(true)
  }

  useEffect(() => {
    fetchOrganizationNames().then((response) => {
      const groupedOrganizations = Object.values(
        response.data.reduce((acc, org) => {
          const key = org.type || 'null'

          if (!acc[key]) {
            acc[key] = {
              type: org.type,
              organizations: [],
            }
          }
          acc[key].organizations.push(org)
          return acc
        }, {})
      )

      // Sort the organizations within each group by name
      groupedOrganizations.forEach((group) => {
        group.organizations.sort((a, b) => a.name.localeCompare(b.name))
      })

      // Sort the groups by type, handling null values
      groupedOrganizations.sort((a, b) => {
        if (a.type === null) return 1 // Put null type at the end
        if (b.type === null) return -1
        return a.type.localeCompare(b.type)
      })

      console.log(groupedOrganizations)

      setOrganizations(groupedOrganizations)
      //setSelectedOrganizations(response.data)
    })
  }, [])

  useEffect(() => {
    if (clearCalendar) setClearCalendar(false)
  }, [clearCalendar, setClearCalendar])

  return (
    <>
      <Heading textAlign="center">Reporting</Heading>
      <Heading fontSize="lg" mt="16px" textAlign="center">
        Filters
      </Heading>
      <Divider />
      <HStack w="100%" mt="16px">
        <VStack>
          {clearCalendar ? (
            <></>
          ) : (
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          )}
          <Button mt="16px" onPress={onCalendarClear}>
            Clear
          </Button>
        </VStack>
        <VStack ml="24px">
          <Checkbox.Group
            value={selectedOrganizations}
            onChange={setSelectedOrganizations}>
            {organizations.map((group) => (
              <Box key={group.type}>
                <Checkbox.Group
                  value={selectedOrganizationTypes}
                  onChange={setSelectedOrganizationTypes}>
                  <Checkbox
                    value={group.type}
                    onChange={(checked) => {
                      const groupOrgs = group.organizations.map(
                        (org) => org.name
                      )
                      if (checked)
                        setSelectedOrganizations((orgs) => [
                          ...orgs,
                          ...groupOrgs,
                        ])
                      else
                        setSelectedOrganizations((orgs) =>
                          orgs.filter((org) => !groupOrgs.includes(org))
                        )
                    }}>
                    {group.type || 'Other'}
                  </Checkbox>
                </Checkbox.Group>
                {group.organizations.map((organization) => (
                  <Checkbox
                    ml={10}
                    key={organization.name}
                    value={organization.name}
                    isDisabled={selectedOrganizationTypes.includes(group.type)}>
                    {organization.name}
                  </Checkbox>
                ))}
              </Box>
            ))}
          </Checkbox.Group>
        </VStack>
      </HStack>
      <Heading fontSize="lg" mt="16px" textAlign="center">
        Download
      </Heading>
      <Divider />
      <VStack width="250px" mb="24px">
        <Button
          mt="16px"
          onPress={fetchContentUtilizationReport}
          isLoading={isLoadingContentUtilizationReport}>
          Content
        </Button>
        <Button
          mt="16px"
          onPress={fetchParticipationReport}
          isLoading={isLoadingParticipationReport}>
          Group participation
        </Button>
        <Button
          mt="16px"
          onPress={fetchMemberReport}
          isLoading={isLoadingMemberReport}>
          Members
        </Button>
        <Button
          mt="16px"
          onPress={fetchMentalHealthMinutesReport}
          isLoading={isLoadingMentalHealthMinutesReport}>
          Mental Health Minutes
        </Button>
        <Button
          mt="16px"
          onPress={fetchVibeCheckDataReport}
          isLoading={isLoadingVibeCheckDataReport}>
          Vibe Check Data
        </Button>
        <Button
          mt="16px"
          onPress={fetchEmailLeadsReport}
          isLoading={isLoadingEmailLeadsReport}>
          Email Leads
        </Button>
      </VStack>
    </>
  )
}

export default Reports
