import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import Compass from '../../components/voyages/compass/Compass'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import secureScreen from '../../hooks/authentication/secureScreen'
import DefaultLayout from '../../layouts/DefaultLayout'

const CompassScreen = () => {
  const isDesktop = useDesktopBreakpoint()

  return (
    <DefaultLayout
      hasNavFooter={false}
      scrollView={isDesktop ? true : false}
      activeLabel="Voyage"
      bg={isDesktop ? 'muted.50' : 'white'}>
      {isDesktop ? (
        <FixedContainer size="md" width="1024px">
          <Compass />
        </FixedContainer>
      ) : (
        <Compass />
      )}
    </DefaultLayout>
  )
}

export default secureScreen(CompassScreen)
