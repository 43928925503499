import { HStack, Text } from 'native-base'
import { ActivityType } from '../../../../../domain/models/interfaces/activityInterfaces'
import FaIcon from '../FaIcon'

const types = {
  [ActivityType.VIDEO]: {
    icon: 'play',
    color: 'secondary.100',
    label: 'Video',
  },
  [ActivityType.ARTICLE]: {
    icon: 'book-open',
    color: 'primary.100',
    label: 'Article',
  },
  [ActivityType.AUDIO]: {
    icon: 'headphones',
    color: 'violet.100',
    label: 'Audio',
  },
  [ActivityType.MOMENT]: { icon: 'bolt', color: 'yellow.100', label: 'Moment' },
  [ActivityType.QUIZ]: { icon: 'sparkles', color: 'lime.100', label: 'Quiz' },
  [ActivityType.GROUP_LISTEN]: {
    icon: 'user-group',
    color: 'secondary.100',
    label: 'Listen In',
  },
  [ActivityType.GROUP_SUPPORT]: {
    icon: 'user-group',
    color: 'yellow.100',
    label: 'Support',
  },
  [ActivityType.GROUP_SKILL]: {
    icon: 'user-group',
    color: 'primary.100',
    label: 'Skill Builder',
  },
  [ActivityType.GROUP_SERIES]: {
    icon: 'user-group',
    color: 'yellow.100',
    label: 'Series',
  },
  [ActivityType.FOR_YOU]: {
    icon: 'user-group',
    color: 'blueGray.100',
    label: 'For You',
  },
  [ActivityType.ACTIVITY]: {
    icon: 'palette',
    color: 'tertiary.100',
    label: 'Activity',
  },
  [ActivityType.INTERACTIVE]: {
    icon: 'palette',
    color: 'lime.100',
    label: 'Typeform',
  },
  ['Duration']: { icon: '', color: 'muted.100' },
  ['Bookmark']: { icon: 'bookmark', color: 'muted.100', label: 'bookmark' },
  ['Bookmarked']: {
    icon: 'bookmark',
    color: 'muted.100',
    label: 'bookmarked',
  },
}

const Chip = ({
  type,
  marginRight = 1,
  duration = null,
  textTransform = 'uppercase',
  iconColor = 'text.900',
  fontWeight = 500,
}) => {
  const { icon, color } = types[type]
  const label = type === 'Duration' ? `${duration} min` : type

  return (
    <HStack
      marginRight={marginRight}
      alignItems="center"
      bg={color}
      height={6}
      paddingX={3}
      borderRadius={12}>
      {icon && (
        <FaIcon
          name={icon}
          color={iconColor}
          size={14}
          isSolid={type === 'Bookmarked'}
        />
      )}
      <Text
        fontSize={12}
        fontWeight={fontWeight}
        color="text.900"
        marginLeft={1}
        textTransform={textTransform}>
        {label}
      </Text>
    </HStack>
  )
}

export default Chip
