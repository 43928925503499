const addMicLevel = (array, level) => {
  array.unshift(level)
  return array.slice(0, 50)
}

const isAboveThreshold = (array, threshold) => {
  const average = array.reduce((a, b) => a + b) / array.length
  return average > threshold
}

export { isAboveThreshold, addMicLevel }
