import { Box, ZStack } from 'native-base'
import { Platform } from 'react-native'
import { AnimatedCircularProgress } from 'react-native-circular-progress'
import FaIcon from './FaIcon'

export default ({ doneMinutes, expectedMinutes, isTiny = false }) => {
  return (
    <ZStack
      alignItems="center"
      justifyContent="center"
      margin={Platform.OS === 'web' ? (isTiny ? '12px' : '24px') : 0}>
      <AnimatedCircularProgress
        size={isTiny ? 30 : 56}
        width={isTiny ? 2 : 5}
        backgroundWidth={isTiny ? 2 : 5}
        fill={(doneMinutes / expectedMinutes) * 100}
        tintColor="#479E9A"
        arcSweepAngle={300}
        backgroundColor="#B2ECEC"
        rotation={210}
        fillLineCap="round"
        lineCap="round"
      />
      <Box>
        <FaIcon
          name="star"
          color="yellow.500"
          size={isTiny ? 20 : 38}
          isSolid
        />
      </Box>
    </ZStack>
  )
}
