import { Box, Center, HStack, Switch } from 'native-base'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import FaIcon from '../../_shared/FaIcon'
import VideoFeed from '../VideoFeed'

const VideoPreview = ({}) => {
  const room = useRoomContext()
  const roomClient = useRoomClientContext()

  return (
    <>
      <Box bg="black" borderRadius="6px" overflow="hidden" mt="3">
        <Center>
          <VideoFeed borderRadius="0" isPreview />
        </Center>
      </Box>
      <HStack p="3" space="3">
        <HStack alignItems="center">
          <Switch
            isChecked={room.me.microphoneOn}
            onValueChange={room.toggleMicrophone}
            isDisabled={!roomClient.hasPermission}
            colorScheme="secondary"
            mr="1"
          />
          <FaIcon name="microphone" size={16} isSolid props={{ ml: '1' }} />
        </HStack>
        <HStack alignItems="center">
          <Switch
            isChecked={room.me.cameraOn}
            onValueChange={room.toggleCamera}
            isDisabled={!roomClient.hasPermission}
            colorScheme="secondary"
            mr="1"
          />
          <FaIcon name="video" size={16} props={{ ml: '1' }} />
        </HStack>
      </HStack>
    </>
  )
}

export default VideoPreview
