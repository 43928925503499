import { Flex, HStack } from 'native-base'
import useMicrophoneLevel from '../../hooks/useMicrophoneLevel'

const MicrophoneLevel = () => {
  const { level } = useMicrophoneLevel()

  return (
    <HStack space="2" mt="2">
      {[...Array(8)].map((_, i) => (
        <Flex
          key={i}
          flex={1}
          height="10px"
          bg={level >= (i + 1) / 8 ? 'secondary.500' : ''}
          borderColor="muted.300"
          borderWidth="1"
          borderRadius="md"
        />
      ))}
    </HStack>
  )
}

export default MicrophoneLevel
