import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  ScrollView,
  Text,
} from 'native-base'
import { useEffect, useMemo, useState } from 'react'
import { Animated, Platform, useWindowDimensions } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import BackButton from '../../components/navigation/BackButton'
import FormInput from '../../components/_shared/form/FormInput'
import FormLabel from '../../components/_shared/form/FormLabel'
import BottomSheet from '../../components/_shared/notification/BottomSheet'
import useMobileKeyboardListeners from '../../hooks/useMobileKeyboardListeners'
import FormLayout from '../../layouts/FormLayout'
import { useRequestOchsnerAccessCode } from './queries'

const OchsnerScreen = ({ navigation }) => {
  const [timer, setTimer] = useState(10)
  const timerEnded = timer === 0
  const [modalOpen, setModalOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [fadeAnim] = useState(new Animated.Value(0))
  const [backButtonEnabled, setBackButtonEnabled] = useState(false)
  const isMobile = useMobileBreakpoint()
  const { width } = useWindowDimensions()
  const { keyboardHeight, keyboardOpen } = useMobileKeyboardListeners()

  const onSuccess = () => {
    navigation.replace('Signup', {
      email,
    })
  }
  const onError = (data) => {
    setError(data)
  }

  const { requestCode, isLoading } = useRequestOchsnerAccessCode(
    onSuccess,
    onError
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) return --prev
        else return 0
      })
    }, 1000)

    return () => {
      setModalOpen(false)
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (timer === 6) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start()
      setBackButtonEnabled(true)
    }
  }, [timer])

  const bottomContent = useMemo(
    () => (
      <>
        {isMobile && (
          <>
            <Heading
              fontSize="18px"
              textAlign="center"
              mt="24px"
              color="primary.900"
              mb={isMobile ? '16px' : undefined}>
              {timerEnded
                ? 'Ready to Continue?'
                : 'Please read carefully to continue'}
            </Heading>
            <Divider />
          </>
        )}
        <Button
          my="16px"
          mx="24px"
          variant={timerEnded ? 'solid' : 'outline'}
          onPress={timerEnded ? () => setModalOpen(true) : undefined}>
          <Text color={timerEnded ? 'white' : 'primary.700'} fontWeight={500}>
            {timerEnded ? 'Request Access Code' : timer}
          </Text>
        </Button>
      </>
    ),
    [timer, timerEnded, isMobile]
  )

  return (
    <>
      <FormLayout
        padding="24px"
        hasKeyboardDismissOverlay={false}
        autoBackButton={false}>
        <ScrollView flexGrow={0} pb={isMobile ? '150px' : undefined}>
          <Animated.View
            style={{
              opacity: fadeAnim,
            }}>
            <BackButton hasText={true} isDisabled={!backButtonEnabled} />
          </Animated.View>
          <Heading
            fontSize="24px"
            textAlign="center"
            mb="3"
            color="primary.900"
            mt="16px">
            Ochsner Sign In
          </Heading>
          <Text textAlign="left" color="primary.900" fontSize="16px">
            {`We are working to get Ochsner Sign-In reinstated in Cabana. Don’t worry, your account and history still exists, we will just need you to create a password log-in.\n\nAs always, your information is private and we will never share it with your employer or outside parties.\n\nFollow these steps to create a password for your account:`}
          </Text>
          <HStack pt="40px">
            <Text
              color="primary.700"
              fontWeight="700"
              fontSize="24px"
              w="30px"
              top={-5}>
              1.
            </Text>
            <Text fontSize="16px" mr="32px" color="primary.900">
              Request an access code below.
            </Text>
          </HStack>
          <HStack pt="24px">
            <Text
              color="primary.700"
              fontWeight="700"
              fontSize="24px"
              w="30px"
              top={-5}>
              2.
            </Text>
            <Text fontSize="16px" mr="32px" color="primary.900">
              Check your inbox for the code.
            </Text>
          </HStack>
          <HStack pt="24px">
            <Text
              color="primary.700"
              fontWeight="700"
              fontSize="24px"
              w="30px"
              top={-5}>
              3.
            </Text>
            <Text
              fontSize="16px"
              ml={Platform.OS === 'web' ? '8px' : undefined}
              mr="32px"
              color="primary.900">
              Return to Cabana to create your account using your Ochsner email,
              a password, and the access code.
            </Text>
          </HStack>
          <Text
            textAlign="center"
            fontSize="16px"
            mt="24px"
            color="primary.900">{`Thanks for your patience\nand understanding!`}</Text>
          {!isMobile && bottomContent}
        </ScrollView>

        <BottomSheet
          body={
            <Box mb={keyboardOpen ? keyboardHeight - 30 : 0}>
              <Text color="primary.700" mb="24px">
                Use your Ochsner email address for verification.{' '}
              </Text>
              <FormInput
                keyboardType="email-address"
                label={<FormLabel>Work email</FormLabel>}
                onChangeText={setEmail}
                placeholder="email@ochsner.org"
                autofocus
              />
              <Button
                onPress={() => {
                  setError(false)
                  requestCode(email)
                }}>
                Verify email
              </Button>
              <Text color="primary.700" mt="12px">
                Check your inbox for the code, you’ll input it in the next page.
              </Text>
              {error && (
                <Box mt="16px" p="12px" bgColor="error.200" borderRadius="4px">
                  <Text color="text.900">{error}</Text>
                </Box>
              )}
            </Box>
          }
          isOpen={modalOpen}
          title="Verify your email"
          handleClose={() => setModalOpen(false)}
          hasCloseOnBottom={false}
        />
      </FormLayout>
      {isMobile && (
        <Box
          position="absolute"
          width={width}
          bottom={-200}
          backgroundColor="white"
          shadow="5"
          bottom="0px"
          pb="20px"
          borderTopRadius="10px">
          {bottomContent}
        </Box>
      )}
    </>
  )
}

export default OchsnerScreen
