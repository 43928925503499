import { HStack, Text, VStack } from 'native-base'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import FaIcon from '../_shared/FaIcon'

const FocusAreaHeading = ({
  color,
  name,
  description,
  icon,
  fontSize = '20px',
  size = 8,
  iconSize = 20,
  ml = 2.5,
  pathName = '',
}) => {
  const isDesktop = useDesktopBreakpoint()
  return (
    <VStack>
      <HStack alignItems="center" mb={2}>
        <HStack
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          width={size}
          height={size}
          background={color}>
          <FaIcon name={icon} isSolid color="white" size={iconSize} />
        </HStack>
        <Text fontSize={fontSize} ml={ml} color="muted.900" fontWeight={500}>
          {name}
        </Text>
      </HStack>
      {pathName && (
        <Text color="primary.900" fontSize="24px" fontWeight={500}>
          {pathName}
        </Text>
      )}
      <Text color="primary.900" mb={isDesktop ? 0 : 6}>
        {description}
      </Text>
    </VStack>
  )
}

export default FocusAreaHeading
