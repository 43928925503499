import { faStar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Flex, HStack, IconButton, Text } from 'native-base'
import FaIcon from '../FaIcon'

const StarRating = ({ rating, setRating, scale = 5, label = null }) => {
  /* Render */
  const stars = []
  for (let i = 1; i <= scale; i++) {
    stars.push(
      <IconButton
        key={i}
        variant="ghost"
        size="md"
        p="1.5"
        icon={
          rating >= i ? (
            <FaIcon name="star" color="yellow.500" size={20} isSolid />
          ) : (
            // color: muted.400
            <FontAwesomeIcon icon={faStar} color="#A3A3A3" size={20} />
          )
        }
        borderRadius="full"
        onPress={() => setRating(i)}
      />
    )
  }

  return (
    <HStack alignItems="center">
      {label && (
        <Flex flex={1}>
          <Text>{label}</Text>
        </Flex>
      )}
      {stars}
    </HStack>
  )
}

export default StarRating
