import { Button, Flex, HStack, Image, Text, View, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import Sheet from '../_shared/card/Sheet'

const ModalImage = require('../../assets/images/voyages/modal.png')

type LiveGroupModalProps = {
  show: boolean
  closeModal?: () => void
  heading: string
  body: string
  groupInactive?: boolean
  exit: () => void
}

const VoyagesScreenerModal = ({
  show,
  closeModal,
  heading,
  body,
  exit,
}: LiveGroupModalProps) => {
  const { height, width } = useWindowDimensions()
  const isDesktop = useDesktopBreakpoint()

  return (
    <View>
      <Modal
        animationIn={'slideInUp'}
        animationOut="slideOutDown"
        isVisible={show}
        deviceHeight={height}
        deviceWidth={width}
        onBackdropPress={closeModal}>
        <Sheet
          props={{
            w: '320px',
            alignSelf: 'center',
            padding: '16px',
          }}>
          <VStack h={isDesktop ? '100%' : '390px'}>
            <Flex alignItems="center" py={3}>
              <Image
                source={ModalImage}
                alt="smiling crab"
                width={270}
                height={210}
              />
              <Text
                mb="8px"
                fontSize="18px"
                color="primary.900"
                fontWeight={700}>
                {heading}
              </Text>
              <Text color="primary.900" textAlign="center">
                {body}
              </Text>
            </Flex>
            <HStack space={2} w="100%" mt={6}>
              <Button
                w="140px"
                h={!isDesktop ? '100%' : '37px'}
                variant="outline"
                onPress={exit}>
                <Text color="primary.700" fontWeight={500}>
                  Exit
                </Text>
              </Button>
              <Button
                w="140px"
                h={!isDesktop ? '100%' : '37px'}
                onPress={closeModal}>
                <Text color="white" fontWeight={600}>
                  Cancel
                </Text>
              </Button>
            </HStack>
          </VStack>
        </Sheet>
      </Modal>
    </View>
  )
}

export default VoyagesScreenerModal
