import { Alert, Box, Button, HStack, Input, Text, View } from 'native-base'
import { useState } from 'react'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import RosterItem from '../RosterItem'

const AliasEntry = ({}) => {
  const roomClient = useRoomClientContext()
  const room = useRoomContext()
  const [value, setValue] = useState(room.me.alias ?? '')
  const isSaveDisabled = value === ''
  const [aliasError, setAliasError] = useState('')

  const onSubmitAlias = () => {
    room
      .setAlias(value)
      .then(() => setAliasError(''))
      .catch((error: string) => setAliasError(error))
      .finally(() => roomClient.requestPermission())
  }

  return (
    <View>
      <Text>This is what you'll be called in the group</Text>
      {aliasError !== '' && (
        <Alert colorScheme="error" variant="subtle">
          <Text w="100%" color="text.900">
            {aliasError}
          </Text>
        </Alert>
      )}
      <HStack mt="1" mb="5">
        <Input
          value={value}
          size="lg"
          flex={1}
          onChangeText={setValue}
          onSubmitEditing={onSubmitAlias}
        />
        <Button
          colorScheme="primary"
          ml="3"
          isDisabled={isSaveDisabled}
          onPress={onSubmitAlias}>
          Save
        </Button>
      </HStack>
      <Text>This is what you'll look like in the group</Text>
      <Box mt="16px">
        <RosterItem
          attendee={{
            alias: room.me.alias,
            seatNumber: room.me.seatNumber,
          }}
        />
      </Box>
    </View>
  )
}

export default AliasEntry
