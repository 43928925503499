import { Button, Flex, HStack, IconButton, Input, Text } from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import FaIcon from '../_shared/FaIcon'

const ChatInput = ({
  onSelectionChange,
  inputRef,
  room,
  RoomApi,
  emojis,
  sendMessage,
}) => {
  const isMobile = useMobileBreakpoint()
  const [inputValue, setInputValue] = useState('')
  const [typingTimeout, setTypingTimeout] = useState(null)

  const inputCursorRef = useRef(0)

  const handleStartTyping = async () => {
    await RoomApi.startTyping(room.roomId)
  }

  const handleStopTyping = async () => {
    await RoomApi.stopTyping(room.roomId)
    setTypingTimeout(null)
  }

  useEffect(() => {
    return () => {
      if (typingTimeout) clearTimeout(typingTimeout)
    }
  }, [typingTimeout])

  const handleSend = () => {
    if (inputValue) {
      sendMessage(inputValue)
      setInputValue('')
      handleStopTyping()
    }
  }

  const sendOnEnter = (event) => {
    if (event.nativeEvent.key === 'Enter') {
      event.preventDefault() // Prevent line break inside input
      handleSend()
    }
  }

  const handleKeyPress = (event) => {
    if (!isMobile) sendOnEnter(event)
    if (!typingTimeout) handleStartTyping()
    if (typingTimeout) clearTimeout(typingTimeout)
    setTypingTimeout(
      setTimeout(() => {
        handleStopTyping()
      }, 2000)
    )
  }

  const insertEmoji = (emojiHex) => {
    const value = inputValue
    const inputCursor = inputCursorRef.current
    const textBefore = value.substring(0, inputCursor)
    const textAfter = value.substring(inputCursor, value.length)
    setInputValue(textBefore + emojiHex + textAfter)
    inputCursorRef.current = inputCursor + 2
    inputRef.current.focus()
  }

  return (
    <>
      <HStack alignItems="flex-end" mt="2">
        <Flex flex={1}>
          {Platform.OS === 'web' ? (
            <Input
              ref={inputRef}
              value={inputValue}
              size="md"
              placeholder="Enter a message"
              multiline={true}
              pr="10"
              onSelectionChange={onSelectionChange}
              onChangeText={setInputValue}
              onKeyPress={handleKeyPress}
            />
          ) : (
            <Input
              value={inputValue}
              size="md"
              placeholder="Enter a message"
              multiline={true}
              maxHeight={110}
              pr="10"
              onChangeText={setInputValue}
              onKeyPress={handleKeyPress}
            />
          )}
          <IconButton
            icon={
              <FaIcon
                name="paper-plane-top"
                size={20}
                color={inputValue ? 'secondary.500' : 'muted.400'}
              />
            }
            position="absolute"
            top="0"
            right="0"
            variant="ghost"
            borderRadius="full"
            onPress={handleSend}
          />
        </Flex>
      </HStack>
      <HStack>
        {Platform.OS === 'web' &&
          emojis.map((emoji) => (
            <Button
              key={emoji.hex}
              py="1"
              px="2"
              variant="ghost"
              borderRadius="full"
              onPress={() => insertEmoji(emoji.hex)}>
              <Text fontSize="md">{emoji.hex}</Text>
            </Button>
          ))}
      </HStack>
    </>
  )
}

export default ChatInput
