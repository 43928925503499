import { Image, Text, View } from 'native-base'
const Jelly = require('../assets/images/my-groups/jelly.png')

const OfflineScreen = () => {
  return (
    <View flex={1} justifyContent="center" alignItems="center">
      <Image
        style={{ height: 168, width: 200 }}
        source={Jelly}
        alt="squid image"
        marginBottom="32px"
      />
      <Text
        fontSize="18px"
        fontWeight={700}
        color="primary.900"
        marginBottom="8px">
        Uh oh! Cabana is offline
      </Text>
      <Text fontSize="14px" color="primary.900" textAlign="center">
        We're down for maintenance. {'\n'}
        We'll be back soon. {'\n'}
        Thank you for your patience.
      </Text>
    </View>
  )
}

export default OfflineScreen
