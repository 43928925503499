const loaderMessages = [
  {
    message:
      'You are not alone in your healing journey. Open your heart up to those who want to support you.',
  },
  {
    message:
      'Your feelings are valid and deserve to be heard. There are no right or wrong feelings here.',
  },
  {
    message:
      "Taking care of your mental health is a courageous act of self-love. You're taking the first step toward healing.",
  },
  {
    message:
      'Your experiences matter. I am worthy of sharing my lived experience.',
  },
  {
    message:
      'Remember, progress is not always linear. Each small step forward is a victory on your path to healing.',
  },
  {
    message:
      'You have the strength within you to overcome any challenges you face. Believe in your resilience and embrace hope.',
  },
  {
    message:
      'Moving forward, sometimes mean going back. Allow yourself to go where you grow.',
  },
]

const useRandomLoaderMessage = () => {
  const rng = Math.floor(Math.random() * loaderMessages.length)
  return loaderMessages[rng]
}

export default useRandomLoaderMessage
