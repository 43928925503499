import { Box, HStack, Image, VStack } from 'native-base'
import { ImageBackground } from 'react-native'

const Wave = require('../../assets/images/moderators/WaveDesktop.png')

const ModeratorHeaderDesktop = ({ moderator }) => {
  return (
    <VStack height="160px" borderTopRadius="20px" overflow="hidden">
      <ImageBackground
        source={Wave}
        style={{
          width: '100%',
        }}>
        <HStack
          pb={2}
          flex={1}
          px={4}
          flexDirection="row"
          alignItems="center"
          justifyContent="center">
          <Box position="relative" top={60} alignItems="center">
            <Image
              borderColor={'yellow.500'}
              borderWidth={2}
              source={{ uri: moderator.photoUrl }}
              alt="Moderator photo"
              borderRadius="full"
              width="100px"
              height="100px"
            />
          </Box>
        </HStack>
      </ImageBackground>
    </VStack>
  )
}

export default ModeratorHeaderDesktop
