import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

const useInvalidateQueriesInTimestamp = (msToInvalidation, queryKey) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    const timeout = setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: queryKey })
    }, msToInvalidation)

    return () => clearTimeout(timeout)
  }, [queryClient])
}

export default useInvalidateQueriesInTimestamp
