import { format } from 'date-fns'
import { Box, Center, Pressable, Text, VStack } from 'native-base'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import useGetOasisColors from '../../../hooks/useGetOasisColors'

const DateItem = ({
  isToday,
  isSelected,
  date,
  onPress,
  width = '35px',
  isDisabled,
}) => {
  const { tabColor } = useGetOasisColors()
  const isDesktop = useDesktopBreakpoint()

  return (
    <Pressable
      onPress={!isDisabled ? onPress : null}
      style={{ cursor: isDisabled ? 'default' : 'pointer' }}>
      {!isDesktop ? (
        <VStack ml={'27px'} pt={'16px'}>
          <Center w={width}>
            <Text color="#FFFFFF">{format(date, 'EEE').toUpperCase()}</Text>
            <Box
              alignItems="center"
              justifyContent="center"
              rounded="full"
              borderRadius={16}
              w="32px"
              h="32px"
              mt="8px"
              borderColor={isToday || isSelected ? 'white' : tabColor}
              borderWidth={isToday || isSelected ? 0.9 : null}
              backgroundColor={isSelected ? 'white' : tabColor}>
              <Text color={isSelected ? tabColor : '#FFFFFF'}>
                {date.getDate()}
              </Text>
            </Box>
          </Center>
        </VStack>
      ) : (
        <Box
          alignItems="center"
          justifyContent="center"
          rounded="full"
          w="32px"
          h="32px"
          borderRadius="full"
          borderColor={isToday || isSelected ? tabColor : 'white'}
          borderWidth={isToday || isSelected ? 0.9 : null}
          backgroundColor={isSelected ? tabColor : 'white'}>
          <Text
            color={isDisabled ? 'gray.300' : isSelected ? 'white' : 'black'}>
            {date.getDate()}
          </Text>
        </Box>
      )}
    </Pressable>
  )
}

export default DateItem
