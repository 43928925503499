import { LinearGradient } from 'expo-linear-gradient'
import { Flex } from 'native-base'
import { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

const Orb = ({ attendee = null, size = '16px', participantColor = null }) => {
  /* State */
  const [gradient, setGradient] = useState(null)

  /* Hooks */
  // Get orb color
  useEffect(() => {
    if (attendee?.seatNumber != null) {
      // [ darkColor, lightColor ]
      const gradients = [
        ['#32FFF1', '#B5FFFF'],
        ['#71FF48', '#E0FFB0'],
        ['#3C45FF', '#A3ABFF'],
        ['#FF814B', '#FFCF94'],
        ['#FF59FF', '#FFCBFF'],
        ['#F23436', '#FF777B'],
      ]

      const gradient = gradients[attendee.seatNumber % gradients.length]
      setGradient(gradient)
    }
  }, [attendee])

  useEffect(() => {
    if (participantColor) {
      const lightColor = lightenColor(participantColor, 0.5)
      setGradient([participantColor, lightColor])
    }
  }, [participantColor])

  const lightenColor = (color, amount) => {
    let usePound = false

    if (color[0] === '#') {
      color = color.slice(1)
      usePound = true
    }

    const num = parseInt(color, 16)
    let r = (num >> 16) + amount * 255
    let g = ((num >> 8) & 0x00ff) + amount * 255
    let b = (num & 0x0000ff) + amount * 255

    r = Math.min(255, Math.max(0, r))
    g = Math.min(255, Math.max(0, g))
    b = Math.min(255, Math.max(0, b))

    return (
      (usePound ? '#' : '') +
      ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')
    )
  }

  return (
    <Flex size={size} borderRadius="full" bg={!gradient && 'muted.400'}>
      {gradient && (
        <LinearGradient
          colors={gradient}
          start={[0, 0.5]}
          end={[0.5, 1]}
          style={styles.gradient}
        />
      )}
    </Flex>
  )
}

const styles = StyleSheet.create({
  gradient: {
    height: '100%',
    width: '100%',
    borderRadius: 9999,
  },
})

export default Orb
