import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Image, Pressable, Text } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { CollectionCardProps } from '../../../../domain/models/interfaces/cardInterfaces'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import { maxStringLength } from '../../../helpers/maxStringLength'
import { NavigationParams } from '../../screens/NavigationScreen'

interface CollectionCard {
  collectionCard: CollectionCardProps
  cardWidth?: string
  mr?: number
  isCollectionsScreen?: boolean
}

const CollectionCard = ({
  collectionCard,
  cardWidth = '304px',
  mr = 0,
  isCollectionsScreen = false,
}: CollectionCard) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { width } = useWindowDimensions()
  const isMobile = useMobileBreakpoint()
  const cardDimensions = width / 2 - 24

  return (
    <Pressable
      mr={mr}
      width={isMobile ? cardDimensions : cardWidth}
      onPress={() =>
        navigation.navigate('Collection', { slug: collectionCard.slug })
      }>
      <Image
        borderRadius={'12px'}
        width="100%"
        height={isMobile ? cardDimensions : '140px'}
        source={{ uri: collectionCard.thumbnail }}
        alt={collectionCard.title}
        mb={3}
      />
      <Text
        fontSize={isCollectionsScreen ? '16px' : '14px'}
        fontWeight={'medium'}
        lineHeight="1.3em">
        {maxStringLength(collectionCard.title, 30)}
      </Text>
      {!isMobile && (
        <Text mb={6} fontSize="12px">
          {collectionCard.summary}
        </Text>
      )}
    </Pressable>
  )
}

export default CollectionCard
