import { Flex, View } from 'native-base'
import { ImageBackground, StyleSheet } from 'react-native'
import AttendeeState from '../../../domain/enums/AttendeeState'
import RoomActions from '../components/room/room-desktop/RoomFooter'
import useRoomContext from '../providers/RoomProvider'

const RoomLayout = ({ actions, children }) => {
  const room = useRoomContext()

  return (
    <View h="100%" bg="muted.700">
      <ImageBackground
        source={{
          uri:
            room.me.state === AttendeeState.Joined &&
            require('../assets/images/room/background-beach.jpg'),
        }}
        resizeMode="cover"
        style={styles.image}>
        <Flex flex={1}>{children}</Flex>
      </ImageBackground>
      <RoomActions actions={actions} />
    </View>
  )
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    justifyContent: 'center',
  },
})

export default RoomLayout
