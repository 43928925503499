import {
  CommonActions,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native'
import { useCallback, useMemo } from 'react'
import { useMobileBreakpoint } from '../../../domain/services/useBreakpoint'

const useTabNavigation = () => {
  const navigation = useNavigation()
  const navigationStack = useNavigationState((state) => state.routes)

  const isMobile = useMobileBreakpoint()

  const tabScreens = useMemo(
    () =>
      isMobile
        ? ['Home', 'Explore', 'Voyage', 'Profile']
        : ['Home', 'Explore', 'Voyage', 'Profile', 'Admin', 'Search'],
    [isMobile]
  )

  const navigate = useCallback(
    (screenName, params) => {
      const index = navigationStack.findIndex(
        (route) => route.name === screenName
      )

      if (tabScreens.includes(screenName) && index !== -1) {
        navigation.dispatch((state) => {
          let routes = state.routes.filter((r) => r.name !== screenName)
          const route = state.routes.find((r) => r.name === screenName)
          routes.push(route)

          return CommonActions.reset({
            ...state,
            routes,
            index: routes.length - 1,
          })
        })
      } else {
        navigation.navigate(screenName, params)
      }
    },
    [navigation, navigationStack, tabScreens]
  )

  return { navigate }
}

export default useTabNavigation
