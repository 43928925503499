import { HStack, Text } from 'native-base'

const VoyageChip = ({ text, bg = 'muted.200' }) => {
  return (
    <HStack
      minWidth="44px"
      alignItems="center"
      justifyContent="center"
      borderRadius="48px"
      bg={bg}
      px={2}
      mr={2}
      height="24px">
      <Text fontWeight={500} fontSize="12px" color="muted.900">
        {text}
      </Text>
    </HStack>
  )
}

export default VoyageChip
