import { Center, Flex, Text } from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import AttendeeRole from '../../../../domain/enums/AttendeeRole'
import useRoomClientContext from '../../providers/RoomClientProvider'
import useRoomContext from '../../providers/RoomProvider'
import FaIcon from '../_shared/FaIcon'

const VideoFeed = ({
  height = '360px',
  borderRadius = '6',
  isPreview = false,
}) => {
  /* State */
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const { width } = useWindowDimensions()

  const [cameraTrack, setCameraTrack] = useState(null)
  const [screenTrack, setScreenTrack] = useState(null)

  const mainVideo = useRef() // createRef<HTMLVideoElement>()
  const nestedVideo = useRef() // createRef<HTMLVideoElement>()

  /* Hooks */
  // Attach tracks to video HTML elements
  useEffect(() => {
    const cameraTrack =
      roomClient.cameraTrack ||
      roomClient.remoteCameraTracks[0]?.mediaStreamTrack

    const screenTrack =
      roomClient.screenVideoTrack ||
      roomClient.remoteScreenVideoTracks[0]?.mediaStreamTrack

    const attach = (videoRef, track: MediaStreamTrack) => {
      const stream = new MediaStream([track])
      videoRef.current.srcObject = stream
    }

    setCameraTrack(cameraTrack)
    setScreenTrack(screenTrack)

    if (screenTrack) {
      attach(mainVideo, screenTrack)
      if (cameraTrack) attach(nestedVideo, cameraTrack)
    } else if (cameraTrack) {
      attach(mainVideo, cameraTrack)
    }
  }, [
    roomClient.cameraTrack,
    roomClient.screenVideoTrack,
    roomClient.remoteCameraTracks,
    roomClient.remoteScreenVideoTracks,
  ])

  const videoWidth = width <= 1350 && width >= 991 ? width - 715 : 640

  return (
    <Flex
      maxWidth={room.active || isPreview ? videoWidth : 167}
      h={room.active || isPreview ? height : 92}
      bg="black"
      borderRadius={borderRadius}>
      <video
        ref={mainVideo}
        playsInline
        autoPlay
        muted
        style={{
          width: room.active || isPreview ? '100%' : 167,
          height: room.active || isPreview ? height : 92,
          borderRadius: borderRadius,
          objectFit: 'cover',
        }}
      />
      {!room.moderator.cameraOn && !room.moderator.screenOn && (
        <Center position="absolute" bg="#262626" width="100%" height="100%">
          <Center>
            <FaIcon name="video-slash" size={28} color="text.50" />
            <Text color="text.50" ml="8px" fontSize="lg" mt="8px">
              {room.me.role === AttendeeRole.Moderator
                ? 'Your camera is off'
                : 'Moderator camera is off'}
            </Text>
          </Center>
        </Center>
      )}
      <Flex
        position="absolute"
        top="8px"
        right="8px"
        bg="muted.900"
        opacity={
          room.moderator.cameraOn && room.moderator.screenOn ? '1' : '0'
        }>
        <Center my="auto">
          <video
            ref={nestedVideo}
            playsInline
            autoPlay
            muted
            style={{ maxWidth: 160, maxHeight: 90, borderRadius: 6 }}
          />
        </Center>
      </Flex>
    </Flex>
  )
}

export default VideoFeed
