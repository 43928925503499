import { Box } from 'native-base'

const MomentFrame = ({ momentUrl }) => {
  return (
    <Box bg="black" width="100%" height="100%">
      <iframe src={momentUrl} width="100%" height="100%" />
    </Box>
  )
}

export default MomentFrame
