import { Box, FlatList, Pressable, Text, VStack } from 'native-base'
import { useCallback } from 'react'
import { useWindowDimensions } from 'react-native'
import { CategoryFilterViewProps } from '../../../../domain/models/interfaces/explore/searchInterfaces'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
  useTabletBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import { CategoryFilters } from '../../constants/filterConstants'
import FaIcon from '../_shared/FaIcon'

const CategoryFilterView = ({
  appliedFilters = [],
  setFilters = () => {},
  onRemoveAllFilters = () => {},
  horizontalMargin = 24,
  inExploreScreen = false,
  onPressFilter = () => {},
}: CategoryFilterViewProps) => {
  const filters = CategoryFilters(inExploreScreen)

  const { width } = useWindowDimensions()
  const isDesktop = useDesktopBreakpoint()
  const isMobile = useMobileBreakpoint()
  const isTablet = useTabletBreakpoint()

  const itemCount = !isMobile && inExploreScreen ? 3 : 2
  const screenWidth = !isDesktop ? width : 410
  const filterOptionWidth =
    isTablet && !inExploreScreen
      ? 342
      : (screenWidth - 16 * (itemCount - 1) - horizontalMargin * 2) / itemCount

  const applyFilter = useCallback((filter) => {
    setFilters((prevFilters) => {
      return [...prevFilters, filter]
    })
  }, [])

  const removeFilter = useCallback((filter) => {
    setFilters((prevFilters) => {
      if (prevFilters.length === 1) {
        onRemoveAllFilters()
      }
      return prevFilters.filter((prevFilter) => prevFilter !== filter)
    })
  }, [])

  const removeAllFilters = useCallback(() => {
    onRemoveAllFilters()
    setFilters([])
  }, [])

  return (
    <VStack>
      <FlatList
        data={filters}
        numColumns={isMobile || !inExploreScreen ? (isTablet ? 1 : 2) : 3}
        scrollEnabled={false} // Disabled scroll because of virtualized list warning but it still throws the warning until we update to RN 0.71
        renderItem={({ item }) => {
          const isApplied = appliedFilters.includes(item.name)
          return (
            <Pressable
              onPress={() => {
                onPressFilter(item)
                isApplied ? removeFilter(item.name) : applyFilter(item.name)
              }}>
              <Box
                width={
                  !isDesktop || !inExploreScreen ? filterOptionWidth : '303px'
                }
                height={
                  isMobile || (!inExploreScreen && !isTablet)
                    ? filterOptionWidth / 3
                    : '56px'
                }
                borderWidth="1px"
                borderColor="primary.700"
                backgroundColor={isApplied ? 'primary.700' : 'white'}
                borderRadius="10px"
                mr="16px"
                my="7px"
                justifyContent="center">
                <Box flexDir="row" justifyContent="center" alignItems="center">
                  <FaIcon
                    name={item.icon}
                    color={isApplied ? 'white' : 'primary.700'}
                    size={20}
                  />
                  <Text
                    pl="8px"
                    alignSelf="center"
                    color={isApplied ? 'white' : 'primary.700'}
                    fontWeight="500">
                    {item.name}
                  </Text>
                </Box>
              </Box>
            </Pressable>
          )
        }}
      />
      {!isTablet && appliedFilters.length > 0 && (
        <Pressable
          bottom="-8px"
          right="8px"
          onPress={removeAllFilters}
          position="absolute"
          p="8px">
          <Text color="secondary.600" fontWeight="500">
            Clear
          </Text>
        </Pressable>
      )}
    </VStack>
  )
}

export default CategoryFilterView
