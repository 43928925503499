/* istanbul ignore file */
import { useEffect, useRef, useState } from 'react'
import useMicrophoneLevel from './useMicrophoneLevel'

const SPEAKING_THRESHOLD = 0.5 // Adjust based on testing, above baseline noise
const SPEAKING_PERCENTAGE = 0.5 // Percentage of levels that need to exceed threshold
const SAMPLE_WINDOW = 2000 // sampling window in milliseconds
const SAMPLE_INTERVAL = 160 // sampling interval in milliseconds

const useSpeakingDetection = () => {
  const { level } = useMicrophoneLevel()
  const currentLevel = useRef(0)
  const sampledLevels = useRef([])
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false)

  useEffect(() => {
    currentLevel.current = level
  }, [level])

  useEffect(() => {
    const intervalId = setInterval(() => {
      sampledLevels.current = [
        ...sampledLevels.current,
        currentLevel.current,
      ].slice(-SAMPLE_WINDOW / SAMPLE_INTERVAL)

      const speakingCount = sampledLevels.current.filter(
        (level) => level > SPEAKING_THRESHOLD
      ).length
      const speakingRatio = speakingCount / sampledLevels.current.length
      setIsSpeaking(speakingRatio > SPEAKING_PERCENTAGE)
    }, SAMPLE_INTERVAL)

    return () => clearInterval(intervalId)
  }, [])

  return { isSpeaking }
}

export default useSpeakingDetection
