import { Image, Pressable, Text } from 'native-base'

const ImageButton = ({ label, imageUri, isDisabled = false, handlePress }) => {
  return (
    <Pressable
      w="342px"
      h="72px"
      mb="5"
      onPress={handlePress}
      borderRadius="lg"
      shadow={isDisabled ? null : '1'}>
      <Image
        source={imageUri}
        alt={label}
        w="100%"
        h="100%"
        borderRadius="lg"
        opacity={isDisabled ? '0.4' : '1'}
      />
      <Text
        position="absolute"
        top="22.5px"
        w="100%"
        color="white"
        fontSize="lg"
        fontWeight="medium"
        textAlign="center">
        {label}
      </Text>
    </Pressable>
  )
}

export default ImageButton
