import { useFocusEffect } from '@react-navigation/native'
import { Box, HStack, Text } from 'native-base'
import { useCallback, useState } from 'react'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import BackButton from '../../components/navigation/BackButton'
import VoyagesAboutYou from '../../components/voyages/VoyagesAboutYou'
import VoyagesIntro from '../../components/voyages/VoyagesIntro'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import secureScreen from '../../hooks/authentication/secureScreen'
import DefaultLayout from '../../layouts/DefaultLayout'
import useVoyagesScreenerContext from '../../providers/VoyagesScreenerProvider'

const VoyagesScreen = () => {
  const isDesktop = useDesktopBreakpoint()
  const [showIntro, setShowIntro] = useState(true)
  const { setSelectedAnswers } = useVoyagesScreenerContext()

  useFocusEffect(
    useCallback(() => {
      setShowIntro(true)
      setSelectedAnswers([])
      return () => {}
    }, [])
  )

  return (
    <DefaultLayout
      bg={!isDesktop ? 'white' : 'muted.50'}
      activeLabel="Voyage"
      scrollView={!isDesktop ? false : true}
      hasScrollBar={!isDesktop ? false : true}
      hasNavFooter={!isDesktop && !showIntro ? false : true}>
      {isDesktop ? (
        <FixedContainer width="1024px" size="md">
          {showIntro ? (
            <Text
              color="primary.900"
              fontSize="24px"
              fontWeight={700}
              mt={4}
              mb={6}>
              Voyage
            </Text>
          ) : (
            <HStack my={2} justifyContent="flex-start" alignItems="center">
              <Box>
                <BackButton onPress={() => setShowIntro(true)} />
              </Box>
            </HStack>
          )}
          <Box
            p={10}
            bg="white"
            borderColor={isDesktop ? 'grey.200' : 'transparent'}
            borderWidth={isDesktop ? 1 : 0}
            borderRadius={'20px'}
            mb={isDesktop ? 10 : 0}>
            {showIntro ? (
              <VoyagesIntro onPress={() => setShowIntro(false)} />
            ) : (
              <VoyagesAboutYou onPress={() => setShowIntro(true)} />
            )}
          </Box>
        </FixedContainer>
      ) : showIntro ? (
        <VoyagesIntro onPress={() => setShowIntro(false)} />
      ) : (
        <VoyagesAboutYou onPress={() => setShowIntro(true)} />
      )}
    </DefaultLayout>
  )
}

export default secureScreen(VoyagesScreen)
