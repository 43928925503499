import { Flex, HStack } from 'native-base'
import { useMemo } from 'react'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import Caption from '../../_shared/text/Caption'

const MicMeasurement = () => {
  /* State */
  const room = useRoomContext()
  const roomClient = useRoomClientContext()

  /* Render */
  const content = useMemo(() => {
    if (roomClient.micLevel > -1) {
      let res = []
      for (let i = 1; i <= 8; i++) {
        res.push(
          <Flex
            key={i}
            flex={1}
            height="10px"
            bg={
              roomClient.hasPermission &&
              room.me.microphoneOn &&
              roomClient.micLevel >= i
                ? 'secondary.500'
                : ''
            }
            borderColor="muted.300"
            borderWidth="1"
            borderRadius="md"
          />
        )
      }
      return res
    } else {
      return <Caption>Mic measurement is not available in Expo</Caption>
    }
  }, [roomClient.micLevel, roomClient.hasPermission, room.me.microphoneOn])
  return (
    <HStack space="2" mt="2">
      {content}
    </HStack>
  )
}

export default MicMeasurement
