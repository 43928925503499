import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

function RewardActive(props) {
  return (
    <Svg
      width={144}
      height={96}
      viewBox="0 0 144 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G clipPath="url(#clip0_10844_34400)">
        <Path d="M136 57.774H46V42.887h90v14.887z" fill="#FDBA74" />
        <Path
          opacity={0.8}
          d="M56 28c-5.516 0-10 4.45-10 9.925v4.962h90v-4.962C136 32.45 131.516 28 126 28H56zm80 29.774H46v20.301C46 83.55 50.484 88 56 88h70c5.516 0 10-4.45 10-9.925v-20.3zM63.5 68.15h10c1.375 0 2.5 1.117 2.5 2.482 0 1.364-1.125 2.48-2.5 2.48h-10c-1.375 0-2.5-1.116-2.5-2.48 0-1.365 1.125-2.482 2.5-2.482zM81 70.632c0-1.365 1.125-2.482 2.5-2.482h20c1.375 0 2.5 1.117 2.5 2.482 0 1.364-1.125 2.48-2.5 2.48h-20c-1.375 0-2.5-1.116-2.5-2.48z"
          fill="#FFD48D"
        />
      </G>
      <Path
        d="M29.873 22.076c.034-1.283-.382-2.479-1.248-3.588-.867-1.11-2.15-1.803-3.848-2.08l-2.444 13.884c1.941.555 3.501 1.11 4.68 1.664a7.819 7.819 0 013.068 2.34c.832 1.005 1.248 2.34 1.248 4.004 0 .728-.07 1.439-.208 2.132-.277 1.63-.953 3.19-2.028 4.68-1.04 1.456-2.444 2.67-4.212 3.64-1.768.97-3.796 1.508-6.084 1.612l-.728 4.16H15.78l.728-4.212c-2.774-.243-4.975-1.11-6.604-2.6-1.595-1.49-2.392-3.38-2.392-5.668 0-.243.034-.676.104-1.3h3.848a6.464 6.464 0 001.196 4.264c.936 1.248 2.41 2.01 4.42 2.288l2.496-14.352c-1.976-.555-3.57-1.092-4.784-1.612a7.819 7.819 0 01-3.068-2.34c-.832-1.04-1.248-2.392-1.248-4.056 0-.381.07-1.057.208-2.028.485-2.773 1.837-5.044 4.056-6.812 2.253-1.768 5.026-2.721 8.32-2.86l.78-4.368h2.288l-.78 4.42c2.808.277 4.94 1.213 6.396 2.808 1.456 1.595 2.114 3.588 1.976 5.98h-3.848zm-15.444.676c-.07.312-.104.763-.104 1.352 0 1.49.485 2.635 1.456 3.432 1.005.797 2.461 1.508 4.368 2.132l2.392-13.416c-2.15.07-3.97.659-5.46 1.768-1.456 1.11-2.34 2.687-2.652 4.732zm13.104 16.484c0-1.525-.503-2.687-1.508-3.484-.97-.832-2.392-1.56-4.264-2.184l-2.444 13.78c1.664-.104 3.12-.537 4.368-1.3 1.248-.763 2.201-1.733 2.86-2.912a8.05 8.05 0 00.988-3.9zm8.2-18.928V12.04h14.403V50H40.88V20.308h-5.148zm45.134-.52H64.643v7.176c.693-.763 1.664-1.387 2.912-1.872 1.248-.485 2.6-.728 4.056-.728 2.6 0 4.75.59 6.448 1.768 1.733 1.179 2.999 2.704 3.796 4.576.797 1.872 1.196 3.9 1.196 6.084 0 4.056-1.144 7.28-3.432 9.672C77.33 48.821 74.107 50 69.947 50c-2.773 0-5.183-.468-7.228-1.404-2.045-.97-3.623-2.305-4.732-4.004-1.11-1.699-1.716-3.657-1.82-5.876h8.684a4.803 4.803 0 001.508 2.704c.797.693 1.89 1.04 3.276 1.04 1.63 0 2.843-.52 3.64-1.56.797-1.04 1.196-2.427 1.196-4.16 0-1.699-.416-2.999-1.248-3.9-.832-.901-2.045-1.352-3.64-1.352-1.179 0-2.15.295-2.912.884-.763.555-1.265 1.3-1.508 2.236h-8.58v-22.62h24.284v7.8z"
        fill="#00A09B"
      />
      <Defs>
        <ClipPath id="clip0_10844_34400">
          <Path fill="#fff" transform="translate(46 28)" d="M0 0H90V60H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default RewardActive
