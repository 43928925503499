import { Flex } from 'native-base'

const defaultProps = {
  bg: 'white',
  borderRadius: '2xl',
  borderColor: 'grey.200',
  borderWidth: 1,
}

const Sheet = ({ props = {}, children }) => {
  const sheetProps = { ...defaultProps, ...props }

  return <Flex {...sheetProps}>{children}</Flex>
}

export default Sheet
