import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { Box, Divider, FlatList, Text } from 'native-base'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'

const getTime = (hours, minutes) => {
  return `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }`
}

const CalendarButtonsWeb = ({ activity }) => {
  const isMobile = useMobileBreakpoint()
  return (
    <FlatList
      ListHeaderComponent={
        <Box mt={isMobile ? '16px' : '18px'}>
          <Divider w="364px" />
          <Text
            alignSelf="center"
            my={isMobile ? '10px' : '16px'}
            color="primary.900"
            fontWeight="700"
            fontSize="18px">
            Add to your calendar
          </Text>
        </Box>
      }
      data={[
        {
          name: 'iCal',
          label: 'Calendar File',
        },
        {
          name: 'Google',
          label: 'Google',
        },
        {
          name: 'Outlook.com',
          label: 'Outlook.com',
        },
        {
          name: 'Yahoo',
          label: 'Yahoo',
        },
      ]}
      renderItem={({ item }) => (
        <AddToCalendarButton
          name={activity?.title}
          iCalFileName={activity?.title}
          startDate={`${activity?.startDate.getFullYear()}-${
            activity?.startDate.getMonth() + 1
          }-${activity?.startDate.getDate()}`}
          startTime={`${getTime(
            activity?.startDate.getHours(),
            activity?.startDate.getMinutes()
          )}`}
          endDate={`${activity?.endDate.getFullYear()}-${
            activity?.endDate.getMonth() + 1
          }-${activity?.endDate.getDate()}`}
          endTime={`${getTime(
            activity?.endDate.getHours(),
            activity?.endDate.getMinutes()
          )}`}
          timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
          location={
            window.location.origin + '/activity?activityId=' + activity?.id
          }
          label={item.label}
          options={[item.name]}
          buttonsList
          customCss={`https://cabanastorage.blob.core.windows.net/public/css/atcb${
            isMobile ? '-mobile' : ''
          }.css`}
          buttonStyle="custom"
        />
      )}
      keyExtractor={(item) => item.name}
      numColumns={isMobile ? 1 : 2}
      alignSelf="center"
      w="100%"
      contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
      scrollEnabled={false}
    />
  )
}

export default CalendarButtonsWeb
