import { Box, Center, HStack, Pressable, Text } from 'native-base'
import MinutesProgressBar from '../_shared/MinutesProgressBar'

const MinutesProgress = ({ doneMinutes, expectedMinutes, onPress }) => {
  return (
    <Pressable onPress={onPress}>
      <Box background="white" borderRadius="full">
        <HStack py="2" px="3">
          <MinutesProgressBar
            key={expectedMinutes}
            doneMinutes={doneMinutes}
            expectedMinutes={expectedMinutes}
            isTiny
          />
          <Center>
            <Text color="text.900" bold ml="3">
              {doneMinutes} min
            </Text>
          </Center>
        </HStack>
      </Box>
    </Pressable>
  )
}

export default MinutesProgress
