export const useIframeSource = (inputString: string, isIframe = true) => {
  if (isIframe) {
    const iframeRegex = /<iframe\s+src="([^#]+)#/
    const iframeMatch = inputString.match(iframeRegex)
    if (iframeMatch && iframeMatch[1]) {
      return iframeMatch[1]
    }
  } else {
    const hashIndex = inputString.indexOf('#')
    if (hashIndex !== -1) {
      return inputString.substring(0, hashIndex)
    }
  }
  return null
}
