import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, HStack, Pressable, ScrollView, Text } from 'native-base'
import { Platform } from 'react-native'
import { getFocusAreaByPriority } from '../../../../helpers/getFocusAreaByPriority'
import { useBalancedSelf } from '../../../queries/voyageQueries'
import { NavigationParams } from '../../../screens/NavigationScreen'
import FaIcon from '../../_shared/FaIcon'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import CompassCompleted from '../compass/CompassCompleted'
import FinishedVoyagesActivities from '../focusArea/FinishedVoyagesActivities'
import FocusArea from '../focusArea/FocusArea'
import FocusAreasList from '../focusArea/FocusAreasList'
import ExtraPaths from '../path/ExtraPaths'

const VoyageMobile = () => {
  const { data, isLoading } = useBalancedSelf()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const values = !isLoading && data.focusGroups[0].focusAreas[0].nextPath
  const valuesCompleted = !values || values.steps === values.stepsCompleted

  const finishedVoyages =
    !isLoading &&
    data.focusGroups.every((group) =>
      group.focusAreas.every((focusArea) => focusArea.nextPath === null)
    )

  return (
    <>
      <HStack
        width="100%"
        alignItems="flex-end"
        justifyContent="space-between"
        height={98}
        backgroundColor="white"
        borderBottomWidth={1}
        px={6}
        shadow={0.5}
        borderBottomColor="muted.50"
        pb={2}>
        <Text fontSize="xl" fontWeight={500}>
          Voyage
        </Text>
        <Pressable
          onPress={() => navigation.navigate('VoyageIntro')}
          flexDirection="row"
          display="flex"
          alignItems="center">
          <FaIcon name="circle-info" isSolid color="primary.700" size={20} />
          <Text ml={1} fontSize="md" fontWeight={500}>
            About
          </Text>
        </Pressable>
      </HStack>
      <ScrollView p={4} showsVerticalScrollIndicator={false}>
        {isLoading ? (
          <>
            <SkeletonLoader mb={4} size={{ width: '100%', height: '300px' }} />
            <SkeletonLoader size={{ width: '100%', height: '300px' }} />
          </>
        ) : (
          <>
            {!finishedVoyages && (
              <FocusArea
                focusArea={getFocusAreaByPriority(1, data.focusGroups)}
              />
            )}

            {valuesCompleted && <CompassCompleted imageSize={250} />}
            {finishedVoyages && <FinishedVoyagesActivities />}
            {!finishedVoyages && (
              <>
                <FocusArea
                  focusArea={getFocusAreaByPriority(2, data.focusGroups)}
                />
                <ExtraPaths
                  focusAreas={getFocusAreaByPriority(0, data.focusGroups)}
                />
                <FocusArea
                  focusArea={getFocusAreaByPriority(3, data.focusGroups)}
                />
              </>
            )}

            <FocusAreasList data={data} />
          </>
        )}

        {Platform.OS === 'ios' && <Box height="50px" />}
      </ScrollView>
    </>
  )
}

export default VoyageMobile
