import { Flex } from 'native-base'
import { theme } from '../../../theme/theme'

const defaultProps = {
  maxW: '100%',
  mx: 'auto',
}

const FixedContainer = ({ width = null, size, children, props = {} }) => {
  const containerProps = { ...defaultProps, ...props }

  return (
    <Flex w={width ?? theme.breakpoints[size]} {...containerProps}>
      {children}
    </Flex>
  )
}

export default FixedContainer
