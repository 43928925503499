import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

function RewardCompleted(props) {
  return (
    <Svg
      width={144}
      height={96}
      viewBox="0 0 144 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G clipPath="url(#clip0_10919_34666)">
        <Path d="M136 57.774H46V42.887h90v14.887z" fill="#A3A3A3" />
        <Path
          opacity={0.8}
          d="M56 28c-5.516 0-10 4.45-10 9.925v4.962h90v-4.962C136 32.45 131.516 28 126 28H56zm80 29.774H46v20.301C46 83.55 50.484 88 56 88h70c5.516 0 10-4.45 10-9.925v-20.3zM63.5 68.15h10c1.375 0 2.5 1.117 2.5 2.482 0 1.364-1.125 2.48-2.5 2.48h-10c-1.375 0-2.5-1.116-2.5-2.48 0-1.365 1.125-2.482 2.5-2.482zM81 70.632c0-1.365 1.125-2.482 2.5-2.482h20c1.375 0 2.5 1.117 2.5 2.482 0 1.364-1.125 2.48-2.5 2.48h-20c-1.375 0-2.5-1.116-2.5-2.48z"
          fill="#D4D4D4"
        />
      </G>
      <Rect x={15} y={6} width={48} height={48} rx={24} fill="#fff" />
      <Path
        d="M39 54c-13.313 0-24-10.688-24-24C15 16.781 25.688 6 39 6c13.219 0 24 10.781 24 24 0 13.313-10.781 24-24 24zm10.594-28.406H49.5c.938-.844.938-2.25 0-3.188a2.207 2.207 0 00-3.094 0L36 32.906 31.594 28.5c-.938-.938-2.344-.938-3.188 0a2.053 2.053 0 000 3.094l6 6c.844.937 2.25.937 3.188 0l12-12z"
        fill="#00A09B"
      />
      <Defs>
        <ClipPath id="clip0_10919_34666">
          <Path fill="#fff" transform="translate(46 28)" d="M0 0H90V60H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default RewardCompleted
