import { Flex, HStack, Slider } from 'native-base'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import FormLabel from '../../_shared/form/FormLabel'
import Caption from '../../_shared/text/Caption'

const PitchShift = ({}) => {
  const roomClient = useRoomClientContext()

  return (
    <Flex>
      <FormLabel>Voice Pitch</FormLabel>
      <Slider
        defaultValue={roomClient.pitch}
        minValue={-3}
        maxValue={3}
        step={1}
        accessibilityLabel="Voice Pitch"
        colorScheme="secondary"
        onChangeEnd={roomClient.setPitch}>
        <Slider.Track>
          <Slider.FilledTrack />
        </Slider.Track>
        <Slider.Thumb />
      </Slider>
      <HStack justifyContent="space-between">
        <Caption>Low</Caption>
        <Caption>Normal</Caption>
        <Caption>High</Caption>
      </HStack>
    </Flex>
  )
}

export default PitchShift
