import {
  ActivityCardBorderState,
  ActivityType,
} from '../../../domain/models/interfaces/activityInterfaces'
const forYouWaves = require('../assets/images/cards/for-you-waves.png')
const listenWaves = require('../assets/images/cards/listen-waves.png')
const seriesWaves = require('../assets/images/cards/series-waves.png')
const skillWaves = require('../assets/images/cards/skill-waves.png')
const supportWaves = require('../assets/images/cards/support-waves.png')

const useCardData = (type: string, cardBorderState: string) => {
  const typeData = types.find((t) => t.name === type)
  const cardBorderStateData = states.find(
    (s) => s.name === cardBorderState
  ) || { name: '', color: '', label: '', labelWidth: '' }
  return { typeData, cardBorderStateData }
}

const types = [
  {
    name: ActivityType.GROUP_LISTEN,
    label: 'Listen in',
    iconName: 'user-group',
    color: 'secondary',
    headerImage: require('../assets/images/live-groups/listen-in.png'),
    gradient1: '#E1FBF9',
    gradient2: '#C3EBE7',
    waves: listenWaves,
  },
  {
    name: ActivityType.GROUP_SUPPORT,
    label: 'Support',
    iconName: 'user-group',
    color: 'yellow',
    headerImage: require('../assets/images/live-groups/support.png'),
    gradient1: '#FFF5EB',
    gradient2: '#FFECD6',
    waves: supportWaves,
  },
  {
    name: ActivityType.GROUP_SKILL,
    label: 'Skill builder',
    iconName: 'user-group',
    color: 'primary',
    headerImage: require('../assets/images/live-groups/skill-builder.png'),
    gradient1: '#E8F4FD',
    gradient2: '#D6EAFA',
    waves: skillWaves,
  },
  {
    name: ActivityType.GROUP_SERIES,
    label: 'Series',
    iconName: 'user-group',
    color: 'tertiary',
    headerImage: require('../assets/images/live-groups/series.png'),
    gradient1: '#FFF4EF',
    gradient2: '#FFEBE3',
    waves: seriesWaves,
  },
  {
    name: ActivityType.ACTIVITY,
    label: 'Activity',
    iconName: 'palette',
    color: 'tertiary',
    headerImage: require('../assets/images/live-groups/series.png'),
    gradient1: '#FFF4EF',
    gradient2: '#FFEBE3',
    waves: seriesWaves,
  },
  {
    name: ActivityType.GROUP_OTHER,
    label: 'For you',
    iconName: 'user-group',
    color: 'blueGray',
    gradient1: '#F8FAFC',
    gradient2: '#F1F5F9',
    waves: forYouWaves,
  },
  {
    name: ActivityType.ARTICLE,
    label: 'Article',
    iconName: 'book-open',
    color: 'primary',
    headerImage: require('../assets/images/live-groups/skill-builder.png'),
    gradient1: '#E1FBF9',
    gradient2: '#C3EBE7',
  },
  {
    name: ActivityType.AUDIO,
    label: 'Audio',
    iconName: 'headphones',
    color: 'violet',
    headerImage: require('../assets/images/live-groups/violetHeader.png'),
  },
  {
    name: ActivityType.VIDEO,
    label: 'Video',
    iconName: 'play',
    isIconSolid: false,
    color: 'secondary',
    headerImage: require('../assets/images/live-groups/listen-in.png'),
  },
  {
    name: ActivityType.ACTIVITY,
    label: 'Activity',
    iconName: 'palette',
    color: 'tertiary',
    gradient1: '#E1FBF9',
    gradient2: '#C3EBE7',
  },
  {
    name: ActivityType.MOMENT,
    label: 'Moment',
    iconName: 'bolt',
    color: 'yellow',
    gradient1: '#E1FBF9',
    gradient2: '#C3EBE7',
    headerImage: require('../assets/images/live-groups/support.png'),
  },
  {
    name: ActivityType.QUIZ,
    label: 'Quiz',
    iconName: 'sparkles',
    color: 'lime',
    gradient1: '#E1FBF9',
    gradient2: '#C3EBE7',
    headerImage: require('../assets/images/live-groups/quiz.png'),
  },
  {
    name: ActivityType.VIBE_CHECK,
    label: 'Vibe check',
    iconName: 'wave-pulse',
    color: 'fuchsia',
    gradient1: '#E1FBF9',
    gradient2: '#C3EBE7',
  },
  {
    name: ActivityType.INTERACTIVE,
    label: 'Interactive',
    iconName: 'play',
    isIconSolid: false,
    color: 'secondary',
    headerImage: require('../assets/images/live-groups/listen-in.png'),
  },
  {
    name: ActivityType.COMPASS,
    label: 'Compass',
    iconName: 'compass',
    isIconSolid: false,
    color: 'darkBlue',
    gradient1: '#DBF4FF',
    gradient2: '#C3EBE7',
  },
]

const states = [
  {
    name: ActivityCardBorderState.DEFAULT,
  },
  {
    name: ActivityCardBorderState.ATTENDING,
    color: 'primary.700',
    label: 'attending',
    labelWidth: '74px',
  },
  {
    name: ActivityCardBorderState.MODERATING,
    color: 'primary.700',
    label: 'moderating',
    labelWidth: '84px',
  },
  {
    name: ActivityCardBorderState.LIVE,
    color: 'tertiary.500',
    label: 'live now',
    labelWidth: '62px',
  },
  {
    name: ActivityCardBorderState.COMPLETED,
  },
  {
    name: ActivityCardBorderState.CHECKED,
    color: 'secondary.500',
  },
]

export default useCardData
