import { useEffect, useState } from 'react'
import { RemoteAudioTrack } from 'twilio-video'
import useRoomClientContext from '../../providers/RoomClientProvider'

const AudioFeed = ({}) => {
  /* State */
  const roomClient = useRoomClientContext()
  const [attachedTracks, setAttachedTracks] = useState(
    new Array<RemoteAudioTrack>()
  )

  /* Functions */
  const includes = (array, value) =>
    array.findIndex((e) => e.sid === value.sid) > -1
  const difference = (array1, array2) =>
    array1.filter((e) => !includes(array2, e))
  const attach = (track) => {
    const audioElement = track.attach()
    document.body.appendChild(audioElement)
  }
  const detach = (track) => {
    track.detach().forEach((el) => {
      el.remove()
    })
  }

  /* Hooks */
  // Attach/Detach remote audio tracks
  useEffect(() => {
    // get the old and new tracks
    const newTracks = roomClient.remoteAudioTracks
    const oldTracks = [...attachedTracks]

    // compute the difference to determine what to add and what to remove
    const tracksToAdd = difference(newTracks, oldTracks)
    const tracksToRemove = difference(oldTracks, newTracks)

    // attack/detach tracks
    tracksToAdd.forEach(attach)
    tracksToRemove.forEach(detach)

    // update the attached tracks list
    const result = [...oldTracks]
    tracksToAdd.forEach((add) => result.push(add))
    tracksToRemove.forEach((remove) =>
      result.splice(
        result.findIndex((e) => e.sid === remove.sid),
        1
      )
    )
    setAttachedTracks(result)
  }, [roomClient.remoteAudioTracks, setAttachedTracks])

  useEffect(() => {
    return () => {
      attachedTracks.forEach(detach)
    }
  }, [])

  return <></>
}

export default AudioFeed
