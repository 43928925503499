import { Flex, Heading, Image, Text, VStack } from 'native-base'
import TextLink from '../components/_shared/text/TextLink'

const NotFoundScreen = () => {
  const img = require('../assets/images/ui/error-404.png')

  return (
    <VStack w="100%" m="auto" px="4" textAlign="center">
      <Flex h="250px">
        <Image
          source={img}
          w="100%"
          flex="1"
          resizeMode="contain"
          alignSelf="center"
        />
      </Flex>
      <Heading size="lg" maxW="450px" mt="10" mb="7" mx="auto">
        Oh no! Looks like this page sailed off into the sunset
      </Heading>
      <Text fontSize="md" mb="5" mx="auto" textAlign="center">
        Here are a few links that may set you back on course
      </Text>
      <VStack
        space="2"
        display="flex"
        flexDirection="column"
        alignItems="center">
        <TextLink href="Home">Home</TextLink>
        <TextLink href="https://help.yourcabana.com/en/">FAQ</TextLink>
      </VStack>
    </VStack>
  )
}

export default NotFoundScreen
