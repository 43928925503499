import { Box, Text } from 'native-base'
import { ImageBackground } from 'react-native'
import { capitalize } from '../../../../helpers/capitalizeString'
import { maxStringLength } from '../../../../helpers/maxStringLength'
const CompassImage = require('../../../assets/images/voyages/compass.png')

const CompassValue = ({ direction, position, borderColor }) => {
  const text = maxStringLength(direction, 10)
  return (
    <Box
      minW="120px"
      maxW="140px"
      zIndex={2}
      position="absolute"
      {...position}
      background="white"
      px={3}
      py={1}
      borderRadius="48px"
      borderColor={borderColor}
      borderWidth={2}>
      <Text
        fontWeight={500}
        textAlign="center"
        fontSize="16px"
        color="primary.900">
        {capitalize(text)}
      </Text>
    </Box>
  )
}

const CompassValues = ({ data, imageSize = 260 }) => {
  return (
    <ImageBackground
      source={CompassImage}
      style={{
        height: imageSize,
        width: imageSize,
        alignSelf: 'center',
      }}
      imageStyle={{ marginTop: 2, marginBottom: 3 }}>
      {data.north && (
        <CompassValue
          borderColor="secondary.500"
          direction={data.north}
          position={{
            alignSelf: 'center',
            top: 5,
            left: '27%',
          }}
        />
      )}

      {data.east && (
        <CompassValue
          borderColor="violet.500"
          direction={data.east}
          position={{ top: '44%', right: -50 }}
        />
      )}
      {data.south && (
        <CompassValue
          borderColor="orange.500"
          direction={data.south}
          position={{
            bottom: 5,
            left: '27%',
          }}
        />
      )}
      {data.west && (
        <CompassValue
          borderColor="pink.500"
          direction={data.west}
          position={{ top: '44%', left: -50 }}
        />
      )}
    </ImageBackground>
  )
}

export default CompassValues
