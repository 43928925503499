import { Checkbox, FlatList, Pressable, Text, VStack } from 'native-base'
import { useState } from 'react'
import { VoyagesAnswer } from '../../../../domain/models/interfaces/voyagesInterfaces'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useVoyagesScreenerContext } from '../../providers/VoyagesScreenerProvider'

const VoyageQuestion = ({
  item: question,
  selectedAnswers,
  handleAnswerSelect,
}) => {
  const isDesktop = useDesktopBreakpoint()
  const { isQuestionActive } = useVoyagesScreenerContext()
  const [groupValues, setGroupValues] = useState([])

  const handleCheckboxChange = (
    values = null,
    questionId,
    newAnswerIds,
    isMultiple = false
  ) => {
    if (isMultiple) {
      setGroupValues(values)
      handleAnswerSelect(questionId, values, isMultiple)
    } else {
      handleAnswerSelect(questionId, newAnswerIds, isMultiple)
    }
  }

  const isActive = Boolean(isQuestionActive(question.id, selectedAnswers))

  return (
    <VStack mb={isDesktop ? 6 : 0}>
      <Text
        opacity={isActive ? 1 : 0.5}
        color="primary.900"
        fontWeight={700}
        fontSize="lg"
        mb={isDesktop ? 4 : 6}>
        {question.question}
      </Text>
      {question.type === 'multiple' ? (
        <>
          <Text color="primary.900" fontWeight={400} fontSize="sm" mb={3}>
            Select all that apply:
          </Text>
          <FlatList
            data={question.answers}
            keyExtractor={(item: VoyagesAnswer) => item.id.toString()}
            numColumns={isDesktop ? 3 : 1}
            renderItem={({ item: answer }) => {
              const isChecked = selectedAnswers
                .find((q) => q.question === question.id)
                ?.answers.includes(answer.id)

              return (
                <Checkbox.Group
                  display="flex"
                  flexDirection="row"
                  height="56px"
                  width={isDesktop ? '306px' : '100%'}
                  alignItems="center"
                  mb={3}
                  mr={3}
                  borderRadius="10px"
                  pl={3}
                  pr={6}
                  borderWidth={1}
                  borderColor={isChecked ? 'primary.900' : 'grey.300'}
                  overflow="hidden"
                  onChange={(values) =>
                    handleCheckboxChange(values, question.id, groupValues, true)
                  }
                  value={groupValues}
                  opacity={isActive ? 1 : 0.5}
                  pointerEvents={isActive ? 'auto' : 'none'}>
                  <Checkbox
                    onPointerEnter={() => {}}
                    key={answer.answer}
                    aria-label={answer.answer}
                    borderWidth={1}
                    p={isDesktop ? 0.5 : 1}
                    borderRadius="full"
                    _checked={{
                      backgroundColor: 'secondary.700',
                      borderColor: 'secondary.700',
                    }}
                    aria-checked={isChecked}
                    _icon={{ color: 'white' }}
                    size="sm"
                    hitSlop={{ top: 50, bottom: 50, left: 50, right: 50 }}
                    value={answer.id}
                    isChecked={isChecked}>
                    <Text color="black" key={answer.id} ml={3}>
                      {answer.answer}
                    </Text>
                  </Checkbox>
                </Checkbox.Group>
              )
            }}
          />
        </>
      ) : (
        <VStack>
          <FlatList
            data={question.answers}
            keyExtractor={(item: VoyagesAnswer) => item.id.toString()}
            numColumns={isDesktop ? 3 : 1}
            renderItem={({ item: answer }) => {
              const isChecked =
                selectedAnswers
                  .find((q) => q.question === question.id)
                  ?.answers?.includes(answer.id) || false

              return (
                <Pressable
                  onPress={() =>
                    handleCheckboxChange(null, question.id, answer.id)
                  }
                  display="flex"
                  flexDirection="row"
                  height="56px"
                  width={isDesktop ? '306px' : '100%'}
                  alignItems="center"
                  mb={3}
                  mr={3}
                  borderRadius="10px"
                  pl={3}
                  pr={6}
                  opacity={isActive ? 1 : 0.5}
                  pointerEvents={isActive ? 'auto' : 'none'}
                  onPointerEnter={() => {}}
                  borderWidth={1}
                  borderColor={isChecked ? 'primary.900' : 'grey.300'}
                  overflow="hidden">
                  <Checkbox
                    onChange={() =>
                      handleCheckboxChange(null, question.id, answer.id)
                    }
                    aria-label={answer.answer}
                    borderWidth={1}
                    p={isDesktop ? 0.5 : 1}
                    borderRadius="full"
                    _checked={{
                      backgroundColor: 'secondary.700',
                      borderColor: 'secondary.700',
                    }}
                    _icon={{ color: 'white' }}
                    size="sm"
                    value={question.answer}
                    isChecked={isChecked}
                  />
                  <Text color="black" key={answer.id} ml={3}>
                    {answer.answer}
                  </Text>
                </Pressable>
              )
            }}
          />
        </VStack>
      )}
    </VStack>
  )
}

export default VoyageQuestion
