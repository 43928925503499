import { AspectRatio, Box, Center, Heading, Text } from 'native-base'
import FaIcon from '../_shared/FaIcon'

const Introduction = () => {
  return (
    <Center>
      <Box mx="112px" mb="48px">
        <AspectRatio ratio={1} width="168px">
          <Box
            width="100%"
            borderRadius="2xl"
            justifyContent="center"
            bg={{
              linearGradient: {
                colors: ['#FAF5FF', '#FAE8FF'],
                start: [0, 0],
                end: [1, 1],
              },
            }}>
            <Center mx="9px" my="6px">
              <FaIcon name="wave-pulse" size={100} color="purple.500" />
            </Center>
          </Box>
        </AspectRatio>
      </Box>
      <Heading color="white" pb="16px">
        Welcome to Vibe Check
      </Heading>
      <Text fontSize="md" color="white" textAlign="center">
        Regularly checking in on your emotions{'\n'}
        can help you build a more mindful and{'\n'}
        balanced life. Let’s get started.
      </Text>
    </Center>
  )
}
export default Introduction
