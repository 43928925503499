import { HStack } from 'native-base'
import React, { useCallback } from 'react'
import {
  Appearance,
  InputAccessoryView,
  Keyboard,
  Platform,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import FaIcon from '../FaIcon'

const FormAccessoryNavigation = ({
  inputRefs,
  focusedInput,
  hasChevrons = true,
}) => {
  const isKeyboardDark = Appearance.getColorScheme() === 'dark'
  const { width } = useWindowDimensions()

  const focusNextInput = useCallback(() => {
    const nextIndex =
      focusedInput.current + 1 >= inputRefs.length
        ? inputRefs.length - 1
        : focusedInput.current + 1
    if (inputRefs[nextIndex]) {
      inputRefs[nextIndex].current.focus()
    }
  }, [])

  const focusPrevInput = useCallback(() => {
    const nextIndex =
      focusedInput.current - 1 < 0 ? 0 : focusedInput.current - 1
    if (inputRefs[nextIndex]) {
      inputRefs[nextIndex].current.focus()
    }
  }, [])

  return Platform.OS == 'ios' ? (
    <TouchableOpacity>
      <InputAccessoryView nativeID={'form'} style={{ borderWidth: 0 }}>
        <HStack
          w={width}
          h="40px"
          bgColor={isKeyboardDark ? 'rgba(69, 69, 70, 0.9)' : 'muted.100'}
          justifyContent="space-between"
          px="16px"
          alignItems="center">
          <HStack space="8px">
            {hasChevrons && (
              <>
                <TouchableOpacity
                  onPress={focusPrevInput}
                  style={{ padding: 5 }}>
                  <FaIcon
                    name="chevron-up"
                    color={isKeyboardDark ? '#FFFFFF' : '#007AFF'}
                    size={24}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={focusNextInput}
                  style={{ padding: 5 }}>
                  <FaIcon
                    name="chevron-down"
                    color={isKeyboardDark ? '#FFFFFF' : '#007AFF'}
                    size={24}
                  />
                </TouchableOpacity>
              </>
            )}
          </HStack>
          <TouchableOpacity
            onPress={() => Keyboard.dismiss()}
            style={{ zIndex: 20 }}>
            <Text
              style={{
                fontSize: 16,
                color: isKeyboardDark ? '#FFFFFF' : '#007AFF',
              }}>
              Done
            </Text>
          </TouchableOpacity>
        </HStack>
      </InputAccessoryView>
    </TouchableOpacity>
  ) : (
    <></>
  )
}

export default React.memo(FormAccessoryNavigation)
