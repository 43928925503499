import { createContext, useContext, useEffect, useState } from 'react'
import useAuthentication from '../hooks/authentication/useAuthentication'
import LoadingScreen from '../screens/LoadingScreen'
import { useCabanaApi } from './CabanaApiProvider'

enum FeatureStatus {
  NOT_LOADED,
  LOADING,
  SUCCESS,
}

const FeatureContext = createContext(null)
const { Provider } = FeatureContext

const FeatureProvider = ({ children }) => {
  const [status, setStatus] = useState<FeatureStatus>(FeatureStatus.NOT_LOADED)
  const { user } = useAuthentication()
  const { FeatureApi } = useCabanaApi()
  const [features, setFeatures] = useState({})

  const loadFeatures = () => {
    setStatus(FeatureStatus.LOADING)
    FeatureApi.features()
      .then(setFeatures)
      .then(() => setStatus(FeatureStatus.SUCCESS))
      .catch(() => setStatus(FeatureStatus.NOT_LOADED))
  }

  useEffect(() => {
    if (user) loadFeatures()
  }, [user])

  return (
    <Provider value={{ features }}>
      {status === FeatureStatus.LOADING ? <LoadingScreen /> : children}
    </Provider>
  )
}

export { FeatureProvider }
export const useFeatures = () => useContext(FeatureContext)
