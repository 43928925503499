import FormLayout from '../../layouts/FormLayout'

import { Button, Heading, HStack, Pressable, Text, VStack } from 'native-base'
import { useRef, useState } from 'react'
import FaIcon from '../../components/_shared/FaIcon'
import FormInput from '../../components/_shared/form/FormInput'
import useLinkHandler from '../../hooks/useLinkHandler'
import { useCabanaApi } from '../../providers/CabanaApiProvider'

const OchsnerSignupScreen = ({ route }) => {
  const { accessCode } = route.params
  const { AuthPasswordlessApi } = useCabanaApi()
  const [createAccountError, setCreateAccountError] = useState(null)
  const [email, setEmail] = useState('')
  const [isValid, setValid] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const inputRefs = [useRef(), useRef()]
  const focusedInput = useRef(0)

  const emailValidation = () =>
    new Promise((resolve, reject) => {
      if (/\S+@\S+\.\S+/.test(email)) resolve(null)
      else
        reject(
          'Uh oh - a valid email address requires an @ followed by the domain.'
        )
    })

  const signup = () => {
    AuthPasswordlessApi.lookupOrganization(accessCode).then(() => {
      AuthPasswordlessApi.createAccount(email, accessCode)
        .then(() => {
          setShowMessage(true)
          setEmail('')
        })
        .catch((error) => {
          console.debug(error)
          setCreateAccountError(error || 'Contact support@mycabana.health')
        })
    })
    setTimeout(() => {
      setShowMessage(false)
    }, 10000)
  }

  return (
    <FormLayout bg="bgDefault" autoBackButton={false}>
      {showMessage && (
        <Pressable
          onPress={() => {
            setShowMessage(false)
          }}
          pt={'9px'}
          mr="auto">
          <HStack alignItems="center" zIndex="3">
            <FaIcon name="chevron-left" color="primary.700" size={20} />
            <Text
              pl="2"
              fontSize="17"
              fontWeight="medium"
              color="primary.700"
              selectable={false}>
              Back
            </Text>
          </HStack>
        </Pressable>
      )}

      <VStack h="100%" w="100%">
        {createAccountError ? (
          <>
            <Heading fontSize="24px" mb="3" mt="3" color="primary.900">
              Uh oh!
            </Heading>
            <Text color="primary.900" fontSize="16px" mb={6}>
              We've encountered an error while creating your account.
            </Text>
            <Text color="primary.900" fontSize="16px" mb={6}>
              {createAccountError}
            </Text>
            <Button
              onPress={() => {
                setCreateAccountError(null)
              }}>
              Try again
            </Button>
          </>
        ) : showMessage ? (
          <>
            <VStack alignItems="center">
              <Heading fontSize="24px" mb="3" mt={6} color="primary.900">
                You're all set!
              </Heading>
              <Text color="primary.900" fontSize="16px" mt={6} mb={2}>
                Welcome to Cabana.
              </Text>
              <Text color="primary.900" fontSize="16px">
                Check your email to get started.
              </Text>
            </VStack>
          </>
        ) : (
          <VStack mt="10">
            <Heading fontSize="24px" mb={6} color="primary.900">
              Enter your email to sign up
            </Heading>
            <FormInput
              testID="email-input"
              bg="white"
              autofocus
              defaultValue={email}
              keyboardType="email-address"
              label="Email"
              onChangeText={setEmail}
              onChangeValid={setValid}
              validation={emailValidation}
              inputAccessoryViewID={'form'}
              inputParamRef={inputRefs[0]}
              onFocusCallback={() => {
                focusedInput.current = 0
              }}
            />
            <Button
              testID="continue-log-in-button"
              onPress={signup}
              isDisabled={!isValid}>
              Create account
            </Button>
            <Text fontSize="10px" textAlign="center" mt={6} color="text.500">
              {`By signing up, you agree to Cabana by Even Health’s\n`}
              <Text
                onPress={() =>
                  useLinkHandler('https://mycabana.health/terms-of-service')
                }
                underline
                color="secondary.600">
                Terms of Service
              </Text>
              {` and `}
              <Text
                onPress={() =>
                  useLinkHandler('https://www.mycabana.health/privacy-policy')
                }
                underline
                color="secondary.600">
                Privacy Policy
              </Text>
              .
            </Text>
          </VStack>
        )}
      </VStack>
    </FormLayout>
  )
}

export default OchsnerSignupScreen
