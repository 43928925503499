import { useMutation } from '@tanstack/react-query'
import { useCabanaApi } from '../../providers/CabanaApiProvider'

export const useRequestOchsnerAccessCode = (
  onSuccessCallback,
  onErrorCallback
) => {
  const { MemberSignupApi } = useCabanaApi()
  const { mutate, isLoading } = useMutation(MemberSignupApi.ochsnerAccessCode, {
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
  })
  return { requestCode: mutate, isLoading }
}
