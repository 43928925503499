import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Flex, Pressable, useTheme } from 'native-base'

const FaIcon = ({
  name,
  size = null,
  color = 'muted.500',
  isSolid = false,
  props = {},
  onPress = null,
}) => {
  const { colors } = useTheme()
  const [colorName, colorShade] = color.split('.')
  const iconColor = colorShade ? colors[colorName][colorShade] : colorName

  const iconElement = (
    <FontAwesomeIcon
      icon={`${!isSolid && 'fa-regular'} fa-${name}`}
      size={size}
      color={iconColor}
    />
  )

  return (
    <>
      {onPress ? (
        <Pressable color={color} onPress={onPress} {...props}>
          {iconElement}
        </Pressable>
      ) : (
        <Flex {...props}>{iconElement}</Flex>
      )}
    </>
  )
}

export default FaIcon
