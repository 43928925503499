import { Button, Flex, Heading, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import useMobileKeyboardListeners from '../../hooks/useMobileKeyboardListeners'
import FormInput from '../_shared/form/FormInput'
import ImageButton from '../_shared/form/ImageButton'

const GoalForm = ({ goal, setGoal, setIsCustomGoal }) => {
  const { keyboardOpen } = useMobileKeyboardListeners()
  /* State */
  const image1 = require('../../assets/images/onboarding/goal-boost-mood.png')
  const image2 = require('../../assets/images/onboarding/goal-reduce-stress.png')
  const image3 = require('../../assets/images/onboarding/goal-build-skills.png')

  /* Render */
  const options = [
    {
      image: image1,
      value: 'BOOST_MOOD',
      label: 'Boost mood',
    },
    {
      image: image2,
      value: 'REDUCE_STRESS',
      label: 'Reduce stress',
    },
    {
      image: image3,
      value: 'BUILD_SKILLS',
      label: 'Build skills',
    },
  ]

  const isGoalInOptions = (goal) => {
    return options.findIndex((item) => item.value == goal) != -1
  }

  const [showOtherInput, setShowOtherInput] = useState(
    !isGoalInOptions(goal) && goal
  )

  const selectGoalFromOptions = (newValue) => {
    setGoal(newValue)
    setShowOtherInput(false)
    setIsCustomGoal(false)
  }

  const clearCustomGoal = () => {
    setGoal(null)
    setShowOtherInput(false)
    setIsCustomGoal(false)
  }

  const openCustomGoalInput = () => {
    setGoal(null)
    setShowOtherInput(true)
    setIsCustomGoal(true)
  }

  useEffect(() => {
    analytics.track('Onboarding Reached')
  }, [])

  return (
    <>
      <VStack alignItems="center">
        <Heading w="275px" textAlign="center" mt="2" mb="5">
          What brings you to Cabana?
        </Heading>
        <Text w="250px" textAlign="center" mb="5">
          We'll personalize your Cabana based on your goals
        </Text>
        {!keyboardOpen &&
          options.map((option, index) => (
            <ImageButton
              key={index}
              label={option.label}
              imageUri={option.image}
              isDisabled={goal && goal !== option.value}
              handlePress={() => selectGoalFromOptions(option.value)}
            />
          ))}
        <Flex w="342px" maxW="100%">
          {showOtherInput ? (
            <FormInput
              defaultValue={goal}
              onChangeText={(value) => {
                setGoal(value)
                setIsCustomGoal(true)
              }}
              onClearText={clearCustomGoal}
              placeholder="What's your goal?"
            />
          ) : (
            <Button
              colorScheme="secondary"
              variant="ghost"
              h="42px"
              onPress={() => openCustomGoalInput()}>
              Other
            </Button>
          )}
        </Flex>
      </VStack>
    </>
  )
}

export default GoalForm
