import Svg, { Path } from 'react-native-svg'

const CabanaVertical = ({ width = 218, height = 175 }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 219 176" fill="none">
      <Path
        d="M142.013 10.9634C134.888 5.66961 126.61 2.19086 117.879 0.829609C115.12 0.375859 112.31 0.174194 109.5 0.174194C82.7076 0.174194 59.8786 19.7359 55.664 46.3054C55.5135 47.1625 55.4132 47.9692 55.363 48.7254C55.1121 50.7925 55.0118 52.8596 55.0118 54.9267C55.0118 85.1767 79.3962 109.679 109.5 109.679C133.533 109.679 155.008 93.5459 161.781 70.4046C162.333 68.4888 162.835 66.5729 163.186 64.6067C166.849 44.1879 158.62 23.3659 142.013 10.9634Z"
        fill="#222B60"
      />
      <Path
        d="M160.176 54.9268C160.176 56.6914 160.076 58.456 159.875 60.1702C148.686 48.4735 140.658 35.1131 135.992 20.3914C137.698 18.7277 139.404 16.9127 141.06 15.0472C153.202 24.7272 160.226 39.4489 160.176 54.9268Z"
        fill="#FFD48D"
      />
      <Path
        d="M151.044 84.1179C140.909 85.5296 130.272 82.9079 122.144 80.4879C120.037 79.8829 117.98 79.1771 115.923 78.5217C108.397 76.1017 100.62 73.6313 92.4413 72.6734C81.3529 71.4129 71.1677 73.4296 64.4946 78.1688C61.7351 72.8246 59.979 67.0267 59.2766 61.0271C72.3719 58.8592 85.7682 59.4642 98.6127 62.8925C106.44 64.9596 114.016 68.1359 121.341 71.1609C125.506 72.875 129.821 74.69 134.186 76.3034C140.257 78.5721 146.528 80.2863 152.901 81.3954C152.248 82.3029 151.646 83.2104 151.044 84.1179Z"
        fill="#8ED3D2"
      />
      <Path
        d="M135.44 72.7237C131.175 71.1608 126.86 69.3963 122.746 67.6317C115.32 64.5563 107.594 61.3296 99.566 59.1617C86.3201 55.6325 72.4722 54.9267 58.9253 57.1954C58.8751 56.4392 58.8752 55.6829 58.8752 54.9267C58.8752 53.6158 58.9253 52.2546 59.0758 50.9437H59.8786C68.9099 50.8429 77.9411 49.6833 86.7215 47.465C97.3081 44.8433 107.493 40.7092 116.876 35.1633C126.911 50.1875 140.658 62.1867 157.767 70.8583C157.015 73.2279 156.061 75.5471 154.907 77.7654C148.887 76.9587 142.515 75.295 135.44 72.7237Z"
        fill="#EA6852"
      />
      <Path
        d="M118.481 4.81238C125.505 6.07279 132.128 8.84571 138.049 12.8286C111.758 42.272 75.9841 46.507 62.4874 47.0111C77.7401 41.7174 101.723 29.8695 118.481 4.81238Z"
        fill="#FFD48D"
      />
      <Path
        d="M120.037 33.1466C124.603 30.2224 128.918 26.9453 132.982 23.3153C138.2 38.7932 147.031 52.7586 159.223 64.9091C159.072 65.6149 158.922 66.3711 158.771 67.077C142.565 58.8086 129.57 47.3641 120.037 33.1466Z"
        fill="#FFBF3C"
      />
      <Path
        d="M76.4359 16.3579C85.6177 8.39211 97.3583 4.00586 109.5 4.00586C111.106 4.00586 112.661 4.10669 114.267 4.25794C97.7095 28.0042 74.429 39.0454 60.0291 43.835C62.4375 33.0963 68.1573 23.4667 76.4359 16.3579Z"
        fill="#FFBF3C"
      />
      <Path
        d="M79.9477 96.3694C76.9372 94.2015 74.1777 91.6806 71.669 88.9077C75.5324 88.6556 79.3957 88.9077 83.1588 89.6135C90.4841 91.0252 97.5084 93.9494 104.282 96.7727C106.239 97.5794 108.195 98.3861 110.152 99.1927C114.668 101.058 119.284 102.52 124 103.68C109.048 108.268 92.7419 105.545 79.9477 96.3694Z"
        fill="#8ED3D2"
      />
      <Path
        d="M130.674 101.159V100.957C123.599 100.1 116.776 97.6297 111.608 95.613C109.651 94.8567 107.694 94.0501 105.788 93.2434C98.8137 90.3697 91.6389 87.3447 83.9121 85.8826C78.945 84.8742 73.8272 84.723 68.8099 85.3784C67.9068 84.1684 67.0538 82.908 66.251 81.5972C73.1248 76.5051 83.8118 75.5976 91.8898 76.5555C99.7168 77.463 107.343 79.883 114.669 82.2022C116.776 82.8576 118.883 83.513 120.94 84.1684C127.764 86.2355 136.243 88.4034 144.973 88.4034C145.877 88.4034 146.78 88.353 147.683 88.3026C143.017 93.7476 137.197 98.0834 130.674 101.159Z"
        fill="#00A09B"
      />
      <Path
        d="M25.0076 166.6L28.1184 172.902C28.1184 172.902 24.5059 175.876 17.5318 175.876C8.45034 175.876 0.0713501 168.415 0.0713501 159.138C0.0713501 149.861 8.40017 142.299 17.5318 142.299C24.5059 142.299 28.1184 145.273 28.1184 145.273L24.9575 151.575C23.101 150.265 20.8934 149.559 18.6356 149.559C11.2601 149.559 7.99878 154.752 7.99878 159.037C7.99878 163.323 11.2601 168.566 18.6356 168.566C20.9436 168.566 23.1512 167.86 25.0076 166.6ZM68.1569 175.271H60.2797L57.8714 168.818H45.629L43.2206 175.271H35.3434L48.3384 142.904H55.1118L68.1569 175.271ZM51.8003 152.886H51.7L48.0875 162.314H55.3627L51.8003 152.886ZM87.2229 142.853C92.7922 142.853 97.1573 144.92 97.1573 151.071C97.1573 154.046 95.8528 156.617 93.1434 158.029C97.1573 158.735 100.017 160.953 100.017 166.196C100.017 171.54 96.1538 175.221 89.4807 175.221H77.1882V142.853H87.2229ZM85.1658 155.558C88.3267 155.558 90.0326 154.6 90.0326 151.878C90.0326 149.559 88.3267 148.601 85.1658 148.601H84.5637V155.558H85.1658ZM86.8717 169.574C90.3337 169.574 92.2403 168.515 92.2403 165.944C92.2403 162.97 90.3337 161.911 86.8717 161.911H84.5637V169.574H86.8717ZM140.407 175.271H132.53L130.121 168.818H117.879L115.471 175.271H107.593L120.588 142.904H127.362L140.407 175.271ZM124.05 152.886H123.95L120.338 162.314H127.613L124.05 152.886ZM156.814 175.271H149.488V142.904H156.814L169.608 163.02H169.708V142.904H177.034V175.271H169.708L156.914 155.155H156.864L156.814 175.271ZM218.878 175.271H211.001L208.643 168.818H196.351L193.942 175.271H186.065L199.06 142.904H205.833L218.878 175.271ZM202.522 152.886H202.422L198.859 162.314H206.134L202.522 152.886Z"
        fill="white"
      />
    </Svg>
  )
}

export default CabanaVertical
