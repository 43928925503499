import Svg, {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg'

const MessageIcon = (props) => {
  return (
    <Svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G clipPath="url(#clip0_9799_34634)">
        <Rect width={52} height={52} rx={26} fill="#222B60" />
        <Path fill="url(#paint0_linear_9799_34634)" d="M0 0H52V52H0z" />
        <Path
          d="M41 25.139c0 6.704-6.716 12.138-15 12.138-1.173 0-2.314-.109-3.41-.315-.503-.095-1.024-.1-1.47.15-1.034.582-2.626 1.746-4.656 1.876-.148.01-.23-.16-.139-.277 1.274-1.613 2.088-3.085 1.533-3.376C13.731 33.173 11 29.414 11 25.14 11 18.435 17.716 13 26 13c8.284 0 15 5.435 15 12.139z"
          fill="#fff"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_9799_34634"
          x1={26}
          y1={0}
          x2={26}
          y2={52}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#5CF777" />
          <Stop offset={1} stopColor="#0EBC29" />
        </LinearGradient>
        <ClipPath id="clip0_9799_34634">
          <Rect width={52} height={52} rx={26} fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default MessageIcon
