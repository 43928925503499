import { useNavigation, useRoute } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  AspectRatio,
  Box,
  Image,
  ScrollView,
  StatusBar,
  Text,
  VStack,
} from 'native-base'
import { useMemo, useRef, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import Markdown from 'react-native-marked'
import {
  ActivityRegistrationState,
  ActivityType,
} from '../../../../../domain/models/interfaces/activityInterfaces'
import mdStylesMobile from '../../../constants/mdStylesMobile'
import useCardData from '../../../hooks/useCardData'
import { NavigationParams } from '../../../screens/NavigationScreen'
import BackButton from '../../navigation/BackButton'
import ActivityCard from '../../_shared/card/ActivityCard'
import VideoPlayer from '../video/VideoPlayer'
import ActivityActionButtonAndModal from './ActivityActionButtonAndModal'
import AddToCalendarButtonAndModal from './AddToCalendarButtonAndModal'
import ContentHeader from './ContentHeader'
import ActivityDownloadableContent from './downloadableContent/ActivityDownloadableContent'
import ActivityDownloadableContentButtons from './downloadableContent/ActivityDownloadableContentButtons'
import StepCompletionForm from './StepCompletionForm'

const getActivityButton = (activity, { onPress, downloadLocation }) => {
  switch (activity.type) {
    case ActivityType.VIDEO:
      return null
    case ActivityType.ARTICLE:
    case ActivityType.ACTIVITY:
      if (!activity.downloadUrl) {
        return null
      } else {
        return (
          <BottomButtonContainer>
            <ActivityDownloadableContentButtons
              activity={activity}
              downloadLocation={downloadLocation}
            />
          </BottomButtonContainer>
        )
      }
    default:
      let startDate = new Date(activity.startDate)
      startDate.setMinutes(startDate.getMinutes() - 15)
      const isJoinable = startDate < new Date() && activity.endDate > new Date()
      let newStartDate = new Date()
      newStartDate.setMinutes(newStartDate.getMinutes() + 16)
      return (
        <BottomButtonContainer>
          <ActivityActionButtonAndModal
            handleClose={() => {}}
            activity={activity}
          />
          <Box
            mt={
              !(
                activity.registration === ActivityRegistrationState.ATTENDING &&
                !isJoinable
              )
                ? undefined
                : '16px'
            }>
            <AddToCalendarButtonAndModal
              handleClose={() => {}}
              activity={activity}
              buttonHidden={
                !(
                  activity.registration ===
                    ActivityRegistrationState.ATTENDING &&
                  !isJoinable &&
                  !activity.ended
                )
              }
            />
          </Box>
        </BottomButtonContainer>
      )
  }
}

const getActivityDescription = (activity, params, articleWidth, hasRelated) => {
  switch (activity.type) {
    case ActivityType.ARTICLE:
    case ActivityType.ACTIVITY:
      if (activity.downloadUrl) {
        return (
          <ActivityDownloadableContent
            activity={activity}
            setDownloadLocation={params.setDownloadLocation}
          />
        )
      } else {
        return (
          <ScrollView
            horizontal
            width="100%"
            scrollEnabled={false}
            mb={Platform.OS === 'web' ? 0 : hasRelated ? 6 : 16}>
            <Markdown
              value={activity.article}
              flatListProps={{
                initialNumToRender: 8,
                scrollEnabled: Platform.OS === 'web' ? true : false, //Disabled scroll because of virtualized list warning but it still throws the warning until we update to RN 0.71
                contentContainerStyle: { backgroundColor: 'white' },
                style: { width: articleWidth },
              }}
              styles={mdStylesMobile}
            />
          </ScrollView>
        )
      }
    default:
      return (
        <Box>
          <Markdown
            value={activity.description}
            flatListProps={{
              initialNumToRender: 8,
              scrollEnabled: Platform.OS === 'web' ? true : false, //Disabled scroll because of virtualized list warning but it still throws the warning until we update to RN 0.71
              contentContainerStyle: { backgroundColor: 'white' },
              style: { width: articleWidth },
            }}
            styles={mdStylesMobile}
          />
        </Box>
      )
  }
}

const ActivityContentMobile = ({ activity, isLoading, autoPlay = false }) => {
  const route = useRoute()
  const stepId = (route.params as { stepId?: string })?.stepId
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const videoPlayerRef = useRef(null)
  const [downloadLocation, setDownloadLocation] = useState('')
  const scrollViewRef = useRef()
  const [pressedPlay, setPressedPlay] = useState(false)

  const { typeData } = useCardData(activity?.type, activity?.cardBorderState)
  const { height, width } = useWindowDimensions()
  const [videoAspectRatio, setVideoAspectRatio] = useState(4 / 7)
  const headerHeight = height / 7
  const articleWidth = width - 48

  const activityButton = useMemo(
    () =>
      getActivityButton(activity, {
        onPress: () => {
          if (Platform.OS === 'web')
            videoPlayerRef?.current?.player.player.play()
          else videoPlayerRef?.current?.injectJavaScript('player.play(); true;')
        },
        downloadLocation:
          downloadLocation != '' ? downloadLocation : activity.downloadUrl,
      }),
    [activity]
  )

  const videoAspectRatioCallback = (event: any) => {
    if (Platform.OS !== 'web') {
      const payload: { name: string; data: any } = JSON.parse(
        event.nativeEvent.data
      )
      const width = payload.data[0]
      const height = payload.data[1]
      setVideoAspectRatio(width / height)
    } else {
      setVideoAspectRatio(
        event.player.player.container.firstChild.width /
          event.player.player.container.firstChild.height
      )
      if (autoPlay) scrollViewRef.current.scrollTo({ y: 250 })
    }
  }

  return (
    !isLoading && (
      <>
        <StatusBar barStyle="light-content" />
        <BackButton
          style={{ position: 'absolute', top: 43, left: 25, zIndex: 1 }}
          color="white"
          customText=""
        />
        <Box height={headerHeight}>
          <Image
            source={typeData.headerImage}
            height={headerHeight}
            width="100%"
            alt="404"
          />
        </Box>
        <ScrollView
          borderTopRadius="2xl"
          top={-15}
          backgroundColor="white"
          showsVerticalScrollIndicator={false}>
          <Box
            borderTopRadius="2xl"
            top={-15}
            backgroundColor="white"
            safeAreaBottom>
            <Box
              px="24px"
              mt={12}
              paddingBottom={activityButton == null ? undefined : '112px'}>
              <ContentHeader activityHeader={activity} />
              {typeData.name === ActivityType.VIDEO && (
                <AspectRatio width="100%" ratio={videoAspectRatio} mb={2}>
                  <Box height="100%" width="100%">
                    <VideoPlayer
                      activity={activity}
                      autoPlay={autoPlay}
                      videoPlayerRef={videoPlayerRef}
                      videoAspectRatioCallback={videoAspectRatioCallback}
                    />
                  </Box>
                </AspectRatio>
              )}
              {getActivityDescription(
                activity,
                {
                  setDownloadLocation,
                },
                articleWidth,
                activity?.recommendations?.length > 0
              )}
              {stepId && <StepCompletionForm stepId={stepId} />}
              {activity?.recommendations?.length > 0 && !stepId && (
                <VStack mb={16}>
                  <Text
                    fontSize="16px"
                    color="primary.900"
                    fontWeight={700}
                    mb={4}
                    mt={2}>
                    Related
                  </Text>
                  {activity?.recommendations?.map((item) => {
                    return (
                      <Box mb={4}>
                        <ActivityCard
                          activity={item}
                          onPress={async () =>
                            navigation.navigate('Activity', {
                              activityId: item.id,
                            })
                          }
                        />
                      </Box>
                    )
                  })}
                </VStack>
              )}
            </Box>
          </Box>
        </ScrollView>
        {activityButton}
      </>
    )
  )
}

const BottomButtonContainer = ({ children }) => {
  return (
    <Box
      width="100%"
      backgroundColor="rgba(229, 229, 229, 0.9)"
      borderTopRadius="20px"
      position="absolute"
      bottom={0}>
      <Box mx="24px" mt="24px" mb="40px">
        {children}
      </Box>
    </Box>
  )
}

export default ActivityContentMobile
