import { Pressable } from 'native-base'
import { Keyboard, Platform } from 'react-native'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'

const KeyboardDismissOverlay = ({ children }) => {
  const isMobile = useMobileBreakpoint()

  return (
    <>
      {Platform.OS === 'ios' ? (
        <Pressable
          flex="1"
          onPress={() => Keyboard.dismiss()}
          accessible={false}>
          {children}
        </Pressable>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default KeyboardDismissOverlay
