import { Box, Center, Flex, HStack, PresenceTransition } from 'native-base'
import { useWindowDimensions } from 'react-native'
import RoomAction from '../RoomAction'

const RoomMobileActions = ({ isOpen, actions, insidePIP = false }) => {
  const mainActions = actions.filter((a) => a.isMain && a.isVisible)
  const { width } = useWindowDimensions()
  const smallScreen = width < 380
  const space = insidePIP && smallScreen ? '3' : '6'
  const padding = insidePIP && smallScreen ? '1.5' : '3'

  return (
    <Center zIndex={2}>
      <Box position="absolute" bottom={insidePIP ? 1 : 2}>
        <PresenceTransition
          visible={isOpen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 100 } }}>
          <Box bg="muted.900:alpha.60" borderRadius="2xl">
            <HStack space={space} p={padding}>
              {mainActions.map((action, index) => (
                <Flex key={index}>
                  <RoomAction action={action} />
                </Flex>
              ))}
            </HStack>
          </Box>
        </PresenceTransition>
      </Box>
    </Center>
  )
}

export default RoomMobileActions
