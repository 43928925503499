import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, FlatList, Text, VStack } from 'native-base'
import { useState } from 'react'
import Markdown from 'react-native-marked'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import mdStylesDesktop from '../../constants/mdStylesDesktop'
import mdStylesMobile from '../../constants/mdStylesMobile'
import { NavigationParams } from '../../screens/NavigationScreen'
import GroupCard from '../_shared/card/GroupCard'

const ModeratorDetail = ({ moderator }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  const [showBio, setShowBio] = useState(false)

  const onPressLiveGroup = (activity) => {
    navigation.navigate('Activity', { activityId: activity.id })
  }

  return (
    <>
      <VStack
        px={isDesktop ? 10 : 6}
        pt={3}
        pb={showBio ? 4 : 1}
        mt={2}
        bg="white"
        borderTopRadius={isDesktop ? '20px' : 0}>
        <Text
          fontSize={isDesktop ? '20px' : '16px'}
          color="primary.900"
          fontWeight={600}
          textAlign="center">
          {moderator?.name}
        </Text>
        {showBio ? (
          <Markdown
            value={moderator?.bio}
            flatListProps={{
              contentContainerStyle: { backgroundColor: 'white' },
            }}
            styles={{ ...(isDesktop ? mdStylesDesktop : mdStylesMobile) }}
          />
        ) : (
          <Text
            mb={isDesktop && showBio ? 4 : 0}
            color="primary.900"
            fontSize={isDesktop ? '16px' : '14px'}
            mt={3}
            numberOfLines={showBio ? undefined : 3}
            ellipsizeMode="tail">
            {moderator?.bio}
          </Text>
        )}
        {moderator.bio && (
          <Button
            m={4}
            borderColor="grey.200"
            borderWidth={1}
            width={isDesktop ? 89 : 137}
            borderRadius={8}
            alignSelf="center"
            variant="unstyled"
            onPress={() => setShowBio(!showBio)}>
            <Text color="secondary.600" fontWeight={700}>
              {showBio ? 'Close' : 'More'}
            </Text>
          </Button>
        )}
      </VStack>
      {moderator?.upcomingGroups.length > 0 && (
        <VStack
          py={6}
          px={isDesktop ? 10 : 6}
          bg={isDesktop ? 'white' : 'muted.50'}
          borderBottomRadius={isDesktop ? '20px' : 0}>
          <Text fontSize="16px" color="primary.900" fontWeight={700}>
            Upcoming Groups
          </Text>
          <FlatList
            showsHorizontalScrollIndicator={false}
            numColumns={isDesktop ? 3 : 1}
            data={moderator.upcomingGroups}
            keyExtractor={(activity) => activity.id.toString()}
            renderItem={({ item: activity, index }) => (
              <Box
                mt={4}
                key={activity.id}
                width={isDesktop ? '304px' : '100%'}
                mr={isDesktop ? 4 : 0}>
                <GroupCard
                  activity={activity}
                  width="100%"
                  onPress={() => onPressLiveGroup(activity)}
                />
              </Box>
            )}
          />
          <Text
            textAlign="center"
            color="primary.900"
            mt={isDesktop ? 8 : 4}
            mb={isDesktop ? 0 : 4}>
            {`New groups are added near the end of\nthe month, check back then!`}
          </Text>
        </VStack>
      )}
    </>
  )
}

export default ModeratorDetail
