import { useEffect } from 'react'
import { Linking } from 'react-native'
import useAuthentication from '../hooks/authentication/useAuthentication'

const LoginWithPasswordScreen = () => {
  console.log(Linking.getInitialURL())

  const { loginWithPassword } = useAuthentication()
  useEffect(() => {
    loginWithPassword()
  })

  return <></>
}

export default LoginWithPasswordScreen
