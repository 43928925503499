import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { differenceInMilliseconds } from 'date-fns'
import { useRef } from 'react'
import {
  ActivityCardBorderState,
  ActivityType,
} from '../../../../domain/models/interfaces/activityInterfaces'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import useInvalidateQueriesInTimestamp from '../../hooks/useInvalidateQueriesInTimestamp'
import { useCabanaApi } from '../../providers/CabanaApiProvider'

export const useGetDailyRecs = () => {
  const { ActivityCardApi } = useCabanaApi()
  const { data, isLoading } = useQuery({
    queryKey: ['Activity', 'DailyRecommendations'],
    queryFn: ActivityCardApi.dailyRecs,
  })

  const date = new Date()
  let nextDate = new Date()
  const UTCHours = date.getUTCHours()
  if (UTCHours >= 9) {
    nextDate.setUTCDate(date.getDate() + 1)
  }
  nextDate.setUTCHours(9, 15, 0, 0)
  const nextDateInUTC = nextDate.toUTCString()

  useInvalidateQueriesInTimestamp(differenceInMilliseconds(nextDate, date), [
    'Activity',
    'DailyRecommendations',
  ])
  const dailyRecs = data?.length === 2 ? [...[vibeCheck], ...data] : data
  return { dailyRecs, isLoading }
}

/* Mock data */
const cabanaTeam: Moderator = {
  id: '1',
  name: 'Cabana Team',
  photoUrl:
    'https://evenhealth.blob.core.windows.net/public/cabana/Cabana_Icon.png',
}

// Content
const vibeCheck: Content = {
  id: '1',
  title: 'Vibe check',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  type: ActivityType.VIBE_CHECK,
  cardBorderState: ActivityCardBorderState.DEFAULT,
  moderator: cabanaTeam,
  duration: 0,
  tags: [],
  subtitle: 'Reflect on your emotions and build awareness every day',
  createdDate: new Date(),
}

export const useCompleteDailyRec = () => {
  const { ActivityApi } = useCabanaApi()
  const queryClient = useQueryClient()
  const isCompleteRef = useRef(false)
  const { mutate, isLoading } = useMutation(ActivityApi.complete, {
    onSuccess: () => {
      isCompleteRef.current = true
      queryClient.invalidateQueries({
        queryKey: ['Activity', 'DailyRecommendations'],
      })
      queryClient.invalidateQueries({
        queryKey: ['Profile'],
      })
    },
  })

  const completeGroup = (room) => {
    if (isCompleteRef.current) return

    mutate({ id: room.activityId, ...room })
  }

  const completeContent = (content) => {
    mutate(content)
    analytics.track('Content Completed', {
      'Activity Title': content.title,
      'Activity ID': content.id,
      'Content Type': content.type,
      Duration: content.duration,
    })
  }

  return { completeGroup, completeContent, isLoading }
}
