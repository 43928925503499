import {
  FocusArea,
  FocusGroup,
} from '../../domain/models/interfaces/voyagesInterfaces'

export const getFocusAreaByPriority = (
  orderNumber: number,
  focusGroups: FocusGroup[]
) => {
  const focusAreas: FocusArea[] = []
  focusGroups.forEach((group) => {
    group.focusAreas.forEach((area) => {
      if (orderNumber === 0) {
        if (area.nextPath && (area.priority > 3 || area.priority === 0)) {
          focusAreas.push(area)
        }
      } else {
        if (area.priority === orderNumber) {
          focusAreas.push(area)
        }
      }
    })
  })
  if (focusAreas.length === 0) {
    return null
  } else {
    return orderNumber === 0 ? focusAreas.splice(0, 3) : focusAreas[0]
  }
}
