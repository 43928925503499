import { FlatList, Skeleton, Text, VStack } from 'native-base'
import { Platform } from 'react-native'
import { CollectionCardProps } from '../../../../domain/models/interfaces/cardInterfaces'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useCollections } from '../../queries/collectionsQueries'
import CollectionCard from './CollectionCard'

const Collections = () => {
  const { data, isLoading } = useCollections()
  const isMobile = useMobileBreakpoint()
  const containerSpace = isMobile ? 4 : 10
  const numColumns = isMobile ? 2 : 3
  const platformMargin = Platform.OS === 'web' ? 0 : 4
  const marginBottom = isMobile ? platformMargin : 10

  return (
    <VStack
      my={isMobile ? 0 : 10}
      p={containerSpace}
      flex={1}
      mb={marginBottom}
      borderRadius={isMobile ? 0 : '20px'}
      borderColor="grey.200"
      borderWidth={isMobile ? 0 : 1}>
      <Text fontSize="24px" color="primary.900" fontWeight={700}>
        Collections
      </Text>
      <Text mb={isMobile ? 4 : 6} fontSize="12px" color="muted.500">
        Discover our handpicked Collection for guidance and encouragement to
        care for your mental well-being
      </Text>
      <Skeleton rounded="2xl" height={300} isLoaded={!isLoading}>
        <FlatList
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ gap: 16 }}
          columnWrapperStyle={{ gap: 16 }}
          numColumns={numColumns}
          data={data}
          renderItem={({ item }: { item: CollectionCardProps }) => (
            <CollectionCard collectionCard={item} isCollectionsScreen />
          )}
          keyExtractor={(item) => item.slug}
        />
      </Skeleton>
    </VStack>
  )
}

export default Collections
