import { AspectRatio, Box, Center, Heading, Text } from 'native-base'
import FaIcon from '../_shared/FaIcon'
import Confetti from './Confetti'

const Success = () => {
  return (
    <>
      <Box position={'absolute'} top="-88" left="0">
        <Confetti />
      </Box>
      <Center>
        <AspectRatio ratio={1} width="88px" justifyContent="center">
          <Box
            width="100%"
            borderRadius="2xl"
            justifyContent="center"
            bg="secondary.50">
            <Center mx="9px" my="6px">
              <FaIcon name="trophy" color="secondary.500" size={50} />
            </Center>
          </Box>
        </AspectRatio>
        <Heading color="white" pb="16px" mt="16px">
          Amazing work!
        </Heading>
        <Text fontSize="md" color="white" textAlign="center" mx="26px">
          You finished your vibe check. {'\n'} You’re on your way to building a
          more mindful and balanced life.
        </Text>
      </Center>
    </>
  )
}

export default Success
