import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useCabanaApi } from '../../providers/CabanaApiProvider'

export const useVibeCheckHistory = () => {
  const { VibeCheckApi } = useCabanaApi()
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(['VibeCheckHistory', VibeCheckApi], getHistory, {
      getNextPageParam: (lastPage, allPages) => {
        const pageCount = lastPage?.totalPages ?? 0
        if (allPages.length < pageCount) {
          return allPages.length + 1
        }
        return undefined
      },
    })
  return {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  }
}

const getHistory = async ({ queryKey, pageParam }) => {
  const [_, VibeCheckApi] = queryKey
  return await VibeCheckApi.history(pageParam || 1)
}

export const useCompleteVibeCheck = () => {
  const { VibeCheckApi } = useCabanaApi()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation({
    mutationFn: (variables) =>
      VibeCheckApi.completeVibeCheck(
        variables.selectedMood,
        variables.emotionNote
      ),
    onSettled: () => {
      queryClient.refetchQueries({
        queryKey: ['VibeCheckHistory'],
      })
      queryClient.invalidateQueries({
        queryKey: ['Activity', 'DailyRecommendations'],
      })
      queryClient.invalidateQueries({
        queryKey: ['Profile'],
      })
    },
  })
  return { completeVibeCheck: mutate, isLoading }
}
