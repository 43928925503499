import ClevertapAdapter from '../clevertap/ClevertapAdapter'
import MixpanelAdapter from '../mixpanel/MixpanelAdapter'

const analytics = {
  track: (eventName, eventData = {}) => {
    MixpanelAdapter.track(eventName, eventData)
    ClevertapAdapter.pushEvent(eventName, eventData)
  },
}

export default analytics
