import { useNavigation } from '@react-navigation/native'
import { Button, FlatList, Text } from 'native-base'
import { useCallback, useMemo } from 'react'
import { Linking } from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import ProfileItem from '../../components/profile/ProfileItem'
import ProfileContainer from '../../components/_shared/layout/ProfileContainer'
import secureScreen from '../../hooks/authentication/secureScreen'
import useLinkHandler from '../../hooks/useLinkHandler'
import { useCabanaApi } from '../../providers/CabanaApiProvider'
import { useUserProfileContext } from '../../providers/UserProfileProvider'

const AccountScreen = () => {
  const isDesktop = useDesktopBreakpoint()
  const navigation = useNavigation()
  const { userProfile } = useUserProfileContext()
  const { MemberProfileApi } = useCabanaApi()
  const data = useMemo(
    () =>
      userProfile
        ? [
            {
              id: 'EMAIL',
              name: userProfile.email,
              description: 'Email',
              hasRightIcon: false,
            },
            {
              id: 'PROFESSIONAL_ROLE',
              name: userProfile.professionalRoles.join(', '),
              description: 'Professional role',
              hasRightIcon: false,
              mutedRightIcon: 'pencil',
              action: () => navigation.navigate('RoleReview'),
            },
            {
              id: 'AFFILIATION',
              name: userProfile.organization,
              description: 'Affiliation',
              hasRightIcon: false,
            },
            {
              id: 'PASSWORD',
              name: '',
              description: userProfile.hasPassword
                ? 'Password'
                : 'Add a password',
              hasRightIcon: false,
              mutedRightIcon: userProfile.hasPassword ? 'pencil' : 'plus',
              action: () => doChangePassword(),
              type: userProfile.hasPassword ? 'password' : '',
            },
          ]
        : [],
    [userProfile?.professionalRoles]
  )

  const doChangePassword = () => {
    MemberProfileApi.changePassword().then(useLinkHandler)
  }

  return (
    <>
      <ProfileContainer headerText="Account">
        <FlatList
          showsVerticalScrollIndicator={false}
          flexGrow={isDesktop ? 0 : 1}
          borderRadius="20px"
          borderColor="grey.200"
          borderWidth={isDesktop ? 1 : 0}
          data={data}
          renderItem={({ item, index }) => {
            return (
              <ProfileItem
                item={item}
                isFirst={index === 0}
                isLast={index + 1 === data.length}
              />
            )
          }}
        />
      </ProfileContainer>
      <FooterComponent />
    </>
  )
}

const FooterComponent = () => {
  const { userProfile } = useUserProfileContext()

  const handleSendEmail = useCallback(async () => {
    const encodedSubject = encodeURIComponent('Delete My Account')
    const encodedBody = encodeURIComponent(
      `Hi! I would like you to delete my Cabana account associated with this email - ${userProfile.email}. (We’ll send you a confirmation email).`
    )
    const encoded = `mailto:support@mycabana.health?subject=${encodedSubject}&body=${encodedBody}`
    if (encoded) {
      await Linking.openURL(encoded)
    } else {
      console.debug('Cannot open message')
    }
  }, [userProfile?.email])

  return (
    <Button marginX="24px" mt={5} variant="empty" onPress={handleSendEmail}>
      <Text color="text.500" fontSize="14px" fontWeight="500">
        Delete account
      </Text>
    </Button>
  )
}

export default secureScreen(AccountScreen)
