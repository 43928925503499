import { format } from 'date-fns'
import { HStack, Text, View, VStack } from 'native-base'
import { useUserProfileContext } from '../../providers/UserProfileProvider'

interface WeeklyProgressProps {
  startDate: string
  endDate: string
  minutes: number
}

const WeeklyProgress = ({
  startDate,
  endDate,
  minutes,
}: WeeklyProgressProps) => {
  const { userProfile } = useUserProfileContext()
  const weeklyMinutes = userProfile.mentalHealthMinutes * 7
  const percentage = (minutes / weeklyMinutes) * 100

  return (
    <VStack mb={3}>
      <View backgroundColor="muted.300" height={2} borderRadius={8} mb={1}>
        <View
          backgroundColor="primary.700"
          height={2}
          borderRadius={8}
          width={percentage > 100 ? '100%' : `${percentage}%`}
        />
      </View>
      <HStack justifyContent="space-between">
        <Text color="text.500" fontSize="12px">
          {format(new Date(endDate), 'do')}
        </Text>
        <Text color="text.800">{`${minutes}/${weeklyMinutes}`}</Text>
        <Text color="text.500" fontSize="12px">
          {format(new Date(startDate), 'do')}
        </Text>
      </HStack>
    </VStack>
  )
}

export default WeeklyProgress
