import { StatusBar } from 'native-base'
import { SafeAreaView, StyleSheet } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import AttendeeRole from '../../../../domain/enums/AttendeeRole'
import AttendeeState from '../../../../domain/enums/AttendeeState'
import useRoomContext from '../../providers/RoomProvider'
import LoadingScreen from '../../screens/LoadingScreen'
import FeedbackSubmitted from './feedback/FeedbackSubmitted'
import RoomCompleted from './feedback/RoomCompleted'
import RoomFeedback from './feedback/RoomFeedback'
import ParticipantLobby from './lobby/ParticipantLobby'
import Orientation from './orientation/Orientation'
import Room from './Room'

const ScreenWrapper = ({ children }) => {
  return (
    <>
      <SafeAreaView style={styles.topSafeArea} />
      <SafeAreaView style={styles.bottomSafeArea}>{children}</SafeAreaView>
    </>
  )
}

const RoomContainer = () => {
  /* State */
  const room = useRoomContext()

  /* Render */
  const isState = (state) => room?.me?.state === state
  const isRole = (role) => {
    return room?.me?.role === role
  }
  const isEnded = () => room?.ended

  const components = [
    {
      condition:
        (isState(AttendeeState.NotJoined) ||
          isState(AttendeeState.Orientation)) &&
        !isEnded(),
      component: (
        <ScreenWrapper>
          <StatusBar barStyle="dark-content" />
          <Orientation />
        </ScreenWrapper>
      ),
    },
    {
      condition:
        isState(AttendeeState.Waiting) && !isRole(AttendeeRole.Moderator),
      component: (
        <ScreenWrapper>
          <StatusBar barStyle="dark-content" />
          <ParticipantLobby />
        </ScreenWrapper>
      ),
    },
    {
      condition:
        isState(AttendeeState.Joined) ||
        (isState(AttendeeState.Waiting) && isRole(AttendeeRole.Moderator)),
      component: (
        <GestureHandlerRootView style={{ flex: 1 }}>
          <StatusBar barStyle="light-content" />
          <Room />
        </GestureHandlerRootView>
      ),
    },
    {
      condition: isState(AttendeeState.FeedbackRequested),
      component: (
        <ScreenWrapper>
          <StatusBar barStyle="dark-content" />
          <RoomFeedback />
        </ScreenWrapper>
      ),
    },
    {
      condition: isState(AttendeeState.FeedbackSubmitted),
      component: (
        <ScreenWrapper>
          <StatusBar barStyle="dark-content" />
          <FeedbackSubmitted />
        </ScreenWrapper>
      ),
    },
    {
      condition: isState(AttendeeState.Complete) || isEnded(),
      component: (
        <ScreenWrapper>
          <StatusBar barStyle="dark-content" />
          <RoomCompleted />
        </ScreenWrapper>
      ),
    },
  ]

  const component = components.find((s) => s.condition)?.component || (
    <LoadingScreen title="Connecting to your group" />
  )

  return <>{component}</>
}

const styles = StyleSheet.create({
  topSafeArea: {
    flex: 0,
    backgroundColor: '#F5F5F5',
  },
  bottomSafeArea: {
    flex: 1,
    backgroundColor: '#F5F5F5',
  },
})

export default RoomContainer
