import { VStack } from 'native-base'
import { Animated, FlatList } from 'react-native'
import voyagesQuestions from '../../../../domain/models/data/voyagesQuestionnaire.json'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import VoyageQuestion from '../../components/voyages/VoyageQuestion'
import VoyagesScreenerContainer from '../../components/voyages/VoyagesScreenerContainer'
import useVoyagesScreenerContext from '../../providers/VoyagesScreenerProvider'

const VoyagesScreener = () => {
  const {
    currentQuestionIndex,
    handleNext,
    handlePrev,
    handleAnswerSelect,
    isNextButtonDisabled,
    selectedAnswers,
    fadeAnim,
    progress,
    handleExit,
  } = useVoyagesScreenerContext()
  const isDesktop = useDesktopBreakpoint()
  const numOfQuestions = isDesktop ? 4 : 1

  const handleBackButton = () => {
    if (currentQuestionIndex === 0) {
      handleExit()
    } else {
      handlePrev()
    }
  }

  return (
    <VoyagesScreenerContainer
      nextButtonTitle={
        (isDesktop && currentQuestionIndex > 3) ||
        (!isDesktop && currentQuestionIndex === 7)
          ? 'Finish'
          : 'Next'
      }
      backButtonOnPress={handleBackButton}
      isNextButtonDisabled={Boolean(isNextButtonDisabled())}
      progressValue={progress}
      onPress={handleNext}>
      <VStack>
        <FlatList
          data={voyagesQuestions.slice(
            currentQuestionIndex,
            currentQuestionIndex + numOfQuestions
          )}
          renderItem={(props) => {
            return (
              <Animated.View style={{ opacity: fadeAnim }}>
                <VoyageQuestion
                  {...props}
                  selectedAnswers={selectedAnswers}
                  handleAnswerSelect={handleAnswerSelect}
                />
              </Animated.View>
            )
          }}
          keyExtractor={(item) => String(item.id)}
        />
      </VStack>
    </VoyagesScreenerContainer>
  )
}

export default VoyagesScreener
