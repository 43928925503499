import { useQuery } from '@tanstack/react-query'
import { useCabanaApi } from '../../providers/CabanaApiProvider'

export const useGetProfile = (loggedIn) => {
  const { MemberProfileApi } = useCabanaApi()
  const { data, isFetched, isSuccess } = useQuery({
    queryKey: ['Profile'],
    queryFn: () => MemberProfileApi.profile(),
    initialData: {
      oasis: null,
      goal: null,
      mentalHealthMinutes: 0,
      professionalRoles: [],
      organization: null,
    },
    enabled: loggedIn,
  })
  const profileComplete =
    !!data.oasis &&
    (!!data.goal || !!data.customGoal) &&
    data.mentalHealthMinutes > 0 &&
    data.professionalRoles.length > 0 &&
    data.organization !== null

  return {
    userProfile: data,
    isFetched,
    profileComplete,
  }
}
