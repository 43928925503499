const formatUrl = (url) => {
  if (!url) return ''
  return url.replace(/ /g, '%20')
}
import { ActivityCardBorderState } from '../../../domain/models/interfaces/activityInterfaces'

export default (activity) => {
  if (!activity) return undefined
  const startDate = new Date(activity.startDate)
  const endDate = new Date(activity.endDate)
  const activityState =
    endDate < new Date() || activity.ended
      ? ActivityCardBorderState.COMPLETED
      : startDate < new Date()
      ? ActivityCardBorderState.LIVE
      : activity.registration === ActivityCardBorderState.MODERATING
      ? ActivityCardBorderState.MODERATING
      : activity.registration === ActivityCardBorderState.ATTENDING
      ? ActivityCardBorderState.ATTENDING
      : ActivityCardBorderState.DEFAULT
  return {
    ...activity,
    downloadUrl: formatUrl(activity.downloadUrl),
    cardBorderState: activityState,
    startDate: new Date(activity.startDate),
    endDate: new Date(activity.endDate),
  }
}
