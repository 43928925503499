import { Box, Text } from 'native-base'
import { Linking } from 'react-native'
import { theme } from '../../theme/theme'

const ContactTechSupportScreen = () => {
  return (
    <Box>
      <Text fontSize="16px">
        {`Having technical issues? Email us at `}
        <Text
          onPress={() =>
            Linking.openURL('mailto:support@mycabana.health')
              .then((data) => console.debug(data))
              .catch((data) => console.debug(data))
          }
          color={theme.colors.secondary[600]}
          underline>
          support@mycabana.health
        </Text>
        .
      </Text>
    </Box>
  )
}

export default ContactTechSupportScreen
