import { Box, Text } from 'native-base'
import useLinkHandler from '../../hooks/useLinkHandler'
import { theme } from '../../theme/theme'

const CrisisSupportScreen = () => {
  return (
    <Box p="32px">
      <Text pt="16px" fontSize="16px">
        {`Experiencing a crisis? View our `}
        <Text
          onPress={() =>
            useLinkHandler('https://www.mycabana.health/resources')
          }
          color={theme.colors.secondary[600]}
          underline>
          resources for immediate support
        </Text>
        .
      </Text>
    </Box>
  )
}

export default CrisisSupportScreen
