import { Box, Flex, StatusBar } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import useGetOasisColors from '../../../hooks/useGetOasisColors'

const ExploreContainer = ({ children }) => {
  const isDesktop = useDesktopBreakpoint()
  const { width } = useWindowDimensions()
  const { tabColor } = useGetOasisColors()

  if (!isDesktop) {
    return (
      <>
        <StatusBar barStyle="light-content" />
        <Flex bg={tabColor} h="100%" safeAreaTop>
          {children}
        </Flex>
      </>
    )
  }
  return (
    <Box bg={tabColor} w="400px" h="750px" mx={`${(width - 400) / 2}px`}>
      {children}
    </Box>
  )
}

export default ExploreContainer
