import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, FlatList, Heading, HStack, View } from 'native-base'
import { useCallback, useLayoutEffect, useState } from 'react'
import useGetProfileActions from '../../hooks/useGetProfileActions'
import BookmarksScreen from '../../screens/BookmarksScreen'
import { NavigationParams } from '../../screens/NavigationScreen'
import AboutScreen from '../../screens/profile/AboutScreen'
import AccountScreen from '../../screens/profile/AccountScreen'
import ContactTechSupportScreen from '../../screens/profile/ContactTechSupportScreen'
import CrisisSupportScreen from '../../screens/profile/CrisisSupportScreen'
import EAPScreen from '../../screens/profile/EAPScreen'
import FAQScreen from '../../screens/profile/FAQScreen'
import LeaderboardScreen from '../../screens/profile/LeaderboardScreen'
import MyGroupsScreen from '../../screens/profile/MyGroupsScreen'
import VibeCheckHistoryScreen from '../../screens/profile/VibeCheckHistoryScreen'
import ReferralScreen from '../../screens/ReferralScreen'
import ProfileItem from './ProfileItem'
import WeeklyProgressCircle from './WeeklyProgressCircle'

const selectedItemInfo = (itemId) => {
  switch (itemId) {
    case 'health-minutes':
      return <WeeklyProgressCircle />
    case 'leaderboard':
      return <LeaderboardScreen />
    case 'vibe-check-history':
      return <VibeCheckHistoryScreen />
    case 'my-groups':
      return <MyGroupsScreen />
    case 'account':
      return <AccountScreen />
    case 'eap':
      return <EAPScreen />
    case 'faq':
      return <FAQScreen />
    case 'crisis-support':
      return <CrisisSupportScreen />
    case 'contact-tech-support':
      return <ContactTechSupportScreen />
    case 'about':
      return <AboutScreen />
    case 'referrals':
      return <ReferralScreen />
    case 'bookmarks':
      return <BookmarksScreen />
    default:
      return null
  }
}

const ProfileViewDesktop = ({ selectedScreen }) => {
  const {
    VibeCheckSection,
    MyContentSection,
    AccountSection,
    HelpSection,
    SystemSection,
  } = useGetProfileActions()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const [selectedItem, setSelectedItem] = useState(
    selectedScreen ?? 'health-minutes'
  )

  const selectItem = useCallback((itemId) => {
    setSelectedItem(itemId)
    navigation.setParams({
      subscreen: itemId,
    })
  }, [])

  useLayoutEffect(() => {
    setSelectedItem(selectedScreen ?? 'health-minutes')
  }, [selectedScreen])

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <ProfileItem
          item={item}
          isFirst={index === 0}
          onPress={() => {
            item.action()
            selectItem(item.id)
          }}
          isSelected={item.id === selectedItem}
        />
      )
    },
    [selectedItem]
  )

  const renderSection = (sectionData) => (
    <FlatList
      scrollEnabled={false}
      showsVerticalScrollIndicator={false}
      borderRadius="20px"
      borderColor="grey.200"
      borderWidth={1}
      flexGrow={0}
      pt="2px"
      px="2px"
      mr="24px"
      extraData={selectedItem}
      data={sectionData}
      renderItem={({ item, index }) => renderItem({ item, index })}
    />
  )

  return (
    <Box bg="muted.50">
      <Heading my="20px" ml={1} color="primary.900" fontSize="24px">
        Profile & Settings
      </Heading>
      <HStack>
        <Box w="35%" mt={0.5}>
          {renderSection(VibeCheckSection)}
          <View h={6} />
          {renderSection(MyContentSection)}
          <View h={6} />
          {renderSection(AccountSection)}
          <View h={6} />
          {renderSection(HelpSection)}
          <View h={6} />
          {renderSection(SystemSection)}
        </Box>
        <Box
          mt="2px"
          mb="50px"
          minH="350px"
          mx="10px"
          w="624px"
          borderRadius="2xl">
          {selectedItemInfo(selectedItem)}
        </Box>
      </HStack>
    </Box>
  )
}

export default ProfileViewDesktop
