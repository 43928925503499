import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useCallback } from 'react'
import {
  ActivityCardBorderState,
  ActivityRegistrationState,
  ActivityType,
} from '../../../domain/models/interfaces/activityInterfaces'
import { useMobileBreakpoint } from '../../../domain/services/useBreakpoint'
import { useCompleteDailyRec } from '../components/home/queries'
import { useFeatures } from '../providers/FeatureProvider'
import { useUserProfileContext } from '../providers/UserProfileProvider'
import { useRegistration } from '../queries/activitiesQueries'
import { NavigationParams } from '../screens/NavigationScreen'
import { useIframeSource } from './useIframeSource'
import useLinkHandler from './useLinkHandler'

const useActivityButtonDetails = (
  activity,
  onSuccess = null,
  onError = null,
  handleClose
) => {
  const { register, unregister } = useRegistration(activity)
  const { completeContent } = useCompleteDailyRec()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isMobile = useMobileBreakpoint()
  const { userProfile } = useUserProfileContext()
  const videoUrl = useIframeSource(
    (activity.type === 'Interactive' && activity.videoUrl) || ''
  )
  const quizUrl = useIframeSource(
    (activity.quizUrl !== '' && activity.quizUrl) || '',
    false
  )
  const { features } = useFeatures()

  const openQuiz = () => {
    if (activity.quizUrl?.startsWith('http')) {
      useLinkHandler(
        `${quizUrl}#member_id=${userProfile.id}&activity_id=${activity.id}`
      )
    }
  }

  const openVideoUrl = () => {
    if (activity.videoUrl?.startsWith('http')) {
      if (activity.type === 'Interactive') {
        let url = activity.videoUrl
        url = url.replace(/(activity_id=)XXXX/, `$1${activity.id}`)
        url = url.replace(/(member_id=)XXXX/, `$1${userProfile.id}`)
        useLinkHandler(url)
      }
      useLinkHandler(
        `${videoUrl}#member_id=${userProfile.id}&activity_id=${activity.id}`
      )
    }
  }

  const customActionText = useCallback(
    (activity) => {
      switch (activity.type) {
        case ActivityType.GROUP_SKILL:
        case ActivityType.GROUP_LISTEN:
        case ActivityType.GROUP_OTHER:
        case ActivityType.GROUP_SERIES:
        case ActivityType.GROUP_SKILL:
        case ActivityType.GROUP_SUPPORT:
          let startDate = new Date(activity.startDate)
          startDate.setMinutes(startDate.getMinutes() - 15)
          const cardBorderState =
            startDate < new Date() &&
            activity.endDate > new Date() &&
            activity.cardBorderState != ActivityCardBorderState.MODERATING &&
            !activity.ended
              ? ActivityCardBorderState.LIVE
              : activity.cardBorderState
          switch (cardBorderState) {
            case ActivityCardBorderState.MODERATING:
              return {
                buttonText: 'Go to room',
                buttonAction: () => {
                  handleClose()
                  navigation.navigate('Room', {
                    roomId: activity.roomId,
                    activityId: activity.id,
                  })
                },
                buttonColor: 'tertiary.600',
              }
            case ActivityCardBorderState.DEFAULT:
              switch (activity.registration) {
                case ActivityRegistrationState.MODERATING:
                  return {
                    buttonText: 'Go to room',
                    buttonAction: () => {
                      handleClose()
                      navigation.navigate('Room', {
                        roomId: activity.roomId,
                        activityId: activity.id,
                      })
                    },
                    buttonColor: 'tertiary.600',
                  }
                case ActivityRegistrationState.AVAILABLE:
                  return {
                    buttonText: 'Attend',
                    buttonAction: () =>
                      register(activity, { onSuccess, onError }),
                  }
                case ActivityRegistrationState.FULL:
                  return {
                    buttonText: 'Join waitlist',
                    buttonAction: () => {},
                  }
              }
            case ActivityCardBorderState.LIVE:
              switch (activity.registration) {
                case ActivityRegistrationState.FULL:
                  return {
                    buttonText: 'Join waitlist',
                    buttonAction: () => {},
                  }
                default:
                  return {
                    buttonText: 'Join',
                    buttonAction: () => {
                      if (
                        activity.registration ===
                        ActivityRegistrationState.ATTENDING
                      ) {
                        handleClose()
                        navigation.navigate('Room', {
                          roomId: activity.roomId,
                          activityId: activity.id,
                        })
                      } else {
                        register(activity, {
                          onSuccess: () => {
                            handleClose()
                            navigation.navigate('Room', {
                              roomId: activity.roomId,
                              activityId: activity.id,
                            })
                          },
                          onError,
                        })
                      }
                    },
                    buttonColor: 'tertiary.600',
                  }
              }
            case ActivityCardBorderState.COMPLETED:
              return {
                buttonText: 'Ended',
                buttonAction: () => {},
                buttonColor: 'muted.400',
              }
            case ActivityRegistrationState.ATTENDING:
              return {
                buttonText: 'Remove',
                buttonAction: () =>
                  unregister(activity, { onSuccess, onError }),
                buttonColor: 'primary.600',
              }
          }

        case ActivityType.AUDIO:
          return {
            buttonText: 'Play now',
            buttonAction: () => {
              navigation.navigate(isMobile ? 'AudioPlayer' : 'Activity', {
                activityId: activity.id,
              })

              handleClose()
            },
          }
        case ActivityType.VIDEO:
          return {
            buttonText: 'Play now',
            buttonAction: () => {
              navigation.navigate('Activity', {
                activityId: activity.id,
                autoPlay: true,
              })
              handleClose()
            },
          }
        case ActivityType.MOMENT:
          return {
            buttonText: 'Start',
            buttonAction: () => {
              completeContent(activity)
              useLinkHandler(activity.sevenTapsUrl)
            },
          }
        case ActivityType.INTERACTIVE:
          return {
            buttonText: 'Start',
            buttonAction: () => {
              completeContent(activity)
              openVideoUrl()
            },
          }
        case ActivityType.QUIZ:
          return {
            buttonText: 'Start',
            buttonAction: () => {
              completeContent(activity)
              openQuiz()
            },
          }
        default:
          return undefined
      }
    },
    [unregister, register]
  )

  return (
    customActionText(activity) ?? {
      buttonText: `${
        ActivityRegistrationState[activity.registration]
      } state in type ${activity.type} not mapped`,
      buttonAction: () => {},
      invalidButton: true,
    }
  )
}

export default useActivityButtonDetails
