import { Box, HStack, Image, Spacer, Text } from 'native-base'
import { useEffect, useState } from 'react'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import Orb from '../Orb'

const RoomMobileHeader = ({}) => {
  const room = useRoomContext()
  const roomClient = useRoomClientContext()

  const [activeSpeaker, setActiveSpeaker] = useState(null)

  useEffect(() => {
    const newSpeaker = room.attendees.find(
      (a) =>
        a.microphoneOn && a.activeSpeaker && a.userId !== activeSpeaker?.userId
    )
    newSpeaker ? setActiveSpeaker(newSpeaker) : setActiveSpeaker(null)
  }, [room.attendees])

  return (
    <HStack bg="muted.800" alignItems="center" h="44px" pl="3" pr="1" py="1">
      {activeSpeaker && (
        <>
          {activeSpeaker.photo ? (
            <Image
              src={activeSpeaker.photo}
              alt="Alternate Text"
              size="16px"
              borderRadius="full"
            />
          ) : (
            <Orb attendee={activeSpeaker} />
          )}
          <Text color="text.50" ml="2" bold>
            {activeSpeaker.alias}
          </Text>
          <Text color="text.50"> is speaking</Text>
        </>
      )}
      <Spacer />
      <Box h="20px" />
    </HStack>
  )
}

export default RoomMobileHeader
