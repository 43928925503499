import Svg, { Defs, LinearGradient, Path, Rect, Stop } from 'react-native-svg'

const CopyIcon = (props) => {
  return (
    <Svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect
        width={52}
        height={52}
        rx={26}
        fill="url(#paint0_linear_11582_38650)"
      />
      <Path
        d="M37.39 26.563l-5.296 5.296c-2.672 2.625-6.938 2.625-9.563 0-2.484-2.53-2.625-6.515-.328-9.187l.235-.281a1.12 1.12 0 011.593-.094 1.12 1.12 0 01.094 1.594l-.188.234c-1.593 1.781-1.5 4.453.188 6.14 1.734 1.735 4.594 1.735 6.375 0l5.297-5.296a4.556 4.556 0 000-6.375c-1.688-1.64-4.36-1.735-6.14-.188l-.282.235a1.12 1.12 0 01-1.594-.094 1.12 1.12 0 01.094-1.594l.281-.234c2.672-2.344 6.703-2.203 9.235.281 2.625 2.625 2.625 6.89 0 9.563zM12.97 25.483l5.344-5.297a6.733 6.733 0 019.515 0c2.531 2.485 2.672 6.516.328 9.188l-.281.281c-.375.469-1.125.563-1.594.14a1.12 1.12 0 01-.093-1.593l.28-.281a4.497 4.497 0 00-.234-6.14 4.415 4.415 0 00-6.328 0l-5.343 5.296a4.556 4.556 0 000 6.375c1.687 1.64 4.359 1.734 6.14.188l.281-.235a1.12 1.12 0 011.594.094 1.12 1.12 0 01-.094 1.594l-.28.234c-2.673 2.344-6.704 2.203-9.235-.281-2.625-2.625-2.625-6.938 0-9.563z"
        fill="#fff"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_11582_38650"
          x1={46.5}
          y1={44}
          x2={7.5}
          y2={6}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#075D5A" />
          <Stop offset={1} stopColor="#00A09B" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

export default CopyIcon
