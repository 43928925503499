import { Flex, ScrollView, VStack } from 'native-base'
import NavFooter from '../components/navigation/NavFooter'
import NavHeader from '../components/navigation/NavHeader'

const DefaultLayout = ({
  activeLabel,
  bg = 'white',
  hasScrollBar = true,
  scrollView = true,
  children,
  hasNavFooter = true,
}) => {
  return (
    <VStack h="100%" bg={bg}>
      <NavHeader activeLabel={activeLabel} />
      {scrollView ? (
        <ScrollView
          scrollEnabled={hasScrollBar}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
          bounces={false}>
          {children}
        </ScrollView>
      ) : (
        <Flex flex={1} bg={bg}>
          {children}
        </Flex>
      )}
      {hasNavFooter && <NavFooter activeLabel={activeLabel} />}
    </VStack>
  )
}

export default DefaultLayout
