import { Text } from 'native-base'

const FormLabel = ({ children, setIsFocused = null, color = undefined }) => {
  const handlePress = () => {
    if (setIsFocused) setIsFocused(true)
  }

  return (
    <Text fontWeight="medium" mb="1" onPress={handlePress} color={color}>
      {children}
    </Text>
  )
}

export default FormLabel
