import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Microphone, NO_MICROPHONE } from '../../../domain/models/interfaces/microphone'
import useMicrophones, { UseMicrophonesResult } from '../hooks/useMicrophones'

export interface UseMicrophonesContext extends UseMicrophonesResult {
  selectMicrophone: (Microphone) => void
  selectedMicrophone: Microphone | null
}

const MicrophonesContext = createContext<UseMicrophonesContext | undefined>(
  undefined
)

export const useMicrophonesContext = (): UseMicrophonesContext =>
  useContext(MicrophonesContext)

interface MicrophonesProviderProps {
  children: ReactNode
}

export const MicrophonesProvider: React.FC<MicrophonesProviderProps> = ({
  children,
}) => {
  const useMicrophonesHook = useMicrophones()
  const [selectedMicrophone, setSelectedMicrophone] = useState<Microphone>(NO_MICROPHONE)

  const context: UseMicrophonesContext = {
    ...useMicrophonesHook,
    selectMicrophone: setSelectedMicrophone,
    selectedMicrophone,
  }

  return (
    <MicrophonesContext.Provider value={context}>
      {children}
    </MicrophonesContext.Provider>
  )
}
