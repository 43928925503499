import Constants from 'expo-constants'
import { useEffect, useState } from 'react'
import { addMicLevel, isAboveThreshold } from './MicLevelService'

const minValue = -127
const maxValue = 0
const hotMicThreshold = 0.35

const useMicLevel = (isMeasuring, audioTrack, captureMicLevel) => {
  const [micLevel, setMicLevel] = useState(0)

  const [isMicHot, setIsMicHot] = useState(false)
  const [isSpeaking, setIsSpeaking] = useState(false)

  useEffect(() => {
    if (!audioTrack) return
    let lastFiveSeconds = Array(50).fill(0)
    let decimalValue = 0

    let micLevelInterval = null
    let isMicHotInterval = null
    let isSpeakingInterval = null
    let trackClone = null

    if (Constants.appOwnership === 'expo') {
      setMicLevel(-1)
    } else if (!isMeasuring) {
      setIsMicHot(false)
      setIsSpeaking(false)
    } else {
      // Set up analyzer
      const audioContext = new AudioContext()
      const analyzer = audioContext.createAnalyser()
      const stream = new MediaStream()
      trackClone = audioTrack.clone()
      stream.addTrack(trackClone)
      const microphone = audioContext.createMediaStreamSource(stream)

      analyzer.fftSize = 512
      analyzer.minDecibels = minValue
      analyzer.maxDecibels = maxValue

      microphone.connect(analyzer)
      const volumes = new Uint8Array(analyzer.frequencyBinCount)

      // Set intervals
      if (captureMicLevel)
        micLevelInterval = setInterval(() => {
          setMicLevel(Math.round(decimalValue / 0.125))
        }, 500)

      isMicHotInterval = setInterval(() => {
        analyzer.getByteFrequencyData(volumes)
        let volumeSum = 0
        for (const volume of volumes) volumeSum += volume
        const averageVolume = volumeSum / volumes.length
        decimalValue = averageVolume * 0.0079
        lastFiveSeconds = addMicLevel(lastFiveSeconds, decimalValue)
        setIsMicHot(
          isAboveThreshold(lastFiveSeconds.slice(0, 10), hotMicThreshold)
        )
      }, 75)

      isSpeakingInterval = setInterval(() => {
        setIsSpeaking(isAboveThreshold(lastFiveSeconds, hotMicThreshold))
      }, 5000)
    }

    return () => {
      clearInterval(micLevelInterval)
      clearInterval(isMicHotInterval)
      clearInterval(isSpeakingInterval)
      micLevelInterval = null
      isMicHotInterval = null
      isSpeakingInterval = null
      trackClone?.stop()
    }
  }, [
    isMeasuring,
    audioTrack,
    setMicLevel,
    setIsSpeaking,
    setIsMicHot,
    captureMicLevel,
  ])

  return { micLevel, isMicHot, isSpeaking }
}

export default useMicLevel
