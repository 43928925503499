import { ScrollView } from 'native-base'

const BaseLayout = ({ children }) => {
  return (
    <ScrollView minH="100%" p="3" bounces={false}>
      {children}
    </ScrollView>
  )
}

export default BaseLayout
