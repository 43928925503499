import { useBreakpointValue } from 'native-base'
import { Platform } from 'react-native'

export const useMobileBreakpoint = () =>
  Platform.OS !== 'web' ||
  useBreakpointValue({
    base: true,
    sm: false,
  })

export const useTabletBreakpoint = () =>
  useBreakpointValue({
    base: false,
    md: true,
  })

export const useDesktopBreakpoint = () =>
  useBreakpointValue({
    base: false,
    lg: true,
  })
