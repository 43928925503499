const moods = [
  {
    name: 'Confident',
    icon: 'face-sunglasses',
    color: 'orange.600',
    bgColor: 'orange.50',
  },
  {
    name: 'Happy',
    icon: 'face-laugh',
    color: 'amber.600',
    bgColor: 'amber.50',
  },
  {
    name: 'Calm',
    icon: 'face-relieved',
    color: 'green.600',
    bgColor: 'green.50',
  },
  {
    name: 'Loving',
    icon: 'face-smile-hearts',
    color: 'purple.600',
    bgColor: 'purple.50',
  },
  {
    name: 'Angry',
    icon: 'face-angry',
    color: 'tertiary.600',
    bgColor: 'tertiary.50',
  },
  {
    name: 'Anxious',
    icon: 'face-worried',
    color: 'muted.500',
    bgColor: 'muted.100',
  },
  {
    name: 'Sad',
    icon: 'face-pensive',
    color: 'primary.600',
    bgColor: 'primary.50',
  },
  {
    name: 'Not sure',
    icon: 'face-meh',
    color: 'muted.300',
    bgColor: 'white',
    textColor: 'muted.500',
  },
]

export default moods
