import { Badge, Text, VStack } from 'native-base'

const BadgeContainer = ({ children, text, bg = 'muted.500' }) => {
  return (
    <VStack>
      {!!text && (
        <Badge
          bg={bg}
          variant="solid"
          rounded="full"
          minW="21px"
          mb="-21px"
          mr="-1"
          px="1.5"
          zIndex="1"
          alignSelf="flex-end">
          <Text fontSize="10px" fontWeight="medium" color="muted.50">
            {text}
          </Text>
        </Badge>
      )}
      {children}
    </VStack>
  )
}

export default BadgeContainer
