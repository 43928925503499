import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'
import { useTabletBreakpoint } from '../../../../domain/services/useBreakpoint'
import BottomSheet from '../_shared/notification/BottomSheet'
import CategoryFilterView from './CategoryFilterView'
import FilterPillContainer from './FilterPillContainer'

const CategoryFilter = ({
  categoryFilters,
  setCategoryFilters,
  onRemoveAllFilters,
  setIsFilterModalOpen,
}) => {
  const [categoryFilterModalOpen, setCategoryFilterModalOpen] = useState(false)
  useEffect(() => {
    setIsFilterModalOpen(categoryFilterModalOpen)
  }, [categoryFilterModalOpen])
  const isTablet = useTabletBreakpoint()

  return (
    <>
      <FilterPillContainer
        key="categoryFilter"
        isApplied={categoryFilters.length > 0}
        onPress={() => {
          Keyboard.dismiss()
          setCategoryFilterModalOpen(true)
        }}
        text={
          'Category' +
          (categoryFilters.length > 1 ? ` (${categoryFilters.length})` : '')
        }
      />
      <BottomSheet
        isOpen={categoryFilterModalOpen}
        handleClose={() => setCategoryFilterModalOpen(false)}
        title="Category"
        body={
          <CategoryFilterView
            appliedFilters={categoryFilters}
            setFilters={setCategoryFilters}
            onRemoveAllFilters={onRemoveAllFilters}
          />
        }
        hasCloseOnBottom={true}
        width={isTablet ? '390px' : '415px'}
      />
    </>
  )
}

export default CategoryFilter
