import { FlatList, Pressable, Text } from 'native-base'
import { useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useGetPopularTags, useGetSuggestedTags } from './queries'

const SuggestedAndPopular = ({ searchValue, handleSubmit }) => {
  const { popularTags, isLoading } = useGetPopularTags()
  const { suggestedTags } = useGetSuggestedTags(searchValue)
  const isMobile = useMobileBreakpoint()
  const { height } = useWindowDimensions()
  const flatListData = useMemo(() => {
    if (searchValue !== '') {
      return suggestedTags
    } else {
      return popularTags
    }
  }, [searchValue, suggestedTags, popularTags])

  const heighOffset = 264

  return (
    <FlatList
      pt="16px"
      data={flatListData}
      maxHeight={isMobile ? '100%' : height - heighOffset}
      keyExtractor={(item: string) => item}
      renderItem={({ item }) => {
        return (
          <Pressable onPress={() => handleSubmit(item)} py="4px">
            <Text fontSize="md">{item}</Text>
          </Pressable>
        )
      }}
    />
  )
}

export default SuggestedAndPopular
