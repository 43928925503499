import {
  Poppins_400Regular,
  Poppins_400Regular_Italic,
  Poppins_500Medium,
  Poppins_500Medium_Italic,
  Poppins_600SemiBold,
  Poppins_600SemiBold_Italic,
  Poppins_700Bold,
  Poppins_700Bold_Italic,
  useFonts,
} from '@expo-google-fonts/poppins'
import { LinearGradient } from 'expo-linear-gradient'
import { NativeBaseProvider } from 'native-base'
import { theme } from '../theme/theme'

const fonts = {
  Poppins_400Regular,
  Poppins_400Regular_Italic,
  Poppins_500Medium,
  Poppins_500Medium_Italic,
  Poppins_600SemiBold,
  Poppins_600SemiBold_Italic,
  Poppins_700Bold,
  Poppins_700Bold_Italic,
}

const config = {
  dependencies: {
    'linear-gradient': LinearGradient,
  },
}

export const ThemeProvider = ({ children }) => {
  const [loaded] = useFonts(fonts)
  if (!loaded) {
    return <></>
  }

  return (
    <NativeBaseProvider theme={theme} config={config}>
      {children}
    </NativeBaseProvider>
  )
}
