import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, FlatList, HStack, Pressable, Text, VStack } from 'native-base'
import {
  FocusAreasListProps,
  FocusGroup,
} from '../../../../../domain/models/interfaces/voyagesInterfaces'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { getFocusAreaByPriority } from '../../../../helpers/getFocusAreaByPriority'
import { useRenderFocusAreaIcon } from '../../../hooks/useRenderFocusAreasIcon'
import { NavigationParams } from '../../../screens/NavigationScreen'
import FaIcon from '../../_shared/FaIcon'
import PathsList from '../path/ExtraPaths'

const FocusAreasList = ({ data }: FocusAreasListProps) => {
  const isDesktop = useDesktopBreakpoint()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  return (
    <VStack w={isDesktop ? '35%' : '100%'}>
      <Box
        borderRadius="20px"
        bg="white"
        p={4}
        borderColor="grey.200"
        borderWidth={isDesktop ? 1 : 0}>
        <HStack justifyContent="space-between" alignItems="center">
          <Text color="primary.900" fontSize="18px" fontWeight={700}>
            Focus areas
          </Text>
          {isDesktop && (
            <Pressable
              onPress={() => navigation.navigate('VoyageIntro')}
              flexDirection="row"
              display="flex"
              alignItems="center">
              <FaIcon
                name="circle-info"
                isSolid
                color="primary.700"
                size={20}
              />
              <Text ml={1} fontSize="md" fontWeight={500}>
                About
              </Text>
            </Pressable>
          )}
        </HStack>
        {data && (
          <FlatList
            data={data.focusGroups}
            renderItem={({ item }: { item: FocusGroup }) => {
              return (
                <VStack>
                  <Text color="muted.500" fontWeight={500} my={4}>
                    {item.name.toUpperCase()}
                  </Text>
                  {item.focusAreas.map((focusArea, index) => {
                    const { icon, color } = useRenderFocusAreaIcon(
                      focusArea.name
                    )
                    return (
                      <Pressable
                        onPress={() =>
                          navigation.navigate('FocusArea', {
                            focusArea: focusArea.name,
                          })
                        }
                        key={index}
                        pb={3}
                        pt={index === 0 ? 0 : 3}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottomColor="grey.200"
                        borderBottomWidth={1}>
                        <HStack alignItems="center">
                          <HStack
                            borderRadius="full"
                            alignItems="center"
                            justifyContent="center"
                            width={8}
                            height={8}
                            background={color}>
                            <FaIcon
                              name={icon}
                              isSolid
                              color="white"
                              size={20}
                            />
                          </HStack>
                          <Text ml={2.5} color="muted.900" fontWeight={500}>
                            {focusArea.name}
                          </Text>
                          {(focusArea.recommendedOrder === 1 ||
                            focusArea.recommendedOrder === 2 ||
                            focusArea.recommendedOrder === 3) && (
                            <Box ml={2}>
                              <FaIcon
                                name="bullseye-arrow"
                                color="info.800"
                                size={16}
                              />
                            </Box>
                          )}
                        </HStack>
                        <HStack alignItems="center">
                          {focusArea.minutesEarned > 0 && (
                            <HStack
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="48px"
                              bg="muted.200"
                              px={2}
                              mr={2}
                              height="24px">
                              <Text
                                fontWeight={500}
                                fontSize="12px"
                                color="muted.900">{`${focusArea.minutesEarned} min`}</Text>
                            </HStack>
                          )}
                        </HStack>
                      </Pressable>
                    )
                  })}
                </VStack>
              )
            }}
            keyExtractor={(item, index) => index.toString()}
          />
        )}
      </Box>
      {isDesktop && (
        <Box mt={6}>
          <PathsList focusAreas={getFocusAreaByPriority(0, data.focusGroups)} />
        </Box>
      )}
    </VStack>
  )
}

export default FocusAreasList
