import { IconButton } from 'native-base'
import FaIcon from '../_shared/FaIcon'

const OasisSelect = ({ onPress }) => {
  return (
    <IconButton
      icon={<FaIcon name="umbrella-beach" size={18} color="white" />}
      variant="ghost"
      h="44px"
      w="44px"
      borderRadius="full"
      mr="3"
      isPressed
      onPress={onPress}
    />
  )
}

export default OasisSelect
