import { Text, VStack } from 'native-base'
import { SkeletonLoader } from '../_shared/SkeletonLoader'
import { useRewards } from './queries'
import RewardItem, { Reward } from './RewardItem'

const Rewards = () => {
  const { data, isLoading } = useRewards()

  return (
    <VStack
      borderRadius="20px"
      py={5}
      px={4}
      mx={5}
      my={5}
      bg="white"
      flexDirection="column"
      borderColor="grey.200"
      borderWidth={1}>
      <Text color="primary.900" fontSize="18px" fontWeight={700}>
        Rewards
      </Text>
      {isLoading ? (
        <SkeletonLoader size={{ width: '100%', height: 300 }} />
      ) : (
        data &&
        data.map((reward: Reward, index: number) => (
          <RewardItem
            key={reward.rewardId}
            reward={reward}
            hasBottomBorder={index === data.length - 1 ? false : true}
          />
        ))
      )}
    </VStack>
  )
}

export default Rewards
