import { Box, Button, Center, HStack, Text } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import BackButton from '../../navigation/BackButton'

const VibeCheckContainer = ({
  mainContent,
  goBack = null,
  goNext = null,
  goNextText = '',
  isCompleted = false,
  date = undefined,
  isProfileScreen = false,
}) => {
  const parsedDate = new Date(date)
  const month = parsedDate?.toLocaleString('default', { month: 'long' })
  const day = parsedDate?.getDate()
  const year = parsedDate?.getFullYear()
  const isMobile = useMobileBreakpoint()
  const { height } = useWindowDimensions()

  return (
    <>
      <Box
        bg={{
          linearGradient: {
            colors: ['#00B3A6', '#30489C'],
            start: [0, 0],
            end: [1, 1],
          },
        }}
        flex={1}
        mt={!isMobile && isProfileScreen && height <= 720 ? '-80px' : '0'}
        borderTopRadius={isMobile ? '0' : '3xl'}
        borderBottomRadius="3xl"
        p="6"
        safeAreaY>
        <HStack alignItems="center" mb="40px">
          <BackButton
            color="white"
            isDisabled={isCompleted}
            hasText={false}
            onPress={goBack}
            style={{ position: 'absolute', zIndex: 1 }}
          />
        </HStack>
        <Center>
          <Text color="white" fontWeight="500" fontSize="18px">
            {date ? `${month} ${day}, ${year}` : ``}
          </Text>
          {mainContent}
        </Center>
      </Box>
      <Box
        bg="white.100"
        h={isProfileScreen && !isMobile && height <= 700 ? '75px' : '150px'}
        justifyContent="flex-end"
        safeAreaBottom>
        {goNext && (
          <Button
            width="88%"
            height="40px"
            mb="6"
            alignSelf="center"
            onPress={goNext}>
            {goNextText}
          </Button>
        )}
      </Box>
    </>
  )
}

export default VibeCheckContainer
