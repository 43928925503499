import { AspectRatio, Box, Flex, Text, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import FaIcon from '../_shared/FaIcon'

const EmotionComponent = ({ emotion }) => {
  const { width } = useWindowDimensions()
  const isMobile = useMobileBreakpoint()
  let boxWidth = (width - 48 * 2 - 16 * 2) / 3
  boxWidth = isMobile ? boxWidth : 100
  const iconWidth = isMobile ? boxWidth - 38 : boxWidth - 44
  return (
    <AspectRatio ratio={1} width={boxWidth} m="8px">
      <Box
        bg="white"
        borderRadius="2xl"
        justifyContent="center"
        backgroundColor={emotion.bgColor}>
        <VStack w="100%" h="100%" p="2">
          <Flex flex="1">
            <FaIcon
              name={emotion.icon}
              color={emotion.color}
              size={iconWidth}
              props={{ m: 'auto' }}
            />
          </Flex>
          <Text
            fontSize="xs"
            textAlign="center"
            color={emotion.textColor ?? emotion.color}>
            {emotion.name}
          </Text>
        </VStack>
      </Box>
    </AspectRatio>
  )
}

export default EmotionComponent
