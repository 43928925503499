import { useIsFocused } from '@react-navigation/native'
import { ZStack } from 'native-base'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { useCompleteDailyRec } from '../../home/queries'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'

const VideoPlayer = ({
  activity,
  autoPlay = false,
  videoPlayerRef = null,
  videoAspectRatioCallback,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const [currentUrl, setCurrentUrl] = useState(activity.videoUrl)
  const { completeContent } = useCompleteDailyRec()
  const videoId = activity.videoUrl.split('/').pop()
  const isMobile = useMobileBreakpoint()
  const isFocused = useIsFocused()

  useEffect(() => {
    if (!isFocused) {
      setCurrentUrl(null)
    } else {
      setCurrentUrl(activity.videoUrl)
    }
  }, [isFocused])

  return (
    <ZStack width="100%" height="100%">
      <ReactPlayer
        url={currentUrl ? `https://vimeo.com/${videoId}` : null}
        controls={true}
        width="100%"
        height="100%"
        onReady={(data) => {
          setIsVideoLoaded(true)
          videoAspectRatioCallback(data)
        }}
        onPlay={() => {
          completeContent(activity)
        }}
        config={{
          vimeo: {
            playerOptions: {
              autoplay: autoPlay,
              playsinline: isMobile ? false : true,
            },
          },
        }}
        ref={videoPlayerRef}
      />
      {!isVideoLoaded && (
        <SkeletonLoader size={{ width: '100%', height: '100%' }} />
      )}
    </ZStack>
  )
}

export default VideoPlayer
