import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useCabanaApi } from '../../providers/CabanaApiProvider'

export const useGetPopularTags = () => {
  const { SearchApi } = useCabanaApi()
  const { data, isLoading } = useQuery(['popularTags'], SearchApi.popular)
  return { popularTags: data, isLoading }
}

export const useGetSuggestedTags = (searchParam) => {
  const { SearchApi } = useCabanaApi()
  const { data, isLoading } = useQuery(['suggestedTags', searchParam], () =>
    SearchApi.suggested(searchParam)
  )
  return { suggestedTags: data, isLoading }
}

export const useActivityCardSearch = (query, timeFilters, categoryFilters) => {
  const { ActivityCardApi } = useCabanaApi()
  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } =
    useInfiniteQuery({
      queryKey: [
        'Activity',
        'cardSearch',
        { query, timeFilters, categoryFilters },
      ],
      queryFn: (context) =>
        ActivityCardApi.search(
          query,
          timeFilters,
          categoryFilters,
          context.pageParam || 0
        ),
      getPreviousPageParam: (page) =>
        page.first ? undefined : page.number - 1,
      getNextPageParam: (page) => (page.last ? undefined : page.number + 1),
    })
  return { data, fetchNextPage, hasNextPage, isLoading, isFetching }
}

export const useCalendarView = () => {
  const { ActivityCardApi } = useCabanaApi()
  const { data, isLoading } = useQuery(['Activity', 'Calendar'], () =>
    ActivityCardApi.calendar()
  )
  return { groups: data, isLoading }
}
