import { Flex, HStack, Text, VStack } from 'native-base'
import { ContentCardProps } from '../../../../../domain/models/interfaces/cardInterfaces'
import useCardData from '../../../hooks/useCardData'
import FaIcon from '../FaIcon'
import CabanaCardBorder from './CabanaCardBorder'

const ContentCard = ({
  activity,
  onPress,
  width = '168px',
}: ContentCardProps) => {
  const { typeData, cardBorderStateData } = useCardData(
    activity.type,
    activity.cardBorderState
  )
  return (
    <CabanaCardBorder
      height="128px"
      width={width}
      hasShadow={false}
      cardBorderState={activity.cardBorderState}
      cardBorderStateData={cardBorderStateData}
      onPress={onPress}>
      <VStack
        h="100%"
        w="100%"
        bg={{
          linearGradient: {
            colors: [`#F8FAFC`, `#F1F5F9`],
            start: [0, 0],
            end: [0, 1],
          },
        }}
        borderRadius="lg"
        pl="4"
        pt="4"
        pr="2"
        pb="2">
        <HStack alignItems="center" mb="1">
          <FaIcon
            name={typeData.iconName}
            color={`${typeData.color}.700`}
            size={16}
            isSolid={typeData.isIconSolid}
          />
          <Text
            color={`${typeData.color}.700`}
            fontSize="12px"
            fontWeight="medium"
            textTransform="uppercase"
            ml="2">
            {typeData.label}
          </Text>
        </HStack>
        <Text
          fontSize="sm"
          fontWeight="medium"
          lineHeight="1.3em"
          numberOfLines={3}>
          {activity.title}
        </Text>
        {!!activity.duration && (
          <Flex bg="white" borderRadius="full" px="1.5" mt="auto" ml="auto">
            <Text fontSize="xs" color={`${typeData.color}.700`}>
              {activity.duration} min
            </Text>
          </Flex>
        )}
      </VStack>
    </CabanaCardBorder>
  )
}

export default ContentCard
