import { useQuery } from '@tanstack/react-query'
import { useCabanaApi } from '../../providers/CabanaApiProvider'

export const useGetMinutesHistory = () => {
  const { MemberProfileApi } = useCabanaApi()
  const { data, isLoading } = useQuery(
    ['weeklyMinutes'],
    MemberProfileApi.getMinutesHistory
  )
  return { data, isLoading }
}
