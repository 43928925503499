import { library } from '@fortawesome/fontawesome-svg-core'
import { StatusBar } from 'native-base'
import { useEffect } from 'react'
import BugSnagService from './src/domain/services/bugsnag/BugSnagService'
import clevertap from './src/domain/services/clevertap/ClevertapAdapter'
import hotjar from './src/domain/services/hotjar/HotjarAdapter'
import MixpanelAdapter from './src/domain/services/mixpanel/MixpanelAdapter'
import AppProvider from './src/infrastructure/views/providers/_AppProvider'
import NavigationScreen from './src/infrastructure/views/screens/NavigationScreen'
import icons from './src/infrastructure/views/theme/icons'

const App = ({}) => {
  useEffect(() => {
    library.add(icons)
    clevertap.initialize()
    MixpanelAdapter.init()
    BugSnagService.init()
    hotjar.initialize(3038137, 6)
  }, [])

  return (
    <AppProvider>
      <StatusBar backgroundColor="#222b60" barStyle="light-content" />
      <NavigationScreen />
    </AppProvider>
  )
}

export default App
