import { useNavigation } from '@react-navigation/native'
import { useCallback, useEffect, useState } from 'react'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import useTabNavigation from '../../hooks/useTabNavigation'
import VibeCheckContainer from '../_shared/layout/VibeCheckContainer'
import EmotionPicker from './EmotionPicker'
import EmotionReview from './EmotionReview'
import Introduction from './Introduction'
import moods from './Moods'
import { useCompleteVibeCheck, useVibeCheckHistory } from './queries'
import Success from './Success'

interface VibeCheckFormProps {
  closeModal?: () => void
  isProfileScreen?: boolean
}

const VibeCheckForm = ({ closeModal, isProfileScreen }: VibeCheckFormProps) => {
  const { navigate } = useTabNavigation()
  const [selectedMood, setSelectedMood] = useState(null)
  const [emotionNote, setEmotionNote] = useState(null)
  const [step, setStep] = useState(0)
  const [hasSubmittedReview, setHasSubmittedReview] = useState(false)
  const { data } = useVibeCheckHistory()
  const navigation = useNavigation()

  const vibeCheckNotEmpty = data?.pages[0].content.length > 0

  useEffect(() => {
    if (vibeCheckNotEmpty) {
      setStep((step) => step + 1)
    }
  }, [])

  const selectMood = (moodIndex) => {
    setSelectedMood(moodIndex)
    setStep((step) => step + 1)
  }

  const { completeVibeCheck } = useCompleteVibeCheck()

  const completeVibeCheckForm = () => {
    analytics.track('Vibe Check Completed', {
      Emotion: selectedMood.name,
      Note: emotionNote ?? '',
    })
    setHasSubmittedReview(true)
    completeVibeCheck({ selectedMood: selectedMood.name, emotionNote })
    setStep((step) => step + 1)
  }

  const goBack = useCallback(() => {
    setStep((step) => step - 1)
  }, [setStep])

  const steps = [
    {
      content: vibeCheckNotEmpty ? (
        <EmotionPicker selectMood={selectMood} moods={moods} />
      ) : (
        <Introduction />
      ),
      goNext: vibeCheckNotEmpty ? null : () => setStep((step) => step + 1),
      goNextText: vibeCheckNotEmpty ? '' : 'Next',
    },
    {
      content: <EmotionPicker selectMood={selectMood} moods={moods} />,
    },
    {
      content: (
        <EmotionReview
          setEmotionNote={setEmotionNote}
          emotionNote={emotionNote}
          emotion={selectedMood}
          hasSubmittedReview={hasSubmittedReview}
        />
      ),
      goNext: hasSubmittedReview
        ? () => navigate('Home')
        : completeVibeCheckForm,
      goNextText: hasSubmittedReview ? 'Done' : 'Complete vibe check',
    },
    {
      content: <Success />,
      goNext: closeModal ? () => closeModal() : () => navigate('Home'),
      goBack,
      goNextText: 'Done',
    },
  ]

  const handleGoBack = () => {
    if (closeModal) {
      if (step === 0 || step === 1) {
        closeModal()
      } else {
        goBack()
      }
    } else if (step === 0 || (vibeCheckNotEmpty && step === 1)) {
      if (navigation.canGoBack()) {
        navigation.goBack()
      }
    } else if (step > 0) {
      goBack()
    }
  }

  return (
    <VibeCheckContainer
      mainContent={steps[step].content}
      goBack={handleGoBack}
      goNext={steps[step].goNext}
      goNextText={steps[step].goNextText}
      isCompleted={hasSubmittedReview && step === 3}
      isProfileScreen={isProfileScreen}
    />
  )
}

export default VibeCheckForm
