import { ImageBackground } from 'react-native'

const background = require('../../assets/images/landing/landing-background.png')

const BackgroundLayout = ({ children }) => {
  return (
    <ImageBackground
      source={background}
      resizeMode="cover"
      style={{ height: '100%' }}>
      {children}
    </ImageBackground>
  )
}

export default BackgroundLayout
