import {
  Box,
  Button,
  IconButton,
  Progress,
  ScrollView,
  VStack,
} from 'native-base'
import { useState } from 'react'
import { VoyagesScreenerContainerProps } from '../../../../domain/models/interfaces/voyagesInterfaces'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useVoyagesScreenerContext } from '../../providers/VoyagesScreenerProvider'
import VoyagesScreenerModal from '../modals/VoyagesScreenerModal'
import FaIcon from '../_shared/FaIcon'
import Header from '../_shared/Header'

const VoyagesScreenerContainer = ({
  children,
  onPress,
  progressValue,
  isNextButtonDisabled,
  backButtonOnPress,
  nextButtonTitle = 'Next',
}: VoyagesScreenerContainerProps) => {
  const isDesktop = useDesktopBreakpoint()
  const [showModal, setShowModal] = useState(false)
  const { handleExit } = useVoyagesScreenerContext()

  return (
    <VStack
      bg="white"
      flex={1}
      borderColor={isDesktop ? 'grey.200' : 'transparent'}
      borderWidth={isDesktop ? 1 : 0}
      borderRadius="20px"
      mb={isDesktop ? 10 : 0}>
      {!isDesktop && (
        <Header
          backButtonOnPress={backButtonOnPress}
          backButtonTitle=""
          centerElement={
            <Progress
              value={progressValue}
              mb={2}
              width={128}
              _filledTrack={{
                bg: 'secondary.500',
              }}
            />
          }
          backgroundColor="white"
          textColor="primary.700"
          rightAction={
            <IconButton
              onPress={() => setShowModal(true)}
              icon={<FaIcon name="xmark" size={20} color="primary.700" />}
              mr={4}
              borderRadius="full"
              alignSelf="flex-end"
            />
          }
        />
      )}
      <ScrollView p={isDesktop ? 10 : 6}>{children}</ScrollView>
      {!isDesktop ? (
        <Box height={98} bg="white" px={6} pt={2}>
          <Button onPress={onPress} isDisabled={isNextButtonDisabled}>
            {nextButtonTitle}
          </Button>
        </Box>
      ) : (
        <Button
          w="342px"
          height="48px"
          mb={10}
          mt={-10}
          alignSelf="center"
          onPress={onPress}
          isDisabled={isNextButtonDisabled}>
          {nextButtonTitle}
        </Button>
      )}
      {!isDesktop && (
        <VoyagesScreenerModal
          closeModal={() => setShowModal(false)}
          show={showModal}
          body="Exiting the assessment will erase your answers. Are you sure you want to exit?"
          heading="Your answers will not be saved"
          exit={() => {
            setShowModal(false)
            handleExit()
          }}
        />
      )}
    </VStack>
  )
}

export default VoyagesScreenerContainer
