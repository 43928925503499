import { Flex, Heading, Text, VStack } from 'native-base'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import FormAccessoryNavigation from '../_shared/form/FormAccessoryNavigation'
import FormInput from '../_shared/form/FormInput'

const MentalHealthMinutesForm = ({ value, setValue }) => {
  const isMobile = useMobileBreakpoint()

  const getWeeklyTotal = () => {
    let description = ''

    const weeklyTotal = value * 7
    const weeklyHours = Math.floor(weeklyTotal / 60)
    const weeklyMinutes = weeklyTotal % 60

    if (weeklyHours)
      description += `${weeklyHours} hour${weeklyHours > 1 ? 's' : ''}`
    if (weeklyHours && weeklyMinutes) description += ', '
    if (weeklyMinutes)
      description += `${weeklyMinutes} minute${weeklyMinutes > 1 ? 's' : ''}`

    return description
  }

  const validation = (minutes) =>
    new Promise((resolve, reject) => {
      if (!Number.isInteger(Number(minutes))) reject('Please enter a number')
      else if (minutes < 1 || minutes > 60)
        reject('Please enter a number between 1-60')
      else resolve(null)
    })

  return (
    <>
      <VStack w="100%" alignItems="center">
        {isMobile && (
          <Heading w="320px" textAlign="center" mt="2" mb="5">
            What's a reasonable daily goal?
          </Heading>
        )}

        <Text w="300px" textAlign="center" mb="5">
          How many minutes per day are you able to dedicate to your mental
          health?
        </Text>
        <Flex w="300px">
          <FormInput
            autofocus
            keyboardType="number-pad"
            label="Minutes per day"
            onChangeText={setValue}
            validation={validation}
            defaultValue={value ? value.toString() : value}
            inputAccessoryViewID={'form'}
          />
        </Flex>
        {!!value && (
          <>
            <Text>That's {getWeeklyTotal()} per week.</Text>
            <Text mb="5">A great place to start!</Text>
          </>
        )}
      </VStack>
      <FormAccessoryNavigation hasChevrons={false} />
    </>
  )
}

export default MentalHealthMinutesForm
