import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Collapse, HStack, Pressable, Text, VStack } from 'native-base'
import { useState } from 'react'
import { NavigationParams } from '../../screens/NavigationScreen'
import FaIcon from '../_shared/FaIcon'
import AccordionItem from './AccordionItem'
import VoyageChip from './VoyageChip'

const PathAccordion = ({ borderColor, path, color, focusArea, startOpen }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()

  const completedSteps = []
  const remainingSteps = []

  path.steps.forEach((obj) => {
    if (obj.completed) {
      completedSteps.push(obj)
    } else {
      remainingSteps.push(obj)
    }
  })

  const [isOpen, setIsOpen] = useState(startOpen)
  return (
    <VStack
      mb={2}
      height={isOpen ? 'auto' : '50px'}
      borderLeftWidth={10}
      borderWidth={1}
      borderColor="muted.300"
      borderLeftColor={borderColor}
      borderRadius="10px"
      pb={1}>
      <Pressable
        onPress={() => setIsOpen(!isOpen)}
        flexDirection="row"
        height={'50px'}
        alignItems="center"
        justifyContent="space-between"
        px={3}>
        <Text color="primary.900" fontWeight={500}>
          {path.name}
        </Text>
        <HStack alignItems="center">
          {path.pathCompleted ? (
            <HStack
              mr={3}
              alignItems="center"
              justifyContent="center"
              width={6}
              height={6}
              background="primary.700"
              borderRadius="full">
              <FaIcon color="white" name="check" size={14} />
            </HStack>
          ) : (
            <VoyageChip
              bg={path.stepsCompleted > 0 ? 'success.200' : 'muted.200'}
              text={`${path.stepsCompleted} / ${path.steps.length}`}
            />
          )}
          <FaIcon
            color="muted.500"
            name={isOpen ? 'chevron-up' : 'chevron-down'}
          />
        </HStack>
      </Pressable>
      <Collapse isOpen={isOpen}>
        {completedSteps.map((step, index) => (
          <AccordionItem
            focusArea={focusArea}
            pathDetails={path}
            currentStep={false}
            key={step.id}
            isAccordionOpen={isOpen}
            index={index + 1}
            step={step}
            color={color}
            handleNavigation={() => {
              navigation.navigate('Path', { focusArea, id: path.id })
            }}
          />
        ))}
        {remainingSteps.map((step, index) => (
          <AccordionItem
            focusArea={focusArea}
            pathDetails={path}
            key={step.id}
            isAccordionOpen={isOpen}
            index={completedSteps.length + index + 1}
            step={step}
            color={color}
            currentStep={index === 0 && !step.completed}
            handleNavigation={() => {
              navigation.navigate('Path', { focusArea, id: path.id })
            }}
          />
        ))}
      </Collapse>
    </VStack>
  )
}

export default PathAccordion
