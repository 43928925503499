import { useQuery } from '@tanstack/react-query'
import { useCabanaApi } from '../providers/CabanaApiProvider'

export const useAllTimeLeaderboard = () => {
  const { LeaderboardApi } = useCabanaApi()
  const { data, isLoading } = useQuery(['all-time-leaderboard'], () =>
    LeaderboardApi.allTimeLeaderboard()
  )
  return { data, isLoading }
}

export const useMonthlyLeaderboard = () => {
  const { LeaderboardApi } = useCabanaApi()
  const { data, isLoading } = useQuery(['monthly-leaderboard'], () =>
    LeaderboardApi.monthlyLeaderboard()
  )
  return { data, isLoading }
}

export const useMemberLeaderboard = () => {
  const { LeaderboardApi } = useCabanaApi()
  const { data, isLoading } = useQuery(['member-leaderboard'], () =>
    LeaderboardApi.memberLeaderboard()
  )
  return { data, isLoading }
}
