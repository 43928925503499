import { Button, HStack, Text } from 'native-base'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ActivityCardBorderState } from '../../../../../domain/models/interfaces/activityInterfaces'
import MessageAllButtonAndModal from './MessageAllButtonAndModal'

const toolbarOptions = {
  options: ['inline', 'link', 'list', 'remove', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    options: ['unordered', 'ordered', 'indent'],
  },
}

const ModeratorActivityPreviewButton = ({
  onPressDetail,
  moderator,
  activity,
  onPressGoToRoom,
}) => {
  const isEnded = activity.cardBorderState === ActivityCardBorderState.COMPLETED

  return (
    <>
      <HStack my="16px">
        <Button variant="outline" onPress={onPressDetail} width="48%">
          <Text fontSize="md" fontWeight={500}>
            Learn more
          </Text>
        </Button>
        <MessageAllButtonAndModal activity={activity} />
      </HStack>
      {!isEnded && (
        <Button onPress={onPressGoToRoom}>
          <Text fontSize="md" fontWeight={500} color="white">
            Go to room
          </Text>
        </Button>
      )}
    </>
  )
}

export default ModeratorActivityPreviewButton
