import { Box, HStack, Image, VStack } from 'native-base'
import { Animated, ImageBackground, useWindowDimensions } from 'react-native'
import BackButton from '../navigation/BackButton'
import Header from '../_shared/Header'
import { SkeletonLoader } from '../_shared/SkeletonLoader'

const Wave = require('../../assets/images/moderators/Wave.png')
const BigWave = require('../../assets/images/moderators/BigWave.png')

const extractName = (moderatorName) => {
  const match = moderatorName.match(/(?:Dr\. )?(\w+)/)

  if (match && match[1]) {
    return match[1]
  }

  return moderatorName
}

const MainHeader = ({ moderator, isLoading }) => {
  const { width } = useWindowDimensions()

  return (
    <ImageBackground source={BigWave} style={{ width: '100%', height: 128 }}>
      <HStack
        pb={2}
        flex={1}
        px={4}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Box width={width / 3} mt={6}>
          <BackButton color="white" customText="Back" />
        </Box>
        <Box
          position="relative"
          top={70}
          alignItems="center"
          width={width / 3}
          ml={-4}>
          {isLoading ? (
            <VStack justifyContent="center" alignItems="center" p={6}>
              <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
            </VStack>
          ) : (
            <Image
              borderColor={'yellow.500'}
              borderWidth={2}
              source={{ uri: moderator.photoUrl }}
              alt="Moderator photo"
              borderRadius="full"
              width="100px"
              height="100px"
            />
          )}
        </Box>
        <Box width={width / 3} />
      </HStack>
    </ImageBackground>
  )
}

const ModeratorCustomHeaderMobile = ({ moderator, scrollY, isLoading }) => {
  const isAtTop = scrollY.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  })

  const isScrolled = scrollY.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  })

  return (
    <>
      <Animated.View
        style={{
          opacity: isAtTop,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 1,
        }}>
        <MainHeader moderator={moderator} isLoading={isLoading} />
      </Animated.View>
      <Animated.View
        style={{
          opacity: isScrolled,
          position: 'absolute',
          top: 0,
          width: '100%',
          backgroundColor: 'white',
        }}>
        <Header source={Wave} title={extractName(moderator?.name || '')} />
      </Animated.View>
    </>
  )
}

export default ModeratorCustomHeaderMobile
