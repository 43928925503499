import { useEffect, useRef, useState } from 'react'
import {
  connect,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from 'twilio-video'

import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import {
  Microphone,
  NO_MICROPHONE,
} from '../../../domain/models/interfaces/microphone'
import { AudioStream, UseMeeting, VideoStream } from './useMeeting'

const useMeeting: UseMeeting = (token: string) => {
  const [connected, setConnected] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const room = useRef<Room | null>(null)
  const microphoneTrack = useRef<LocalAudioTrack | null>(null)
  const cameraTrack = useRef<LocalVideoTrack | null>(null)
  const screenTrack = useRef<LocalVideoTrack | null>(null)
  const [audio, setAudio] = useState<AudioStream[]>([])
  const [video, setVideo] = useState<VideoStream[]>([])

  const join = async () => {
    // connect to the room and store the reference
    const twilioRoom = await connect(token, { audio: false, video: false })
    room.current = twilioRoom
    setConnected(true)
    setError(null)

    // handle track subscription
    twilioRoom.on('trackSubscribed', handleTrackSubscribed)
    twilioRoom.on('trackUnsubscribed', handleTrackUnsubscribed)
  }

  const leave = async () => {
    // disconnect from the room and clear the reference
    await room.current.disconnect()
    setConnected(false)
    room.current = null
  }

  const setMicrophone = async (microphone: Microphone) => {
    // unpublish the current microphone track, if it exists
    if (microphoneTrack.current) {
      room.current.localParticipant.unpublishTrack(microphoneTrack.current)
      microphoneTrack.current.stop()
      microphoneTrack.current = null
    }

    // if we're setting NO_MICROPHONE, we're done
    if (microphone === NO_MICROPHONE) return

    // turn the microphone id into a track, publish it, and save the reference
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: microphone.object.deviceId },
    })
    const track = stream.getAudioTracks()[0]
    const audioTrack = new LocalAudioTrack(track)
    await room.current.localParticipant.publishTrack(audioTrack)
    microphoneTrack.current = audioTrack
  }

  const microphoneOff = () => {
    // disable the current microphone track
    microphoneTrack.current?.disable()
  }

  const microphoneOn = () => {
    // enable the current microphone track
    microphoneTrack.current?.enable()
  }

  const setCamera = async (camera: Camera) => {
    // unpublish the current camera track, if it exists
    if (cameraTrack.current) {
      room.current.localParticipant.unpublishTrack(cameraTrack.current)
      cameraTrack.current.stop()
      cameraTrack.current = null
    }

    // if we're setting NO_CAMERA, we're done
    if (camera === NO_CAMERA) return

    // turn the camera id into a track, publish it, and save the reference
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: camera.object.deviceId },
    })
    const track = stream.getVideoTracks()[0]
    const videoTrack = new LocalVideoTrack(track)
    await room.current.localParticipant.publishTrack(videoTrack)
    cameraTrack.current = videoTrack
  }

  const cameraOff = () => {
    // disable the current camera track
    cameraTrack.current?.disable()
  }

  const cameraOn = () => {
    // enable the current camera track
    cameraTrack.current?.enable()
  }

  const screenShareOff = async () => {
    room.current.localParticipant.unpublishTrack(screenTrack.current)
    screenTrack.current = null
  }

  const screenShareOn = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia({video: {frameRate: 15}})
    const track = new LocalVideoTrack(stream.getTracks()[0])
    await room.current.localParticipant.publishTrack(track)
    screenTrack.current = track
  }

  const noop = async () => {}
  const actions = room.current ? {
    join,
    leave,
    setMicrophone,
    microphoneOff,
    microphoneOn,
    setCamera,
    cameraOff,
    cameraOn,
    screenShareOff,
    screenShareOn
  } : {
    join,
    leave: noop,
    setMicrophone: noop,
    microphoneOff: noop,
    microphoneOn: noop,
    setCamera: noop,
    cameraOff: noop,
    cameraOn: noop,
    screenShareOff: noop,
    screenShareOn: noop
  }

  const handleTrackSubscribed = (track: RemoteTrack) => {
    if (track.kind === 'audio') {
      handleAudioTrackSubscribed(track as RemoteAudioTrack)
    }
    else if (track.kind === 'video') {
      handleVideoTrackSubscribed(track as RemoteVideoTrack)
    }
  }

  const handleAudioTrackSubscribed = (track: RemoteAudioTrack) => {
    // create an audio stream from the track
    const stream: AudioStream = {
      id: track.sid,
      play: () => {
        track.attach().play()
      },
      stop: () => {
        track.detach().forEach(el => el.remove())
      }
    }

    // add the new stream to the audio state
    setAudio(prev => [...prev, stream])
  }

  const handleVideoTrackSubscribed = (track: RemoteVideoTrack) => {
    // create an video stream from the track
    const stream: VideoStream = {
      id: track.sid,
      attach: (el :HTMLVideoElement) => {
        el.srcObject = track.attach().srcObject
      },
      detach: () => {
        track.detach().forEach(el => el.remove())
      }
    }

    // add the new stream to the video state
    setVideo(prev => [...prev, stream])
  }

  const handleTrackUnsubscribed = (track: RemoteTrack) => {
    if(track.kind === 'audio') {
      handleAudioTrackUnsubscribed(track as RemoteAudioTrack)
    }
    else if (track.kind === 'video') {
      handleVideoTrackUnsubscribed(track as RemoteVideoTrack)
    }
  }

  const handleAudioTrackUnsubscribed = (track: RemoteAudioTrack) => {
    // remove the audio stream matching the track sid
    setAudio(prev => prev.filter(stream => stream.id !== track.sid))
  }

  const handleVideoTrackUnsubscribed = (track: RemoteVideoTrack) => {
    // remote the video stream matching the track sid
    setVideo(prev => prev.filter(stream => stream.id !== track.sid))
  }

  return {
    connected,
    error,
    audio,
    video,
    ...actions
  }
}

export default useMeeting

/*

const useMeeting: UseMeeting = (token: string) => {
  const [meeting, setMeeting] = useState<Meeting>(null)
  const [error, setError] = useState<string>(null)

  const createMeeting: (token: string) => Promise<Meeting> = async (token) => {
    const room = await connect(token, { audio: false, video: false })
    let microphoneTrack: LocalAudioTrack
    let cameraTrack: LocalVideoTrack

    const meeting: Meeting = {
      setMicrophone: async (microphone: Microphone) => {
        if (microphoneTrack) {
          room.localParticipant.unpublishTrack(microphoneTrack)
          microphoneTrack.stop()
          microphoneTrack = null
        }

        if (microphone && microphone !== NO_MICROPHONE) {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: microphone.object.deviceId },
          })
          const track = stream.getAudioTracks()[0]
          const audioTrack = new LocalAudioTrack(track)
          await room.localParticipant.publishTrack(audioTrack)
          microphoneTrack = audioTrack
        }
      },
      mute: () => {
        microphoneTrack?.disable()
      },
      unmute: () => {
        microphoneTrack?.enable()
      },
      setCamera: async (camera: Camera) => {
        if (cameraTrack) {
          room.localParticipant.unpublishTrack(cameraTrack)
          cameraTrack.stop()
          cameraTrack = null
        }

        if (camera && camera !== NO_CAMERA) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: camera.object.deviceId },
          })
          const track = stream.getVideoTracks()[0]
          const videoTrack = new LocalVideoTrack(track, {})
          await room.localParticipant.publishTrack(videoTrack)
          cameraTrack = videoTrack
        }
      },
      cameraOff: () => {
        cameraTrack?.disable()
      },
      cameraOn: () => {
        cameraTrack?.enable()
      },
      onNewAudio: (callback) => {
        room.on('trackSubscribed', (track: RemoteTrack) => {
          if (track.kind === 'audio') {
            callback(createAudio(track))
          }
        })
      },
      onNewVideo: (callback) => {
        room.on('trackSubscribed', (track: RemoteTrack) => {
          if (track.kind === 'video') {
            callback(createVideo(track))
          }
        })
      },
      onVideoRemoved: (callback) => {
        room.on('trackUnsubscribed', (track: RemoteTrack) => {
          if (track.kind === 'video') {
            callback(createVideo(track))
          }
        })
      },
      leave: () => {
        room.disconnect()
      },
    }

    return meeting
  }

  const createAudio: (track: RemoteAudioTrack) => Audio = (track) => ({
    play: () => track.attach(),
    stop: () => track.detach().forEach((el: HTMLMediaElement) => el.remove()),
  })

  const createVideo: (track: RemoteVideoTrack) => Video = (track) => ({
    play: (element: HTMLVideoElement) => track.attach(element),
    stop: () => track.detach().forEach((el: HTMLMediaElement) => el.remove()),
  })

  useEffect(() => {
    if (!token) return () => {}

    createMeeting(token)
      .then(setMeeting)
      .catch((error) => setError(error.message))

    return () => meeting?.leave()
  }, [token])

  return { meeting, error }
}
export default useMeeting
*/