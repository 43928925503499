import mixpanel from 'mixpanel-browser'
import Configuration from '../ConfigurationService'

const MixpanelAdapter = {
  ...mixpanel,
  track: (data) => mixpanel.track(data),
  track: (data, params) => mixpanel.track(data, params),
  identify: (data) => mixpanel.identify(data),
  people: {
    set: (data) => mixpanel.people.set(data),
  },
  init: () => {
    mixpanel.init(Configuration.MIXPANEL_TOKEN, { debug: true })
  },
}

export default MixpanelAdapter
