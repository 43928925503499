import { Button, Heading, Image, Text, VStack } from 'native-base'
import { ImageSourcePropType } from 'react-native'
import { useDesktopBreakpoint } from '../../../domain/services/useBreakpoint'

interface EmptyListComponentProps {
  description: string
  buttonText: string
  buttonAction: () => void
  imageSource: ImageSourcePropType
  title?: string
  height?: number
  width?: number
}

const EmptyListComponent = ({
  description,
  buttonText,
  buttonAction,
  imageSource,
  title,
  height = 99,
  width = 118,
}: EmptyListComponentProps) => {
  const isDesktop = useDesktopBreakpoint()
  return (
    <VStack
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      pt={isDesktop && 2}
      pb={isDesktop && 4}>
      {isDesktop && (
        <Text
          color="primary.900"
          fontWeight="bold"
          alignSelf="flex-start"
          fontSize="16px">
          {title}
        </Text>
      )}
      <Image
        style={{ height, width }}
        source={imageSource}
        alt="squid image"
        my={4}
      />
      <Heading
        textAlign="center"
        fontWeight="700"
        maxW="288px"
        fontSize="18px"
        color="primary.900"
        mb="8px">
        There’s nothing here yet
      </Heading>
      <Text
        fontSize="14px"
        textAlign="center"
        mb="28px"
        maxW="288px"
        color="primary.900">
        {description}
      </Text>
      <Button w="310px" onPress={buttonAction}>
        {buttonText}
      </Button>
    </VStack>
  )
}

export default EmptyListComponent
