import { Button, Text } from 'native-base'
import { useCabanaApi } from '../../../providers/CabanaApiProvider'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import BottomSheet from '../../_shared/notification/BottomSheet'

const EndGroupModal = ({}) => {
  const { RoomApi } = useCabanaApi()
  const room = useRoomContext()
  const roomClient = useRoomClientContext()

  return (
    <BottomSheet
      isOpen={roomClient.endGroupOpen}
      width="300px"
      title="End live group"
      closeButtonText="Cancel"
      body={
        <>
          <Text mb="5">Are you sure you want to end this live group?</Text>
          <Text color="tertiary.600">
            Warning: you will not be able to restart the group once it's ended.
          </Text>
        </>
      }
      actions={
        <Button
          colorScheme="tertiary"
          onPress={() => {
            const {
              cameraTrack,
              microphoneTrack,
              screenVideoTrack,
              screenAudioTrack,
            } = roomClient
            cameraTrack?.stop()
            microphoneTrack?.stop()
            screenVideoTrack?.stop()
            screenAudioTrack?.stop()
            RoomApi.end(room.roomId)
          }}>
          End group
        </Button>
      }
      handleClose={roomClient.toggleEndGroup}
    />
  )
}

export default EndGroupModal
