import { Pressable } from 'native-base'

const Overlay = ({
  isLoading = false,
  isLight = false,
  borderRadius = null,
  handlePress = null,
}) => {
  return (
    <Pressable
      position="absolute"
      bg={isLight ? 'muted.50' : 'muted.900'}
      width="100%"
      height="100%"
      opacity=".75"
      borderRadius={borderRadius}
      zIndex={3}
      onPress={handlePress}
    />
  )
}

export default Overlay
