import { Box } from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useCabanaApi } from '../../providers/CabanaApiProvider'
import useRoomClientContext from '../../providers/RoomClientProvider'
import useRoomContext from '../../providers/RoomProvider'
import Card from '../_shared/card/Card'
import Caption from '../_shared/text/Caption'
import ChatInput from './ChatInput'
import RenderMessages from './RenderMessages'

const Chat = ({ messages, sendMessage, otherUserMessageCount }) => {
  /* State */
  const input = useRef(null)
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const isDesktop = useDesktopBreakpoint()
  const { width } = useWindowDimensions()
  const { RoomApi } = useCabanaApi()
  const scrollView = useRef(null)
  const [typingMessage, setTypingMessage] = useState('')
  const inputCursorRef = useRef(0)

  const emojis = [
    { hex: '👍', name: 'Thumbs up' },
    { hex: '❤️', name: 'Heart' },
    { hex: '👏', name: 'Clapping' },
    { hex: '🤔', name: 'Inquisitive' },
    { hex: '😂', name: 'Laughing' },
    { hex: '🎉', name: 'Party popper' },
  ]

  const onSelectionChange = (cursorPosition) => {
    const newSelection = cursorPosition.nativeEvent.selection
    if (newSelection.start === newSelection.end) {
      inputCursorRef.current = newSelection.start
    }
  }

  /* Hooks */
  // Reset input height and set lastReadIndex on open
  useEffect(() => {
    if (roomClient.chatOpen) {
      roomClient.setLastReadIndex(otherUserMessageCount)
    }
  }, [roomClient.chatOpen, otherUserMessageCount])

  // Increment lastReadIndex as visible messages come in
  useEffect(() => {
    if (
      roomClient.chatOpen &&
      roomClient.lastReadIndex < otherUserMessageCount
    ) {
      roomClient.setLastReadIndex(otherUserMessageCount)
    }
  }, [messages, otherUserMessageCount])

  // Display-hide user is typing message
  useEffect(() => {
    let typingCount = 0

    for (const user of room.attendees) {
      if (user.typing && user.userId !== room.me.userId) {
        typingCount++

        if (user.role === 'MODERATOR' && typingCount === 1) {
          room.setIsUserTyping(true)
          setTypingMessage('Moderator is typing...')
        }
        if (user.role === 'PARTICIPANT' && typingCount === 1) {
          room.setIsUserTyping(true)
          setTypingMessage(`${user.alias} is typing...`)
        }
      }

      if (typingCount >= 2) {
        room.setIsUserTyping(true)
        setTypingMessage('Participants are typing...')
        break
      }

      if (typingCount === 0) {
        setTypingMessage('')
      }
    }
  }, [room.setIsUserTyping, room.attendees])

  return (
    <Card
      title={isDesktop && 'Chat'}
      boxProps={{
        w: !isDesktop ? width : '322px',
        h: '100%',
        borderWidth: !isDesktop ? '0' : '1px',
      }}
      scrollViewProps={{
        ref: scrollView,
        onContentSizeChange: () => scrollView.current?.scrollToEnd(),
      }}
      hasPadding={{ body: false, title: isDesktop, actions: true }}
      handleClose={roomClient.toggleChat}
      bottomMessage={room.isUserTyping ? typingMessage : ''}
      body={<RenderMessages messages={messages} room={room} />}
      actions={
        <Box width="100%">
          <Caption>To everyone</Caption>
          <ChatInput
            sendMessage={sendMessage}
            emojis={emojis}
            inputRef={input}
            onSelectionChange={onSelectionChange}
            room={room}
            RoomApi={RoomApi}
          />
        </Box>
      }
    />
  )
}

export default Chat
