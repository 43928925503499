const mdStylesDesktop = {
  container: {
    backgroundColor: 'white',
  },
  text: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    lineHeight: 24,
  },
  em: {
    fontFamily: 'Poppins_400Regular_Italic',
  },
  strong: {
    fontFamily: 'Poppins_700Bold',
  },
  strikethrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  link: {
    fontStyle: 'italic',
    color: '#018786',
  },
  h1: {
    fontFamily: 'Poppins_700Bold',
    fontSize: 32,
    paddingTop: 16,
    paddingBottom: 10,
  },
  h2: {
    fontFamily: 'Poppins_700Bold',
    fontSize: 24,
    paddingTop: 16,
    paddingBottom: 10,
  },
  h3: {
    fontFamily: 'Poppins_700Bold',
    fontSize: 20,
    paddingVertical: 10,
  },
  h4: {
    fontFamily: 'Poppins_700Bold',
    fontSize: 16,
    paddingVertical: 10,
  },
  h5: {
    fontFamily: 'Poppins_700Bold',
    fontSize: 14,
    paddingVertical: 10,
  },
  h6: {
    fontFamily: 'Poppins_700Bold',
    fontSize: 12,
    paddingVertical: 10,
  },
  li: {
    fontSize: 16,
    width: '98%',
  },
  blockquote: {
    backgroundColor: '#F5F5F5',
    paddingVertical: 24,
    paddingHorizontal: 32,
    margin: 16,
  },
  code: {
    backgroundColor: '#F5F5F5',
    paddingVertical: 2,
    paddingHorizontal: 2,
  },
}

export default mdStylesDesktop
