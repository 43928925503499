import { useNavigation } from '@react-navigation/native'
import { LinearGradient } from 'expo-linear-gradient'
import { Box, Button, Text } from 'native-base'
import { useCallback, useState } from 'react'
import Markdown from 'react-native-marked'
import GroupCardType from '../../../../../domain/enums/GroupCardType'
import {
  ActivityRegistrationState,
  ActivityType,
} from '../../../../../domain/models/interfaces/activityInterfaces'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import mdStylesDesktop from '../../../constants/mdStylesDesktop'
import mdStylesMobile from '../../../constants/mdStylesMobile'
import { useUserProfileContext } from '../../../providers/UserProfileProvider'
import { useGetActivityDetails } from '../../../queries/activitiesQueries'
import GroupCard from '../../_shared/card/GroupCard'
import ActivityActionButtonAndModal from './ActivityActionButtonAndModal'
import ModeratorActivityPreviewButton from './ModeratorActivityPreviewButton'

const activitiesWithoutCustomAction = [
  ActivityType.ARTICLE,
  ActivityType.MOMENT,
]

const ActivityPreview = ({ activity, handleClose }) => {
  const navigation = useNavigation()
  const { activity: fetchedActivity, isLoading } = useGetActivityDetails(
    activity.id
  )
  const isMobile = useMobileBreakpoint()
  const { userProfile } = useUserProfileContext()
  const [descriptionHeight, setDescriptionHeight] = useState(0)

  const onPressDetail = useCallback(() => {
    handleClose()
    navigation.navigate('Activity', {
      activityId: activity.id,
    })
  }, [])

  const onPressGoToRoom = () => {
    //TODO Should not register
    handleClose()
    navigation.navigate('Room', {
      roomId: activity.roomId,
      activityId: activity.id,
    })
  }

  const isModerator =
    activity.registration === ActivityRegistrationState.MODERATING
  return (
    <>
      <Box>
        <GroupCard
          activity={fetchedActivity ?? activity}
          type={GroupCardType.Preview}
        />
      </Box>

      <Box
        mt={isMobile ? '-30px' : undefined}
        maxH="154px"
        overflow="hidden"
        onLayout={(event) => {
          setDescriptionHeight(event.nativeEvent.layout.height)
        }}>
        <LinearGradient
          colors={['rgba(255,255,255,0)', 'white']}
          style={{
            height: descriptionHeight - 105,
            position: 'absolute',
            zIndex: 2999,
            bottom: 0,
            width: '100%',
          }}
        />
        <Markdown
          value={
            fetchedActivity ? fetchedActivity.description : activity.description
          }
          flatListProps={{
            scrollEnabled: false,
            contentContainerStyle: { backgroundColor: 'white' },
          }}
          styles={{ ...(isMobile ? mdStylesMobile : mdStylesDesktop) }}
        />
      </Box>
      {!isMobile && isModerator ? (
        <ModeratorActivityPreviewButton
          moderator={activity.moderator}
          onPressDetail={onPressDetail}
          onPressGoToRoom={onPressGoToRoom}
          activity={activity}
        />
      ) : (
        <>
          <Button my="16px" variant="outline" onPress={onPressDetail}>
            <Text fontSize="md" fontWeight={500}>
              Learn more
            </Text>
          </Button>
          {!activitiesWithoutCustomAction.includes(activity.type) &&
            (activity.type === 'Quiz' || activity.type === 'Interactive' ? (
              fetchedActivity && (
                <ActivityActionButtonAndModal
                  handleClose={handleClose}
                  activity={fetchedActivity}
                />
              )
            ) : (
              <ActivityActionButtonAndModal
                handleClose={handleClose}
                activity={fetchedActivity ?? activity}
              />
            ))}
        </>
      )}
    </>
  )
}

export default ActivityPreview
