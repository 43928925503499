import { Image } from 'native-base'

const WaveLayout = ({ children }) => {
  const waveLines = require('./../assets/images/ui/wave-lines.svg')

  return (
    <>
      <Image
        source={{
          uri: waveLines,
        }}
        alt="thin wavy lines"
        position="absolute"
        right="0"
        bottom="0"
        h="100%"
        w="100%"
        maxW="808px"
        maxH="376.06px"
      />
      <>{children}</>
    </>
  )
}

export default WaveLayout
