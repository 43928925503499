import { useQueryClient } from '@tanstack/react-query'

const useInvalidateQueries = () => {
  const queryClient = useQueryClient()
  return {
    invalidateQueries: (queryKey) =>
      queryClient.invalidateQueries({ queryKey: queryKey }),
  }
}

export default useInvalidateQueries
