import {
  Badge,
  Box,
  HStack,
  IconButton,
  Image,
  Spacer,
  Text,
} from 'native-base'
import AttendeeRole from '../../../../domain/enums/AttendeeRole'
import AttendeeState from '../../../../domain/enums/AttendeeState'
import useRoomContext from '../../providers/RoomProvider'
import FaIcon from '../_shared/FaIcon'
import Orb from './Orb'

const RosterItem = ({ attendee }) => {
  /* State */
  const room = useRoomContext()
  /* Render */
  let aliasInfo
  if (attendee.userId === room.me.userId) aliasInfo = '(You)'
  else if (attendee.role === AttendeeRole.Moderator) aliasInfo = '(Moderator)'

  let deviceIcon
  switch (attendee.deviceType) {
    case 'BROWSER':
      deviceIcon = 'web'
      break
    case 'MOBILE':
      deviceIcon = 'cellphone'
      break
    case 'DIAL-IN':
      deviceIcon = 'phone-outline'
      break
  }

  let handlePressMic = () => {}
  if (room.me.role === AttendeeRole.Moderator && attendee.microphoneOn)
    handlePressMic = () => room.muteParticipant(attendee.userId)
  else if (attendee.userId === room.me.userId)
    handlePressMic = () => room.toggleMicrophone()

  let rightHandContent
  if (
    attendee.role === AttendeeRole.Participant &&
    attendee.state === AttendeeState.Waiting
  ) {
    rightHandContent = (
      <Badge bg="muted.500" borderRadius="full">
        <Text fontSize="xs" color="text.50">
          waiting to join
        </Text>
      </Badge>
    )
  } else if (
    attendee.role === AttendeeRole.Moderator &&
    attendee.state === AttendeeState.NotJoined
  ) {
    rightHandContent = (
      <Badge bg="muted.500" borderRadius="full">
        <Text fontSize="xs" color="text.50">
          not joined yet
        </Text>
      </Badge>
    )
  } else if (
    attendee.state === AttendeeState.Joined ||
    (attendee.role === AttendeeRole.Moderator &&
      attendee.state === AttendeeState.Waiting)
  ) {
    rightHandContent = (
      <>
        {attendee.handRaised && (
          <FaIcon name="hand" size={20} color="yellow.500" isSolid />
        )}
        {attendee.role === AttendeeRole.Spectator ? (
          <Text fontSize="12px" color="muted.500">
            Chat Only
          </Text>
        ) : (
          <IconButton
            icon={
              <FaIcon
                name={attendee.microphoneOn ? 'microphone' : 'microphone-slash'}
                size={20}
                color={
                  attendee.microphoneOn && attendee.activeSpeaker
                    ? 'success.500'
                    : 'text.400'
                }
                isSolid
              />
            }
            borderRadius="full"
            p="1"
            isHovered={attendee.microphoneOn && attendee.activeSpeaker}
            colorScheme={
              attendee.microphoneOn && attendee.activeSpeaker ? 'success' : ''
            }
            onPress={handlePressMic}
          />
        )}
      </>
    )
  }

  return (
    <Box>
      <HStack alignItems="center" justifyContent="space-between">
        {attendee.photo ? (
          <Image
            src={attendee.photo}
            alt="Alternate Text"
            size="16px"
            borderRadius="full"
          />
        ) : (
          <Orb attendee={attendee} />
        )}
        <Text color="primary.900" ml="10px" fontSize="16px" fontWeight="500">
          {attendee.alias} {aliasInfo}
        </Text>
        <Spacer />
        {rightHandContent}
      </HStack>
    </Box>
  )
}

export default RosterItem
