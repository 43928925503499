import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import BottomSheet from '../../_shared/notification/BottomSheet'
import DeviceSettings from './DeviceSettings'
import PitchShift from './PitchShift'

const RoomSettings = ({}) => {
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const isMobile = useMobileBreakpoint()

  return (
    <BottomSheet
      isOpen={roomClient.settingsOpen}
      width="320px"
      atBottom
      atRight
      title="Settings"
      body={
        isMobile ? (
          <PitchShift />
        ) : (
          <>
            <DeviceSettings
              isRoom
              role={room.me.role}
              hasCamera={room.me.role === AttendeeRole.Moderator}
            />
            <PitchShift />
          </>
        )
      }
      handleClose={roomClient.toggleSettings}
      hasCloseOnBottom={true}
    />
  )
}

export default RoomSettings
