import { useEffect, useState } from 'react'
import { Microphone, NO_MICROPHONE } from '../../../domain/models/interfaces/microphone'
import { UseMicrophones } from './useMicrophones'

const fetchMicrophones: () => Promise<Microphone[]> = async () => {
  // permission check
  await navigator.mediaDevices.getUserMedia({ audio: true })

  // map devices to microphones
  const devices = await navigator.mediaDevices.enumerateDevices()
  const audioDevices = devices.filter(device => device.kind === 'audioinput')
  const microphones = audioDevices.map(device => ({
    name: device.label || 'Unnamed microphone',
    object: device
  }))
  return microphones
}

export const useMicrophones: UseMicrophones = () => {
  const [microphones, setMicrophones] = useState<Microphone[]>([])
  const [error, setError] = useState<string | null>(null)

  const refresh = async () => {
    const fetchedMicrophones = await fetchMicrophones()
    setMicrophones([...fetchedMicrophones, NO_MICROPHONE])
  }

  useEffect(() => {
    refresh().catch(error => setError(error.message))
  }, [])

  return {
    microphones,
    error,
    refresh,
  }
}

export default useMicrophones
