import { Client, IMessage } from "@stomp/stompjs"
import { useEffect, useRef, useState } from "react"
import useAuthentication from "./authentication/useAuthentication"

export interface UseWebSocket {
  (url: string): UseWebSocketResult
}

export interface UseWebSocketResult {
  connected: boolean
  error: string | null

  connect: () => void
  disconnect: () => void
  subscribe: (topic: string, callback: (data: any) => void) => { unsubscribe: () => void }
}

const useWebSocket: UseWebSocket = (url: string) => {
  const [connected, setConnected] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const websocket = useRef<Client | null>(null)

  const { getAccessToken } = useAuthentication()

  const connect: () => void = async () => {
    // if we're already connected, disconnect
    if(websocket.current) disconnect()

    // get an access token and create the socket
    const accessToken = await getAccessToken()
    const client = new Client({
      brokerURL: `${url}?access_token=${accessToken}`,
      reconnectDelay: 1000,
      onConnect: () => {
        setConnected(true)
        setError(null)
      },
      onDisconnect: () => {
        setConnected(false)
        setError(null)
      },
      onWebSocketClose: (event) => {
        setConnected(false)
        setError('WebSocket closed')
      },
    })

    // activate the socket and store the reference
    client.activate()
    websocket.current = client
  }

  const disconnect: () => void = () => {
    // deactivate the socket and clear the reference
    websocket.current?.deactivate()
    websocket.current = null
    setConnected(false)
  }

  const subscribe: (topic: string, callback: (data: any) => void) => { unsubscribe: () => void } = (topic, callback) => {
    // subscribe to a topic
    const subscription = websocket.current?.subscribe(topic, (message) => {
      const data = JSON.parse(message.body)
      callback(data)
    })

    // return subscription with an unsubscribe method
    return {
      unsubscribe: () => {
        subscription?.unsubscribe()
      }
    }
  }

  // clean-up
  useEffect(() => {
    return () => {
      disconnect()
    }
  }, [])

  return {
    connected,
    error,
    connect,
    disconnect,
    subscribe
  }
}

export default useWebSocket