import { HStack, Pressable, Text } from 'native-base'
import FaIcon from '../FaIcon'

const ChipSelect = ({ value = [], setValue, options = [] }) => {
  const isSelected = (option) => value.find((o) => o === option)

  const add = (option) => {
    if (!isSelected(option)) setValue((state) => [...state, option])
  }

  const remove = (option) => {
    const newValue = value.filter((o) => o !== option)
    setValue(newValue)
  }

  return (
    <HStack flexWrap="wrap" justifyContent="center">
      {options.map((option, index) => (
        <Pressable
          key={index}
          bg={isSelected(option) ? 'secondary.700' : 'white'}
          borderColor="secondary.700"
          borderWidth="2px"
          borderRadius="full"
          p="3"
          m="1.5"
          onPress={
            isSelected(option) ? () => remove(option) : () => add(option)
          }>
          <HStack alignItems="center">
            <Text
              color={isSelected(option) ? 'white' : 'secondary.700'}
              selectable={false}>
              {option}
            </Text>
            {isSelected(option) && (
              <FaIcon
                name="xmark"
                size={14}
                color="white"
                props={{ ml: '2' }}
              />
            )}
          </HStack>
        </Pressable>
      ))}
    </HStack>
  )
}

export default ChipSelect
