import {
  Button,
  Flex,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from 'native-base'
import { useState } from 'react'
import { Pressable } from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import useTabNavigation from '../../hooks/useTabNavigation'
import { useUserProfileContext } from '../../providers/UserProfileProvider'
import ActivityModalPreview from '../explore/activities/ActivityModalPreview'
import Card from '../_shared/card/Card'
import CardRow from '../_shared/card/CardRow'
import FixedContainer from '../_shared/layout/FixedContainer'
import { SkeletonLoader } from '../_shared/SkeletonLoader'
import Checklist from './dailyRecs/Checklist'
import InspirationalQuote from './InspirationalQuote'

const HomeDesktopView = ({
  upcomingGroups,
  checklistItems,
  checklistConditions,
  isFetched = false,
}) => {
  const isDesktop = useDesktopBreakpoint()
  const { navigate } = useTabNavigation()
  const { userProfile } = useUserProfileContext()
  const [modalLiveGroupData, setModalLiveGroupData] = useState(null)
  const [modalLiveGroupShown, setModalLiveGroupShown] = useState(false)
  const handleCloseModal = () => setModalLiveGroupShown(false)

  let goalHeading = 'Daily boost'
  if (userProfile?.goal === 'REDUCE_STRESS') {
    goalHeading = 'Daily stress relief'
  } else if (userProfile?.goal === 'BUILD_SKILLS') {
    goalHeading = 'Daily skill builder'
  }

  const onPressLiveGroup = (activity) => {
    setModalLiveGroupData(activity)
    setModalLiveGroupShown(true)
  }

  return (
    <FixedContainer width={1024} props={{ px: isDesktop ? '0' : '4', pb: '5' }}>
      {isFetched ? (
        <>
          {!!upcomingGroups?.length && (
            <Flex mb="3">
              <Card
                boxProps={{ w: '100%', borderRadius: '20px' }}
                body={
                  <VStack mx="24px" mt="24px" mb="40px">
                    <HStack alignItems="center">
                      <Heading size="md">Upcoming groups</Heading>
                      <Spacer />
                      <Pressable onPress={() => navigate('LiveGroups')}>
                        <Text fontSize="16px">see all</Text>
                      </Pressable>
                    </HStack>
                    <CardRow
                      width="304px"
                      onPressLiveGroup={onPressLiveGroup}
                      data={upcomingGroups}
                      showsHorizontalScrollIndicator={upcomingGroups.length > 3}
                    />
                  </VStack>
                }
              />
            </Flex>
          )}
          <Flex>
            <Card
              boxProps={{ w: '100%', borderRadius: '20px' }}
              body={
                <VStack m="24px" mb="40px">
                  <Heading size="md" mb="3">
                    {goalHeading}
                  </Heading>
                  <Checklist
                    items={checklistItems}
                    itemConditions={checklistConditions}
                    isHorizontal={isDesktop}
                  />
                  <Button
                    alignSelf="center"
                    variant="outline"
                    mt="24px"
                    w="32%"
                    flex="1"
                    onPress={() => navigate('Explore')}>
                    <Text color="primary.700" fontWeight="500">
                      Keep exploring
                    </Text>
                  </Button>
                  <InspirationalQuote />
                </VStack>
              }
            />
          </Flex>
          <ActivityModalPreview
            modalData={modalLiveGroupData}
            modalShown={modalLiveGroupShown}
            handleCloseModal={handleCloseModal}
            bottomSheetStyling={{
              hasDivider: false,
              width: 400,
              top: '0px',
              hasCloseOnBottom: false,
            }}
          />
        </>
      ) : (
        <SkeletonLoader size={{ width: '100%', height: '100%' }} />
      )}
    </FixedContainer>
  )
}

export default HomeDesktopView
