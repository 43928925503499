import { Box, Pressable, ScrollView, View } from 'native-base'
import { useEffect } from 'react'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import clevertap from '../../../../domain/services/clevertap/ClevertapAdapter'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import ExploreView from '../../components/explore/ExploreView'
import Sheet from '../../components/_shared/card/Sheet'
import SearchBar from '../../components/_shared/form/SearchBar'
import ExploreContainer from '../../components/_shared/layout/ExploreContainer'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import secureScreen from '../../hooks/authentication/secureScreen'
import DefaultLayout from '../../layouts/DefaultLayout'

const ExploreScreen = ({ navigation }) => {
  const isDesktop = useDesktopBreakpoint()
  const isMobile = useMobileBreakpoint()

  useEffect(() => {
    clevertap.registerForPush()
    analytics.track('Explore Reached')
  }, [])

  return (
    <>
      <DefaultLayout
        bg={!isDesktop ? 'white' : 'muted.50'}
        activeLabel="Explore"
        scrollView={isDesktop ? true : false}>
        {!isDesktop ? (
          <ExploreContainer>
            {isMobile && (
              <Box mx="24px" mt="24px">
                <Box
                  bg="white"
                  mb="25px"
                  borderRadius={4}
                  borderColor="muted.300"
                  borderWidth="1px">
                  <Pressable
                    onPress={() => {
                      navigation.navigate('Search')
                    }}>
                    <View pointerEvents="none">
                      <SearchBar placeholder="Live groups, topics, activities" />
                    </View>
                  </Pressable>
                </Box>
              </Box>
            )}
            <ScrollView
              showsVerticalScrollIndicator={false}
              flex={1}
              bg="white"
              borderTopRadius={isMobile && '2xl'}>
              <ExploreView />
            </ScrollView>
          </ExploreContainer>
        ) : (
          <FixedContainer width={'1024px'} size="md">
            <Sheet props={{ my: '40px' }}>
              <ExploreView />
            </Sheet>
          </FixedContainer>
        )}
      </DefaultLayout>
    </>
  )
}

export default secureScreen(ExploreScreen)
