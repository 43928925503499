import { useEffect, useState } from 'react'
import { useCabanaApi } from '../../infrastructure/views/providers/CabanaApiProvider'
import { useCabanaSocket } from '../../infrastructure/views/providers/CabanaSocketProvider'

export const useRoster = (roomId: string) => {
  const { RoomApi } = useCabanaApi()
  const socket = useCabanaSocket()
  const [roster, setRoster] = useState(null)

  useEffect(() => {
    RoomApi.getRoster(roomId).then((roster) => {
      setRoster((current) => current || roster)
    })
  }, [roomId])

  useEffect(() => {
    const subscription = socket.subscribe(`/roster/${roomId}`, (roster) => {
      setRoster(roster)
    })
    return () => subscription.unsubscribe()
  }, [socket, roomId])

  const toggleMicrophone = () => {
    socket.publish(`/roster/${roomId}/toggle-microphone`, null)
  }

  const toggleCamera = () => {
    socket.publish(`/roster/${roomId}/toggle-camera`, null)
  }

  const toggleHandRaised = () => {
    socket.publish(`/roster/${roomId}/toggle-hand-raised`, null)
  }

  const toggleScreen = () => {
    socket.publish(`/roster/${roomId}/toggle-screenshare`, null)
  }

  return [
    roster,
    toggleMicrophone,
    toggleCamera,
    toggleHandRaised,
    toggleScreen,
  ]
}
