export const CategoryFilters = (inExplore) => [
  { name: 'Live groups', icon: 'user-group', index: 0 },
  ...(!inExplore ? [{ name: 'Library', icon: 'books', index: 1 }] : []),
  { name: 'Moments', icon: 'bolt', index: 2 },
  { name: 'Movement', icon: 'heart-pulse', index: 3 },
  { name: 'Meditations', icon: 'leaf', index: 4 },
  { name: 'Activity', icon: 'palette', index: 5 },
  { name: 'Articles', icon: 'book-open', index: 6 },
]

export const TimeFilters = ['< 3', '5', '10', '15', '20', '30', '45', '> 45']
