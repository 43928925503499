import { Heading, Text } from 'native-base'
import { useEffect, useState } from 'react'
import useRoomContext from '../../providers/RoomProvider'

const RoomHeading = ({ textAlign = 'center', hasWelcome = true }) => {
  const room = useRoomContext()
  const [startsAt, setStartsAt] = useState('')
  const dateFormat = new Intl.DateTimeFormat([], {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
  })
  const timeFormat = new Intl.DateTimeFormat([], {
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  })

  useEffect(() => {
    const startsAtDate = new Date(room.startsAt)
    const date = dateFormat.format(startsAtDate)
    const time = timeFormat.format(startsAtDate)

    setStartsAt(`${date} • ${time}`)
  }, [room.startsAt])

  return (
    <>
      {hasWelcome && (
        <Text fontSize="xl" color="#000000" textAlign={textAlign}>
          Welcome to
        </Text>
      )}
      <Heading mb="1" color="#000000" textAlign={textAlign}>
        {room.name}
      </Heading>
      <Text fontSize="md" color="#000000" textAlign={textAlign}>
        {startsAt}
      </Text>
    </>
  )
}

export default RoomHeading
