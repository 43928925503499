import EmployersResources from '../../components/profile/EmployersResources'
import ProfileContainer from '../../components/_shared/layout/ProfileContainer'
import secureScreen from '../../hooks/authentication/secureScreen'

const EAPScreen = () => {
  return (
    <ProfileContainer headerText="EAP">
      <EmployersResources />
    </ProfileContainer>
  )
}

export default secureScreen(EAPScreen)
