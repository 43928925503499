import {
  AspectRatio,
  Box,
  FlatList,
  Pressable,
  Text,
  VStack,
} from 'native-base'
import { useCallback } from 'react'
import { useWindowDimensions } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import { TimeFilters } from '../../constants/filterConstants'

const TimeFilterView = ({
  appliedFilters = [],
  setFilters = () => {},
  onRemoveAllFilters = () => {},
}) => {
  const { width } = useWindowDimensions()
  const isMobile = useMobileBreakpoint()

  const screenWidth = isMobile ? width : 410
  const filterOptionWidth = (screenWidth - 6 * 2 - 24 * 2) / 3

  const applyFilter = useCallback((filter) => {
    setFilters((prevFilters) => {
      const currentFilters = [...prevFilters, filter]
      return currentFilters
    })
  }, [])

  const removeFilter = useCallback((filter) => {
    setFilters((prevFilters) => {
      if (prevFilters.length === 1) {
        onRemoveAllFilters()
      }
      return prevFilters.filter((prevFilter) => prevFilter !== filter)
    })
  }, [])

  const removeAllFilters = useCallback(() => {
    onRemoveAllFilters()
    setFilters([])
  }, [])

  return (
    <VStack>
      <FlatList
        mt="10px"
        mb="24px"
        data={TimeFilters}
        numColumns={3}
        scrollEnabled={false}
        renderItem={({ item }) => {
          const isApplied = appliedFilters.includes(item)
          return (
            <Pressable
              onPress={() =>
                isApplied ? removeFilter(item) : applyFilter(item)
              }>
              <AspectRatio
                width={filterOptionWidth}
                ratio={2 / 1}
                borderWidth="1px"
                borderColor="primary.700"
                backgroundColor={isApplied ? 'primary.700' : 'white'}
                borderRadius="10px"
                mr="5px"
                my="7px">
                <Box justifyContent="center">
                  <Text
                    alignSelf="center"
                    color={isApplied ? 'white' : 'primary.700'}>
                    {item} min
                  </Text>
                </Box>
              </AspectRatio>
            </Pressable>
          )
        }}
      />
      {appliedFilters.length > 0 && (
        <Pressable
          bottom="-8px"
          right="8px"
          onPress={removeAllFilters}
          position="absolute"
          p="8px">
          <Text color="secondary.600" fontWeight="500">
            Clear
          </Text>
        </Pressable>
      )}
    </VStack>
  )
}

export default TimeFilterView
