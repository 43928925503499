import { HStack,Select,Spinner,Text,VStack } from 'native-base'
import { useState } from 'react'

import { useMicrophonesContext } from '../../providers/MicrophoneProvider'
import FaIcon from '../_shared/FaIcon'
import MicrophoneLevel from './MicrophoneLevel'

const MicrophonePicker = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { microphones, refresh, selectMicrophone, selectedMicrophone } = useMicrophonesContext()

  const handleSelect = (selectedName: string) => {
    const selectedMicrophone = microphones.find(
      (microphone) => microphone.name === selectedName
    )
    if (selectedMicrophone) selectMicrophone(selectedMicrophone)
  }

  const doRefresh = () => {
    setLoading(true)
    refresh().finally(() => setLoading(false))
  }

  const picker = (
    <VStack space={4}>
      <HStack alignItems="center" justifyContent="space-between">
        <Select
          selectedValue={selectedMicrophone?.name || ''}
          accessibilityLabel="Select Microphone"
          placeholder="Select Microphone"
          size="lg"
          onValueChange={handleSelect}>
          {microphones.map((mic) => (
            <Select.Item key={mic.name} label={mic.name} value={mic.name} />
          ))}
        </Select>
        <FaIcon name="rotate" onPress={() => doRefresh()} />
      </HStack>
      <MicrophoneLevel />
    </VStack>
  )

  const spinner = (
    <HStack space={4} height={8} alignItems={'center'}>
      <Spinner />
      <Text>Loading microphones...</Text>
    </HStack>
  )

  return loading ? spinner : picker
}

export default MicrophonePicker
