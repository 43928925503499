import { useUserProfileContext } from '../providers/UserProfileProvider'

const bgColors = new Map([
  [
    'BEACH',
    {
      backgroundColor: 'primary.600',
      tabColor: 'primary.600',
      secondaryBackgroundColor: '#8CB2E9',
      fillColor: '#E8F4FD',
    },
  ],
  [
    'FOREST',
    {
      backgroundColor: 'success.700',
      tabColor: 'green.700',
      secondaryBackgroundColor: '#00A09B',
      fillColor: '#D4D4D4',
    },
  ],
  [
    'LAKE',
    {
      backgroundColor: 'secondary.700',
      tabColor: 'secondary.700',
      secondaryBackgroundColor: '#5DBFB6',
      fillColor: '#E1FBF9',
    },
  ],
])

const useGetOasisColors = () => {
  const { userProfile } = useUserProfileContext()
  const bgColor = bgColors.get(userProfile?.oasis ?? 'BEACH')
  return (
    bgColor ?? {
      backgroundColor: 'white',
      tabColor: 'white',
      secondaryBackgroundColor: 'white',
      fillColor: 'white',
    }
  )
}

export default useGetOasisColors
