import moment from 'moment-timezone'
import { Box, HStack, Text } from 'native-base'
import FaIcon from '../FaIcon'

const TodayBadge = ({ date, typeData }) => {
  return date && typeData && moment(date).isSame(moment(), 'day') ? (
    <Box
      padding="2px"
      px="6px"
      mr="8px"
      borderRadius={10}
      bgColor={`${typeData.color}.600`}>
      <HStack alignItems="center">
        <FaIcon name="alarm-clock" color="white" size={10} />
        <Text ml="4px" fontSize="10px" color="white">
          today
        </Text>
      </HStack>
    </Box>
  ) : null
}

export default TodayBadge
