import { withAuthenticationRequired } from '@auth0/auth0-react'
import { memo } from 'react'

type AuthParams = {
  screen_hint: string
}

const secureScreen = (
  screen: React.ComponentType<object>,
  authParams?: AuthParams
) =>
  memo(
    withAuthenticationRequired(screen, {
      loginOptions: {
        authorizationParams: {
          connection: 'email',
          ...authParams,
        },
      },
    })
  )

export default secureScreen
