import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, FlatList, Pressable, Text } from 'native-base'
import { useWindowDimensions } from 'react-native'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import { NavigationParams } from '../../screens/NavigationScreen'
import { useGetPopularTags } from './queries'

const PopularTagButtons = () => {
  const { popularTags, isLoading } = useGetPopularTags()

  const isMobile = useMobileBreakpoint()
  const isDesktop = useDesktopBreakpoint()

  return (
    <>
      {isLoading ? (
        <PopularTagButton />
      ) : (
        <FlatList
          scrollEnabled={false}
          data={popularTags as string[]}
          renderItem={({ item, index }) => (
            <Box key={item.toString()} mr={!isMobile && '16px'}>
              {index < 4 ? <PopularTagButton tag={item} /> : <></>}
            </Box>
          )}
          numColumns={!isDesktop && (isMobile ? 1 : 2)}
          horizontal={isDesktop}
        />
      )}
    </>
  )
}

const PopularTagButton = ({ tag = null }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isMobile = useMobileBreakpoint()
  const isDesktop = useDesktopBreakpoint()
  const { width } = useWindowDimensions()
  const tagWidth = (width - 40 * 2 - 16) / 2
  const searchTag = (tag) => {
    if (!tag) return
    navigation.navigate('Search', { searchValue: tag })
  }

  return (
    <Pressable
      mb="16px"
      flex="1"
      bg="primary.50"
      borderRadius="10px"
      width={isMobile ? undefined : isDesktop ? '224px' : tagWidth}
      onPress={() => searchTag(tag)}>
      <Text textAlign="center" fontWeight="500" p="4" numberOfLines={1}>
        {tag ?? 'Loading...'}
      </Text>
    </Pressable>
  )
}

export default PopularTagButtons
