import CleverTap from 'clevertap-web-sdk'
import Configuration from '../ConfigurationService'

const clevertap = {
  initialize: () => {
    CleverTap.init(Configuration.CLEVERTAP_ID, 'us1')
    CleverTap.setLogLevel(3) // Display all logs
  },
  initUserProfile: (email) => {
    CleverTap.onUserLogin.push({
      Site: {
        Email: email,

        // optional fields. controls whether the user will be sent email, push etc.
        'MSG-email': true,
        'MSG-push': true,
        'MSG-sms': true,
        'MSG-whatsapp': true,
      },
    })
  },
  pushEvent: (eventName, props) => {
    Object.keys(props).forEach((key) => {
      const value = props[key]
      if (Array.isArray(value)) {
        console.log(key, value)

        props[key] = value.join(',')
        value.forEach((val) => {
          props[`${key}-${val}`] = true
        })
      }
    })

    CleverTap.event.push(eventName, props)
  },
  registerForPush: () => undefined,
  getInitialURL: () => Promise.reject(),
  onPushNotificationClicked: (callback) => undefined,
  setProfile: (profile) => {
    CleverTap.profile.push({
      Site: { ...profile },
    })
  },
}

export default clevertap
