import { useQuery } from '@tanstack/react-query'
import { useCabanaApi } from '../../providers/CabanaApiProvider'

const downloadCsv = (data, name) => {
  const fields = Object.keys(data[0] || [])
  const replacer = function (key, value) {
    return value === null ? '' : value
  }
  let csv = data.map(function (row) {
    return fields
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      })
      .join(',')
  })
  csv.unshift(fields.join(','))
  csv = csv.join('\r\n')
  // download
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
  )
  element.setAttribute('download', `${name}.csv`)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const useGetOrganizations = () => {
  const { OrganizationApi } = useCabanaApi()
  const { isLoading, refetch } = useQuery({
    queryKey: ['Report', 'Organization'],
    queryFn: () => OrganizationApi.getOrganizations(),
    enabled: false,
  })

  return {
    isLoading,
    fetchOrganizationNames: refetch,
  }
}

export const useGetMemberReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useCabanaApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'Member'],
    queryFn: () => ReportApi.member(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'members'),
  })

  return {
    isLoadingMemberReport: isFetching,
    fetchMemberReport: refetch,
  }
}

export const useGetParticipationReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useCabanaApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'Participation'],
    queryFn: () =>
      ReportApi.participation(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'participation'),
  })

  return {
    isLoadingParticipationReport: isFetching,
    fetchParticipationReport: refetch,
  }
}

export const useGetContentUtilizationReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useCabanaApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'ContentUtilization'],
    queryFn: () =>
      ReportApi.contentUtilization(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'contentUtilization'),
  })

  return {
    isLoadingContentUtilizationReport: isFetching,
    fetchContentUtilizationReport: refetch,
  }
}

export const useGetMentalHealthMinutesReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useCabanaApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'MentalHealthMinutes'],
    queryFn: () =>
      ReportApi.mentalHealthMinutes(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'mentalHealthMinutes'),
  })

  return {
    isLoadingMentalHealthMinutesReport: isFetching,
    fetchMentalHealthMinutesReport: refetch,
  }
}

export const useGetVibeCheckDataReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useCabanaApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'VibeCheckData'],
    queryFn: () =>
      ReportApi.vibeCheckData(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'vibeCheckData'),
  })

  return {
    isLoadingVibeCheckDataReport: isFetching,
    fetchVibeCheckDataReport: refetch,
  }
}

export const useGetEmailLeadsReport = (startDate: Date, endDate: Date) => {
  const { ReportApi } = useCabanaApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'EmailLeasd'],
    queryFn: () => ReportApi.emailLeads(startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'emailLeads'),
  })

  return {
    isLoadingEmailLeadsReport: isFetching,
    fetchEmailLeadsReport: refetch,
  }
}
