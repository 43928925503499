import { Box, FlatList, HStack, Image, Text, VStack } from 'native-base'
import { useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import BackButton from '../../components/navigation/BackButton'
import useGetFileExtension from '../../hooks/useGetFileExtension'
import useGetIsImage from '../../hooks/useGetIsImage'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const FullPDFScreen = ({ route }) => {
  const { fileUri, articleTitle } = route.params
  const { width } = useWindowDimensions()
  const [numPages, setNumPages] = useState(null)
  const isImage = useGetIsImage(fileUri)
  const extension = useGetFileExtension(fileUri)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <>
      <Box h="50px" bg="primary.600" width="100%" justifyContent="flex-end">
        <HStack alignItems="center" justifyContent="center" pb="2px">
          <BackButton color="white" style={{ position: 'absolute', left: 5 }} />
          <VStack>
            <Text
              alignSelf="center"
              color="white"
              fontWeight={500}
              fontSize={10}>
              {articleTitle}
            </Text>
            <Text
              alignSelf="center"
              color="white"
              fontWeight={500}
              fontSize={10}>
              {extension.toUpperCase()}
            </Text>
          </VStack>
        </HStack>
      </Box>
      <Box flex={1} p="8px">
        {!isImage ? (
          <Document file={fileUri} onLoadSuccess={onDocumentLoadSuccess}>
            <FlatList
              data={Array.from(new Array(numPages), (el, index) => index + 1)}
              renderItem={({ item }) => (
                <Box marginBottom="5px">
                  <Page pageNumber={item} width={width} />
                </Box>
              )}
              keyExtractor={(item) => item.toString()}
            />
          </Document>
        ) : (
          <Image
            source={{ uri: fileUri }}
            alt="Full image"
            style={{ flex: 1 }}
            resizeMode="contain"
          />
        )}
      </Box>
    </>
  )
}

export default FullPDFScreen
