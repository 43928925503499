import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  ScrollView,
  Text,
  VStack,
} from 'native-base'
import FaIcon from '../FaIcon'

const defaultBoxProps = {
  w: '322',
  h: null,
  borderWidth: '1',
  px: '16px',
}

const Card = ({
  title = null,
  body,
  actions = null,
  titleLeftIcon = null,
  titleRightIcon = null,
  boxProps = {},
  hasPadding = { title: true, body: true, actions: true },
  scrollViewProps = null,
  handleClose = null,
  hasDivider = true,
  hasCenterTitle = false,
  bottomMessage = '',
}) => {
  const props = { ...defaultBoxProps, ...boxProps }
  return (
    <Box borderColor="muted.300" borderRadius="md" bg="white" {...props}>
      <VStack
        h="100%"
        divider={hasDivider && <Divider left="-17px" width={props.w} />}>
        {title && (
          <Flex>
            <HStack w="100%" px={hasPadding.title && '4px'} py={5} space="3">
              {titleLeftIcon && <Flex>{titleLeftIcon}</Flex>}
              <Flex flex={1}>
                <Heading
                  fontSize="18px"
                  color="secondary.900"
                  textAlign={hasCenterTitle ? 'center' : 'left'}>
                  {title}
                </Heading>
              </Flex>
              {titleRightIcon && !handleClose && <Flex>{titleRightIcon}</Flex>}
            </HStack>
            {handleClose && (
              <Button
                position="absolute"
                variant="ghost"
                borderRadius="full"
                right="-16px"
                top="4px"
                p="3"
                zIndex="3"
                onPress={handleClose}>
                <FaIcon name="xmark" size={20} />
              </Button>
            )}
          </Flex>
        )}
        <Flex
          flex={1}
          h="100%"
          mt={hasPadding.body ? '16px' : undefined}
          mx={hasPadding.body ? undefined : '-16px'}
          justifyContent={bottomMessage ? 'space-between' : 'flex-start'}>
          {scrollViewProps ? (
            <ScrollView
              ref={scrollViewProps?.ref ?? null}
              onContentSizeChange={scrollViewProps?.onContentSizeChange ?? null}
              bounces={false}>
              {body}
            </ScrollView>
          ) : (
            body
          )}
          {bottomMessage && (
            <Text
              fontSize="10px"
              color="text.500"
              fontWeight={500}
              mb="16px"
              ml="16px">
              {bottomMessage}
            </Text>
          )}
        </Flex>

        {actions && (
          <Flex align="flex-end" py="12px">
            {actions}
          </Flex>
        )}
      </VStack>
    </Box>
  )
}

export default Card
