import {
  addDays,
  addMonths,
  differenceInDays,
  getDaysInMonth,
  previousSunday,
  startOfMonth,
} from 'date-fns'
import { Center, Flex, HStack, Spinner } from 'native-base'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DefaultLayout from '../../../layouts/DefaultLayout'
import BackButton from '../../navigation/BackButton'
import FixedContainer from '../../_shared/layout/FixedContainer'
import GroupsOnDateList from '../GroupsOnDateList'
import DateSelectionDesktop from './DateSelectionDesktop'

const GroupCalendarViewDesktop = ({ dateEvents, onLiveGroupPress }) => {
  const userPickedDate = useRef(false)
  const [loading, setLoading] = useState(
    () => new Date(dateEvents[0].date) < new Date()
  )
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    startOfMonth(new Date())
  )
  const [selectedDateIndex, setSelectedDateIndex] = useState(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    let index = -1

    do {
      index = dateEvents.findIndex(
        (item) =>
          item.type == 'title' && item.date.toString() == today.toString()
      )
      today.setDate(today.getDate() + 1)
    } while (index === -1)
    return index
  })
  const flatListRef = useRef(null)
  const sectionListRef = useRef(null)
  const scrollTimerRef = useRef(null)

  const onScrollAnimationEnd = useCallback(() => {
    if (scrollTimerRef.current != null) {
      clearTimeout(scrollTimerRef.current)
    }
    setTimeout(() => setLoading(false), 50)
    scrollTimerRef.current = setTimeout(() => {
      userPickedDate.current = false
    }, 150)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      sectionListRef.current.scrollToIndex({
        animated: false,
        index: selectedDateIndex,
      })
    }, 250)
  }, [])

  const differenceFromFirstDayToSunday2 = (date) =>
    differenceInDays(date, previousSunday(date))

  const calendar = useMemo(() => {
    if (!dateEvents || dateEvents.length == 0) return []
    let month = 0
    let year = 0
    let dayInMonth = 0
    let calendar = []
    let currentMonthData = []
    let currentUnusedDatesLength = 0
    let currentEventIndex = 0
    const titles = dateEvents.filter((item) => item.type === 'title')
    for (let eventIndex in titles) {
      currentEventIndex = parseInt(eventIndex)
      const event = titles[eventIndex]
      const eventDate = event.date.getDate()
      const eventMonth = event.date.getMonth() + 1
      const eventYear = event.date.getFullYear()
      const lastEvent = titles[eventIndex - 1]
      if (eventMonth !== month || eventYear !== year) {
        if (eventYear !== year) {
          calendar[eventYear] = []
        }
        let unusedDates = [
          ...Array(differenceFromFirstDayToSunday2(startOfMonth(event.date))),
        ].map(() => false)
        unusedDates.length == 7 ? (unusedDates = []) : unusedDates
        currentUnusedDatesLength = unusedDates.length
        if (year != 0) {
          if (lastEvent) {
            for (
              dayInMonth;
              dayInMonth < getDaysInMonth(lastEvent.date);
              dayInMonth++
            ) {
              currentMonthData.data.push({
                date: addDays(startOfMonth(lastEvent.date), dayInMonth),
                eventIndex: event.index,
                nextDate: currentUnusedDatesLength + eventDate - 1,
                isNextMonth: true,
              })
            }
          }
          calendar[year][month] = currentMonthData
        }

        currentMonthData = { data: unusedDates, isFirst: !lastEvent }
        month = eventMonth
        year = eventYear
        dayInMonth = 0
      }

      const nextDate = currentUnusedDatesLength + eventDate - 1
      let groupCount = currentMonthData.data[currentMonthData.data.length - 1]
        ? currentMonthData.data[currentMonthData.data.length - 1].nextDate -
          nextDate +
          1
        : null
      const isGroup = groupCount < 0
      for (
        dayInMonth;
        dayInMonth < getDaysInMonth(event.date) && dayInMonth < eventDate;
        dayInMonth++
      ) {
        currentMonthData.data.push({
          date: addDays(startOfMonth(event.date), dayInMonth),
          eventIndex: event.index,
          nextDate: nextDate + (isGroup && groupCount),
          disabled:
            currentEventIndex == 0 && currentMonthData.data.length < nextDate,
        })
        groupCount++
      }
    }

    const lastDateEvent = titles[titles.length - 1]
    for (
      dayInMonth;
      dayInMonth < getDaysInMonth(lastDateEvent.date);
      dayInMonth++
    ) {
      currentMonthData.data.push({
        date: addDays(startOfMonth(lastDateEvent.date), dayInMonth),
        eventIndex: lastDateEvent.index,
        nextDate: currentUnusedDatesLength + lastDateEvent.date.getDate() - 1,
        disabled: true,
      })
    }
    currentMonthData.isLast = true
    calendar[year][month] = currentMonthData
    return calendar
  }, [dateEvents])

  const year = selectedMonthYear.getFullYear()
  const month = selectedMonthYear.getMonth() + 1
  const days = calendar.length > 0 ? calendar[year][month].data : []
  const isLast = calendar.length > 0 ? calendar[year][month].isLast : true
  const isFirst = calendar.length > 0 ? calendar[year][month].isFirst : true

  const differenceFromFirstDayToSunday = useMemo(
    () =>
      differenceInDays(selectedMonthYear, previousSunday(selectedMonthYear)),
    [selectedMonthYear]
  )

  const onScrollBegin = useCallback(() => {}, [])

  const onDatePress = useCallback(
    (calendarDateIndex, sectionIndex, isNextMonth) => {
      if (isNextMonth) {
        advanceMonth()
      }
      userPickedDate.current = true
      setSelectedDateIndex(calendarDateIndex)
      sectionListRef.current.scrollToIndex({
        animated: true,
        index: sectionIndex,
      })
    },
    [calendar, advanceMonth, setSelectedDateIndex]
  )

  const setIndexOnScroll = useCallback(
    (newDate) => {
      if (!userPickedDate.current) {
        const year = newDate.getFullYear()
        const month = newDate.getMonth() + 1
        const newDays = calendar[year][month].data
        const index = newDays.findIndex(
          (x) => x?.date?.toString() == newDate.toString()
        )

        setSelectedMonthYear(newDate)
        setSelectedDateIndex(index)
      }
    },
    [
      calendar,
      days,
      selectedMonthYear,
      setSelectedMonthYear,
      setSelectedDateIndex,
    ]
  )

  const advanceMonth = useCallback(() => {
    setSelectedDateIndex(-1)
    setSelectedMonthYear((previousDate) => addMonths(previousDate, 1))
  }, [selectedMonthYear])

  const retractMonth = useCallback(() => {
    setSelectedDateIndex(-1)
    setSelectedMonthYear((previousDate) => addMonths(previousDate, -1))
  }, [selectedMonthYear])

  const group = useMemo(
    () => (
      <GroupsOnDateList
        dateEvents={dateEvents}
        setIndexOnScroll={setIndexOnScroll}
        onScrollBegin={onScrollBegin}
        setIndexOnScroll={setIndexOnScroll}
        sectionListRef={sectionListRef}
        onScrollAnimationEnd={onScrollAnimationEnd}
        onLiveGroupPress={onLiveGroupPress}
      />
    ),
    [setIndexOnScroll, dateEvents]
  )

  const dateSelection = useMemo(
    () => (
      <DateSelectionDesktop
        selectedMonthYear={selectedMonthYear}
        isLast={isLast}
        isFirst={isFirst}
        advanceMonth={advanceMonth}
        retractMonth={retractMonth}
        onDatePress={onDatePress}
        selectedDateIndex={selectedDateIndex}
        calendar={calendar}
        flatListRef={flatListRef}
        key={selectedMonthYear.toDateString()}
      />
    ),
    [
      selectedMonthYear,
      isLast,
      isFirst,
      advanceMonth,
      retractMonth,
      onDatePress,
      selectedDateIndex,
      calendar,
      flatListRef,
    ]
  )

  return (
    <DefaultLayout activeLabel="Explore" hasScrollBar={false}>
      <FixedContainer size="lg">
        <BackButton color="text.400" isDisabled />
        {loading && (
          <Center w="100%" h="100%" mt="-40px">
            <Spinner size={'lg'} color={'#222C5E'} mr={2} />
          </Center>
        )}
        <HStack alignItems="flex-start" mt="10px" opacity={loading ? 0 : 100}>
          <Flex
            borderColor="grey.200"
            borderWidth={1}
            bg="white"
            width="384px"
            px="33px"
            pb="33px"
            pt="17px"
            borderRadius="2xl">
            {dateSelection}
          </Flex>
          <Flex
            flex="1"
            ml="40px"
            borderColor="grey.200"
            borderWidth={1}
            height="750px"
            borderTopRadius="2xl">
            {group}
          </Flex>
        </HStack>
      </FixedContainer>
    </DefaultLayout>
  )
}

export default GroupCalendarViewDesktop
