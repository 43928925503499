import { Box, HStack, Pressable, Text } from 'native-base'
import useGetOasisColors from '../../hooks/useGetOasisColors'
import FaIcon from '../_shared/FaIcon'

const FilterPillContainer = ({
  text,
  isApplied,
  onPress,
  customBgColor = null,
  icon = 'chevron-down',
  direction = 'row',
}) => {
  const { tabColor } = useGetOasisColors()
  const bgColor = isApplied ? 'white' : tabColor
  const customBg = isApplied ? customBgColor : tabColor

  return (
    <Pressable onPress={onPress}>
      <Box
        borderRadius="2xl"
        mr="7px"
        py="6px"
        px="12px"
        backgroundColor={customBgColor ? customBg : bgColor}
        borderColor={customBgColor ? tabColor : 'white'}
        borderWidth={'1px'}>
        <HStack flexDirection={direction}>
          <Text color={!isApplied ? 'white' : tabColor} mr="4px">
            {text}
          </Text>
          <Box alignSelf="center">
            <FaIcon name={icon} color={!isApplied ? 'white' : tabColor} />
          </Box>
        </HStack>
      </Box>
    </Pressable>
  )
}

export default FilterPillContainer
