import { useNavigation } from '@react-navigation/native'
import { VStack } from 'native-base'
import { useCallback, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import clevertap from '../../../../domain/services/clevertap/ClevertapAdapter'
import MixpanelAdapter from '../../../../domain/services/mixpanel/MixpanelAdapter'
import { useUserProfileContext } from '../../providers/UserProfileProvider'
import Stepper from '../_shared/form/Stepper'
import GoalForm from './GoalForm'
import MentalHealthMinutesForm from './MentalHealthMinutesForm'
import OasisThemeForm from './OasisThemeForm'
import ProfessionalRoleForm from './ProfessionalRoleForm'

const PersonalizationForm = ({ profileData }) => {
  /* State */
  const {
    userProfile,
    selectOasis,
    selectGoal,
    selectProfessionalRole,
    selectMentalHealthMinutes,
  } = useUserProfileContext()
  const navigation = useNavigation()

  const [goal, setGoal] = useState(
    profileData.customGoal ? profileData.customGoal : profileData.goal
  )
  const [isCustomGoal, setIsCustomGoal] = useState(
    profileData.customGoal != null
  )
  const [mentalHealthMinutes, setMentalHealthMinutes] = useState(
    profileData.mentalHealthMinutes ?? 5
  )
  const [professionalRole, setProfessionalRole] = useState(
    profileData.professionalRoles
  )
  const [oasis, setOasis] = useState(profileData.oasis)

  /* Functions */
  const changeGoal = useCallback(
    () => selectGoal({ goal, isCustomGoal }),
    [goal, isCustomGoal, selectGoal]
  )

  const changeMentalHealthMinutes = () => {
    return selectMentalHealthMinutes(mentalHealthMinutes)
  }

  const changeProfessionalRole = () => {
    return selectProfessionalRole(professionalRole)
  }

  const changeOasisTheme = () => {
    return selectOasis(oasis)
  }

  const identifyMixpanelUser = () => {
    MixpanelAdapter.identify(userProfile.id)
    const profile = {
      goal: userProfile.goal,
      'Professional-Role': userProfile.professionalRoles,
      oasis: userProfile.oasis,
      Organization: userProfile.organization,
      'Referral-Code': userProfile.referralCode,
      'Referral-Count': userProfile.referralCount,
    }
    MixpanelAdapter.people.set({
      ...profile,
      $email: userProfile.email,
    })
    clevertap.setProfile(profile)
  }

  useEffect(() => {
    if (
      !!userProfile &&
      !!userProfile.oasis &&
      (!!userProfile.goal || !!userProfile.customGoal) &&
      !!userProfile.mentalHealthMinutes > 0 &&
      !!userProfile.professionalRoles.length > 0
    ) {
      identifyMixpanelUser()
      if (Platform.OS === 'web') {
        navigation.replace('Home')
      } else {
        navigation.replace('CreatingOasis')
      }
    }
  }, [identifyMixpanelUser, userProfile])

  /* Render */
  const minuteOptions = []
  for (let i = 1; i <= 60; i++) {
    minuteOptions.push({
      value: i.toString(),
      label: i.toString(),
    })
  }

  return (
    <>
      <VStack h="100%" w="100%" bg="white">
        <Stepper
          key={JSON.stringify(profileData)}
          openStepOnStart
          steps={[
            <GoalForm
              goal={goal}
              setGoal={setGoal}
              setIsCustomGoal={setIsCustomGoal}
            />,
            <MentalHealthMinutesForm
              value={mentalHealthMinutes}
              setValue={setMentalHealthMinutes}
            />,
            <ProfessionalRoleForm
              value={professionalRole}
              setValue={setProfessionalRole}
            />,
            <OasisThemeForm value={oasis} setValue={setOasis} />,
          ]}
          stepConditions={[
            goal,
            mentalHealthMinutes,
            professionalRole.length,
            oasis,
          ]}
          onCompleteSteps={[
            changeGoal,
            changeMentalHealthMinutes,
            changeProfessionalRole,
            changeOasisTheme,
          ]}
        />
      </VStack>
    </>
  )
}

export default PersonalizationForm
