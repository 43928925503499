import { useIsFocused } from '@react-navigation/native'
import { Audio } from 'expo-av'
import { Sound } from 'expo-av/build/Audio'
import { Box, Pressable, Text } from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { useCompleteDailyRec } from '../../home/queries'
import FaIcon from '../../_shared/FaIcon'
import AudioProgressBar from './AudioProgressBar'

const emptySound: Sound = undefined

const AudioPlayer = ({ customHeight = null, activity }) => {
  const [sound, setSound] = useState(emptySound)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progressInMs, setProgressInMs] = useState(0)
  const loadingIntervalRef = useRef(null)
  const loadingStepRef = useRef(0)
  const [loadingText, setLoadingText] = useState('Loading')
  const { completeContent } = useCompleteDailyRec()
  const isFocused = useIsFocused()

  const audioFinishedPlaying = useRef(false)
  const progressValue = useRef(0)
  const audioLength = useRef(0)

  const willUnmount = useRef(false)

  useEffect(() => {
    if (!isFocused && sound) {
      sound.pauseAsync()
    }
  }, [isFocused])

  useEffect(() => {
    return sound
      ? () => {
          console.debug('Unloading Sound')
          sound.unloadAsync()
        }
      : undefined
  }, [sound])

  useEffect(() => {
    if (activity?.audioUrl === undefined) return

    Audio.setAudioModeAsync({
      staysActiveInBackground: true,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      playThroughEarpieceAndroid: false,
    })

    const initializeAudio = async () => {
      const parsedAudioUrl = activity.audioUrl.replace(/ /g, '%20')
      const { sound } = await Audio.Sound.createAsync(
        {
          uri: parsedAudioUrl,
        },
        undefined,
        (data) => {
          if (!willUnmount.current) {
            if (!data.didJustFinish && !audioFinishedPlaying.current) {
              let prog = (data.positionMillis / data.durationMillis) * 100
              progressValue.current = prog || 0
              setProgressInMs(data.positionMillis)
              setIsPlaying(data.isPlaying)
            } else {
              audioFinishedPlaying.current = true
              progressValue.current = 0
              setProgressInMs(0)
              setIsPlaying(false)
            }
            audioLength.current = data.durationMillis
          }
        }
      )
      setSound(sound)
      await sound.playAsync().then(() => clearLoadingIntervalIfExists())
    }

    initializeAudio().then(() => completeContent(activity))
    loadingIntervalRef.current = setInterval(() => {
      const dotCount = loadingStepRef.current % 4
      setLoadingText('Loading' + '.'.repeat(dotCount))
      loadingStepRef.current = loadingStepRef.current + 1
    }, 1000)

    return () => {
      willUnmount.current = true
      clearLoadingIntervalIfExists()
    }
  }, [])

  const clearLoadingIntervalIfExists = () => {
    if (loadingIntervalRef.current) {
      clearInterval(loadingIntervalRef.current)
      loadingStepRef.current = 0
      setLoadingText('Loading')
    }
  }

  const handleAudio = async () => {
    if (isPlaying) {
      await sound.pauseAsync()
    } else {
      audioFinishedPlaying.current = false
      await sound.playFromPositionAsync(progressInMs)
    }
  }

  const stopPlaying = async () => {
    if (isPlaying) {
      await sound.pauseAsync()
    }
  }
  const seekAudio = async (ms) => {
    const newProgress = progressInMs + ms
    await sound.playFromPositionAsync(newProgress)
  }

  const handleDrag = (percentage) => {
    progressValue.current = percentage
    setProgressInMs((percentage / 100) * audioLength.current)
  }

  const seekAudioAtPercentage = async (percentage) => {
    const newProgress = (percentage / 100) * audioLength.current
    await sound.playFromPositionAsync(newProgress)
  }

  const getCurrentPlayTimeString = () => {
    if (isNaN(progressInMs)) return loadingText
    const seconds = Math.floor(progressInMs / 1000)
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds - minutes * 60

    return `${minutes} : ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  return (
    <>
      <Box alignItems="center">
        <AudioProgressBar
          handleAudio={handleAudio}
          handleDrag={handleDrag}
          isPlaying={isPlaying}
          progressValue={progressValue}
          seekAudioAtPercentage={seekAudioAtPercentage}
          stopPlaying={stopPlaying}
          customHeight={customHeight && (customHeight * 2) / 5}
        />
        <Text
          mt={customHeight ? `${customHeight / 25}px` : '24px'}
          lineHeight={customHeight ? 22 : null}
          fontSize={18}
          fontWeight="bold"
          color="white">
          {getCurrentPlayTimeString()}
        </Text>
      </Box>
      <Box
        alignItems="center"
        justifyContent="center"
        flexDir="row"
        mt={customHeight ? `${customHeight / 15}px` : '87px'}>
        <ForwardBackwardButton
          onPress={() => seekAudio(-15000)}
          iconName="rotate-left"
          size={customHeight ? Math.max(customHeight / 17, 25) : 32}
        />
        <ForwardBackwardButton
          onPress={() => seekAudio(15000)}
          iconName="rotate-right"
          size={customHeight ? Math.max(customHeight / 17, 25) : 32}
        />
      </Box>
    </>
  )
}

const ForwardBackwardButton = ({ iconName, onPress, size }) => {
  return (
    <Pressable
      bg="white"
      borderRadius="full"
      alignItems="center"
      justifyContent="center"
      mx="32px"
      onPress={onPress}>
      <Text position="absolute" fontSize="9" pt="1" fontWeight="600">
        15
      </Text>
      <Box p="9px">
        <FaIcon name={iconName} size={size} color="#4C1D95" />
      </Box>
    </Pressable>
  )
}

export default AudioPlayer
