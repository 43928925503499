import { createContext, ReactNode, useContext } from 'react'
import useRoom, { UseRoomResult } from '../hooks/useRoom'


export interface UseRoomContext extends UseRoomResult {}

const RoomContext = createContext<UseRoomContext | undefined>(undefined)

export const useRoomContext = (): UseRoomContext => useContext(RoomContext)

interface RoomProviderProps {
  children: ReactNode
  roomId: string
}

export const RoomProvider: React.FC<RoomProviderProps> = ({
  children,
  roomId,
}) => {
  const context: UseRoomContext = useRoom(roomId)

  return <RoomContext.Provider value={context}>{children}</RoomContext.Provider>
}
