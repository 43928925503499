import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import MyGroupsDesktop from '../../components/profile/MyGroups/MyGroupsDesktop'
import MyGroupsMobile from '../../components/profile/MyGroups/MyGroupsMobile'

const MyGroupsScreen = () => {
  const isDesktop = useDesktopBreakpoint()

  return isDesktop ? <MyGroupsDesktop /> : <MyGroupsMobile />
}

export default MyGroupsScreen
