import { Box, View, VStack } from 'native-base'
import { useRef, useState } from 'react'
import { Animated } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import ModeratorCustomHeaderMobile from '../../components/moderators/ModeratorCustomHeaderMobile'
import ModeratorDetail from '../../components/moderators/ModeratorDetail'
import ModeratorHeaderDesktop from '../../components/moderators/ModeratorHeaderDesktop'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import { SkeletonLoader } from '../../components/_shared/SkeletonLoader'
import secureScreen from '../../hooks/authentication/secureScreen'
import DefaultLayout from '../../layouts/DefaultLayout'
import { useModeratorDetail } from './queries'

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" p={6}>
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
  </VStack>
)

const ModeratorDetailScreen = ({ route }) => {
  const { moderatorId } = route.params
  const { data, isLoading } = useModeratorDetail(moderatorId)
  const isMobile = useMobileBreakpoint()
  const [topScreen, setTopScreen] = useState(true)
  const scrollY = useRef(new Animated.Value(0)).current

  scrollY.addListener(({ value }) => {
    if (value <= 0) {
      setTopScreen(true)
    } else {
      setTopScreen(false)
    }
  })

  return (
    <>
      {isMobile ? (
        <View flex={1} bg="white">
          <ModeratorCustomHeaderMobile
            isLoading={isLoading}
            moderator={data}
            scrollY={scrollY}
          />
          <View height={topScreen ? 170 : 100} />
          <Animated.ScrollView
            alwaysBounceVertical={false}
            scrollEventThrottle={16}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { y: scrollY } } }],
              { useNativeDriver: true }
            )}>
            {isLoading ? <LoadingView /> : <ModeratorDetail moderator={data} />}
          </Animated.ScrollView>
        </View>
      ) : (
        <DefaultLayout activeLabel="Moderator Details" hasScrollBar={true}>
          <FixedContainer width="1024px" size="lg">
            <Box
              mt={10}
              marginBottom={4}
              borderColor="grey.200"
              borderWidth={1}
              backgroundColor="white"
              borderRadius="20px">
              {isLoading ? (
                <LoadingView />
              ) : (
                <>
                  <ModeratorHeaderDesktop moderator={data} />
                  <ModeratorDetail moderator={data} />
                </>
              )}
            </Box>
          </FixedContainer>
        </DefaultLayout>
      )}
    </>
  )
}

export default secureScreen(ModeratorDetailScreen)
