import Bugsnag from '@bugsnag/js'
import Configuration from '../ConfigurationService'

const BugSnagWebService = {
  init: () => {
    if (Configuration.BUGSNAG_API_KEY) {
      Bugsnag.start({
        apiKey: Configuration.BUGSNAG_API_KEY,
        releaseStage: 'prod',
        appVersion: Configuration.BUILD_NUMBER,
      })
    }
  },
  setUser: (user) => {
    if (user) {
      Bugsnag.setUser(user.id, user.email)
    }
  },
}

export default BugSnagWebService
