import { useNavigation } from '@react-navigation/native'
import * as NativeBase from 'native-base'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { Document, Page, pdfjs } from 'react-pdf'
import useGetIsImage from '../../../../hooks/useGetIsImage'
import useGetMeta from '../../../../hooks/useGetMeta'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ActivityDownloadableContent = ({ activity, setDownloadLocation }) => {
  const [aspectRatio, setAspectRatio] = useState(0.8)
  const downloadedLocation = useRef('')
  const { width, height } = useWindowDimensions()
  const isImage = useGetIsImage(activity.downloadUrl)

  useEffect(() => {
    if (isImage)
      useGetMeta(activity?.downloadUrl, (err, img) => {
        setAspectRatio(img.naturalWidth / img.naturalHeight)
      })
  }, [activity?.downloadUrl])

  const navigation = useNavigation()

  const viewFullContent = useCallback(() => {
    navigation.navigate('FullPDF', {
      fileUri: activity.downloadUrl,
      articleTitle: activity.title,
    })
  }, [activity])

  return (
    <NativeBase.Box mb="80px">
      <NativeBase.Text mt="20px" mb="16px">
        {activity.description}
      </NativeBase.Text>
      <NativeBase.Pressable onPress={viewFullContent}>
        <NativeBase.Box
          w="100%"
          h="auto"
          shadow={5}
          justifyContent="flex-start"
          overflow="hidden"
          alignItems="center">
          {!isImage ? (
            <Document file={activity.downloadUrl}>
              <Page pageNumber={1} width={width} />
            </Document>
          ) : (
            <NativeBase.Image
              source={{
                uri: activity.downloadUrl,
              }}
              alt="Full image"
              w={width}
              style={{ aspectRatio: aspectRatio }}
              resizeMode="contain"
            />
          )}
        </NativeBase.Box>
      </NativeBase.Pressable>
    </NativeBase.Box>
  )
}

export default ActivityDownloadableContent
