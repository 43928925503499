import { useMemo } from 'react'

const exercise0 = require('../assets/images/breathing-exercises/exercise-0.gif')
const exercise1 = require('../assets/images/breathing-exercises/exercise-1.gif')
const exercise2 = require('../assets/images/breathing-exercises/exercise-2.gif')
const exercise3 = require('../assets/images/breathing-exercises/exercise-3.gif')
const exercise4 = require('../assets/images/breathing-exercises/exercise-4.gif')
const exercise5 = require('../assets/images/breathing-exercises/exercise-5.gif')
const exercise6 = require('../assets/images/breathing-exercises/exercise-6.gif')
const exercise7 = require('../assets/images/breathing-exercises/exercise-7.gif')
const exercise8 = require('../assets/images/breathing-exercises/exercise-8.gif')
const animations = [
  exercise0,
  exercise1,
  exercise2,
  exercise3,
  exercise4,
  exercise5,
  exercise6,
  exercise7,
  exercise8,
]

const getRandomAnimation = () => {
  const rng = Math.floor(Math.random() * animations.length)
  return animations[rng]
}

const useLoadAnimations = () => {
  return useMemo(() => getRandomAnimation(), [])
}

export default useLoadAnimations
