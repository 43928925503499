import {
  Alert,
  Flex,
  FormControl,
  HStack,
  Input,
  KeyboardAvoidingView,
  Spacer,
  Text,
} from 'native-base'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Platform } from 'react-native'
import FaIcon from '../FaIcon'
import FormLabel from './FormLabel'

const FormInput = forwardRef(
  (
    {
      autofocus = false,
      defaultValue = '',
      keyboardType = 'default',
      label = null,
      onChangeValid = (isValid) => {},
      onChangeText = (text) => {},
      onClearText = null,
      placeholder = null,
      requirements = [],
      rightLabel = null,
      type = 'text',
      validation = null,
      isDisabled = false,
      inputAccessoryViewID,
      inputParamRef,
      onFocusCallback = () => {},
      bg = 'transparent',
      testID = null,
    },
    ref
  ) => {
    const input = useRef(null)
    const [isDirty, setDirty] = useState(false)
    const [isFocused, setFocused] = useState(false)
    const [isValid, setValid] = useState(true)
    const [text, setText] = useState(defaultValue)
    const [validationError, setValidationError] = useState(null)

    useImperativeHandle(ref, () => ({
      setValue(value) {
        if (!value) return Promise.reject()
        input.current.value = value
        setText(value)
        input.current.blur()
        return validate()
      },
    }))

    const validate = async () => {
      const requirementsMet = requirements.every(
        (requirement) => !requirement.logic || requirement.logic(text)
      )
      const validated = validation
        ? await validation(text).then(
            () => {
              setValidationError(null)
              return true
            },
            (error) => {
              console.log('validation error', error)
              setValidationError(error)
              return false
            }
          )
        : true

      const valid = requirementsMet && validated
      setValid(valid)
      onChangeValid(valid)
    }

    // auto-focus on render
    useEffect(() => {
      if (autofocus)
        inputParamRef ? inputParamRef.current.focus() : input.current.focus()
    }, [])

    // dirty the field when the text changes
    useEffect(() => {
      if (text) setDirty(true)
    }, [text])

    // validate dirty input on blur
    useEffect(() => {
      if (isDirty && !isFocused) validate()
    }, [isDirty, isFocused])

    // clear validation error when the text changes
    useEffect(() => {
      setValidationError(null)
    }, [text])

    return (
      <KeyboardAvoidingView
        behavior={Platform.OS == 'ios' ? 'padding' : 'height'}>
        <FormControl isInvalid={!isValid} mb="6">
          <HStack>
            <FormLabel>{label}</FormLabel>
            <Spacer />
            {rightLabel}
          </HStack>
          <Input
            testID={testID}
            bg={bg}
            autoCapitalize="none"
            defaultValue={defaultValue}
            InputRightElement={
              onClearText && (
                <FaIcon
                  name="xmark"
                  size={20}
                  props={{ mr: '2' }}
                  onPress={onClearText}
                />
              )
            }
            onBlur={() => setFocused(false)}
            onChangeText={(text) => {
              onChangeText(text)
              setText(text)
            }}
            onFocus={() => {
              setFocused(true)
              onFocusCallback()
            }}
            keyboardType={keyboardType}
            placeholder={placeholder}
            ref={inputParamRef ?? input}
            size={'lg'}
            returnKeyType={inputParamRef ? undefined : 'done'}
            type={type}
            isDisabled={isDisabled}
            inputAccessoryViewID={inputAccessoryViewID}
          />
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
            alignContent="center">
            {isFocused &&
              requirements.map((requirement, index) => {
                const color =
                  isDirty && requirement.logic
                    ? requirement.logic(text)
                      ? 'success.700'
                      : 'error.700'
                    : 'primary.900'

                return (
                  <HStack
                    key={index}
                    mt={index === 0 ? '2' : '1'}
                    flexDirection="column"
                    alignItems="center">
                    {isDirty && requirement.logic ? (
                      requirement.logic(text) ? (
                        <FaIcon
                          name="circle-check"
                          isSolid
                          size={20}
                          color={color}
                          props={{ mr: '1' }}
                        />
                      ) : (
                        <Text color="error.700" fontSize="14px">
                          {requirement.symbol}
                        </Text>
                      )
                    ) : (
                      <Text color="primary.900" fontSize="14px">
                        {requirement.symbol}
                      </Text>
                    )}

                    <Text color={color} fontSize="12px">
                      {requirement.text}
                    </Text>
                  </HStack>
                )
              })}
          </Flex>
          {validationError && (
            <Alert colorScheme="error" variant="subtle" mt="2">
              <Text w="100%" color="text.900">
                {validationError}
              </Text>
            </Alert>
          )}
        </FormControl>
      </KeyboardAvoidingView>
    )
  }
)

export default FormInput
