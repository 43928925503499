import { Avatar, Button, Flex, Text } from 'native-base'
import { useEffect } from 'react'
import Card from '../../_shared/card/Card'
import FaIcon from '../../_shared/FaIcon'
import Overlay from '../../_shared/Overlay'

const OrientationStep = ({
  index,
  stepperIndex,
  setStepperIndex,
  title,
  body,
  condition,
  isLinear = false,
  isDisabled = false,
  isLast,
  onPressNext = () => {},
  onPressBack = () => {},
}) => {
  useEffect(() => {
    if (!isLinear && condition && !isLast && index >= stepperIndex) {
      setStepperIndex(index + 1)
    }
  }, [condition])

  return (
    <>
      <Flex width="340">
        {isDisabled && <Overlay borderRadius="md" />}
        <Card
          boxProps={{
            h: '490',
            w: '340',
          }}
          title={title}
          titleRightIcon={
            <Avatar size="xs" bg={condition ? 'success.700' : 'muted.500'}>
              {condition ? (
                <FaIcon name="check" size={16} color="white" />
              ) : (
                <Text fontSize="md" color="white">
                  {index + 1}
                </Text>
              )}
            </Avatar>
          }
          body={body}
          actions={
            isLinear && (
              <Button.Group width="100%" justifyContent="space-between">
                <Button
                  variant="ghost"
                  onPress={onPressBack}
                  borderWidth={1}
                  borderColor="muted.300">
                  <Text fontWeight={500}>Back</Text>
                </Button>
                <Button
                  colorScheme="primary"
                  isDisabled={!condition || isLast}
                  onPress={onPressNext}>
                  <Text color="white" fontWeight={500}>
                    Next
                  </Text>
                </Button>
              </Button.Group>
            )
          }
        />
      </Flex>
    </>
  )
}

export default OrientationStep
