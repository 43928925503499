import { useNetInfo } from '@react-native-community/netinfo'
import { Client } from '@stomp/stompjs'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { AppState, AppStateStatus } from 'react-native'
import { default as ConfigurationService } from '../../../domain/services/ConfigurationService'
import DefaultModal from '../components/modals/DefaultModal'
import useAuthentication from '../hooks/authentication/useAuthentication'
import LoadingScreen from '../screens/LoadingScreen'
import OfflineScreen from '../screens/OfflineScreen'

enum WebsocketState {
  OFFLINE,
  CONNECTING,
  CONNECTED,
  ERROR,
}

const crab = require('../assets/images/ui/crab.png')

const CabanaSocketContext = createContext(null)
export const useCabanaSocket = () => useContext(CabanaSocketContext)

export const CabanaSocketProvider = ({ children }) => {
  const [stomp, setStomp] = useState(null)
  const [state, setState] = useState(WebsocketState.CONNECTING)
  const appStateRef = useRef<AppStateStatus>(AppState.currentState)
  const { isConnected } = useNetInfo()
  const { getAccessToken } = useAuthentication()

  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    getAccessToken().then((token) => {
      setAccessToken(token)
    })
  }, [getAccessToken])

  const create = () => {
    const client = new Client({
      debug: console.debug,
      logRawCommunication: true,
      forceBinaryWSFrames: true,
      appendMissingNULLonIncoming: true,
    })
    client.webSocketFactory = () => {
      return new WebSocket(
        accessToken
          ? `${ConfigurationService.CABANA_SERVICE_WEBSOCKET_URL}?access_token=${accessToken}`
          : `${ConfigurationService.CABANA_SERVICE_WEBSOCKET_URL}/public`
      )
    }

    client.reconnectDelay = 1500
    client.onConnect = () => {
      setState(WebsocketState.CONNECTED)
    }
    client.onDisconnect = () => {
      setState(WebsocketState.CONNECTING)
    }
    client.onWebSocketClose = () => {
      setState((current) =>
        current === WebsocketState.ERROR
          ? WebsocketState.ERROR
          : WebsocketState.CONNECTING
      )
    }
    client.onWebSocketError = () => {
      setState(
        appStateRef.current === 'active'
          ? WebsocketState.ERROR
          : WebsocketState.CONNECTING
      )
    }

    client.activate()
    setStomp(client)
  }

  const destroy = () => {
    if (stomp) {
      stomp.onDisconnect = () => {}
      stomp.onWebSocketClose = () => {}
      stomp.onWebSocketError = () => {}
      stomp.deactivate()
      setStomp(null)
    }
  }

  const content = () => {
    switch (state) {
      case WebsocketState.OFFLINE:
        return (
          <DefaultModal
            modalInfo={{
              image: crab,
              title: 'Well, crab...',
              description:
                'Slow or no internet connection.\nPlease check your internet settings.',
              buttonText: 'Try again',
            }}
            show={true}
            action={() => {}}
          />
        )
      case WebsocketState.CONNECTING:
        return <LoadingScreen />
      case WebsocketState.CONNECTED:
        return children
      case WebsocketState.ERROR:
        return <OfflineScreen />
    }
  }

  const socket = {
    subscribe: (topic, callback) => {
      return stomp.subscribe(topic, (message) =>
        callback(JSON.parse(message.body))
      )
    },
    publish: (topic, message) => {
      return stomp.publish({
        destination: topic,
        body: JSON.stringify(message),
        skipContentLengthHeader: true,
      })
    },
  }

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      (newState) => (appStateRef.current = newState)
    )

    return () => {
      subscription.remove()
    }
  }, [])

  useEffect(() => {
    if (stomp) {
      destroy()
    }
    if (accessToken) {
      create()
    }

    return () => destroy()
  }, [accessToken])

  useEffect(() => {
    setState((current) => {
      if (!isConnected) return WebsocketState.OFFLINE
      else if (current !== WebsocketState.CONNECTING)
        return WebsocketState.CONNECTING
      else return current
    })

    if (!isConnected) setState(WebsocketState.OFFLINE)
  }, [isConnected])

  return (
    <CabanaSocketContext.Provider value={socket}>
      {content()}
    </CabanaSocketContext.Provider>
  )
}
