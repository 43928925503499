import { useEffect } from 'react'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import VoyageDesktop from '../../components/voyages/landingVoyage/VoyageDesktop'
import VoyageMobile from '../../components/voyages/landingVoyage/VoyageMobile'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import secureScreen from '../../hooks/authentication/secureScreen'
import DefaultLayout from '../../layouts/DefaultLayout'

const LandingVoyageScreen = () => {
  const isDesktop = useDesktopBreakpoint()

  useEffect(() => {
    analytics.track('Voyage Viewed')
  }, [])

  return (
    <DefaultLayout
      scrollView={isDesktop ? true : false}
      activeLabel="Voyage"
      bg={isDesktop ? 'muted.50' : 'white'}>
      {!isDesktop ? (
        <VoyageMobile />
      ) : (
        <FixedContainer size="lg">
          <VoyageDesktop />
        </FixedContainer>
      )}
    </DefaultLayout>
  )
}

export default secureScreen(LandingVoyageScreen)
