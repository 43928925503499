import { useNavigation } from '@react-navigation/native'
import { Box, Button, HStack } from 'native-base'
import { useCallback, useState } from 'react'
import { useMobileBreakpoint } from '../../../../../../domain/services/useBreakpoint'
import { useCompleteDailyRec } from '../../../home/queries'
import FaIcon from '../../../_shared/FaIcon'

const ActivityDownloadableContentButtons = ({ downloadLocation, activity }) => {
  const [downloadProgress, setDownloadProgress] = useState(0)
  const { completeContent } = useCompleteDailyRec()
  const isMobile = useMobileBreakpoint()

  const navigation = useNavigation()

  const openPdf = useCallback(() => {
    completeContent(activity)
    if (!isMobile) window.open(downloadLocation, '_blank')
    else
      navigation.navigate('FullPDF', {
        fileUri: activity.downloadUrl,
        articleTitle: activity.title,
      })
  }, [downloadLocation])

  const download = (url, name) => {
    if (!url) {
      throw new Error('Resource URL not provided! You need to provide one')
    }
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = blobURL
        a.style = 'display: none'
        if (name && name.length) a.download = name
        document.body.appendChild(a)
        a.click()
        completeContent(activity)
      })
      .catch((err) => console.debug(err))
  }

  return (
    <>
      <HStack mt="24px">
        <Box flex={1}>
          <Button onPress={openPdf}>View Now</Button>
        </Box>
        <Button
          height="100%"
          ml="16px"
          onPress={() => download(activity.downloadUrl, activity.title)}>
          <FaIcon name="cloud-arrow-down" color="white" size={20} />
        </Button>
      </HStack>
    </>
  )
}

export default ActivityDownloadableContentButtons
