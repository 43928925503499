import { Heading, Text, VStack } from 'native-base'
import ImageButton from '../_shared/form/ImageButton'

const OasisThemeForm = ({ value, setValue, isInModal = false }) => {
  const image1 = require('../../assets/images/onboarding/oasis-beach.png')
  const image2 = require('../../assets/images/onboarding/oasis-forest.png')
  const image3 = require('../../assets/images/onboarding/oasis-lake.png')

  const options = [
    {
      image: image1,
      value: 'BEACH',
      label: 'Tropical beach',
    },
    {
      image: image2,
      value: 'FOREST',
      label: 'Mossy forest',
    },
    {
      image: image3,
      value: 'LAKE',
      label: 'Alpine lake',
    },
  ]

  return (
    <>
      <VStack alignItems="center">
        {isInModal ? (
          <Text w="300px" textAlign="center" mt="2" mb="5">
            Personalize your mental oasis
          </Text>
        ) : (
          <>
            <Heading w="300px" textAlign="center" my="5">
              If you had an Oasis, where would it be?
            </Heading>
            <Text w="300px" textAlign="center" mb="5">
              Select the most calming environment for you and we'll personalize
              your experience
            </Text>
          </>
        )}
        {options.map((option, index) => (
          <ImageButton
            key={index}
            label={option.label}
            imageUri={option.image}
            isDisabled={value && value !== option.value}
            handlePress={() => setValue(option.value)}
          />
        ))}
      </VStack>
    </>
  )
}

export default OasisThemeForm
