import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import WeeklyInsights from '../../components/insights/WeeklyInsights'
import secureScreen from '../../hooks/authentication/secureScreen'

const InsightsScreen = () => {
  const isDesktop = useDesktopBreakpoint()

  return !isDesktop && <WeeklyInsights />
}

export default secureScreen(InsightsScreen)
