import LottieView from 'react-native-web-lottie'

const Confetti = () => {
  return (
    <LottieView
      autoPlay
      loop={false}
      style={{
        width: '100%',
      }}
      source={require('../../assets/images/vibe-check/confetti.json')}
    />
  )
}

export default Confetti
