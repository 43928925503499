import { useEffect, useState } from 'react'
import { useCabanaApi } from '../../infrastructure/views/providers/CabanaApiProvider'
import { useCabanaSocket } from '../../infrastructure/views/providers/CabanaSocketProvider'

export const useChat = (chatId: string) => {
  const { ChatApi } = useCabanaApi()
  const socket = useCabanaSocket()
  const [messages, setMessages] = useState([])

  const addMessage = (message) => {
    setMessages((state) => [...state, message])
  }

  useEffect(() => {
    ChatApi.getMessages(chatId).then(setMessages)
  }, [])

  useEffect(() => {
    const subscription = socket.subscribe(`/chat/${chatId}`, addMessage)
    return () => subscription.unsubscribe()
  }, [socket, chatId])

  const sendMessage = (message: string) => {
    return ChatApi.sendMessage(chatId, message)
  }

  return [messages, sendMessage]
}
