import { useEffect, useState } from 'react'
import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import { UseCameras } from './useCameras'

const fetchCameras: () => Promise<Camera[]> = async () => {
  // permission check
  await navigator.mediaDevices.getUserMedia({ video: true })

  // map devices to cameras
  const devices = await navigator.mediaDevices.enumerateDevices()
  const videoDevices = devices.filter((device) => device.kind === 'videoinput')
  const cameras = videoDevices.map((device) => ({
    name: device.label || 'Unnamed camera',
    object: device,
  }))
  return cameras
}

export const useCameras: UseCameras = () => {
  const [cameras, setCameras] = useState<Camera[]>([])
  const [error, setError] = useState<string | null>(null)

  const refresh = async () => {
    const fetchedCameras = await fetchCameras()
    setCameras([...fetchedCameras, NO_CAMERA])
  }

  useEffect(() => {
    refresh().catch(error => setError(error.message))
  }, [])

  return {
    cameras,
    error,
    refresh,
  }
}

export default useCameras
