import { Platform } from 'react-native'

export default (height = 4) => {
  if (Platform.OS === 'android') {
    return { shadow: '5' }
  } else {
    return {
      style: {
        shadowOffset: {
          width: 0,
          height,
        },
        shadowOpacity: 0.12,
        shadowRadius: 15,
        shadowColor: '#000000',
      },
    }
  }
}
