import { format, isSameDay } from 'date-fns'
import { Box, FlatList, HStack, Pressable, Text } from 'native-base'
import { useCallback } from 'react'
import useGetOasisColors from '../../../hooks/useGetOasisColors'
import FaIcon from '../../_shared/FaIcon'
import DateItem from './DateItem'

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const DateSelectionDesktop = ({
  selectedMonthYear,
  advanceMonth,
  retractMonth,
  calendar,
  selectedDateIndex,
  onDatePress,
  flatListRef,
  isLast,
  isFirst,
}) => {
  const width = 318
  const dateWidth = width / 7
  const year = selectedMonthYear.getFullYear()
  const month = selectedMonthYear.getMonth() + 1
  const days = calendar.length > 0 ? calendar[year][month].data : []

  const renderHeader = useCallback(() => {
    return (
      <MonthYear
        advanceMonth={advanceMonth}
        retractMonth={retractMonth}
        isLast={isLast}
        isFirst={isFirst}
        dateWidth={dateWidth}
        selectedMonthYear={selectedMonthYear}
      />
    )
  }, [
    selectedMonthYear,
    dateWidth,
    advanceMonth,
    retractMonth,
    isLast,
    isFirst,
  ])

  return (
    <FlatList
      ref={flatListRef}
      ListHeaderComponent={renderHeader}
      w="100%"
      numColumns={7}
      extraData={selectedDateIndex}
      keyExtractor={(item, index) => (item ? item.date.getDate() : index * 10)}
      data={days}
      renderItem={({ item, index }) => {
        return (
          <Box w={dateWidth} alignItems="center" my="7px">
            {item ? (
              <DateItem
                date={item.date}
                isSelected={index === selectedDateIndex}
                isToday={isSameDay(item.date, new Date())}
                onPress={() => {
                  onDatePress(item.nextDate, item.eventIndex, item.isNextMonth)
                }}
                width={`${dateWidth - 7 * 2}px`}
                isDisabled={item.disabled}
              />
            ) : null}
          </Box>
        )
      }}
      minH="300px"
    />
  )
}

const MonthYear = ({
  selectedMonthYear,
  retractMonth,
  advanceMonth,
  dateWidth,
  isLast,
  isFirst,
}) => {
  const { tabColor } = useGetOasisColors()
  return (
    <>
      <HStack
        mx="10px"
        justifyContent="space-between"
        mb="14px"
        alignItems="center">
        <Text fontSize={24} color={tabColor}>
          {format(selectedMonthYear, 'MMMM yyyy')}
        </Text>
        <HStack>
          <Pressable onPress={retractMonth} disabled={isFirst} mr="20px">
            <FaIcon
              name="chevron-left"
              color={isFirst ? 'muted.300' : tabColor}
              size={20}
            />
          </Pressable>

          <Pressable onPress={advanceMonth} disabled={isLast}>
            <FaIcon
              name="chevron-right"
              color={isLast ? 'muted.300' : tabColor}
              size={20}
            />
          </Pressable>
        </HStack>
      </HStack>
      <HStack alignItems="space-between">
        {daysOfWeek.map((day) => (
          <Box w={dateWidth} alignItems="center" key={day}>
            <Text color="text.400">{day}</Text>
          </Box>
        ))}
      </HStack>
    </>
  )
}

export default DateSelectionDesktop
