import Svg, { Defs, LinearGradient, Path, Rect, Stop } from 'react-native-svg'

const ShareIcon = (props) => {
  return (
    <Svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect
        width={52}
        height={52}
        rx={26}
        fill="url(#paint0_linear_9799_34632)"
      />
      <Path
        d="M30.5 16.546l-1.598 1.613-1.788-1.807v12.694h-2.228V16.352l-1.788 1.807-1.598-1.613L26 12l4.5 4.546zm4.5 5.681v12.5C35 35.977 33.987 37 32.75 37h-13.5C18.001 37 17 35.977 17 34.727v-12.5a2.254 2.254 0 012.25-2.273h3.375v2.273H19.25v12.5h13.5v-12.5h-3.375v-2.273h3.375A2.26 2.26 0 0135 22.227z"
        fill="#fff"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_9799_34632"
          x1={46.5}
          y1={44}
          x2={7.5}
          y2={6}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#222B60" />
          <Stop offset={1} stopColor="#3948A1" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

export default ShareIcon
