import Svg, { Path } from 'react-native-svg'

const LogoHorizontalWhite = ({ width = 160, height = 32 }) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 454 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M93.4 40.28A39.53 39.53 0 0076 32.99a40.742 40.742 0 00-6-.46 39.31 39.31 0 00-38.82 33.11c-.1.61-.17 1.2-.23 1.75a38.807 38.807 0 00-.25 4.45 39.31 39.31 0 0078 7c.406-2.311.61-4.653.61-7A39.53 39.53 0 0093.4 40.28z"
        fill="#222B60"
      />
      <Path
        d="M106.541 71.84c0 1.26-.08 2.52-.2 3.77a72.997 72.997 0 01-17.22-28.55c1.24-1.2 2.47-2.47 3.66-3.82a36.263 36.263 0 0113.76 28.6z"
        fill="#FFD48D"
      />
      <Path
        d="M99.93 92.77c-7.31 1-15-.86-20.85-2.62l-4.5-1.41c-5.42-1.72-11-3.49-16.94-4.18-8-.91-15.33.56-20.16 4a36.569 36.569 0 01-3.77-12.3 67.712 67.712 0 0128.37 1.32 132.107 132.107 0 0116.39 6c3 1.24 6.12 2.53 9.26 3.68a74.119 74.119 0 0013.48 3.64c-.41.57-.81 1.23-1.28 1.87z"
        fill="#8ED3D2"
      />
      <Path
        d="M88.68 84.58c-3.09-1.13-6.18-2.4-9.16-3.64a134.899 134.899 0 00-16.73-6.06 69.552 69.552 0 00-29.31-1.42v-1.62c0-1 0-1.92.13-2.88h.56a82.997 82.997 0 0019.36-2.49 81.278 81.278 0 0021.75-8.82 76.502 76.502 0 0029.47 25.61 36.706 36.706 0 01-2.06 5 68.571 68.571 0 01-14.01-3.68z"
        fill="#EA6852"
      />
      <Path
        d="M76.47 35.86a36.85 36.85 0 0114.09 5.76c-19 21.14-44.74 24.18-54.47 24.54 11.01-3.8 28.31-12.3 40.38-30.3z"
        fill="#FFD48D"
      />
      <Path
        d="M77.59 56.22a73.567 73.567 0 009.31-7.07 76.403 76.403 0 0018.92 29.91c-.1.52-.21 1-.34 1.56a73.582 73.582 0 01-27.89-24.4zM46.14 44.14a36.52 36.52 0 0123.85-8.85c1.14 0 2.29.06 3.43.16-11.93 17.06-28.73 25-39.09 28.4a36.57 36.57 0 0111.81-19.71z"
        fill="#FFBF3C"
      />
      <Path
        d="M48.7 101.55a35.848 35.848 0 01-6-5.37 31.755 31.755 0 018.29.53c5.29 1 10.35 3.11 15.24 5.14 1.41.58 2.82 1.16 4.24 1.72a70.047 70.047 0 0010 3.24 36.44 36.44 0 01-31.82-5.26h.05z"
        fill="#8ED3D2"
      />
      <Path
        d="M85.26 105v-.13a56.733 56.733 0 01-13.73-3.81c-1.4-.55-2.8-1.13-4.2-1.71-5-2.07-10.21-4.22-15.77-5.3a34.06 34.06 0 00-10.89-.37c-.65-.87-1.26-1.78-1.83-2.71 5-3.64 12.66-4.29 18.48-3.62 5.66.65 11.13 2.39 16.43 4.07 1.51.48 3 1 4.54 1.41 4.92 1.48 11.05 3 17.32 3 .65 0 1.31 0 2-.06A36.994 36.994 0 0185.26 105z"
        fill="#00A09B"
      />
      <Path
        d="M164.93 81.76l4.15 8.29s-4.77 3.94-14 3.94c-12.08 0-23.16-9.85-23.16-22.08 0-12.23 11-22.22 23.16-22.22 9.27 0 14 3.93 14 3.93l-4.15 8.29a14.85 14.85 0 00-8.39-2.64c-9.79 0-14.1 6.84-14.1 12.54 0 5.7 4.31 12.59 14.1 12.59a14.85 14.85 0 008.39-2.64zM222.13 93.21h-10.47l-3.16-8.55h-16.27l-3.16 8.55h-10.46l17.25-42.75h9l17.27 42.75zM200.4 63.67h-.1l-4.77 12.44h9.64l-4.77-12.44zM247.4 50.46c7.36 0 13.16 2.75 13.16 10.88a9.758 9.758 0 01-5.33 9.17c5.33.94 9.11 3.89 9.11 10.78 0 7-5.13 11.92-14 11.92h-16.19V50.46h13.25zm-2.74 16.74c4.19 0 6.47-1.3 6.47-4.87 0-3.06-2.23-4.35-6.47-4.35h-.78v9.22h.78zm2.28 18.5c4.61 0 7.15-1.4 7.15-4.77 0-3.94-2.54-5.34-7.15-5.34h-3.06V85.7h3.06zM317.98 93.21h-10.47l-3.11-8.55h-16.32l-3.16 8.55h-10.47l17.26-42.75h9l17.27 42.75zm-21.71-29.54h-.11l-4.76 12.44h9.63l-4.76-12.44zM339.73 93.21h-9.74V50.46h9.74l17 26.53h.1V50.46h9.75v42.75h-9.75l-17-26.53h-.11l.01 26.53zM422.11 93.21h-10.46l-3.16-8.55h-16.27l-3.16 8.55h-10.47l17.25-42.75h9l17.27 42.75zM400.4 63.67h-.1l-4.77 12.44h9.64l-4.77-12.44z"
        fill="#fff"
      />
    </Svg>
  )
}

export default LogoHorizontalWhite
