import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { connect } from 'twilio-video'
import useRoomClientContext from './RoomClientProvider'
import useRoomContext from './RoomProvider'

const TwilioRoomContext = createContext(undefined)

export const useTwilioRoom = () => useContext(TwilioRoomContext)

export const TwilioRoomProvider = ({ children }) => {
  /* State */
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const twilioRoom = useRef(undefined)
  const [twilioRoomLoaded, setTwilioRoomLoaded] = useState(false)
  const [token, setToken] = useState(undefined)
  const [micPublished, setMicPublished] = useState(false)
  const tracksOnHold = useRef([])

  /* Functions */
  const isDisabled = (track) =>
    (track.name.startsWith('microphone') && !room.me.microphoneOn) ||
    (track.name.startsWith('camera') && !room.me.cameraOn)

  const connectToRoom = () => {
    if (twilioRoom.current) twilioRoom.current.disconnect()
    connect(token, {
      audio: false,
      video: false,
    }).then((room) => {
      twilioRoom.current = room
      setTwilioRoomLoaded(true)
    })
  }

  const disconnectFromRoom = () => {
    if (!twilioRoom.current) return
    twilioRoom.current.disconnect()
    setTwilioRoomLoaded(false)
  }

  const publishTrack = (track, trackName) => {
    twilioRoom.current.localParticipant.unpublishTrack(track)
    // Append date to prevent duplicate named tracks (race condition with unpublish)
    const name = `${trackName}-${Date.now()}`
    twilioRoom.current.localParticipant
      .publishTrack(track, { name: name })
      .then((subscription) => {
        if (trackName === 'microphone') setMicPublished(true)
        if (isDisabled(subscription.track)) subscription.track.disable()
      })
  }

  const unpublishTrack = (track) => {
    return twilioRoom.current?.localParticipant.unpublishTrack(track)
  }

  const setterFor = useCallback((remoteTrack) => {
    switch (remoteTrack.kind) {
      case 'audio':
        return roomClient.setRemoteAudioTracks
      case 'video':
        if (remoteTrack.name.startsWith('camera'))
          return roomClient.setRemoteCameraTracks
        else if (remoteTrack.name.startsWith('screen_video'))
          return roomClient.setRemoteScreenVideoTracks
      default:
        console.warn('unknown track kind', remoteTrack.kind)
        return () => {}
    }
  }, [])

  const addTrack = useCallback(
    (track) => {
      setterFor(track)((previous) => [...previous, track])
      tracksOnHold.current = tracksOnHold.current.filter(
        (elem) => elem.sid != track.sid
      )
    },
    [setterFor]
  )

  const removeTrack = (track) => {
    setterFor(track)((previous) => previous.filter((t) => t.sid !== track.sid))
  }
  /* Hooks */

  //publish tracks on hold
  useEffect(() => {
    if (micPublished) {
      const tracks = tracksOnHold.current
      tracks.forEach((track) => {
        setterFor(track)((previous) => [...previous, track])
      })
      tracksOnHold.current = []
    }
  }, [micPublished, setterFor])

  // Get Twilio access token
  useEffect(() => {
    setToken(room.me?.token)
    // Cleanup
    return () => {
      setToken(null)
    }
  }, [room.me?.token])

  // Authenticate with token
  useEffect(() => {
    if (token) connectToRoom()
    else disconnectFromRoom()

    // Cleanup
    return () => disconnectFromRoom()
  }, [token])

  // Set remote streams on Twilio subscribed/unsubscribed events
  useEffect(() => {
    if (!twilioRoomLoaded || !twilioRoom.current) {
      roomClient.setRemoteAudioTracks([])
      roomClient.setRemoteCameraTracks([])
      roomClient.setRemoteScreenVideoTracks([])
      tracksOnHold.current = []
      return
    }

    twilioRoom.current.on('trackSubscribed', (track) => {
      addTrack(track)
    })

    twilioRoom.current.on('trackUnsubscribed', (track) => {
      removeTrack(track)
    })

    // Cleanup
    return () => {
      if (!twilioRoomLoaded || !twilioRoom.current) {
        roomClient.setRemoteAudioTracks([])
        roomClient.setRemoteCameraTracks([])
        roomClient.setRemoteScreenVideoTracks([])
        tracksOnHold.current = []
      }
    }
  }, [twilioRoomLoaded, addTrack])

  // Publish microphone
  useEffect(() => {
    if (!twilioRoomLoaded || !twilioRoom.current || !roomClient.microphoneTrack)
      return
    if (roomClient?.microphoneTrack?.start) roomClient?.microphoneTrack?.start()
    publishTrack(roomClient.microphoneTrack, 'microphone')

    // Cleanup
    return () => {
      unpublishTrack(roomClient.microphoneTrack)
    }
  }, [twilioRoomLoaded, twilioRoom.current, roomClient.microphoneTrack])

  // Publish camera
  useEffect(() => {
    if (!twilioRoomLoaded || !twilioRoom.current || !roomClient.cameraTrack)
      return
    publishTrack(roomClient.cameraTrack, 'camera')

    // Cleanup
    return () => {
      unpublishTrack(roomClient.cameraTrack)
    }
  }, [twilioRoomLoaded, roomClient.cameraTrack])

  // Publish screenshare video
  useEffect(() => {
    if (
      !twilioRoomLoaded ||
      !twilioRoom.current ||
      !roomClient.screenVideoTrack
    )
      return
    publishTrack(roomClient.screenVideoTrack, 'screen_video')

    // Cleanup
    return () => {
      unpublishTrack(roomClient.screenVideoTrack)
    }
  }, [twilioRoomLoaded, roomClient.screenVideoTrack])

  // Publish screenshare audio
  useEffect(() => {
    if (
      !twilioRoomLoaded ||
      !twilioRoom.current ||
      !roomClient.screenAudioTrack
    )
      return
    publishTrack(roomClient.screenAudioTrack, 'screen_audio')

    // Cleanup
    return () => {
      unpublishTrack(roomClient.screenAudioTrack)
    }
  }, [twilioRoomLoaded, roomClient.screenAudioTrack])

  // Enable/Disable published tracks
  useEffect(() => {
    if (!twilioRoom.current) return
    for (const publication of twilioRoom.current.localParticipant.tracks.values()) {
      const track = publication.track
      isDisabled(track) ? track.disable() : track.enable()
    }
  }, [
    twilioRoomLoaded,
    room.me.microphoneOn,
    room.me.cameraOn,
    twilioRoom.current,
  ])

  useEffect(() => {
    return () => (twilioRoom.current = null)
  }, [])

  return (
    <>
      <TwilioRoomContext.Provider value={twilioRoom}>
        {children}
      </TwilioRoomContext.Provider>
    </>
  )
}
