import { Auth0Provider } from '@auth0/auth0-react'
import Configuration from '../../../../domain/services/ConfigurationService'

const Auth0 = ({ children }) => {
  return (
    <Auth0Provider
      clientId={Configuration.AUTH0_CLIENT_ID}
      domain={Configuration.AUTH0_DOMAIN}
      authorizationParams={{
        audience: Configuration.AUTH0_AUDIENCE,
        redirect_uri: `${Configuration.CABANA_UI}/login-callback`,
      }}>
      {children}
    </Auth0Provider>
  )
}

export default Auth0
