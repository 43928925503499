import { Pressable, ZStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { AnimatedCircularProgress } from 'react-native-circular-progress'
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler'
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
} from 'react-native-reanimated'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import FaIcon from '../../_shared/FaIcon'

const AudioProgressBar = ({
  handleAudio,
  stopPlaying,
  handleDrag,
  isPlaying,
  seekAudioAtPercentage,
  progressValue,
  customHeight = null,
}) => {
  const { height } = useWindowDimensions()
  const playButtonHeightWidth =
    customHeight != null ? customHeight : 0.26 * height
  const isMobile = useMobileBreakpoint()
  type contextType = {
    previousProgress: number
  }

  const panGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    contextType
  >({
    onStart: (event, context) => {
      context.previousProgress = progressValue.current
      runOnJS(stopPlaying)()
    },
    onActive: (event, context) => {
      const xDistanceFromCenter = (playButtonHeightWidth + 20) / 2 - event.y
      const yDistanceFromCenter = (playButtonHeightWidth + 20) / 2 - event.x

      let newProgressAngle = Math.atan(
        yDistanceFromCenter / xDistanceFromCenter
      )

      if (newProgressAngle > 0) {
        if (yDistanceFromCenter < 0) {
          newProgressAngle = newProgressAngle + Math.PI
        }
      } else {
        if (yDistanceFromCenter > 0) {
          newProgressAngle = Math.PI + newProgressAngle
        } else {
          newProgressAngle = 2 * Math.PI + newProgressAngle
        }
      }

      const newProgress =
        ((2 * Math.PI - newProgressAngle) / (2 * Math.PI)) * 100
      if (
        Math.abs(newProgress - context.previousProgress) < 8 &&
        Math.abs(newProgress - context.previousProgress) > 1.5
      ) {
        runOnJS(handleDrag)(newProgress)
        context.previousProgress = newProgress
      }
    },
    onEnd: (_, context) => {
      runOnJS(seekAudioAtPercentage)(context.previousProgress)
    },
  })

  return (
    <ZStack
      alignItems="center"
      justifyContent="center"
      mt={customHeight ? `${customHeight / 12}px` : '68px'}>
      <PanGestureHandler onGestureEvent={panGestureEvent}>
        <Animated.View
          style={{
            padding: 10,
            borderRadius: (playButtonHeightWidth + 20) / 2,
          }}>
          <AnimatedCircularProgress
            size={playButtonHeightWidth}
            width={13}
            tintColor="#C3EBE7"
            backgroundColor="#4C1D95"
            fillLineCap="round"
            lineCap="round"
            fill={progressValue.current}
            rotation={0}
            duration={300}
          />
        </Animated.View>
      </PanGestureHandler>
      <Pressable
        onPress={handleAudio}
        bg="white"
        w={playButtonHeightWidth - 60}
        h={playButtonHeightWidth - 60}
        borderRadius="full"
        alignItems="center"
        justifyContent="center">
        {isPlaying ? (
          <FaIcon
            name="pause"
            size={isMobile ? 50 : playButtonHeightWidth * 0.13}
            color="#4C1D95"
            isSolid
          />
        ) : (
          <FaIcon
            name="play"
            size={isMobile ? 50 : playButtonHeightWidth * 0.13}
            color="#4C1D95"
            isSolid
          />
        )}
      </Pressable>
    </ZStack>
  )
}

export default AudioProgressBar
