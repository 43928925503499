import { HStack, Text, VStack } from 'native-base'
import { VoyageSingleResultProps } from '../../../../domain/models/interfaces/voyagesInterfaces'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useRenderFocusAreaIcon } from '../../hooks/useRenderFocusAreasIcon'
import FaIcon from '../_shared/FaIcon'
import VoyageBadge from './VoyageBadge'

const indexText = (index) => {
  switch (index) {
    case 1:
      return '1st for you'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${index + 1}th`
  }
}

const VoyageSingleResult = ({ focusArea }: VoyageSingleResultProps) => {
  const { icon, color } = useRenderFocusAreaIcon(focusArea.name)
  const isDesktop = useDesktopBreakpoint()
  return (
    <VStack
      py={5}
      width={isDesktop ? '292px' : '100%'}
      borderTopColor="muted.300"
      borderBottomColor="muted.300"
      borderTopWidth={isDesktop ? 0 : focusArea.recommendedOrder === 0 ? 1 : 0}
      borderBottomWidth={isDesktop ? 0 : 1}>
      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" mb={isDesktop ? 2 : 3}>
          <HStack
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            width={8}
            height={8}
            background={color}>
            <FaIcon name={icon} isSolid color="white" size={20} />
          </HStack>
          <Text ml={2.5} fontSize="md" color="primary.900" fontWeight={500}>
            {focusArea.name}
          </Text>
        </HStack>
        <VoyageBadge text={indexText(focusArea.recommendedOrder)} />
      </HStack>
      {/* To Do: refactor when endpoint is ready */}
      <Text color="primary.900">{focusArea.description}</Text>
    </VStack>
  )
}

export default VoyageSingleResult
