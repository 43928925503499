import { useEffect, useState } from 'react'
import { ImageBackground, StyleSheet } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useUserProfileContext } from '../../providers/UserProfileProvider'

const HomeImageBackground = ({ children }) => {
  const { userProfile } = useUserProfileContext()
  const isMobile = useMobileBreakpoint()

  const oases = {
    BEACH: {
      desktopImage: require('../../assets/images/home/desktop-header-beach.jpg'),
      mobileImage: require('../../assets/images/home/mobile-header-beach.png'),
    },
    FOREST: {
      desktopImage: require('../../assets/images/home/desktop-header-forest.jpg'),
      mobileImage: require('../../assets/images/home/mobile-header-forest.png'),
    },
    LAKE: {
      desktopImage: require('../../assets/images/home/desktop-header-lake.jpg'),
      mobileImage: require('../../assets/images/home/mobile-header-lake.png'),
    },
  }
  const [bg, setBg] = useState(null)

  useEffect(() => {
    if (userProfile) {
      const oasis = oases[userProfile.oasis] ?? oases.BEACH
      if (oasis) setBg(isMobile ? oasis.mobileImage : oasis.desktopImage)
    }
  }, [userProfile, isMobile])

  return (
    <ImageBackground
      source={bg}
      style={isMobile ? styles.imageMobile : styles.imageDesktop}>
      {children}
    </ImageBackground>
  )
}

export default HomeImageBackground

const styles = StyleSheet.create({
  imageMobile: {
    height: 330,
    width: '100%',
    position: 'absolute',
  },
  imageDesktop: {
    flex: 1,
  },
})
