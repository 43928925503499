import { Text, VStack, ZStack } from 'native-base'
import { AnimatedCircularProgress } from 'react-native-circular-progress'
import useGetOasisColors from '../../hooks/useGetOasisColors'
import { useUserProfileContext } from '../../providers/UserProfileProvider'
import { SkeletonLoader } from '../_shared/SkeletonLoader'

interface Colors {
  fill: string
  bg: string
}
interface MinutesProgressBarProfileProps {
  headerHeight?: number
  colors?: Colors
  previousMinutes?: number
  duration?: number
  mt?: number
}

const MinutesProgressBarProfile = ({
  headerHeight,
  colors,
  previousMinutes = 0,
  duration = 1000,
  mt = 5,
}: MinutesProgressBarProfileProps) => {
  const selectedOasisColors = useGetOasisColors()
  const circleSize = (headerHeight * 2) / 3

  const { userProfile, isFetched } = useUserProfileContext()
  const doneMinutes = userProfile?.healthMinutesProgress
  const expectedMinutes = userProfile?.mentalHealthMinutes * 7

  return (
    <ZStack mt={mt} alignItems="center" width="100%" height={circleSize}>
      {doneMinutes === undefined || doneMinutes < 0 || !isFetched ? (
        <SkeletonLoader size={{ width: '100%', height: 100 }} />
      ) : (
        <ZStack
          alignItems="center"
          justifyContent="center"
          width={circleSize}
          height={circleSize}>
          <AnimatedCircularProgress
            duration={duration}
            size={circleSize}
            width={15}
            backgroundWidth={15}
            fill={(doneMinutes / expectedMinutes) * 100}
            prefill={(previousMinutes / expectedMinutes) * 100}
            tintColor={
              colors
                ? colors.fill
                : selectedOasisColors.secondaryBackgroundColor
            }
            arcSweepAngle={180}
            backgroundColor={colors ? colors.bg : selectedOasisColors.fillColor}
            rotation={270}
            fillLineCap="round"
            lineCap="round"
          />
          <VStack alignItems="center">
            <Text
              color="text.800"
              fontSize={32}
              fontWeight="700"
              lineHeight={40}
              mt={-10}>
              {doneMinutes}
            </Text>
            <Text color="text.900">of {expectedMinutes} mins</Text>
          </VStack>
        </ZStack>
      )}
    </ZStack>
  )
}

export default MinutesProgressBarProfile
