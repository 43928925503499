import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'
import BottomSheet from '../_shared/notification/BottomSheet'
import FilterPillContainer from './FilterPillContainer'
import TimeFilterView from './TimeFilterView'

const TimeFilter = ({
  timeFilters,
  setTimeFilters,
  onRemoveAllFilters,
  setIsFilterModalOpen,
}) => {
  const [timeFilterModalOpen, setTimeFilterModalOpen] = useState(false)
  useEffect(() => {
    setIsFilterModalOpen(timeFilterModalOpen)
  }, [timeFilterModalOpen])

  return (
    <>
      <FilterPillContainer
        key="timeFilter"
        isApplied={timeFilters.length > 0}
        onPress={() => {
          Keyboard.dismiss()
          setTimeFilterModalOpen(true)
        }}
        text={
          'Time' + (timeFilters.length > 1 ? ` (${timeFilters.length})` : '')
        }
      />
      <BottomSheet
        isOpen={timeFilterModalOpen}
        handleClose={() => setTimeFilterModalOpen(false)}
        title="Time"
        body={
          <TimeFilterView
            appliedFilters={timeFilters}
            setFilters={setTimeFilters}
            onRemoveAllFilters={onRemoveAllFilters}
          />
        }
        hasCloseOnBottom={true}
        width="415px"
      />
    </>
  )
}

export default TimeFilter
