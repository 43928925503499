import { Box, Center, Input, KeyboardAvoidingView, Text } from 'native-base'
import { useWindowDimensions } from 'react-native'
import EmotionComponent from './EmotionComponent'

const EmotionReview = ({
  emotion,
  setEmotionNote = null,
  emotionNote,
  hasSubmittedReview,
  date = undefined,
}) => {
  const { height } = useWindowDimensions()
  return (
    <Center>
      <EmotionComponent emotion={emotion} />
      <Text mx="50px" color="white" textAlign="center" fontSize={16} my="24px">
        Way to show up for yourself and checking in with your emotions.
      </Text>
      <KeyboardAvoidingView width="88%">
        <Box mx="26px" alignSelf="center" width="100%">
          <Text color="white" fontSize={16} alignSelf="flex-start">
            Something to add?
          </Text>
          <Input
            value={emotionNote}
            onChangeText={(newComment) =>
              setEmotionNote && setEmotionNote(newComment)
            }
            blurOnSubmit
            width="100%"
            height={`${height / 7}px`}
            backgroundColor="primary.50"
            textAlignVertical="top"
            multiline={true}
            mt="8px"
            editable={!hasSubmittedReview}
            returnKeyType="done"
            fontSize="md"
            maxLength={255}
          />
        </Box>
      </KeyboardAvoidingView>
    </Center>
  )
}

export default EmotionReview
