import Svg, {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg'

const EmailIcon = (props) => {
  return (
    <Svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G clipPath="url(#clip0_9799_34633)">
        <Rect width={52} height={52} rx={26} fill="#222B60" />
        <Path fill="url(#paint0_linear_9799_34633)" d="M0 0H52V52H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.287 28.745l-2.006-2.006-8.983 8.982a3 3 0 00.34.202c.641.327 1.481.327 3.162.327h22.4c1.68 0 2.52 0 3.162-.327a2.98 2.98 0 00.339-.202l-8.983-8.982-2.006 2.006A5.227 5.227 0 0126 30.283a5.234 5.234 0 01-3.713-1.538zm19.184 6.207c.074-.108.142-.222.202-.34C42 33.97 42 33.13 42 31.45v-10.9c0-1.68 0-2.52-.327-3.162a3.006 3.006 0 00-.228-.376l-8.957 8.957 8.983 8.983zm-.733-18.647L29.005 28.038a4.23 4.23 0 01-2.194 1.167 4.274 4.274 0 01-1.665-.008 4.23 4.23 0 01-2.152-1.159L11.261 16.305c.12-.085.245-.161.377-.228.641-.327 1.481-.327 3.162-.327h22.4c1.68 0 2.52 0 3.162.327.131.067.257.143.376.228zm-30.184.707c-.085.12-.16.245-.228.376C10 18.03 10 18.87 10 20.55v10.9c0 1.68 0 2.52.326 3.162.06.118.128.231.203.34l8.982-8.983-8.957-8.957z"
          fill="url(#paint1_linear_9799_34633)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_9799_34633"
          x1={26}
          y1={0}
          x2={26}
          y2={52}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#1A6AE7" />
          <Stop offset={1} stopColor="#20C2F4" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_9799_34633"
          x1={25.9997}
          y1={15.75}
          x2={25.9997}
          y2={36.2502}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F7F7F7" />
          <Stop offset={1} stopColor="#F9F9F9" />
        </LinearGradient>
        <ClipPath id="clip0_9799_34633">
          <Rect width={52} height={52} rx={26} fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default EmailIcon
