import { Box, Heading, HStack, VStack } from 'native-base'
import { getFocusAreaByPriority } from '../../../../helpers/getFocusAreaByPriority'
import { useBalancedSelf } from '../../../queries/voyageQueries'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import CompassCompleted from '../compass/CompassCompleted'
import FinishedVoyagesActivities from '../focusArea/FinishedVoyagesActivities'
import FocusArea from '../focusArea/FocusArea'
import FocusAreasList from '../focusArea/FocusAreasList'

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" py={4}>
    {Array.from({ length: 8 }).map((_, index) => (
      <SkeletonLoader
        key={index}
        mb={4}
        size={{ width: '100%', height: '40px' }}
      />
    ))}
  </VStack>
)

const VoyageDesktop = () => {
  const { data, isLoading } = useBalancedSelf()
  const values = !isLoading && data.focusGroups[0].focusAreas[0].nextPath
  const valuesCompleted = !values || values.steps === values.stepsCompleted
  const finishedVoyages =
    !isLoading &&
    data.focusGroups.every((group) =>
      group.focusAreas.every((focusArea) => focusArea.nextPath === null)
    )
  return (
    <Box bg="muted.50" mb={6}>
      <Heading my="20px" ml={1} color="primary.900" fontSize="24px">
        Voyage
      </Heading>
      <HStack>
        {isLoading ? (
          <VStack w={'35%'}>
            <Box
              borderRadius="20px"
              bg="white"
              p={4}
              borderColor="grey.200"
              borderWidth={1}>
              <LoadingView />
            </Box>
          </VStack>
        ) : (
          <FocusAreasList data={data} />
        )}
        <VStack ml={6} w="624px">
          {isLoading ? (
            <Box
              borderRadius="20px"
              bg="white"
              p={4}
              borderColor="grey.200"
              borderWidth={1}>
              <SkeletonLoader size={{ width: '100%', height: '300px' }} />
            </Box>
          ) : (
            <>
              {!finishedVoyages && (
                <FocusArea
                  focusArea={getFocusAreaByPriority(1, data.focusGroups)}
                />
              )}

              {valuesCompleted && <CompassCompleted imageSize={260} />}
              {finishedVoyages && <FinishedVoyagesActivities />}
              {!finishedVoyages && (
                <>
                  <FocusArea
                    focusArea={getFocusAreaByPriority(2, data.focusGroups)}
                  />
                  <FocusArea
                    focusArea={getFocusAreaByPriority(3, data.focusGroups)}
                  />
                </>
              )}
            </>
          )}
        </VStack>
      </HStack>
    </Box>
  )
}

export default VoyageDesktop
