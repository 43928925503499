import { Box, Pressable, Text } from 'native-base'
import { ActivityCardBorderState } from '../../../../../domain/models/interfaces/activityInterfaces'
import { CabanaCardBorderProps } from '../../../../../domain/models/interfaces/cardInterfaces'
import useGetShadowProps from '../../../hooks/useGetShadowProps'

const CabanaCardBorder = ({
  height = null,
  width = null,
  cardBorderState,
  cardBorderStateData,
  onPress,
  children,
  hasShadow = true,
  reduceOpacityOnComplete = true,
  borderStyle = {},
  testID = null,
}: CabanaCardBorderProps) => {
  const borderProps =
    cardBorderStateData.color && !borderStyle.borderColor
      ? {
          borderColor: cardBorderStateData.color,
          borderWidth: '1',
        }
      : {}

  const shadowProps =
    !cardBorderStateData.color && hasShadow ? useGetShadowProps() : {}
  return (
    <Pressable onPress={onPress} testID={testID} isDisabled={onPress === null}>
      {!!cardBorderStateData.label && (
        <Box
          position="absolute"
          h="18px"
          w={cardBorderStateData.labelWidth}
          mt="-10px"
          zIndex="1"
          right="8px"
          borderRadius="full"
          bg={cardBorderStateData.color}>
          <Text fontSize="12px" color="white" textAlign="center">
            {cardBorderStateData.label}
          </Text>
        </Box>
      )}
      <Box
        overflow={cardBorderStateData.label ? 'hidden' : undefined}
        height={height}
        width={width}
        bg="white"
        borderColor={cardBorderStateData.color}
        borderRadius="lg"
        borderWidth={cardBorderStateData.color ? '1' : '0'}
        opacity={
          reduceOpacityOnComplete &&
          cardBorderState === ActivityCardBorderState.COMPLETED
            ? '.4'
            : '1'
        }
        {...borderStyle}
        {...borderProps}
        {...shadowProps}>
        {children}
      </Box>
    </Pressable>
  )
}

export default CabanaCardBorder
