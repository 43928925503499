import { Flex } from 'native-base'
import FaIcon from '../../_shared/FaIcon'

const ChecklistBullet = ({ isChecked }) => {
  return (
    <Flex
      h="24px"
      w="24px"
      borderRadius="full"
      borderWidth="2"
      borderColor={isChecked ? 'secondary.500' : 'secondary.300'}
      bg={isChecked ? 'secondary.500' : 'white'}>
      {isChecked && (
        <FaIcon name="check" size={14} color="white" props={{ m: 'auto' }} />
      )}
    </Flex>
  )
}

export default ChecklistBullet
