/* istanbul ignore file */
import { useEffect, useRef, useState } from 'react'
import { NO_MICROPHONE } from '../../../domain/models/interfaces/microphone'
import { useMicrophonesContext } from '../providers/MicrophoneProvider'

const useMicrophoneLevel = () => {
  const { selectedMicrophone } = useMicrophonesContext()
  const [permission, setPermission] = useState(false)
  const [level, setLevel] = useState(0)
  const noiseFloor = useRef(Infinity)
  let audioContext
  let analyser
  let stopMonitoring = false

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: selectedMicrophone.object.deviceId } },
        })
        setPermission(true)

        audioContext = new AudioContext()
        analyser = audioContext.createAnalyser()
        analyser.fftSize = 256

        const source = audioContext.createMediaStreamSource(stream)
        source.connect(analyser)

        startMonitoring()
      } catch (error) {
        console.error('Microphone permission denied or error:', error)
      }
    }

    const startMonitoring = () => {
      const dataArray = new Uint8Array(analyser.frequencyBinCount)
      stopMonitoring = false

      const updateLevel = () => {
        if (stopMonitoring) return
        analyser.getByteFrequencyData(dataArray)
        const currentLevel = Math.max(...dataArray)

        if (currentLevel > 0)
          noiseFloor.current = Math.min(noiseFloor.current, currentLevel)

        const adjustedLevel =
          (currentLevel - noiseFloor.current) / (255 - noiseFloor.current)
        setLevel(adjustedLevel)
        requestAnimationFrame(updateLevel)
      }

      updateLevel()
    }

    if (selectedMicrophone && selectedMicrophone !== NO_MICROPHONE) {
      requestPermission()
    } else {
      setLevel(0)
      stopMonitoring = true
      if (audioContext) {
        audioContext.close()
      }
    }

    // Clean up on unmount or when `selectedMicrophone` changes
    return () => {
      stopMonitoring = true
      if (audioContext) {
        audioContext.close()
      }
    }
  }, [selectedMicrophone])

  return { level, permission }
}

export default useMicrophoneLevel
