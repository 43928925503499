import { Box, HStack, ScrollView, VStack } from 'native-base'
import { Platform } from 'react-native'
import { useRenderFocusAreaIcon } from '../../../hooks/useRenderFocusAreasIcon'
import BackButton from '../../navigation/BackButton'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import FocusAreaHeading from '../FocusAreaHeading'
import PathAccordion from '../PathAccordion'
import VoyageChip from '../VoyageChip'

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" py={4}>
    {Array.from({ length: 4 }).map((_, index) => (
      <SkeletonLoader
        key={index}
        mb={4}
        size={{ width: '100%', height: '40px' }}
      />
    ))}
  </VStack>
)
const FocusAreaMobile = ({ data, isLoading, focusArea }) => {
  const { icon, color } =
    !isLoading && data !== null && useRenderFocusAreaIcon(data.name)

  const openIndex = isLoading
    ? -1
    : data.paths.findIndex((path) => !path.pathCompleted)

  return (
    <>
      <HStack
        width="100%"
        alignItems="flex-end"
        justifyContent="space-between"
        height={98}
        backgroundColor="white"
        borderBottomWidth={1}
        px={6}
        shadow={0.5}
        borderBottomColor="muted.50"
        pb={2}>
        <BackButton customText="" />
        {!isLoading && <LoadingView />}
        {!isLoading && data && data.minutesEarned > 0 && (
          <VoyageChip text={`${data.minutesEarned} min`} />
        )}
      </HStack>
      <ScrollView p={4} showsVerticalScrollIndicator={false}>
        {data && (
          <VStack>
            <FocusAreaHeading
              name={data.name}
              description={data.description}
              color={color}
              icon={icon}
            />

            {data.paths.map((path, index) => (
              <PathAccordion
                color={color}
                key={path.id}
                path={path}
                borderColor={color}
                focusArea={focusArea}
                startOpen={openIndex === index}
              />
            ))}
          </VStack>
        )}
        {Platform.OS === 'ios' && <Box height="50px" />}
      </ScrollView>
    </>
  )
}

export default FocusAreaMobile
