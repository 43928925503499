import { useCallback, useEffect, useState } from 'react'
import { Keyboard } from 'react-native'

export default () => {
  const [keyboardOpen, setKeyboardOpen] = useState(false)
  const [keyboardHeight, setKeyboardHeight] = useState(0)
  const closeKeyboard = useCallback(() => {
    setKeyboardOpen(false)
    Keyboard.dismiss()
  }, [setKeyboardOpen])

  useEffect(() => {
    const willShowSubscription = Keyboard.addListener(
      'keyboardWillShow',
      () => {
        setKeyboardOpen(true)
      }
    )
    const willHideSubscription = Keyboard.addListener(
      'keyboardWillHide',
      () => {
        setKeyboardOpen(false)
      }
    )
    const updateHeight = (e) => {
      setKeyboardHeight(e.endCoordinates.height)
    }
    const didShowSubscription = Keyboard.addListener('keyboardDidShow', (e) => {
      updateHeight(e)
      setKeyboardOpen(true)
    })
    const didHideSubscription = Keyboard.addListener('keyboardDidHide', (e) => {
      updateHeight(e)
      setKeyboardOpen(false)
    })

    return () => {
      willShowSubscription.remove()
      willHideSubscription.remove()
      didShowSubscription.remove()
      didHideSubscription.remove()
    }
  }, [])

  return { keyboardOpen, keyboardHeight, closeKeyboard }
}
