import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Button, FlatList, HStack, Text, View } from 'native-base'
import useGetShadowProps from '../../hooks/useGetShadowProps'
import { useBookmark } from '../../queries/activitiesQueries'
import { NavigationParams } from '../../screens/NavigationScreen'
import EmptyListComponent from '../EmptyListComponent'
import ActivityCard from '../_shared/card/ActivityCard'
import { SkeletonLoader } from '../_shared/SkeletonLoader'

const EmptyImage = require('../../assets/images/vibe-check/empty-bookmarks.png')

const borderStyle = {
  borderColor: 'primary.700',
  borderWidth: '1',
  borderRadius: '10px',
}
const Bookmarks = () => {
  const { bookmarks, isLoading } = useBookmark()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const shadow = useGetShadowProps(15)

  return (
    <HStack
      borderRadius="2xl"
      p={4}
      mx={5}
      mt={5}
      bg="white"
      flexDirection="column"
      borderColor="grey.200"
      borderWidth={1}>
      {isLoading && <SkeletonLoader size={{ width: '100%', height: 300 }} />}

      <View flex={1}>
        <Text fontSize="16px" color="primary.900" fontWeight={700} mb={6}>
          Your bookmarks
        </Text>
        {bookmarks?.length > 0 && !isLoading && (
          <>
            <FlatList
              data={bookmarks?.slice(0, 3)}
              renderItem={({ item }) => {
                return (
                  <View mb={4} bg="white">
                    <ActivityCard
                      hasShadow={false}
                      borderStyle={borderStyle}
                      reduceOpacityOnComplete={false}
                      activity={item}
                      onPress={async () =>
                        navigation.navigate('Activity', {
                          activityId: item.id,
                        })
                      }
                    />
                  </View>
                )
              }}
            />
            <Button
              variant="link"
              alignSelf="flex-end"
              mt={-2}
              onPress={() => navigation.navigate('Bookmarks')}>
              <Text
                color="primary.900"
                textDecorationLine="underline"
                fontSize="12px">
                View all
              </Text>
            </Button>
          </>
        )}
      </View>
      {bookmarks?.length === 0 && !isLoading && (
        <View {...shadow} px={4} py={5} mb={4}>
          <EmptyListComponent
            description="See all content"
            buttonText="Explore"
            buttonAction={() => navigation.navigate('Explore')}
            imageSource={EmptyImage}
          />
        </View>
      )}
    </HStack>
  )
}

export default Bookmarks
