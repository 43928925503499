const DESKTOP_ENABLED_SCREENS = [
  'Login',
  'Signup',
  'Ochsner',
  'Home',
  'Explore',
  'Profile',
]

import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import useAuthentication from '../hooks/authentication/useAuthentication'
import { useCabanaApi } from '../providers/CabanaApiProvider'

const IntercomWrapper = ({ children }) => {
  const { boot, shutdown } = useIntercom()
  const { user } = useAuthentication()
  const { MemberProfileApi } = useCabanaApi()

  const setup = async () => {
    if (user) {
      const memberProfile = await MemberProfileApi.profile()
      boot({
        email: memberProfile.email,
        userId: memberProfile.id,
        userHash: memberProfile.intercomHmacValue,
        customAttributes: {
          professionalRoles: JSON.stringify(memberProfile.professionalRoles),
          goal: memberProfile.goal || memberProfile.customGoal,
        },
      })
    } else {
      boot()
    }
  }

  useEffect(() => {
    setup()

    return () => shutdown()
  }, [user])

  return children
}

export default IntercomWrapper
