import { useQueryClient } from '@tanstack/react-query'
import { differenceInMilliseconds } from 'date-fns'
import { useEffect } from 'react'

const useInvalidateActivityQueriesInTimestamp = (arrayData) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    if (!arrayData || arrayData.length === 0) return
    let initialStartDate = new Date(arrayData[0].startDate)
    initialStartDate.setMinutes(initialStartDate.getMinutes() - 15)
    if (initialStartDate < new Date())
      initialStartDate = new Date(arrayData[0].endDate)
    const firstDate = arrayData?.reduce((acc, curr) => {
      let startDateMinus15Minutes = new Date(curr.startDate)
      startDateMinus15Minutes.setMinutes(
        startDateMinus15Minutes.getMinutes() - 15
      )
      const dateToCheck =
        startDateMinus15Minutes > new Date()
          ? startDateMinus15Minutes
          : curr.endDate
      if (acc > dateToCheck) {
        return dateToCheck
      }
      return acc
    }, initialStartDate)

    if (firstDate) {
      const msDifference = differenceInMilliseconds(firstDate, new Date())
      //See https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
      const maxMs = 2147483646
      const msToDate = msDifference > maxMs ? maxMs : msToDate
      if (!msToDate || msToDate < 0) return
      const timeout = setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['Activity'] })
      }, msToDate)

      return () => clearTimeout(timeout)
    }
  }, [arrayData, queryClient])
}

export default useInvalidateActivityQueriesInTimestamp
