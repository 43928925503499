const useRandomQuote = () => {
  const rng = Math.floor(Math.random() * quotes.length)
  return quotes[rng]
}

export default useRandomQuote

const quotes = [
  {
    quote:
      "You're braver than you believe, and stronger than you seem, and smarter than you think.",
    author: 'A.A. Mine',
  },
  {
    quote:
      "Changing your mind doesn't make you a flip-flopper or a hypocrite. It means you were open to learning.",
    author: 'Adam Grant',
  },
  {
    quote: 'No act of kindness, no matter how small, is ever wasted.',
    author: 'Aesop',
  },
  {
    quote:
      'Life is like riding a bicycle. To keep your balance, you must keep moving.',
    author: 'Albert Einstein',
  },
  {
    quote: 'It does not do well to dwell on dreams and forget to live.',
    author: 'Albus Dumbledore (Harry Potter)',
  },
  {
    quote:
      'Experience is not what happens to you; it is what you do with what happens to you.',
    author: 'Aldous Huxley',
  },
  {
    quote: 'Each day comes bearing its gifts. Untie the ribbon.',
    author: 'Ann Ruth Schabacker',
  },
  {
    quote:
      'Almost everything will work again if you unplug it for a few minutes, including you.',
    author: 'Anne Lamott',
  },
  {
    quote: 'How we spend our days is, of course, how we spend our lives.',
    author: 'Annie Dillard',
  },
  {
    quote: 'Never let the fear of striking out keep you from playing the game.',
    author: 'Babe Ruth',
  },
  {
    quote:
      "Winning doesn't always mean being first. Winning means you're doing better than you've done before.",
    author: 'Bonnie Blair',
  },
  {
    quote:
      "We need to dispel the myth that empathy is \"walking in someone else's shoes\". Rather than walking in your shoes, I need to learn how to listen to the story you tell about what it's like in your shoes and believe you even when it doesn't match my experiences.",
    author: 'Brené Brown',
  },
  {
    quote:
      'What do your team members do that earn your trust? The most common answer: asking for help.',
    author: 'Brené Brown',
  },
  {
    quote:
      'We need to stay curious and resist choosing comfort over courage. It’s brave to invite new information to the table, to sit with it and hear it out.',
    author: 'Brené Brown',
  },
  {
    quote:
      'The good life is a process, not a state of being. It is a direction, not a destination.',
    author: 'Carl Rogers',
  },
  {
    quote:
      'The curious paradox is that when I accept myself just as I am, then I can change.',
    author: 'Carl Rogers',
  },
  {
    quote:
      'The sun himself is weak when he first rises, and gathers strength and courage as the day gets on.',
    author: 'Charles Dickens',
  },
  {
    quote:
      "Don't try to lessen yourself for the world; let the world catch up to you.",
    author: 'Colleen Hoover',
  },
  {
    quote:
      'We all have two lives. The second one starts when we realize we only have one.',
    author: 'Confucius',
  },
  {
    quote:
      'Happiness is not something readymade. It comes from your own actions.',
    author: 'Dalai Lama',
  },
  {
    quote:
      'People take different roads seeking fulfillment and happiness. Just because they’re not on your road doesn’t mean they’ve gotten lost.',
    author: 'Dalai Lama',
  },
  {
    quote:
      'In a very real sense we have two minds, one that thinks and one that feels.',
    author: 'Daniel Goleman',
  },
  {
    quote: 'It’s salt that makes the sugar sweet.',
    author: 'David Black',
  },
  {
    quote:
      'If you restore balance in your own self, you will be contributing immensely to the healing of the world.',
    author: 'Deepak Chopra',
  },
  {
    quote:
      'The way I see it, if you want the rainbow, you gotta put up with the rain.',
    author: 'Dolly Parton',
  },
  {
    quote: 'We generate fears while we sit. We overcome them by action.',
    author: 'Dr. Henry Link',
  },
  {
    quote:
      'There’s a time when a man needs to fight, and a time when he needs to accept that his destiny is lost, that the ship has sailed, and that only a fool would continue. The truth is, I’ve always been a fool.',
    author: 'Ed Bloom (Big Fish)',
  },
  {
    quote:
      'A little consideration, a little thought for others, makes all the difference.',
    author: 'Eeyore (Winnie the Pooh)',
  },
  {
    quote: "Living is the art of getting used to what we didn't expect.",
    author: 'Eleanor C. Wood',
  },
  {
    quote:
      'If life were predictable it would cease to be life, and be without flavor.',
    author: 'Eleanor Roosevelt',
  },
  {
    quote: "It isn't where you came from. It's where you're going that counts.",
    author: 'Ella Fitzgerald',
  },
  {
    quote:
      'You can’t do anything about the length of your life, but you can do something about its width and depth.',
    author: 'Evan Esar',
  },
  {
    quote: 'I attribute my success to this: I never gave or took any excuse.',
    author: 'Florence Nightingale',
  },
  {
    quote:
      'Were there none who were discontented with what they have, the world would never reach anything better.',
    author: 'Florence Nightingale',
  },
  {
    quote: "Everything you've ever wanted is on the other side of fear.",
    author: 'George Addair',
  },
  {
    quote:
      "Twenty years from now you will be more disappointed by the things that you didn't do than by the ones you did do.",
    author: 'H. Jackson Brown, Jr.',
  },
  {
    quote: "Accept no one's definition of your life; define yourself.",
    author: 'Harvey Fierstein',
  },
  {
    quote:
      'Some people look for a beautiful place. Others make a place beautiful.',
    author: 'Hazrat Inayat Khan',
  },
  {
    quote: 'Sometimes I do need to go to karaoke, sometimes I need to relax.',
    author: 'Jackie Chan',
  },
  {
    quote:
      'Every action you take is a vote for the type of person you wish to become.',
    author: 'James Clear',
  },
  {
    quote: "Balance is not something you find, it's something you create.",
    author: 'Jana Kingsford',
  },
  {
    quote:
      "I can't change the direction of the wind, but I can adjust my sails to always reach my destination.",
    author: 'Jimmy Dean',
  },
  {
    quote:
      'The difference between ordinary and extraordinary is that little extra.',
    author: 'Jimmy Johnson',
  },
  {
    quote:
      'Extraordinary things are always hiding in places people never think to look.',
    author: 'Jodi Picoult',
  },
  {
    quote: "Life is what happens when you're busy making other plans.",
    author: 'John Lennon',
  },
  {
    quote: "It's your life; live it well.",
    author: 'Judge Judy Sheindlin',
  },
  {
    quote:
      'Always be a first-rate version of yourself, instead of a second-rate version of somebody else.',
    author: 'Judy Garland',
  },
  {
    quote: 'I am not afraid of storms for I am learning to sail my ship.',
    author: 'Louisa May Alcott',
  },
  {
    quote: 'In a gentle way, you can shake the world.',
    author: 'Mahatma Gandhi',
  },
  {
    quote:
      'The best way to find yourself is to lose yourself in the service of others.',
    author: 'Mahatma Gandhi',
  },
  {
    quote: 'Be the change you want to see in the world.',
    author: 'Mahatma Gandhi',
  },
  {
    quote: 'You are never too old to set another goal or to dream a new dream.',
    author: 'Malala Yousafzai',
  },
  {
    quote:
      'Nothing in life is to be feared, it is only to be understood. Now is the time to understand more, so that we may fear less.',
    author: 'Marie Curie',
  },
  {
    quote: 'Only in the darkness can you see the stars.',
    author: 'Martin Luther King, Jr.',
  },
  {
    quote: 'If I cannot do great things, I can do small things in a great way.',
    author: 'Martin Luther King, Jr.',
  },
  {
    quote:
      'Lean forward into your life. Begin each day as if it were on purpose.',
    author: 'Mary Anne Radmacher',
  },
  {
    quote:
      "Courage doesn't always roar. Sometimes courage is the little voice at the end of the day that says I'll try again tomorrow.",
    author: 'Mary Anne Radmacher',
  },
  {
    quote:
      'Tell me, what is it you plan to do with your one wild and precious life?',
    author: 'Mary Oliver',
  },
  {
    quote: 'My mission in life is not merely to survive, but to thrive.',
    author: 'Maya Angelou',
  },
  {
    quote:
      'Do the best you can until you know better. Then when you know better, do better.',
    author: 'Maya Angelou',
  },
  {
    quote:
      "I think it's important to take the time to tell the people you love how much you love them while they can hear you.",
    author: "Meredith Grey (Grey's Anatomy)",
  },
  {
    quote: "The bad news is time flies. The good news is you're the pilot.",
    author: 'Michael Altshuler',
  },
  {
    quote:
      'Would I rather be feared or loved? Easy. Both. I want people to be afraid of how much they love me.',
    author: 'Michael Scott (The Office)',
  },
  {
    quote: "If opportunity doesn't knock, build a door.",
    author: 'Milton Barle',
  },
  {
    quote:
      'Believe in yourself and there will come a day when others will have no choice but to believe with you.',
    author: 'Mufasa (The Lion King)',
  },
  {
    quote: 'It always seems impossible until it is done.',
    author: 'Nelson Mandela',
  },
  {
    quote:
      'The greatest glory in living lies not in never falling, but in rising every time we fall.',
    author: 'Nelson Mandela',
  },
  {
    quote:
      "Mental health... is not a destination but a process. It's about how you drive, not where you're going.",
    author: 'Noam Shpancer, Ph.D.',
  },
  {
    quote: 'You get in life what you have the courage to ask for.',
    author: 'Oprah Winfrey',
  },
  {
    quote: 'We can’t become what we need to be by remaining what we are.',
    author: 'Oprah Winfrey',
  },
  {
    quote:
      "There's a lot of beauty in ordinary things. Isn't that kind of the point?",
    author: 'Pam Beesly (The Office)',
  },
  {
    quote: 'Today is a good day to try.',
    author: 'Quasimodo (The Hunchback of Notre Dame)',
  },
  {
    quote:
      'Oh yes, the past can hurt. But, you can either learn from it or run from it.',
    author: 'Rafiki (The Lion King)',
  },
  {
    quote:
      'To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.',
    author: 'Ralph Waldo Emerson',
  },
  {
    quote:
      "In three words I can sum up everything I've learned about life. It goes on.",
    author: 'Robert Frost',
  },
  {
    quote: 'What would you attempt if you knew you could not fail?',
    author: 'Robert H. Schuller',
  },
  {
    quote:
      'No matter what people tell you, words and ideas can change the world.',
    author: 'Robin Williams',
  },
  {
    quote:
      'Communication leads to community, that is, to understanding, intimacy and mutual valuing.',
    author: 'Rollo May',
  },
  {
    quote:
      'Stop comparing yourself to other people, just choose to be happy and live your own life.',
    author: 'Roy T. Bennett',
  },
  {
    quote:
      'Am I going to change the world, or am I going to change me? Or maybe change the world a little bit, just by changing me?',
    author: 'Sadie Delaney',
  },
  {
    quote: 'I was built this way for a reason, so I’m going to use it.',
    author: 'Simone Biles',
  },
  {
    quote: "The time to relax is when you don't have time for it.",
    author: 'Sydney J. Harris',
  },
  {
    quote:
      "Your purpose is to be yourself. You don't have to run anywhere to become someone else. You are wonderful just as you are.",
    author: 'Thich Nhat Hanh',
  },
  {
    quote: 'Many of us have been running all our lives. Practice stopping.',
    author: 'Thich Nhat Hanh',
  },
  {
    quote:
      'To meditate means to go home to yourself. Then you know how to take care of the things that are happening inside you, and you know how to take care of the things that happen around you.',
    author: 'Thich Nhat Hanh',
  },
  {
    quote:
      'I am larger than I thought. I did not think I held so much goodness.',
    author: 'Walt Whitman',
  },
  {
    quote:
      'Keep your face always toward the sunshine, and shadows will fall behind you.',
    author: 'Walt Whitman',
  },
  {
    quote:
      'If you change the way you look at things, the things you look at change.',
    author: 'Wayne Dyer',
  },
  {
    quote:
      'Be like the fox who makes more tracks than necessary, some in the wrong direction. Practice resurrection.',
    author: 'Wendell Berry',
  },
  {
    quote: "Don't let yesterday take up too much of today.",
    author: 'Will Rogers',
  },
  {
    quote: 'Act as if what you do makes a difference. It does.',
    author: 'William James',
  },
  {
    quote:
      'Success is not final, failure is not fatal: it is the courage to continue that counts.',
    author: 'Winston Churchill',
  },
  {
    quote: 'Always pass on what you have learned.',
    author: 'Yoda (Star Wars)',
  },
]
