import { useFocusEffect } from '@react-navigation/native'
import { Box, HStack, IconButton, Progress } from 'native-base'
import { useCallback, useState } from 'react'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import DefaultLayout from '../../layouts/DefaultLayout'
import useVoyagesScreenerContext from '../../providers/VoyagesScreenerProvider'
import VoyagesScreenerModal from '../modals/VoyagesScreenerModal'
import BackButton from '../navigation/BackButton'
import FaIcon from '../_shared/FaIcon'
import FixedContainer from '../_shared/layout/FixedContainer'
import VoyagesScreener from './VoyagesScreener'

const VoyagesScreenerContent = () => {
  const {
    currentQuestionIndex,
    handlePrev,
    progress,
    handleExit,
    setSelectedAnswers,
    setCurrentQuestionIndex,
    setProgress,
  } = useVoyagesScreenerContext()
  const [showModal, setShowModal] = useState(false)
  const isDesktop = useDesktopBreakpoint()
  const headerWidth = 1024 / 3

  useFocusEffect(
    useCallback(() => {
      setSelectedAnswers([])
      setCurrentQuestionIndex(0)
      setProgress(0)
      return () => {}
    }, [])
  )

  const handleBackButton = () => {
    if (currentQuestionIndex === 0) {
      setShowModal(true)
    } else {
      handlePrev()
    }
  }

  return (
    <>
      {isDesktop ? (
        <DefaultLayout activeLabel="Voyage" bg="muted.50">
          <FixedContainer width="1024px" size="md">
            <HStack
              my={4}
              height={10}
              width="1024px"
              alignSelf="center"
              justifyContent="space-between"
              alignItems="center">
              <HStack
                justifyContent="flex-start"
                alignItems="center"
                width={`${headerWidth}px`}>
                <Box>
                  <BackButton onPress={handleBackButton} />
                </Box>
              </HStack>
              <HStack width={`${headerWidth}px`} justifyContent="center">
                <Progress
                  value={progress}
                  width={128}
                  _filledTrack={{
                    bg: 'secondary.500',
                  }}
                />
              </HStack>
              <Box width={`${headerWidth}px`}>
                <IconButton
                  onPress={() => {
                    setShowModal(true)
                  }}
                  icon={<FaIcon name="xmark" size={20} color="primary.700" />}
                  borderRadius="full"
                  alignSelf="flex-end"
                />
              </Box>
            </HStack>
            <VoyagesScreener />
          </FixedContainer>
          <VoyagesScreenerModal
            closeModal={() => setShowModal(false)}
            show={showModal}
            body="Exiting the assessment will erase your answers. Are you sure you want to exit?"
            heading="Your answers will not be saved"
            exit={() => {
              setShowModal(false)
              handleExit()
            }}
          />
        </DefaultLayout>
      ) : (
        <VoyagesScreener />
      )}
    </>
  )
}

export default VoyagesScreenerContent
