import { useNavigation } from '@react-navigation/native'
import { HStack, Pressable, Text, VStack } from 'native-base'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box'
import { useRenderFocusAreaIcon } from '../../../hooks/useRenderFocusAreasIcon'
import FaIcon from '../../_shared/FaIcon'

export interface Path {
  id: string
  name: string
  description: string
  focusArea: string
}

export interface PathCardProps extends InterfaceBoxProps {
  path: Path
}

const PathCard = ({ path, width }: PathCardProps) => {
  const { icon, color } = useRenderFocusAreaIcon(path.focusArea)
  const navigation = useNavigation()

  const gotoPath = () => {
    navigation.navigate('Path', {
      id: path.id,
      focusArea: path.focusArea,
    })
  }

  return (
    <Pressable onPress={gotoPath}>
      <HStack
        width={width}
        padding={4}
        space={4}
        borderColor={'grey.200'}
        borderWidth={1}
        borderRadius={10}
        backgroundColor={'white'}>
        <FaIcon name={icon} color={color} size={30} />
        <VStack justifyContent={'center'}>
          <Text fontSize={14} fontWeight={500} color={'text.900'}>
            {path.name}
          </Text>
          {/* <Text fontSize={'xs'} color={'text.500'}>{path.description}</Text> */}
        </VStack>
      </HStack>
    </Pressable>
  )
}

export default PathCard
