import { Button, HStack, Image, Menu, Text } from 'native-base'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import useIntercom from '../../hooks/useIntercom'

const intercom = require('../../assets/images/logos/intercom-white.png')
const intercomMobile = require('../../assets/images/logos/intercom-grey.png')

const messages = [
  {
    message: "I can't see the moderator",
  },
  {
    message: "I can't hear the moderator",
  },
  {
    message: 'No one can hear me',
  },
  {
    message: "I can't use the chat",
  },
]

const TechSupport = ({ isMobile = false }) => {
  const handleAnalytics = (message) => {
    analytics.track('Tech Support Requested', {
      Reason: message,
    })
  }
  return (
    <Menu
      w="190"
      trigger={(triggerProps) => {
        return (
          <Button
            {...triggerProps}
            borderColor={isMobile ? 'muted.500' : 'white'}
            borderWidth={1.5}
            width={isMobile && '160px'}
            borderRadius="50px"
            height="40px"
            variant="ghost">
            <HStack space={2} alignItems="center">
              {isMobile ? (
                <Image
                  alt="intercom"
                  height={6}
                  width={6}
                  source={intercomMobile}
                />
              ) : (
                <Image
                  alt="intercom"
                  height="24px"
                  width="24px"
                  source={intercom}
                />
              )}
              <Text color={isMobile ? 'muted.500' : 'white'} fontWeight={500}>
                Tech support
              </Text>
            </HStack>
          </Button>
        )
      }}>
      {messages.map((message) => {
        const { openChat } = useIntercom(`Tech support - ${message.message}`)
        return (
          <Menu.Item
            key={message.message}
            onPress={() => {
              openChat()
              handleAnalytics(message.message)
            }}>
            {message.message}
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default TechSupport
