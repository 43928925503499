import { createContext, ReactNode, useContext, useState } from 'react'
import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import useCameras, { UseCamerasResult } from '../hooks/useCameras'

export interface UseCamerasContext extends UseCamerasResult {
  selectCamera: (Camera) => void
  selectedCamera: Camera | null
}

const CamerasContext = createContext<UseCamerasContext | undefined>(undefined)

export const useCamerasContext = (): UseCamerasContext =>
  useContext(CamerasContext)

interface CamerasProviderProps {
  children: ReactNode
}

export const CamerasProvider: React.FC<CamerasProviderProps> = ({
  children,
}) => {
  const useCamerasHook = useCameras()
  const [selectedCamera, setSelectedCamera] = useState<Camera>(NO_CAMERA)

  const context: UseCamerasContext = {
    ...useCamerasHook,
    selectCamera: setSelectedCamera,
    selectedCamera
  }

  return (
    <CamerasContext.Provider value={context}>
      {children}
    </CamerasContext.Provider>
  )
}
