import { Center, FlatList, Heading, Pressable } from 'native-base'
import EmotionComponent from './EmotionComponent'

const EmotionPicker = ({ moods, selectMood }) => {
  return (
    <Center>
      <Heading textAlign="center" color="white" fontWeight="700" mb="28">
        How are you feeling{'\n'}in this moment?
      </Heading>
      <FlatList
        data={moods}
        keyExtractor={(item) => item.name}
        renderItem={({ item }) => {
          return (
            <Pressable onPress={() => selectMood(item)}>
              <EmotionComponent emotion={item} />
            </Pressable>
          )
        }}
        numColumns={3}
      />
    </Center>
  )
}

export default EmotionPicker
