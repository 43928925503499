import { useToast } from 'native-base'
import { useEffect, useState } from 'react'
import { useMobileBreakpoint } from '../../../domain/services/useBreakpoint'
import Toast from '../components/_shared/notification/Toast'
import useRoomClientContext from '../providers/RoomClientProvider'

export default ({
  isAudioEnabled,
  iMutedMyself,
  personSharing,
  isScreenSharing,
}) => {
  const { isSpeaking } = useRoomClientContext()
  const screenSharingToast = useToast()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const isMobile = useMobileBreakpoint()
  const id = 'mute-toast'

  useEffect(() => {
    if (isMobile)
      if (isScreenSharing) {
        screenSharingToast.show({
          title: `${personSharing ?? 'Moderator'} is now presenting`,
          placement: 'top',
        })
      } else if (!isScreenSharing && personSharing !== '') {
        screenSharingToast.show({
          title: `${personSharing ?? 'Moderator'} stopped screen sharing`,
          placement: 'top',
        })
      }
  }, [isScreenSharing])

  Toast({
    isActive: !isAudioEnabled && !iMutedMyself,
    title: 'Heads up, you are now muted!',
    id: id,
    ...toastMuteIconAndDescription,
  })

  Toast({
    isActive: !isAudioEnabled && iMutedMyself && isFirstRender,
    title: 'Psst! You’re on mute.',
    id: id,
    callback: () => setIsFirstRender(false),
    ...toastMuteIconAndDescription,
  })

  Toast({
    isActive: isSpeaking && !isAudioEnabled,
    title: 'Psst! You’re on mute.',
    id: id,
    ...toastMuteIconAndDescription,
  })
}

const toastMuteIconAndDescription = {
  description: 'Please unmute your mic if you want to share with the group',
  icon: 'microphone-slash',
  isIconSolid: true,
}
