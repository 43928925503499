import { Box, Flex, Heading, HStack, Text, useToast, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import FaIcon from '../FaIcon'

const Toast = ({
  isActive,
  title,
  description,
  icon = null,
  placement = null,
  isIconSolid = false,
  callback = () => {},
  id,
}) => {
  const toast = useToast()
  const [isShowing, setIsShowing] = useState(false)
  const isMobile = useMobileBreakpoint()
  placement = placement ?? 'top'
  useEffect(() => {
    if (isActive && !isShowing && !toast.isActive(id)) {
      toast.show({
        placement: placement,
        id,
        render: () => {
          return !isMobile ? (
            <Box
              bg="muted.900"
              color="text.50"
              opacity="0.8"
              px="3"
              py="2"
              borderWidth="1"
              borderColor="muted.700"
              borderRadius="4px"
              mb="3"
              maxW="322px">
              <HStack>
                {icon && (
                  <Flex width="64px">
                    <FaIcon
                      name={icon}
                      size={48}
                      color="text.50"
                      props={{ my: 'auto' }}
                      isSolid={isIconSolid}
                    />
                  </Flex>
                )}
                <Flex flex={1}>
                  <Text color="muted.50" bold>
                    {title}
                  </Text>
                  <Text color="muted.50">{description}</Text>
                </Flex>
              </HStack>
            </Box>
          ) : (
            <Box safeAreaTop>
              <HStack
                justifyContent="center"
                bg="muted.900"
                opacity="0.9"
                mt="3"
                px="12px"
                py="7px"
                rounded="md"
                borderRadius="4px">
                {icon && (
                  <FaIcon
                    name={icon}
                    size={16}
                    color="text.50"
                    props={{ alignSelf: 'center', mr: '16px' }}
                    isSolid={isIconSolid}
                  />
                )}
                <VStack>
                  <Heading color="muted.50" size="xs">
                    {title}
                  </Heading>
                </VStack>
              </HStack>
            </Box>
          )
        },
      })
      setTimeout(() => {
        callback()
        setIsShowing(false)
      }, 5000)
    }
  }, [isActive])
}

export default Toast
