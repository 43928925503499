import { useNavigation } from '@react-navigation/native'
import {
  Button,
  Divider,
  HStack,
  IconButton,
  Spacer,
  StatusBar,
  Text,
  View,
  VStack,
} from 'native-base'
import { useCallback, useState } from 'react'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import MentalHealthMinutesForm from '../../components/onboarding/MentalHealthMinutesForm'
import FaIcon from '../../components/_shared/FaIcon'
import secureScreen from '../../hooks/authentication/secureScreen'
import FormLayout from '../../layouts/FormLayout'
import { useUserProfileContext } from '../../providers/UserProfileProvider'

interface HealthMinutesScreenProps {
  closeModal?: () => void
}

const HealthMinutesScreen = ({ closeModal }: HealthMinutesScreenProps) => {
  const { userProfile, selectMentalHealthMinutes } = useUserProfileContext()
  const [mentalMinutes, setMentalMinutes] = useState(
    userProfile.mentalHealthMinutes
  )
  const navigation = useNavigation()
  const isDesktop = useDesktopBreakpoint()

  const modifyMentalHealthMinutes = useCallback(() => {
    selectMentalHealthMinutes(mentalMinutes)
    if (isDesktop) {
      closeModal && closeModal()
    } else {
      navigation.goBack()
    }
  }, [mentalMinutes])

  return (
    <FormLayout
      minH={isDesktop ? '400px' : '700px'}
      padding={isDesktop ? '0' : '5'}>
      <StatusBar barStyle="dark-content" />
      <VStack bg="white" h="100%" borderRadius="10px">
        {isDesktop && (
          <VStack mb={4}>
            <HStack
              justifyContent="space-between"
              h="40px"
              alignItems="center"
              my={3}>
              <View />
              <Text color="primary.900" fontSize="18px" fontWeight="700">
                Set your daily goal
              </Text>
              <IconButton
                onPress={() => closeModal()}
                icon={<FaIcon name="close" color="muted.500" size={24} />}
              />
            </HStack>
            <Divider color="muted.300" />
          </VStack>
        )}
        <MentalHealthMinutesForm
          value={mentalMinutes}
          setValue={setMentalMinutes}
        />
        <Spacer />
        <Button m="5" onPress={modifyMentalHealthMinutes}>
          Done
        </Button>
      </VStack>
    </FormLayout>
  )
}

export default secureScreen(HealthMinutesScreen)
