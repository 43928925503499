import { useNavigation } from '@react-navigation/native'
import { Box, FlatList, HStack, IconButton, Text } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import FaIcon from '../../_shared/FaIcon'
import DateItem from './DateItem'

const DateSelectionMobile = ({
  flatListRef,
  calendar,
  selectedDateIndex,
  onDatePress,
  visible = true,
}) => {
  const isDesktop = useDesktopBreakpoint()
  const { width } = useWindowDimensions()
  const navigation = useNavigation()
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return (
    <Box pb={5}>
      <HStack mx="3" mb={!isDesktop ? 'auto' : '10'} alignItems="center">
        <IconButton
          icon={<FaIcon name="chevron-left" color="white" size={20} />}
          size="lg"
          borderRadius="full"
          w="48px"
          onPress={() => navigation.goBack()}
        />
        <Text color="white" fontSize="lg">
          Live groups
        </Text>
      </HStack>
      <FlatList
        width={width}
        height={'78px'}
        contentContainerStyle={{
          paddingRight: 27,
        }}
        ref={flatListRef}
        data={calendar}
        keyExtractor={(item) => item.date.getTime()}
        renderItem={({ item, index }) => {
          return (
            <DateItem
              isToday={item.date.toString() == today.toString()}
              isSelected={selectedDateIndex == index}
              date={item.date}
              onPress={() => {
                onDatePress(item.index, item.date)
              }}
            />
          )
        }}
        extraData={selectedDateIndex}
        horizontal
        showsHorizontalScrollIndicator={false}
        getItemLayout={(data, index) => ({
          length: 62,
          offset: 62 * index,
          index,
        })}
        style={{ opacity: visible ? 100 : 0 }}
      />
    </Box>
  )
}

export default DateSelectionMobile
