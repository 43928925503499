import { Skeleton, useColorModeValue } from 'native-base'

export const SkeletonLoaderSize = {
  ACTIVITY_CARD: { width: null, height: '88px' },
  CONTENT_CARD: { width: '168px', height: '128px' },
  GROUP_CARD: { width: '342px', height: '128px' },
}

interface SkeletonLoaderProps {
  size: { width; height }
  mb?: number
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ size, mb }) => {
  const skeletonBg = useColorModeValue('muted.300', 'muted.700')
  return (
    <Skeleton
      w={size.width}
      h={size.height}
      bg={skeletonBg}
      borderRadius="md"
      startColor="muted.100"
      endColor="muted.300"
      speed={2}
      mb={mb}
    />
  )
}
