import { Box, Button, Center, Flex, Text } from 'native-base'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import Caption from '../../_shared/text/Caption'
import TextLink from '../../_shared/text/TextLink'

const JoinRoom = ({ condition, handlePress }) => {
  const roomClient = useRoomClientContext()

  const userHasMic = roomClient.userHasMic
  return (
    <>
      <Flex>
        <Text fontWeight="bold" color="primary.900">
          Don't forget...
        </Text>
        {userHasMic ? (
          <>
            <Text color="primary.900">• Your voice is key</Text>
            <Text color="primary.900">• Sharing is encouraged</Text>
            <Text color="primary.900">• Come with an open mind</Text>
            <Text color="primary.900">• Invest in yourself</Text>
          </>
        ) : (
          <>
            <Text color="primary.900">• You’re “Chat Only”</Text>
            <Text color="primary.900">• Sharing is encouraged</Text>
            <Text color="primary.900">• Come with an open mind</Text>
            <Text color="primary.900">
              • Good work–you showed up for yourself today
            </Text>
          </>
        )}
      </Flex>
      <Center pt="84px">
        <Button
          colorScheme="primary"
          w="100%"
          my="3"
          isDisabled={!condition}
          onPress={handlePress}>
          Join Room
        </Button>
        <Box maxW="250">
          <Caption textAlign="center">
            By joining, you agree to Cabana by Even Health's{' '}
            <TextLink
              color="text.500"
              fontWeight="bold"
              href="https://mycabana.health/terms-of-service">
              Terms of Service
            </TextLink>{' '}
            and{' '}
            <TextLink
              fontWeight="bold"
              color="text.500"
              href="https://www.mycabana.health/privacy-policy">
              Privacy Policy
            </TextLink>
            .
          </Caption>
        </Box>
      </Center>
    </>
  )
}

export default JoinRoom
