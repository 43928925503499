import { Box, FlatList } from 'native-base'
import GroupCard from './GroupCard'

const CardRow = ({
  data,
  width,
  onPressLiveGroup,
  showsHorizontalScrollIndicator = false,
}) => {
  return (
    <FlatList
      data={data}
      renderItem={({ item, index }) => {
        return (
          <Box
            pl={index === 0 ? '16px' : undefined}
            pr="16px"
            mb={showsHorizontalScrollIndicator ? '2' : undefined}>
            <GroupCard
              activity={item}
              width={width}
              onPress={() => onPressLiveGroup(item)}
            />
          </Box>
        )
      }}
      keyExtractor={(item, index) => index.toString()}
      horizontal
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
      pt="4"
      pr="4"
      mx="-4"
    />
  )
}

export default CardRow
