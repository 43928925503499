import { useNavigation } from '@react-navigation/native'
import { Box, Button, Center, Text } from 'native-base'
import WaveLayout from '../../../layouts/WaveLayout'
import RoomHeading from '../RoomHeading'

const RoomCompleted = ({}) => {
  const navigation = useNavigation()

  const goHome = () => {
    navigation.replace('Home')
  }

  return (
    <WaveLayout>
      <Box position="absolute" w="100%" mt="2">
        <RoomHeading hasWelcome={false} />
      </Box>
      <Center my="auto">
        <Text textAlign="center" fontSize="3xl">
          Oops!
          {'\n'}
          {/* TODO Add line separator */}
          This live group has ended
        </Text>
        <Button colorScheme="primary" onPress={goHome} mt="10">
          Return to home
        </Button>
      </Center>
    </WaveLayout>
  )
}

export default RoomCompleted
