import { useNavigation } from '@react-navigation/native'
import { Button, Spacer, StatusBar, VStack } from 'native-base'
import { useCallback, useEffect, useState } from 'react'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import ProfessionalRoleForm from '../../components/onboarding/ProfessionalRoleForm'
import secureScreen from '../../hooks/authentication/secureScreen'
import useTabNavigation from '../../hooks/useTabNavigation'
import FormLayout from '../../layouts/FormLayout'
import { useUserProfileContext } from '../../providers/UserProfileProvider'

const SelectRoleScreen = () => {
  const navigation = useNavigation()
  const { navigate } = useTabNavigation()
  const isMobile = useMobileBreakpoint()
  const { userProfile, selectProfessionalRole } = useUserProfileContext()

  const [professionalRoles, setProfessionalRoles] = useState(
    userProfile?.professionalRoles ?? []
  )

  useEffect(() => {
    if (userProfile?.professionalRoles) {
      setProfessionalRoles(userProfile.professionalRoles)
    }
  }, [userProfile?.professionalRoles])

  const onDonePressed = useCallback(async () => {
    await selectProfessionalRole(professionalRoles)
    if (isMobile) navigation.goBack()
    else navigate('Profile', { subscreen: 'account' })
  }, [professionalRoles])

  return (
    <FormLayout>
      <StatusBar barStyle="dark-content" />
      <VStack bg="white" h="100%">
        <ProfessionalRoleForm
          value={professionalRoles}
          setValue={setProfessionalRoles}
        />
        <Spacer />
        <Button m="0" onPress={onDonePressed}>
          Done
        </Button>
      </VStack>
    </FormLayout>
  )
}

export default secureScreen(SelectRoleScreen)
